import React, { useState, useEffect, useRef } from "react";
import "../../App_one.scss";
import styled from "styled-components";
import "./index.scss";
import * as R from "ramda";
import FilterTicketListPopup from "./FilterTicketListPopup";
import moment from "moment";
import { useStateValue } from "../../redux/StateProvider";
import { DashBoardServices } from "../../services/DasboardServices/DashboardService";
import Lottie from "react-lottie";
import loading from "../../assets/Lotties/activitycentreloading.json";
import { PaginationMap } from "../../features/eventList/types/EventListTypes";

const Tab = styled.button`
  font-size: 20px;
  padding: 10px 60px;
  cursor: pointer;
  background: #eff2ff;
  border: 0;
  outline: 0;
  border-bottom: 2px solid transparent;
  ${({ active }) =>
    active &&
    `
    border-bottom: 3px solid #426BFA;
    border-radius:50px;
    opacity: 1;
    background:white !important;
  `}
`;
const ButtonGroup = styled.div`
  display: flex;
  text-align: left;
`;

type PaginationDataType = {
  alerts: PaginationMap;
  tickets: PaginationMap;
  watchList: PaginationMap;
};

function TabGroup({ callBack, data }) {

  const [ {lastSelectedActivityCenterTab}, dispatch] = useStateValue();
  const [tabDataSet, setTabDataSet] = useState([...data]);
  const [paginationData, setPaginationData] = useState<PaginationDataType>({
    watchList: data[0].pagination,
    alerts: data[1].pagination,
    tickets: data[2].pagination,
  });

  const active = useRef(lastSelectedActivityCenterTab || "WatchList");
  const [activityAlertData, setActivityAlertData] = useState(
    data.find((d) => d.name === active.current)?.value || data[0].value
  );
  const [{ dateRange }] = useStateValue();
  const isRequestOngoing = useRef(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const findList = tabDataSet.find((ele) => ele.name === active.current);
    if (findList) {
      setActivityAlertData(findList.value);
    }
  }, [lastSelectedActivityCenterTab, tabDataSet]);


  function handleActive(type) {
    active.current = type;
    dispatch({
      type: "SELECTED_ACTIVITY_TAB",
      lastSelectedActivityCenterTab: type,
    });
    if (!R.isEmpty(data)) {
      let findList = tabDataSet.find((ele) => ele.name === type);
      setActivityAlertData(findList?.value);
    }
  }

  const getDataSet = async (index) => {
    if (isRequestOngoing.current) {
      return;
    }
    try {
      isRequestOngoing.current = true;
      setIsLoading(true);

      const filterByValue = active.current === "WatchList" ? "WATCH" : active.current === "Alerts" ? "ALERT" : "TICKET";
      const fromDate = moment(dateRange[0]).startOf("day").toISOString();
      const endDate = moment(dateRange[1]).endOf("day").toISOString();
      const DBservice = new DashBoardServices();

      const fetchData = await DBservice.getFilterActivityCenter(
        moment(fromDate).valueOf(),
        index,
        moment(endDate).valueOf(),
        "",
        filterByValue,
      );

      if (fetchData === "error") {
      } else if (typeof fetchData === "number") {
      } else if (R.isEmpty(fetchData)) {
      } else {
        let newJsonSet = [];
        let newPaginationData = {};

        if (active.current === "WatchList") {
          newJsonSet = [
            {
              name: "WatchList",
              id: 1,
              count: fetchData?.watchList.count,
              value: {
                data: [
                  ...activityAlertData.data,
                  ...fetchData?.watchList.data,
                ],
              },
            },
            tabDataSet[1],
            tabDataSet[2],
          ];
          newPaginationData = {
            ...paginationData,
            watchList: fetchData?.watchList?.pagination
          };
        } else if (active.current === "Alerts") {
          newJsonSet = [
            tabDataSet[0],
            {
              name: "Alerts",
              id: 2,
              count: fetchData?.alerts.count,
              value: {
                data: [
                  ...activityAlertData.data,
                  ...fetchData?.alerts.data,
                ],
              },
            },
            tabDataSet[2],
          ];
          newPaginationData = {
            ...paginationData,
            alerts: fetchData?.alerts?.pagination
          };
        } else {
          newJsonSet = [
            tabDataSet[0],
            tabDataSet[1],
            {
              name: "Tickets",
              id: 3,
              count: fetchData?.tickets.count,
              value: {
                data: [
                  ...activityAlertData.data,
                  ...fetchData?.tickets.data,
                ],
              },
            },
          ];
          newPaginationData = {
            ...paginationData,
            tickets: fetchData?.tickets?.pagination
          };
        }

        setTabDataSet(newJsonSet);
        setPaginationData(newPaginationData);
        setActivityAlertData(newJsonSet.find((ele) => ele.name === active.current)?.value);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
      isRequestOngoing.current = false;
    }
  };


  const handleNextClick = () => {
    if (
      active.current === "WatchList" &&
      paginationData.watchList.nextPage &&
      paginationData.watchList.nextPage !== paginationData.watchList.currentPage
    ) {
      getDataSet(paginationData.watchList.nextPage);
    } else if (
      active.current === "Alerts" &&
      paginationData.alerts.nextPage &&
      paginationData.alerts.nextPage !== paginationData.alerts.currentPage
    ) {
      getDataSet(paginationData.alerts.nextPage);
    } else if (
      active.current === "Tickets" &&
      paginationData.tickets.nextPage &&
      paginationData.tickets.nextPage !== paginationData.tickets.currentPage
    ) {
      getDataSet(paginationData.tickets.nextPage);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = containerRef.current;
      const scrollOffset = 800;

      if (
        scrollContainer &&
        scrollContainer.scrollHeight - scrollContainer.scrollTop <=
          scrollContainer.clientHeight + scrollOffset
      ) {
        if (!isLoading) {
          handleNextClick();
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      handleScroll();
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isLoading, activityAlertData]);


  const defaulterrorLottie = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(()=>{ 
    if(!isLoading && typeof lastSelectedActivityCenterTab == 'function'){
      let Tab = 'WatchList' 
      handleActive(Tab);
    }
  },[isLoading])


  return (
    <div className="tab-Class">
      <div className="tabClass-button">
        <ButtonGroup className="tabClass">
          {tabDataSet.map((ele, index) => (
            <Tab
              key={index}
              style={{backgroundColor: active.current === ele.name ? "#FFFFFF" : "#EFF2FF"}}
              active={active.current === ele.name}
              onClick={() => handleActive(ele.name)}
              className={`${active.current === ele.name ? "TabBtn active" : "TabBtn"}`}
            >
              <h5>{ele.count}</h5>
              <h6>{ele.name}</h6>
            </Tab>
          ))}
        </ButtonGroup>
      </div>

      <h3 className="alertsStyle">
        you are viewing {activityAlertData?.data?.length} {active.current}
      </h3>

      <div
        className="tabBody"
        ref={containerRef}
        style={{ overflow: "scroll", height: "70vh" }}
      >
        {activityAlertData ? (
          <>
            <FilterTicketListPopup
              data={activityAlertData.data}
              type={active.current}
              callBack={callBack}
            />
            {isLoading && (
              <div className="lottieLoading">
                <Lottie
                  style={{ marginLeft: "0px", marginRight: "0px" }}
                  options={defaulterrorLottie}
                  height={50}
                  width={50}
                />
              </div>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
}

export default TabGroup;

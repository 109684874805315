import IconClose from "../../assets/icons/IconClose.svg";
import IconWarning from "../../assets/icons/IconWarning.svg";
import "./DeleteOrganisationModal.scss";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

function DeleteOrganisationModal({
  name,
  deleteOrgPopup,
  setDeleteOrgPopup,
}: any) {
  let tokenId = localStorage.getItem("@tokenID");

  const myHeaders = new Headers();
  // @ts-ignore
  myHeaders.append("token", tokenId);

  function deleteOrg() {
    fetch(
      "https://dev-tnt.app.aone.ai/api/1.0/partyGroup/delete?partyId=10118",
      {
        method: "GET",
        headers: myHeaders,
      }
    )
      .then((response) => {
        const statusCode = response.status;
        return response.text().then((result) => ({ statusCode, result }));
      })
      .then(({ statusCode, result }) => {
        if (statusCode === 200) {
          toast.success("Organization deleted successfully!");
          console.log(result);
        } else {
          toast.error("Request Failed.");
        }
      })
      .catch((error) => {
        toast.error("Request Failed.");
        console.log("error", error);
      })
      .finally(() => {
        setDeleteOrgPopup(false);
      });
  }

  return (
    <Modal
      show={deleteOrgPopup}
      onHide={() => setDeleteOrgPopup(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      style={{ zIndex: 1055 }}
    >
      <Modal.Header className="deleteFacilityHeaderCont noBorder">
        <h4 className="deleteFacilityHeader deleteOrgHeader">Delete {name}?</h4>
        <div className="closeIconDiv">
          <img
            className="deleteFacilityCloseIcon"
            src={IconClose}
            alt="icon"
            onClick={() => setDeleteOrgPopup(false)}
          />
        </div>
      </Modal.Header>
      <Modal.Body className="deleteFacilityBodyCont ">
        <div className="deleteFacilityBody deleteOrgBody">
          <div className="deleteFacilityTxtCont">
            <p>
              This action will permanently delete {name} and all its
              associations. Do you want to proceed?
            </p>
          </div>
          <div className="deleteFacilityWarningCont">
            <div className="warningImgTxtCont">
              <img src={IconWarning} alt="icon" className="warningIcon" />
              <p>Warning</p>
            </div>
            <p className="warningTxt">
              Any other asset associations and facilities present in this
              organisation will also be deleted.
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="noBorder deleteOrgFooter">
        <button
          className="shiftAssetsBtn"
          onClick={() => {
            setDeleteOrgPopup(false);
          }}
        >
          Cancel
        </button>
        <button
          className="deleteButton"
          onClick={() => {
            // setDeleteOrgPopup(false)
            deleteOrg();
          }}
        >
          Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteOrganisationModal;

import React from "react";
import "./CustomToast.scss";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Report_Que from "../../../assets/icons/Email_NotLinked.svg";
import DownloadErrorQue from "../../../assets/icons/Download_Que.svg";
import reportSucc from "../../../assets/icons/Report_Que.svg";
import warningTost from "../../../assets/icons/warningTost.svg";
import CloseButton from "../../../assets/icons/CloseButton.svg";
import Download_Que from "../../../assets/icons/Download_Que.svg";
import { NavLink } from "react-router-dom";
import NotificationIcon from '../../../assets/icons/icon-notifications-off.svg';

const CustomToast = ({ message, onClose }: any) => {
  return (
    <div className="customToast-Div">
      {message === "Birt Email initiated successfully" ? (
        <div className="custom-toast" style={{ backgroundColor: "#FFF9C5" }}>
        <div className="toast-content">
          <div className="image_Div">
            <img className="email-icon" src={reportSucc} alt="image" loading="lazy"/>
          </div>
          <div className="middle_Div">
            <h6>Report Queued</h6>
            <p>Your report has been queued. You will receive an email when it is completed.</p>
          </div>
          <div className="end_Div">
          <FontAwesomeIcon
            className="toast-close"
            icon={faXmark}
            onClick={onClose}
          />
          </div>
        </div>
      </div>
      ) : message === "Report content not available" ? (
              <div className="custom-toast" style={{  backgroundColor: "#FCA9A4" }}>
                <div className="toast-content">
                  <div className="image_Div">
                    <img className="email-icon" src={Report_Que} alt="image" loading="lazy"/>
                  </div>
                  <div className="middle_Div">
                    <h6>Report not found</h6>
                    <p>Content not available.</p>
                  </div>
                  <div className="end_Div">
                    <FontAwesomeIcon
                        className="toast-close"
                        icon={faXmark}
                        onClick={onClose}
                    />
                  </div>
                </div>
              </div>
          ) :
         message === "NOTIFICATION_ACCESS_DENIED" || message === "NOTIFICATION_ERROR"  ? (
        <div className="custom-toast" style={{  backgroundColor: "#FCA9A4" }}>
      <div className="toast-content">
        <div className="image_Div">
          <img className="email-icon" src={NotificationIcon} alt="image" loading="lazy"/>
        </div>
        <div className="middle_Div">
          <h6>{message === "NOTIFICATION_ACCESS_DENIED" ? 'Notification Access Denied' : 'Notification Error'}</h6>
          <p>{message === "NOTIFICATION_ACCESS_DENIED" ? 'You have denied permission for notifications. To enable notifications, adjust your settings in the browser.' :
          'An error occurred while enabling notifications. Please refresh the page or log in again.'}</p>
        </div>
        <div className="end_Div">
          <FontAwesomeIcon
              className="toast-close"
              icon={faXmark}
              onClick={onClose}
          />
        </div>
      </div>
    </div>
  ) : message === "error" ? (
        <div className="custom-toast" style={{  backgroundColor: "#FCA9A4" }}>
          <div className="toast-content">
            <div className="image_Div">
              <img className="email-icon" src={Report_Que} alt="image" loading="lazy"/>
            </div>
            <div className="middle_Div">
               <h6>Email not linked</h6>
               <p>No Email is linked to the current profile. Please update the profile and try again.</p>
            </div>
            <div className="end_Div">
            <FontAwesomeIcon
              className="toast-close"
              icon={faXmark}
              onClick={onClose}
            />
            </div>
          </div>
        </div>
      ) : message === "Report download initiated successfully" ?
       (
        <div className="custom-toast" style={{ backgroundColor: "#FFF9C5" }}>
          <div className="toast-content">
            <div className="image_Div">
              <img className="email-icon" src={Download_Que} alt="image" loading="lazy"/>
            </div>
            <div className="middle_Div">
              <h6>Download Queued</h6>
              <p className="message">Your report download has been queued. You can view the progress in the downloads page</p>
              <NavLink to={"/download-list"}
                style={{ backgroundColor: "#DFE6FF" }}>
                <p className="anchor">Go to Downloads</p>
              </NavLink>
            </div>
            <div className="end_Div">
            <FontAwesomeIcon
              className="toast-close"
              icon={faXmark}
              onClick={onClose}
            />
            </div>
          </div>
        </div>
      ) : <div className="custom-toast" style={{ backgroundColor: "#FFF9C5" }}>
      <div className="toast-content">
        <img className="email-icon" src={warningTost} />
        <div>
          <h6>Download Failed</h6>
          <p>{message}</p>
          <p>Unable to download Report</p>
        </div>
      </div>
    </div>
      }

      {/* <FontAwesomeIcon
        className="toast-close"
        icon={faXmark}
        onClick={onClose}
      /> */}
      {/* <div  className="toast-close" onClick={onClose}>
        <img src={CloseButton} />
      </div> */}
    </div>
  );
};

export default CustomToast;

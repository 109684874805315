import Modal from "react-bootstrap/Modal";
import IconClose from "../../../assets/icons/IconClose.svg";
import IconWarning from "../../../assets/icons/IconWarning.svg";
import DeleteFacilityIcon from "../../../assets/icons/DeleteFacilityIcon.svg";
import { useState } from "react";
import "./DeletePopUpScreen.scss";

const DeletePopUpScreen = ({
  closePopupCall,
  retainAssets,
  DeactivateAsset,
}: any) => {
  const [showDeleteFacilities, setShowDeleteFacilities] = useState(false);

  return (
    <Modal
      show={true}
      onHide={closePopupCall}
      backdrop="static"
      keyboard={false}
      className="bulkDeletePopModal"
      centered
    >
      <Modal.Header className="bulkDeleteFacilityHeader">
        <h4 className="bulkDeleteHeaderTxt">Delete Facilities?</h4>
        <div className="closeIconDiv" onClick={closePopupCall}>
          <img src={IconClose} alt="icon"/>
        </div>
      </Modal.Header>
      <Modal.Body className="bulkDeleteBodyCont">
        <ul className="bulkDeleteBody">
          <li>
            This action will permanently delete selected facilities and all the
            assets associated with it. Please choose an appropriate option from
            the dropdown to handle the assets.
          </li>
        </ul>
      </Modal.Body>
      <Modal.Footer className="bulkDeleteFacilityFooterCont">
        <button
          className="deleteFacilitiesBtn"
          onClick={() => setShowDeleteFacilities(true)}
        >
          Delete Facilities
          <img src={DeleteFacilityIcon} alt="icon" />
        </button>
        <button className="cancelButton">Cancel</button>
        {showDeleteFacilities && (
          <div
            className="deleteFacilityFeaturesCont"
            onMouseLeave={() => setShowDeleteFacilities(false)}
          >
            <div className="deleteFacilityFeatures" onClick={retainAssets}>
              <p className="deleteFacilityFeaturesHeader">Retain Assets</p>
              <p className="deleteFacilityFeatureTxt">
                Assets will be retained in the same facility while the facility
                is deleted
              </p>
            </div>

            <div className="deleteFacilityFeatures" onClick={DeactivateAsset}>
              <p className="deleteFacilityFeaturesHeader">Deactivate Assets</p>
              <p className="deleteFacilityFeatureTxt">
                Assets will be deactivated while the facility is deleted
              </p>
            </div>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DeletePopUpScreen;

import React, { useEffect, useState } from "react";
import "./ViewReport.scss";
import searchIcon from "./../../assets/icons/ic_settings_search.svg";
import { DashBoardServices } from "../../services/DasboardServices/DashboardService";
import { ReportCard } from "./ReportCard";
import DisplayError from "../DisplayError/DisplayError";
import LoaderStyle from "../LoaderStyle/LoaderStyle";
import CustomToast from "../ModelPopupcomp/CustomToast/CustomToast";
import { ToastContainer, toast } from "react-toastify";
import { useStateValue } from "../../redux/StateProvider";
import {ReportCardData} from "./DataTypes";
import {SearchListType} from "../../features/assetList/types";
import {API_BASE_URL} from "../../Constants/constants";


function ViewReport() {
  const [responseList, setResponseList] = useState<any>([]);
  const [apiLoader, setApiLoader] = useState(false);
  const [searchInputForReports, setSearchInputForReports] = useState("");
  const [tempReportList, setTempRepoList] = useState<any>([]);
  const [showToast, setShowToast] = useState(false);
  const [tostData, setTostData] = useState("");
  const [toastState, setToastState] = useState(false);
  const [{}, dispatch] = useStateValue();
  const service = new DashBoardServices();


  const getReportResponse = (message:string) => {
    setTostData(message);
    if (message === "Report download initiated successfully"){
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 2500);
    }else {
      setToastState(true);
      setTimeout(() => {
        setToastState(false);
      }, 2500);
      toast.success(message, {
        position: "bottom-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }

  const getReportLists = async () => {
    let listOfReports:any = await service.getListofReports();
    if (typeof listOfReports === "object") {
      let list = await listOfReports?.reportList?.reports;
      setTempRepoList(list);
      setResponseList(list);
      setApiLoader(false);
    } else {
      setResponseList(listOfReports);
      setApiLoader(false);
    }
  };

  const validateSearchInput = (input:string) => {
    if (/^[A-Za-z\s]+$/.test(input)) {
      setSearchInputForReports(input);
    }
    else if (input.startsWith(" ")) {
      setSearchInputForReports(input.trim());
    }else {
      setSearchInputForReports("");
    }
  } 

  const [activeCard, setActiveCard] = useState<number>();
  const [initialAssetList, setInitialAssetList] = useState<{
    value:string,
    label: string,
    key: string,}[]>([]);

  const getAssetListData = async (searchByValue: SearchListType[]) => {

    const body = {
      viewIndex: 1,
      viewSize: 1000,
      isLowDataMode: true,
      withLiveMeters: false,
      withCardMeters: false,
      isMonitoring: false,
      withAssetAttributes: false,
      searchBy: searchByValue,
    }

    const url = `${API_BASE_URL}assets/assetList`;

    try {
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const response = await resp.json();
      const result: {
        value:string,
        label: string,
        key: string,}[] = response?.assets?.map((item) => ({value: item?.assetId,
        label: item?.assetName,
        key: item?.assetId,}));

      setInitialAssetList(result);
    } catch (error) {
    }
  }

  useEffect(() => {
    getAssetListData([]);
    dispatch({ type: "LOCATION_PATH_UPDATE", locationPath: window.location.pathname });
    setApiLoader(true);
    getReportLists();
  }, []);

  useEffect(() => {
    if(searchInputForReports === ""){
      setTempRepoList(responseList);
    }else{
      let tempList = [...tempReportList];
      let filterList = tempList.filter((el:any) => {
        if(el.contentName.toLowerCase().includes(searchInputForReports.toLowerCase())) return el
      });
      setTempRepoList(filterList);
    }
  }, [searchInputForReports])


  return (
    <div className="mainContainerDiv">
      {apiLoader ? (
        <div className="centerDivCss">
          <LoaderStyle />
        </div>
      ) : responseList === "error" ? (
        <div className="centerDivCss">
          <DisplayError type={"err_1"} />
        </div>
      ) : typeof responseList === "number" ? (
        <div className="centerDivCss">
          <DisplayError type={"err-500/404"} />
        </div>
      ) : responseList.length === 0 ? (
        <div className="centerDivCss">
          <DisplayError type={"err-empty"} />
        </div>
      ) : (
        <>
          <div className="viewReportHeader">
            <p>Reports</p>
            <div className="searchView">
              <input placeholder="Search reports" value={searchInputForReports} onChange={(e) => validateSearchInput(e.target.value.trim())} type="text" />
              <img className="searchImg" src={searchIcon} />
            </div>
          </div>
          {tempReportList.length === 0 ? (
            <div className="centerDivCss">
              <DisplayError type={"err-empty"} />
            </div>
          ) : (
            tempReportList.map((obj: object, index: number) => (
              <ReportCard
                  index={index}
                  activeState={() => setActiveCard(index)}
                  active={activeCard === index}
                  callBackRepoStatus={getReportResponse}
                  key={index}
                  reportCardData={obj}
                  isAssetAvailable={obj?.attributeProperties.find((item) => ["ASSET", "ASSET_MS"].includes(item.attributeName) && item.attributeValue === "TRUE") !== undefined}
                  initialAssetListData={initialAssetList}
              />
            ))
          )}
        </>
      )}

      { showToast ?
        <div className="pagination_value">
          <CustomToast message={tostData} onClose={() => setShowToast(false)} />
        </div> : null 
      }

      {
        toastState ? 
        <div className="pagination_value">
          <ToastContainer position="bottom-right" />
        </div> : null
      }

    </div>
  );
}

export default ViewReport;

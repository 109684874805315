import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import IconClose from "../../../assets/icons/IconClose.svg";
import "./FacilityAddressPopupScreen.scss";
import * as R from "ramda";
import { FieldValidatorUtil } from "../../../Utils/fieldValidation/FieldValidatorUtil";
import { ToastContainer, toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import FormValidator from "../../../Utils/FormValidations/FormValidations";

const FacilityAddressPopUpScreen = ({
  propsData,
  closeFacilityAddressPopUpScreen,
  type,
  updataData,
  countryListData,
  contactPurposeListData,
}: any) => {
  //Adress pop-up screen state
  //contact purpose static data

  const [postalCodeError, setPostalCodeError] = useState<null | string>(null);

  const [invalidArr, setInvalidArr] = useState<string[]>([]);
  const validate = new FieldValidatorUtil();

  const [addressPopupData, setAddressPopupData] = useState({
    contactPurposeList: [...contactPurposeListData],
    countryListData: [...countryListData],
    stateList: [],
  });

  // console.log(addressPopupData, "@stateListResp")

  //address obj
  const [tempAddressObject, setTempAddressObject] = useState(
    Object.assign({}, propsData)
  );

  const [activeAddressPurposeList, setActiveAddressPurposeList] = useState([]);
  const [toNameError, setToNameError] = useState("");
  const [attNameError, setAttNameError] = useState("");
  const [addressLine1Error, setAddressLine1Error] = useState("");
  const [addressLine2Error, setAddressLine2Error] = useState("");
  const [cityError, setCityError] = useState("");

  const updatePhoneNumberPurposeListUpdate = (dataList: any) => {
    let out = "[";
    let n = dataList.length;
    for (let i = 0; i < n; i++) {
      let item = dataList[i];
      out += item;
      if (i < n - 1) {
        out += ", ";
      }
    }
    out += "]";
    setTempAddressObject({
      ...tempAddressObject,
      addressPurpose: out,
    });
  };

  const dataCheck = (e: any) => {
    let tempList = [...activeAddressPurposeList];
    if (tempList.includes(e)) {
      let index = tempList.indexOf(e); // get the index of the element to remove
      tempList.splice(index, 1); // remove the element at the specified index
    } else {
      tempList.push(e);
    }
    updatePhoneNumberPurposeListUpdate(tempList);
    setActiveAddressPurposeList(tempList);
  };

  const onChangeAddress = (e: any, key: any) => {
    let tempObj = { ...tempAddressObject };
    tempObj[key] = e.target.value;
    console.log("$$$$:", tempObj[key], key);
    setTempAddressObject(tempObj);
  };

  const updateAddress = () => {
    checkName("validateToName", tempAddressObject.toName);
    checkName("validateAddressLine1", tempAddressObject.address1);
    checkName("validateCity", tempAddressObject.city);
    checkName("validateAttName", tempAddressObject.attnName);
    checkName("validateAddressLine2", tempAddressObject.address2);
    checkName("validateState", tempAddressObject.stateProvinceGeoId);
    checkName("validateCountry", tempAddressObject.countryGeoId);

    if (invalidArr.length !== 0) {
      toast("Please Fill Mandatory Fields");
      return;
    }

    if (tempAddressObject.postalCode == "") {
      setPostalCodeError("This field is required*");
      toast("Please Fill Mandatory Fields");
      return;
    }

    console.log("AAAAA::success");

    updataData({ ...tempAddressObject });
    closeFacilityAddressPopUpScreen();
  };

  const updateStateList = (e: any) => {
    let tempList;
    for (let item of addressPopupData.countryListData) {
      if (item.geoId === e.target.value) {
        tempList = [...item?.states];
      }
    }
    setAddressPopupData({
      ...addressPopupData,
      stateList: [...tempList],
    });
  };

  useEffect(() => {
    if (type !== "add") {
      let tempList;
      for (let item of addressPopupData.countryListData) {
        if (item.geoId === tempAddressObject.countryGeoId) {
          tempList = [...item?.states];
        }
      }
      setAddressPopupData({
        ...addressPopupData,
        stateList: [...tempList],
      });
    }
  }, []);

  const checkName = (name: string, val: string) => {
    const validArr = [
      "validateToName",
      "validateAttName",
      "validateAddressLine1",
      "validateAddressLine2",
      "validateCity",
      "validatePostalCode",
      "validateState",
      "validateCountry",
    ];

    if (!validArr.includes(name)) {
      return;
    }

    let functionName = name === "validateToName" ? "validateToName" : name;
    functionName = name === "validateAttName" ? "validateAttName" : name;

    let isValid = false;

    if (functionName === "validateToName") {
      const result = validate.validateToAndAttName(val);
      isValid = result.isValid;
      setToNameError(result.message);
    } else if (name === "validateAttName") {
      const result = validate.validateToAndAttName(val);
      isValid = result.isValid;
      setAttNameError(result.message);
    } else if (name === "validateAddressLine1") {
      const result = validate.validateAddressLine(val);
      isValid = result.isValid;
      setAddressLine1Error(result.message);
    } else if (name === "validateAddressLine2") {
      const result = validate.validateAddressLine(val);
      isValid = result.isValid;
      setAddressLine2Error(result.message);
    } else if (name === "validateCity") {
      const result = validate.validateCity(val);
      isValid = result.isValid;
      setCityError(result.message);
    } else {
      isValid = validate.checkNullData(val);
    }

    setInvalidArr((prevInvalidArr) => {
      if (isValid) {
        return prevInvalidArr.filter((obj) => obj !== name);
      } else {
        if (!invalidArr.includes(name)) {
          return [...prevInvalidArr, name];
        } else {
          return [...prevInvalidArr];
        }
      }
    });
  };

  return (
    <>
      {
        //ADD ADRESS SCREEN POP UP
        <Modal
          show={true}
          backdrop="static"
          keyboard={false}
          onHide={closeFacilityAddressPopUpScreen}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="modal-90w"
          className="createdAndEditAddressPopup"
        >
          <Modal.Header className="createFacilityAddressHeaderCont">
            <h1 className="addAddressTxt">
              {type === "add" ? "Add" : "Edit"} Address
            </h1>
            <div
              className="closeIconDiv"
              onClick={closeFacilityAddressPopUpScreen}
            >
              <img src={IconClose} alt="icon" />
            </div>
          </Modal.Header>
          <Modal.Body className="addressPopModalCont">
            <div className="addressInputsCont">
              <div className="inputOneCont">
                <div className="popInputCont">
                  <label className="lableTxt">To Name *</label>
                  <div className="inputPopCont">
                    <input
                      className="popInput"
                      type="text"
                      value={tempAddressObject.toName}
                      onChange={(e) => onChangeAddress(e, "toName")}
                      style={{
                        border: `${
                          invalidArr.includes("validateToName")
                            ? "1px solid red"
                            : ""
                        }`,
                      }}
                      onBlur={() =>
                        checkName("validateToName", tempAddressObject.toName)
                      }
                      data-tip="To Name can only contain letters (A-Z, a-z) and following special characters: ' and whitespace"
                      data-for="name-tooltip"
                    />
                    <ReactTooltip
                      id="name-tooltip"
                      effect="solid"
                      place="bottom"
                      className="tooltip"
                    />

                    {invalidArr.includes("validateToName") && (
                      <label style={{ color: "red", fontSize: "10px" }}>
                        {new FormValidator().validateValue(
                          tempAddressObject.toName,
                          /^[A-Za-z ']+$/,
                          100
                        )}
                      </label>
                    )}
                  </div>
                </div>

                <div className="popInputCont">
                  <label className="lableTxt">Address Line 1 *</label>
                  <div className="inputPopCont">
                    <input
                      className="popInput"
                      type="text"
                      value={tempAddressObject.address1}
                      onChange={(e) => onChangeAddress(e, "address1")}
                      style={{
                        border: `${
                          invalidArr.includes("validateAddressLine1")
                            ? "1px solid red"
                            : ""
                        }`,
                      }}
                      onBlur={() =>
                        checkName(
                          "validateAddressLine1",
                          tempAddressObject.address1
                        )
                      }
                      data-tip="Address Line 1 can only contain letters (A-Z, a-z), numbers (0-9) and following special characters: #, ., ', -, commas and whitespace"
                      data-for="name-tooltip"
                    />
                    <ReactTooltip
                      id="name-tooltip"
                      effect="solid"
                      place="bottom"
                      className="tooltip"
                    />

                    {invalidArr.includes("validateAddressLine1") && (
                      <label style={{ color: "red", fontSize: "10px" }}>
                        {new FormValidator().validateValue(
                          tempAddressObject.address1,
                          /^[\w\s,#-]+$/,
                          255
                        )}
                      </label>
                    )}
                  </div>
                </div>

                <div className="popInputCont">
                  <label className="lableTxt">City *</label>
                  <div className="inputPopCont">
                    <input
                      className="popInput"
                      type="text"
                      onChange={(e) => {
                        onChangeAddress(e, "city");
                      }}
                      style={{
                        border: `${
                          invalidArr.includes("validateCity")
                            ? "1px solid red"
                            : ""
                        }`,
                      }}
                      value={tempAddressObject.city}
                      onBlur={() =>
                        checkName("validateCity", tempAddressObject.city)
                      }
                      data-tip="City can only contain letters (A-Z, a-z) and following special characters: ' and whitespace"
                      data-for="name-tooltip"
                    />
                    {invalidArr.includes("validateCity") && (
                      <label style={{ color: "red", fontSize: "10px" }}>
                        {new FormValidator().validateValue(
                          tempAddressObject.city,
                          /^[A-Za-z ']+$/,
                          100
                        )}
                      </label>
                    )}
                  </div>
                </div>

                <div className="popInputCont">
                  <label className="lableTxt">Postal Code *</label>
                  <div className="inputPopCont">
                    <input
                      type="text"
                      onChange={(e) => {
                        if (
                          FieldValidatorUtil.isNumeric(e.target.value, 6) ||
                          e.target.value === ""
                        ) {
                          onChangeAddress(e, "postalCode");
                          const error = FieldValidatorUtil.postalCode(
                            e.target.value
                          );
                          console.log("Validation:Line164:", error);
                          setPostalCodeError(error);
                        }
                      }}
                      value={tempAddressObject.postalCode}
                      className={
                        postalCodeError
                          ? "form-control is-invalid numberInput popInput"
                          : "numberInput popInput"
                      }
                    />
                    {postalCodeError && (
                      <label className="formErrorPostalCode">
                        {postalCodeError}
                      </label>
                    )}
                  </div>
                </div>
              </div>

              <div className="inputOneCont">
                <div className="popInputCont">
                  <label className="lableTxt">Attn Name *</label>
                  <div className="inputPopCont">
                    <input
                      className="popInput"
                      type="text"
                      value={tempAddressObject.attnName}
                      onChange={(e) => onChangeAddress(e, "attnName")}
                      style={{
                        border: `${
                          invalidArr.includes("validateAttName")
                            ? "1px solid red"
                            : ""
                        }`,
                      }}
                      onBlur={() =>
                        checkName("validateAttName", tempAddressObject.attnName)
                      }
                      data-tip="Attn Name can only contain letters (A-Z, a-z) and following special characters: ' and whitespace"
                      data-for="name-tooltip"
                    />
                    <ReactTooltip
                      id="name-tooltip"
                      effect="solid"
                      place="bottom"
                      className="tooltip"
                    />
                    {invalidArr.includes("validateAttName") && (
                      <label style={{ color: "red", fontSize: "10px" }}>
                        {new FormValidator().validateValue(
                          tempAddressObject.attnName,
                          /^[A-Za-z ']+$/,
                          100
                        )}
                      </label>
                    )}
                  </div>
                </div>

                <div className="popInputCont">
                  <label className="lableTxt">Address Line 2 *</label>
                  <div className="inputPopCont">
                    <input
                      type="text"
                      className="popInput"
                      onChange={(e) => onChangeAddress(e, "address2")}
                      value={tempAddressObject.address2}
                      style={{
                        border: `${
                          invalidArr.includes("validateAddressLine2")
                            ? "1px solid red"
                            : ""
                        }`,
                      }}
                      onBlur={() =>
                        checkName(
                          "validateAddressLine2",
                          tempAddressObject.address2
                        )
                      }
                      data-tip="Address Line 2 can only contain letters (A-Z, a-z), numbers (0-9) and following special characters: #, ., ', -, commas and whitespace"
                      data-for="name-tooltip"
                    />
                    <ReactTooltip
                      id="name-tooltip"
                      effect="solid"
                      place="bottom"
                      className="tooltip"
                    />

                    {invalidArr.includes("validateAddressLine2") && (
                      <label style={{ color: "red", fontSize: "10px" }}>
                        {new FormValidator().validateValue(
                          tempAddressObject.address2,
                          /^[\w\s,#-]+$/,
                          255
                        )}
                      </label>
                    )}
                  </div>
                </div>

                <div className="popInputCont">
                  <label className="lableTxt">State/Province *</label>
                  <div className="inputPopCont">
                    <select
                      onChange={(e) => onChangeAddress(e, "stateProvinceGeoId")}
                      value={tempAddressObject.stateProvinceGeoId}
                      className="addressSelector"
                      style={{
                        border: `${
                          invalidArr.includes("validateState")
                            ? "1px solid red"
                            : ""
                        }`,
                      }}
                      onBlur={() => {
                        checkName(
                          "validateState",
                          tempAddressObject.stateProvinceGeoId
                        );
                      }}
                      // disabled={addressPopupData.stateList.length === 0}
                    >
                      <option value={""} disabled>
                        Select State
                      </option>
                      {addressPopupData.stateList.length !== 0 ? (
                        addressPopupData.stateList.map((obj, i) => {
                          return (
                            <option key={i} value={obj?.geoId}>
                              {obj?.geoName}
                            </option>
                          );
                        })
                      ) : (
                        <option value=""></option>
                      )}
                    </select>
                    {invalidArr.includes("validateState") && (
                      <label style={{ color: "red", fontSize: "10px" }}>
                        This field is required*
                      </label>
                    )}
                  </div>
                </div>

                <div className="popInputCont">
                  <label className="lableTxt">Country</label>
                  <div className="inputPopCont">
                    <select
                      onChange={(e) => {
                        onChangeAddress(e, "countryGeoId");
                        updateStateList(e);
                      }}
                      value={tempAddressObject.countryGeoId}
                      className="addressSelector"
                      style={{
                        border: `${
                          invalidArr.includes("validateCountry")
                            ? "1px solid red"
                            : ""
                        }`,
                      }}
                      onBlur={() => {
                        checkName(
                          "validateCountry",
                          tempAddressObject.countryGeoId
                        );
                      }}
                    >
                      <option value={""} disabled>
                        Select Country
                      </option>
                      {addressPopupData.countryListData.length !== 0
                        ? addressPopupData.countryListData.map((obj, i) => {
                            return (
                              <option key={i} value={obj?.geoId}>
                                {obj?.geoName}
                              </option>
                            );
                          })
                        : null}
                    </select>
                    {invalidArr.includes("validateCountry") && (
                      <label style={{ color: "red", fontSize: "10px" }}>
                        This field is required*
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="inputThree">
              <h1 className="lableTxt">Contact Purposes</h1>
              <div className="ContactPurposesList">
                {addressPopupData.contactPurposeList.length !== 0
                  ? addressPopupData.contactPurposeList.map((obj, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() =>
                            dataCheck(obj?.contactMechPurposeTypeId)
                          }
                          className="checkBoxDiv"
                        >
                          <input
                            checked={tempAddressObject.addressPurpose.includes(
                              obj?.contactMechPurposeTypeId
                            )}
                            className="checkbox"
                            type="checkbox"
                          />
                          <h1 className="checkboxTxt">{obj?.description}</h1>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div> */}
          </Modal.Body>
          <Modal.Footer className="addressPopupModalFooter">
            <button
              className="cancelBtn"
              onClick={closeFacilityAddressPopUpScreen}
            >
              Cancel
            </button>
            <button className="addAddressBtn" onClick={updateAddress}>
              {type === "add" ? "Add address" : "Update address"}
            </button>
          </Modal.Footer>
        </Modal>
      }
    </>
  );
};

export default FacilityAddressPopUpScreen;

import {API_BASE_URL} from "../../../Constants/constants";
import {SelectOptionType} from "../types";
import * as R from "ramda";

export const getAssetList = async (
    pageSize: number,
    pageIndex: number,
    assetFilters: Map<string, SelectOptionType[]>,
    searchText: any
) => {
    const token_id: string | null = localStorage.getItem('@tokenID');
    const assetTypesLocal: any = localStorage.getItem('assetTypes');

    let url = "";

    if (searchText === "" || searchText[0]?.serchVal === "") {
        url = `${API_BASE_URL}assets?token=${token_id}&pageSize=${pageSize}&pageIndex=${pageIndex}&assetTypeList=${assetTypesLocal}&mode=view`;
    } else {
        let assetName = "";
        let assetID = "";
        if (searchText[0]?.targetVal === "Asset Id") {
            assetID = searchText[0]?.serchVal;
            assetName = "";
        } else if (searchText[0]?.targetVal === "Asset Name") {
            assetID = "";
            assetName = searchText[0]?.serchVal;
        }
        url = `${API_BASE_URL}assets?token=${token_id}&pageSize=${pageSize}&pageIndex=${pageIndex}&assetTypeList=${assetTypesLocal}&assetId=${assetID}&assetName=${assetName}&mode=search`;
    }


    let isFacilityNotEmpty = !R.isNil(assetFilters) &&
        !R.isEmpty(assetFilters.get("Facility")) &&
        !R.isNil(assetFilters.get("Facility"));


    let isAssetTypeNotEmpty = !R.isNil(assetFilters) &&
        !R.isEmpty(assetFilters.get("Asset Type")) &&
        !R.isNil(assetFilters.get("Asset Type"));


    let isStatusNotEmpty = !R.isNil(assetFilters) &&
        !R.isEmpty(assetFilters.get("Status")) &&
        !R.isNil(assetFilters.get("Status"));

    let isAssetCategoryNotEmpty = !R.isNil(assetFilters) &&
        !R.isEmpty(assetFilters.get("Asset Category")) &&
        !R.isNil(assetFilters.get("Asset Category"))

    if ( isFacilityNotEmpty ) {
        const facilityList = assetFilters?.get("Facility");
        const facilityIdList = facilityList?.map((item) => item?.value?.id);
        const facIdStr = facilityIdList?.join(",").toString();
        if (facIdStr !== "") url += `&facilityList=${facIdStr}`;
    }

    if ( isAssetTypeNotEmpty ) {
        const assetTypeList = assetFilters?.get("Asset Type");
        const assetTypeIdList = assetTypeList?.map((item) => item?.value?.id);
        const monAssetTypeIdStr = assetTypeIdList?.join(",").toString();
        if (monAssetTypeIdStr !== "")
            url += `&monitoredAssetTypeList=${monAssetTypeIdStr}`;
    }


    if ( isStatusNotEmpty ) {
        const healthStatusList = assetFilters?.get("Status");
        const healthStatusIdList = healthStatusList?.map((item) => item?.value?.id);
        const healthStatusStr = healthStatusIdList?.join(",").toString();
        if (healthStatusStr !== "") url += `&healthStatus=${healthStatusStr}`;
    }


    if ( isAssetCategoryNotEmpty ) {
        const assetCategoryList = assetFilters?.get("Asset Category");
        const assetCategoryIdList = assetCategoryList?.map((item) => item?.value?.id);
        const monAssetCategoryIdStr = assetCategoryIdList?.join(",").toString();
        if (monAssetCategoryIdStr !== "")
            url += `&parentTypeId=${monAssetCategoryIdStr}`;
    }


    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return await response.json();

    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
}
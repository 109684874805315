import React from "react";
import "./FilterHeading.scss";
import DownArrowIcon from "../assets/dropdownIcon.svg";

type PropTypes = {
  title: string;
  selectedChildren: string[];
  group: string;
  handleFilterHeading: (index: number, group: string) => void;
  index: number;
};

function FilterHeading({
  title,
  selectedChildren,
  group,
  handleFilterHeading,
  index,
}: PropTypes) {
  return (
    <div
      className="filterHeadingDiv"
      onClick={() => handleFilterHeading(index, group)}
    >
      <img
        className={`dropdownIcon ${
          selectedChildren.includes(group) ? "rotate-0" : "rotate-90"
        }`}
        src={DownArrowIcon}
      />
      <p className="filterHeadingText">{title}</p>
    </div>
  );
}

export default FilterHeading;

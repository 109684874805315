import React, {useState } from "react";
import Lottie from "react-lottie";
import loader from "../../a1Components/lotties/round-loader-white.json";
import { NavLink} from "react-router-dom";
import { DashBoardServices } from "../../services/DasboardServices/DashboardService";
import "../LoginPage/LoginPageStyles.scss";
import { useStateValue } from "../../redux/StateProvider";


type dataType = {
  onSumbitData:() => void; 
}

const UserInputForChangePassword = ({onSumbitData}:dataType) => {
    const [userInput, setUserInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, seterrorMessage] = useState<any>("");
    const [{ forgotPassword }, dispatch] = useStateValue();
    const dbService = new DashBoardServices();
  
    const validateInput = (e: any) => {
      if(e === "") setUserInput(e);
      else if(/^[a-zA-Z0-9@.]+$/.test(e)) {
        setUserInput(e);
        seterrorMessage("");
      }
    };
  
    const getUserinfo = async (e:any) => {
      seterrorMessage("");
      setLoading(true);
      if(userInput === "") {
        seterrorMessage("Please enter username");
      }else {
        let response:any = await dbService.getUserInfo(userInput);
        if(response === "error") {
          seterrorMessage("Failed, please try again ");
        }else {
          let useData = await response.json();
          if(useData?.statusCode === "OK") {
            let obj = {...forgotPassword,user:userInput, userInfo: useData.apiData }
            dispatch({ type: "UPDATE_FORGOTPASSWORD_VARIABLES", forgotPassword: obj });
            onSumbitData();
          }else {
            let errorMsg = useData?.apiErrors[0];
            seterrorMessage(errorMsg);
          }
        }
      }
      setLoading(false);
    };
  
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: loader,
    };
  return (
    <div className="subContent">
      <div className="loginContent">
        <h6>Reset Password</h6>
        <p>Please enter your username to proceed with resetting your password.</p>
        <input placeholder="Username" readOnly={loading} minLength={5} maxLength={30} onChange={(e) => validateInput(e.target.value.trim())} className="userInput" type="text" name="username" value={userInput} />
        {userInput.length > 0 ? 
          <button onClick={getUserinfo} className="signInbutton">
              {loading ? <Lottie options={defaultOptions} /> : "Continue" }
          </button> : <button className="signInbuttonOpacity">Continue</button>
        }
        <div className="errorMessageContainer">
          <p>{errorMessage}</p>
        </div>
        <NavLink to={"/"}>
          <div className="redirectLoginDiv"><p>Back to Login</p></div>  
        </NavLink>            
      </div>
    </div>
  )
}

export default UserInputForChangePassword;
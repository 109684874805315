import { useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import FacilityCardIcon from '../Assets/facilityCardIcon.svg'
import { formatNumberData } from "../../../Utils/utils";
import MeterCard from "../meterCard/MeterCard";
import leftAlignedAssetIcon from '../../../assets/icons/leftAlignedAssetTypeICon.svg';
import activeEnergyAssetTypeICon from '../../../assets/icons/ActiveEnergyAssetTypeIcon.svg';
import energyAssetTypeIcon from '../../../assets/icons/ennergyAssetTypeIcon.svg';
import BeatLoader from "react-spinners/BeatLoader";
import rightScrollIcon from '../Assets/rightScroll.png';

function ThreeRowsScrollableDiv(props) {
  const { setActiveAssetType, setMouseHoverCard, mouseHoverCard,
          changeMeterId, activeMeter, activeTab, tabsArr, mainMeter,
          metersCard,  showLatestDecimalValue, setFacilityId, setAPICallType,
          mainCallTypeRef, setAssetTypeRespStat, setShiftId, setOpacityVal, 
          facilityDataForMainMeters} = props
  const containerRef = useRef(null);
  const [elementsPerRow, setElementsPerRow] = useState(0);
  const [showDummyDiv, setShowDummyDiv] = useState(false);
  const [splittedElements, setSplittedElements] = useState([]);
  const [elementsArr, setElementsArr] = useState([]);

  useEffect(()=>{
    if(facilityDataForMainMeters.length !== 0){
      setElementsArr(facilityDataForMainMeters)
      calculateLayout();
    }
  },[facilityDataForMainMeters])
  


  const findStringLength = (key) =>{
    let lengthArr = elementsArr.map((obj)=> {
      let name = obj[key]?.length
      let value = (obj?.consumptionData?.length === 0 ? '--' : findNullValueCheck(obj?.consumptionData[0].value, obj?.consumptionData[0].defaultUom, showLatestDecimalValue)).length
      if(name > value) return name
      return value 
    }).sort((a,b)=> b-a)[0];


    if(key === 'fixedAssetName'){
     return mainMeter === false ? (lengthArr * 9) + 10 : (lengthArr * 10) + 30
    }


    return (lengthArr * 10) + 10


  }

 
const calculateLayout = () => {
  const container = containerRef.current;
  if (container) {
    const containerWidth = container.offsetWidth;
    const itemWidth =
      metersCard === 'meters'
        ? findStringLength('fixedAssetName')
        : metersCard === 'AssetType'
        ? findStringLength('fixedAssetType')
        : 210;

    const calculatedElementsPerRow = Math.floor(containerWidth / itemWidth);

    if (calculatedElementsPerRow !== elementsPerRow) {
      setElementsPerRow(calculatedElementsPerRow);
    }

    const totalRows = Math.ceil(elementsArr.length / calculatedElementsPerRow);

    if (totalRows > 3) {
      setShowDummyDiv(true);
      const splitElements = splitArrayIntoRows(elementsArr, 3);
      setSplittedElements(splitElements);
    } else {
      setShowDummyDiv(false);
      setSplittedElements([]); // Clear if no splitting is required
    }
  }
};

const splitArrayIntoRows = (elements, rowCount) => {
  const totalElements = elements.length;
  const baseSize = Math.floor(totalElements / rowCount);
  const remainder = totalElements % rowCount;

  const rows = [];
  let currentIndex = 0;

  for (let i = 0; i < rowCount; i++) {
    const additional = i < remainder ? 1 : 0;
    const groupSize = baseSize + additional;

    rows.push(elements.slice(currentIndex, currentIndex + groupSize));
    currentIndex += groupSize;
  }

  return rows;
};


useEffect(() => {
  const debouncedCalculate = debounce(calculateLayout, 100);
  const observer = new ResizeObserver(() => debouncedCalculate());

  if (containerRef.current) {
    observer.observe(containerRef.current);
  }

  return () => {
    if (containerRef.current) {
      observer.unobserve(containerRef.current);
    }
    debouncedCalculate.cancel();
  };
}, [elementsArr, elementsPerRow]);

  const findNullValueCheck = (value, uom, decimalType, cost='noCost') => {
    uom = uom == null ? '' : uom;
    let decimal = decimalType === 'common' ? 2 : decimalType
    const locale = Intl.DateTimeFormat().resolvedOptions().locale;
    if (value === null) {
      return '--'
    } else {
      return cost === 'cost' ? uom + " " + formatNumberData(value, decimal) : formatNumberData(value, decimal) + " " + uom;
    }
  }

  const [hideContent, setHideContent] = useState(true);

  useEffect(()=>{
setTimeout(() => {
  setHideContent(false)
}, 250);
  },[])


  const scrollableContainerRef1 = useRef(null);
  const [isAtLeft, setIsAtLeft] = useState(true); // Initially at the left
  const [isAtRight, setIsAtRight] = useState(false); // Initially not at the right
  
  const handleScroll = (direction) => {
    const container = scrollableContainerRef1.current;
    if (container) {
      const scrollAmount = 130;
      container.scrollBy({
        left: direction === "right" ? scrollAmount : -scrollAmount,
        behavior: "smooth",
      });

      // Adjust scroll position check after scrolling
      setTimeout(() => {
        checkScrollPosition(container);
      }, 150);
    }
  };

  const checkScrollPosition = (container) => {
    const isLeft = container.scrollLeft === 0;
    const isRight =
      Math.abs(
        container.scrollLeft + container.clientWidth - container.scrollWidth
      ) <= 1;
    setIsAtLeft(isLeft);
    setIsAtRight(isRight);
  };

  useEffect(() => {
    if (scrollableContainerRef1.current) {
      checkScrollPosition(scrollableContainerRef1.current);
    }
  }, [splittedElements, setActiveAssetType, facilityDataForMainMeters, activeMeter, metersCard]);


  return (<div
      ref={containerRef}
      style={{
        width: "100%",
        // padding: "10px",
        backgroundColor: "white",
        overflowX: "hidden",
      }}
    >
       { hideContent && <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
       <BeatLoader color={'rgb(6,117,230)'} /></div> }
      {/* Showing Three Row Div */}
      {showDummyDiv && (
        <div style={{width:'100%', position:'relative'}} >

        {!isAtLeft && <button className="assetTypeScrollLeftButton" onClick={() => handleScroll('left')} >
              <img src={rightScrollIcon } alt='' />
              </button>}

        <div
        ref={scrollableContainerRef1}
        onScroll={() => checkScrollPosition(scrollableContainerRef1.current, setIsAtLeft, setIsAtRight)}    
        className="hide-scrollbar"
          style={{
            display: "flex",
            backgroundColor: "white",
            flexDirection: "column",
            gap: "10px",
            overflowX: "scroll",
          }}
        >
          {splittedElements.map((ele, index) => (
            <div
              style={{display: hideContent ? "none" : 'flex', gap: "10px" }}
              key={index}
            >
              {ele.map((obj,ind) => (
                  metersCard === 'meters' ? <MeterCard
                  mainMeter={mainMeter}
                  decimalVal={showLatestDecimalValue}
                  tabsArr={tabsArr}
                  activeTab={activeTab}
                  active={activeMeter === obj.fixedAssetId}
                  changeMeterId={changeMeterId}
                  consumption={obj?.consumptionData?.length !== 0 ? obj?.consumptionData[0].value : null}
                  abbreviation={obj?.consumptionData?.length !== 0 ? obj?.consumptionData[0].defaultUom : null}
                  key={obj.fixedAssetId}
                  data={obj}
                /> :
                metersCard === 'AssetType' ? 
                <div key={obj?.fixedAssetTypeId} className="energyAssetTypeCard" 
                onClick={() => { 
                  setActiveAssetType(obj.fixedAssetTypeId); 
                  setAPICallType("AssetType"); 
                  mainCallTypeRef.current = ''; 
                  setAssetTypeRespStat('load'); 
                  setShiftId(""); 
                  setOpacityVal(1) }} 
                  onMouseOver={() => setMouseHoverCard(obj?.fixedAssetTypeId)} 
                  onMouseLeave={() => setMouseHoverCard('')} >
                  <img src={obj?.fixedAssetTypeId === mouseHoverCard ? activeEnergyAssetTypeICon : energyAssetTypeIcon} alt="icon" />
                  <div className="assetTypeInfoCont">
                    <div>
                      <p className="energyAssetTypeDesc">{obj?.fixedAssetType}</p>
                      <p className="energyAssetTypevalue">{obj?.consumptionData?.length == 0 ? '--' : findNullValueCheck(obj?.consumptionData[0].value, obj?.consumptionData[0].defaultUom, showLatestDecimalValue)}</p>
                    </div>
                    <img src={leftAlignedAssetIcon} alt='icon' />
                  </div>
                </div>
                   : <div
                  key={ind}
                  style={{
                    boxSizing: "border-box",
                  }}
                  className="facilityCards"
                  onClick={() => {
                    setFacilityId(obj.facilityId)
                    //  setActiveAssetType(obj.facilityId);
                   setAPICallType("AssetType"); mainCallTypeRef.current = '';
                   setAssetTypeRespStat('load');
                   setShiftId("");
                   setOpacityVal(1)
                    }} >
              <img src={FacilityCardIcon} alt="icon" />
              <p className="facilityTitle">{obj?.facilityName}</p>
              <p className="facilityValue">{obj?.consumptionData?.length === 0 ? '--' : findNullValueCheck(obj?.consumptionData[0].value, obj?.consumptionData[0].defaultUom, showLatestDecimalValue)}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
        
        {!isAtRight && <button className="assetTypeScrollRightButton" 
            onClick={() => handleScroll('right')}>
            <img src={rightScrollIcon } alt='' />
            </button>}  

    </div>

      )}
      {/* Showing Elements Div */}
      {!showDummyDiv && (
        <div
          style={{
            display: hideContent ? "none" : 'flex' ,
            flexWrap: "wrap",
            gap: "10px",
            backgroundColor: "white",
            height: "auto",
          }}
        >
          {elementsArr.map((obj, index) => (
            metersCard === 'meters' ? 
                    <MeterCard
                    mainMeter={mainMeter}
                    decimalVal={showLatestDecimalValue}
                    tabsArr={tabsArr}
                    activeTab={activeTab}
                    active={activeMeter === obj.fixedAssetId}
                    changeMeterId={changeMeterId}
                    consumption={obj?.consumptionData?.length !== 0 ? obj?.consumptionData[0]?.value : null}
                    abbreviation={obj?.consumptionData?.length !== 0 ? obj?.consumptionData[0]?.defaultUom : null}
                    key={obj.fixedAssetId}
                    data={obj}
                  />
            : metersCard === 'AssetType' ? 
                    <div key={obj?.fixedAssetTypeId} className="energyAssetTypeCard" 
                    onClick={() => { 
                      setActiveAssetType(obj.fixedAssetTypeId); 
                      setAPICallType("AssetType"); 
                      mainCallTypeRef.current = ''; 
                      setAssetTypeRespStat('load'); 
                      setShiftId(""); 
                      setOpacityVal(1) }} 
                      onMouseOver={() => setMouseHoverCard(obj?.fixedAssetTypeId)} 
                      onMouseLeave={() => setMouseHoverCard('')} >
                      <img src={obj?.fixedAssetTypeId === mouseHoverCard ? activeEnergyAssetTypeICon : energyAssetTypeIcon} alt="icon" />
                      <div className="assetTypeInfoCont">
                        <div>
                          <p className="energyAssetTypeDesc">{obj?.fixedAssetType}</p>
                          <p className="energyAssetTypevalue">{obj?.consumptionData?.length == 0 ? '--' : findNullValueCheck(obj?.consumptionData[0].value, obj?.consumptionData[0].defaultUom, showLatestDecimalValue)}</p>
                        </div>
                        <img src={leftAlignedAssetIcon} alt='icon' />
                      </div>
                    </div>
            :  <div
            key={index}
            style={{
              boxSizing: "border-box",
            }}
            className="facilityCards"
            onClick={() => {
              setFacilityId(obj.facilityId)
              //  setActiveAssetType(obj.facilityId);
              setAPICallType("AssetType"); mainCallTypeRef.current = '';
              setAssetTypeRespStat('load');
              setShiftId("");
              setOpacityVal(1);
            }} >

            <img src={FacilityCardIcon} alt="icon" />
            <p className="facilityTitle">{obj?.facilityName}</p>
            <p className="facilityValue">{obj?.consumptionData?.length === 0 ? '--' : findNullValueCheck(obj?.consumptionData[0].value, obj?.consumptionData[0].defaultUom, showLatestDecimalValue)}</p>
          </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ThreeRowsScrollableDiv;

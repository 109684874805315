import { AREA_HIERARCHY, FACILITY_HIERARCHY } from "../../../Constants/constants";
import { DropdownType } from "../../../Utils/enumData";
import LocalStorageService from "../../../Utils/LocalStorageService";
import {
  AreaDataObj,
  FilterDataObj,
  FilterDataType,
} from "../types/EventListTypes";

export default class EventFilterData {
  getEventCategoryFilterData = () => {
    let response = LocalStorageService.getFacilityStaticsData();
    const eventCategory = response?.data.eventCategory.EVENT_ALERT_TYPE;

    const result: FilterDataObj[] =
      eventCategory?.map((element: any) => ({
        id: element.enumId,
        name: element.description,
        group: "Event Category",
        isSelected: false,
        isVisible: true,
        parentId: "",
        indexId: element.enumId,
      })) || [];
    return result;
  };

  getEventTypeFilterData = () => {
    const response = LocalStorageService.getFacilityStaticsData();
    const eventType = response?.data.eventType;

    let result = [];

    if (eventType) {
      result = Object.keys(eventType).flatMap((key) => {
        return eventType[key].map((item: any) => ({
          id: item.noteTypeId,
          name: item.description,
          group: "Event Type",
          isSelected: false,
          isVisible: true,
          parentId: key,
          indexId: item.noteTypeId,
        }));
      });
    }

    return result;
  };

  getRegionFilterData = () => {
    let response: {
      facilityGroupTypeId: string;
      parentFacilityGroupType: string;
      facilityGroupId: string;
      facilityGroupName: string;
      parentFacilityGroupId: string;
    }[] = LocalStorageService.getRegionData();

    const result: FilterDataObj[] =
      response.map((element) => ({
        id: element.facilityGroupId,
        name: element.facilityGroupName,
        group: "Region",
        isSelected: false,
        isVisible: true,
        parentId: element.parentFacilityGroupId,
        indexId: element.facilityGroupId,
      })) || [];
    return result;
  };

  getCityFilterData = () => {
    let response: {
      facilityGroupTypeId: string;
      parentFacilityGroupType: string;
      facilityGroupId: string;
      facilityGroupName: string;
      parentFacilityGroupId: string;
    }[] = LocalStorageService.getCityData();

    const result: FilterDataObj[] =
      response.map((element) => ({
        id: element.facilityGroupId,
        name: element.facilityGroupName,
        group: "City",
        isSelected: false,
        isVisible: true,
        parentId: element.parentFacilityGroupId,
        indexId: element.facilityGroupId,
      })) || [];
    return result;
  };

  getClusterFilterData = () => {
    let response: {
      facilityGroupTypeId: string;
      parentFacilityGroupType: string;
      facilityGroupId: string;
      facilityGroupName: string;
      parentFacilityGroupId: string;
    }[] = LocalStorageService.getClusterData();

    const result: FilterDataObj[] =
      response.map((element) => ({
        id: element.facilityGroupId,
        name: element.facilityGroupName,
        group: "Cluster",
        isSelected: false,
        isVisible: true,
        parentId: element.parentFacilityGroupId,
        indexId: element.facilityGroupId,
      })) || [];
    return result;
  };

  getFacilityFilterData = () => {
    let response: {
      facilityId: string;
      parentFacilityGroupType: string;
      facilityName: string;
      parentFacilityGroupId: string;
    }[] = LocalStorageService.getFacilityData();

    const result: FilterDataObj[] =
      response.map((element) => ({
        id: element.facilityId,
        name: element.facilityName,
        group: "Facility",
        isSelected: false,
        isVisible: true,
        parentId: element.parentFacilityGroupId,
        indexId: element.facilityId,
      })) || [];
    return result;
  };

  getAreaFilterData = () => {
    let response: AreaDataObj[] = LocalStorageService.getAreaData();

    const result: FilterDataObj[] =
      response.map((element: AreaDataObj) => ({
        id: element.locationSeqId,
        name: element.locationName,
        group: "Area",
        isSelected: false,
        isVisible: true,
        parentId: element.facilityId,
        indexId: `${element.locationSeqId}_${element.facilityId}`
      })) || [];
    return result;
  };

  getEventFilterData = () => {
    const newFilterList: FilterDataType[] = [];

    // Region -> City -> Cluster -> Facility -> Area

    // For Region
    const regionList = this.getRegionFilterData();

    // For City
    const cityList = this.getCityFilterData();

    // For Cluster
    const clusterList = this.getClusterFilterData();

    // For Facility
    const facilityList = this.getFacilityFilterData();

    // For Area
    const areaList = this.getAreaFilterData();

    newFilterList.push({
      key: "Facility Groups/Facilities",
      data: [
        ...regionList,
        ...cityList,
        ...clusterList,
        ...facilityList,
        ...areaList,
      ],
      placeholder: "Facility Groups/Facilities",
      dropdown: DropdownType.MULTIPLE,
      parent: "",
      searchValue: "",
      selectedCount: 0,
      hierarchyList: AREA_HIERARCHY,
      selectedChildren: [],
    });

    // Event Category
    const eventCategoryList = this.getEventCategoryFilterData();
    newFilterList.push({
      key: "Event Category",
      data: eventCategoryList,
      placeholder: "Event Category",
      dropdown: DropdownType.SINGLE,
      parent: "",
      searchValue: "",
      selectedCount: 0,
      hierarchyList: [],
      selectedChildren: [],
    });

    // Event Type
    const eventTypeList = this.getEventTypeFilterData();
    newFilterList.push({
      key: "Event Type",
      data: eventTypeList,
      placeholder: "Event Type",
      dropdown: DropdownType.SINGLE,
      parent: "",
      searchValue: "",
      selectedCount: 0,
      hierarchyList: [],
      selectedChildren: [],
    });

    return newFilterList;
  };
}

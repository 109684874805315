import axios from 'axios';
import { addAuthTokenToHeaders } from './authInterceptorHelper';
import LocalStorageService from "../Utils/LocalStorageService";
import { API_BASE_URL } from "../Constants/constants";
import {deregisterDevice} from "../Firebase/Firebase";

let isRefreshingToken = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string = null) => {
    failedQueue.forEach(prom => {
        if (token) {
            prom.resolve(token);
        } else {
            prom.reject(error);
        }
    });

    failedQueue = [];
};

async function logOut() {
    try {

        const tokenID = LocalStorageService.getToken();
        const response = await fetch(`${API_BASE_URL}auth/logout`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenID}`,
            },
        })

        if (response.status === 200 || response.status === 400) {
            deregisterDevice();
            localStorage.clear();
            localStorage.setItem("loginState", "false");
            window.location.href = "/";
        }
    } catch (e) {
        deregisterDevice();
        localStorage.clear();
        localStorage.setItem("loginState", "false");
        window.location.href = "/";
    }
}

export async function refreshToken() {
    try {
        const refreshToken = LocalStorageService.getRefreshTokenData();
        const tokenID = LocalStorageService.getToken();

        const response = await fetch(`${API_BASE_URL}auth/refresh-token`, {
            method: "POST",
            body: JSON.stringify({
                refreshToken: refreshToken,
            }),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenID}`,
            },
        })

        // Access token and refresh token has been expired.
        if(response.status === 493) {
            deregisterDevice();
            localStorage.clear();
            localStorage.setItem("loginState", "false");
            window.location.href = "/";
        }

        // Access token is not ready to be re-fresh.
        if (response.status === 492) {
            await new Promise((resolve) => setTimeout(resolve, 3000));
        }

        // For any other status
        if (response.status !== 200) {
            deregisterDevice();
            localStorage.clear();
            localStorage.setItem("loginState", "false");
            window.location.href = "/";
        }

        const responseData = await response.json();

        const token = responseData?.token;
        LocalStorageService.setToken(token);

        // Simulate delay (3 seconds)
        await new Promise((resolve) => setTimeout(resolve, 3000));

        return token;
    } catch (error) {
        await new Promise((resolve) => setTimeout(resolve, 3000));
    }
}

export function setAuthTokenInterceptor() {
    console.log("Interceptor initialized");

    // Axios request interceptor
    axios.interceptors.request.use(
        config => {
            config.headers = addAuthTokenToHeaders(config.headers);
            console.log("Token added to Axios request headers");
            return config;
        },
        error => {
            console.error("Axios Interceptor Error:", error);
            return Promise.reject(error);
        }
    );

    // Axios response interceptor for handling 403 status
    axios.interceptors.response.use(
        response => response, // Pass through successful responses
        async error => {
            const originalRequest = error.config;

            if (error.response.status === 403 && !originalRequest._retry) {
                if (isRefreshingToken) {
                    return new Promise(function (resolve, reject) {
                        failedQueue.push({ resolve, reject });
                    }).then(token => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return axios(originalRequest);
                    }).catch(err => {
                        return Promise.reject(err);
                    });
                }

                originalRequest._retry = true;
                isRefreshingToken = true;

                try {
                    const newToken = await refreshToken();
                    processQueue(null, newToken); // Process all queued requests
                    isRefreshingToken = false;

                    // Retry the original request with the new token
                    originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
                    return axios(originalRequest);
                } catch (err) {
                    console.error("Interceptor caught error:", err);

                    // If refresh token is invalid or expired, log out and avoid further actions
                    if (err.message === 'Refresh token is invalid or expired.') {
                        await logOut();
                    }
                    processQueue(err, null); // Notify all queued requests of failure
                    isRefreshingToken = false;
                    return Promise.reject(err);
                }
            }

            return Promise.reject(error);
        }
    );


    // Fetch interceptor
    const { fetch: originalFetch } = window;

    window.fetch = async (...args) => {
        let [resource, config] = args;
        let response;

        config = config || {};
        config.headers = addAuthTokenToHeaders(config.headers);

        response = await originalFetch(resource, config);

        // If 403, attempt to refresh the token
        if (response.status === 403) {
            if (isRefreshingToken) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                }).then(newToken => {
                    config.headers['Authorization'] = 'Bearer ' + newToken;
                    return originalFetch(resource, config);
                }).catch(err => {
                    throw err;
                });
            }

            isRefreshingToken = true;

            try {
                const newToken = await refreshToken();
                processQueue(null, newToken);
                isRefreshingToken = false;

                // Retry the original request with the new token
                config.headers['Authorization'] = 'Bearer ' + newToken;
                return originalFetch(resource, config);
            } catch (err) {
                processQueue(err, null);
                isRefreshingToken = false;
                throw err;
            }
        }

        // Intercept JSON response
        const json = () =>
            response
                .clone()
                .json()
                .then((data) => ({ ...data, title: `Intercepted: ${data.title}` }));

        response.json = json;

        return response;
    };
}

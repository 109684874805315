/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./ErrorPage.scss";
import imageConnectionerror from "../../assets/image/img-error-connection.svg";

function Connectionerror() {
  return (
    <div className="errorpage-container">
      <div className="img-container">
        <img className="wrongpage-img" src={imageConnectionerror} />
        <div className="text-container">
          <h1 className="text">Connection error</h1>
          <p className="paragraph">
            Your computer may be offline or we may be experiencing problems.
          </p>
          <p className="paragraph">We’re trying to reconnect.</p>
          <p>
            <a className="gobackAnchorclass" href="https://google.com">
              Refresh this page
            </a>
          </p>
        </div>
      </div>
      <p className="support">
        For help, contact <a href="">support@atsuyatech.com</a>
      </p>
    </div>
  );
}
export default Connectionerror;

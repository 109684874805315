import { API_BASE_URL } from "../../../Constants/constants";
import { GetAssetBody } from "./../types/index";

const abortControllers: { [key: string]: AbortController } = {};

const getAbortController = (key: string): AbortController => {
  if (!abortControllers[key]) {
    abortControllers[key] = new AbortController();
  }
  return abortControllers[key];
};

export const getAssetList = async (body: GetAssetBody) => {
  const tokenID = localStorage.getItem("@tokenID");
  const url = `${API_BASE_URL}assets/assetList`;
  const key = "getAssetList";

  const abortController = getAbortController(key);

  try {
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenID}`,
      },
      signal: abortController.signal,
    });

    if (!resp.ok) {
      return resp.status;
    }

    return await resp.json();
  } catch (error) {
    return error;
  }
};

import "./ToggleSwitch.scss";

type PropTypes = {
    uiModeState: boolean;
    updateUiMode: () => void;
};

function ToggleSwitch({ uiModeState, updateUiMode }: PropTypes) {
    return (
        <div className="toggleSwitchCont">
            <p className="toggleText">Off</p>
            <div
                className="toggleSwitchMainDiv"
                style={{
                    backgroundColor: !uiModeState
                        ? "rgba(244, 67, 54, 1)"
                        : "rgba(76, 175, 80, 1)",
                    justifyContent: uiModeState ? "flex-end" : "flex-start",
                }}
                onClick={updateUiMode}
            >
                {!uiModeState ? (
                    <div className="toggleOffStyle">
                        <div className="toggleOffInnerStyle" />
                    </div>
                ) : (
                    <div className="toggleOnStyle" />
                )}
            </div>
            <p className="toggleText">On</p>
        </div>
    );
}

export default ToggleSwitch;

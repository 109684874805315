/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import React from "react";
import "./TicketsTotal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTriangleExclamation,
  faStar,
  faXmark,
  faFontAwesomeFlag,
} from "@fortawesome/free-solid-svg-icons";
function TicketsTotal({ data }: any) {
  return (
    <div className="ticketsTotal">
      <div className="ticketsTotal-header">
        <h3>{data.carouselHeader[0].title}</h3>
        <h3>{data.carouselHeader[0].subText}</h3>
      </div>
      <div className="ticketsTotal-subCard">
        <div className="header">
          <h3>Open</h3>
          <h3>{data.carouselHeader[1].subText}</h3>
        </div>
        <div className="body">
          <div className="titleHead">
            <h4>{data.carouselBody[0].title}</h4>
            <h3>
              <span>
                <FontAwesomeIcon icon={faFontAwesomeFlag} />
              </span>{" "}
              {data.carouselBody[0].value}
            </h3>
          </div>
          <div className="titleHead">
            <h4>{data.carouselBody[1].title}</h4>
            <h3>{data.carouselBody[1].value}</h3>
          </div>
          <div className="titleHead">
            <h4>{data.carouselBody[2].title}</h4>
            <h3> {data.carouselBody[2].value}</h3>
          </div>
          <div className="titleHead">
            <h4>{data.carouselBody[3].title}</h4>
            <h3>{data.carouselBody[3].value}</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TicketsTotal;

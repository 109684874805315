import React from "react";
import "./ScoreCard.scss";

function ScoreCard({ key, value, name, postName, abbreviation, id }) {
  return (
    <div
      key={key}
      className="scoreCardScss"
      style={{
        borderLeftColor:
          value >= 80
            ? "#4CAF50"
            : value > 40 && value < 80
            ? "#FFC107"
            : "#F44336",
      }}
    >
      <h3>{name}</h3>
      <h4>
        {value}
        {abbreviation}
        <span>{postName}</span>
      </h4>
    </div>
  );
}

export default ScoreCard;

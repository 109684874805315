import React, { useEffect, useState } from "react";
import "./PasswordPopup.scss";
import ActiveChipCloseIcon from '../../assets/icons/ActiveChipCloseIcon.svg';
import UserTextInput from "./UserTextInput";
import { DashBoardServices } from "../../services/DasboardServices/DashboardService";

type dataType = {
  closeCallBackFunction: () => void;
  responseCallBack: (arr: string) => void;
}

const PasswordPopup = ({ responseCallBack, closeCallBackFunction }: dataType) => {
  const [validateWarning, setValidateWarning] = useState(false);
  const [validateWarningMsg, setValidateWarningMsg] = useState("");
  const [currentPassword, setCcurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [apiLoader, setApiLoader] = useState(false);
  let dbService = new DashBoardServices();


  const changePasswordFunction = async () => {
    let user = localStorage.getItem("userName");
    setApiLoader(true);
    if (newPassword !== confirmPassword) {
      setValidateWarningMsg("Passwords does not match");
      setValidateWarning(true);
      setApiLoader(false);
    } else {
      setValidateWarning(false);
      let obj = {
        username: user,
        password: currentPassword,
        newPassword: newPassword,
        newPasswordVerify: confirmPassword,
      }
      let dataFetch: any = await dbService.getChangePassword(obj);
      if (dataFetch === "error") {
        setValidateWarningMsg("Failed! please try again...!");
        setValidateWarning(true);
        setApiLoader(false);
      }
      else{
        if (dataFetch.status === 200) {
          let response = await dataFetch.json();
          setApiLoader(false);
          responseCallBack(response?.successMessage);
        } else {
          let response = await dataFetch.json();
          setValidateWarning(true);
          setApiLoader(false);
          setValidateWarningMsg(response?.errorMessageList[0]);
        }
      }
    }
  }

  return (
    <div className="changePasswordContainer">
      <div className="headerDivContainer">
        <p>Change Password</p>
        <img onClick={closeCallBackFunction} src={ActiveChipCloseIcon} alt="icon" loading="lazy" />
      </div>
      <div className="bodyDivContaier">
        <UserTextInput editable={apiLoader} title="Current Password" userInput={currentPassword} setVariable={(e: string) => setCcurrentPassword(e)} />
        <UserTextInput editable={apiLoader} title="New Password" userInput={newPassword} setVariable={(e: string) => setNewPassword(e)} />
        <UserTextInput editable={apiLoader} title="Confirm Password" userInput={confirmPassword} setVariable={(e: string) => setconfirmPassword(e)} />
        <div className="validateWarningMessage">
          <p style={{ opacity: validateWarning ? 1 : 0 }}>{validateWarningMsg}</p>
        </div>
      </div>
      <div className="footerDivContainer">
        <div>
          <button className="closeButton" onClick={closeCallBackFunction} >Cancel</button>
          {
            currentPassword.length >= 5 && confirmPassword.length >= 5 && newPassword.length >= 5 ?
              <button className="changePasswordButton" onClick={() => apiLoader ? null : changePasswordFunction()} >{apiLoader ? "Please wait..." : "Change Password"}</button> :
              <button className="changePasswordButton invalidPassword">Change Password</button>
          }
        </div>
      </div>
    </div>
  );
};

export default PasswordPopup;

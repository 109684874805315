/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";

const CustomBreadcrumbs = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pathnames = pathname.split("/").filter(Boolean);
console.log(pathnames,"pathnames")
  return (
    <div className="breadCrumbRow">
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;
        const isFirst = index === 0;
        const nameH = !name.includes("_")
          ? name.charAt(0).toUpperCase() + name.substr(1).toLowerCase()
          : name;
          console.log(nameH,"pathnames")
          const decodedString = decodeURIComponent(nameH);
        // const nameF = nameH.replace(/[^a-zA-Z_0-9 ]/g, " ");
        // const decodedString = decodeURIComponent(nameF);
        // console.log(decodedString,"pathnames")
        return isLast ? (
          <a key={name}>{decodedString}</a>
        ) : isFirst ? (
          <a key={name} onClick={() => navigate(routeTo)}>
            {decodedString !== "Asset list" ? "Home" : decodedString}
            <FontAwesomeIcon icon={faAngleRight} />
          </a>
        ) : (
          <a key={name} onClick={() => navigate(routeTo)}>
            {decodedString}
            <FontAwesomeIcon icon={faAngleRight} />
          </a>
        );
      })}
    </div>
  );
};

export default CustomBreadcrumbs;

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import './DateTimeRangeSelectorInput.scss';
import DateTimeInput from './DateTimeInput/DateTimeInput';
import DateFormat from '../DateFormatters/DateFormat';
import { ReportDatePickerData} from "../DataTypes"
import { convertIntoDateObject, exptectedDateFormat, get365DaysAgo } from '../../ModelPopupcomp/DateTimeRangeInputComponent/Utils';
import moment from 'moment';

const DateTimeRangeSelectorInput = ({PlaceHolder,state,activeInput, field, title, value, updateDateRange}:ReportDatePickerData) => {
  const [calenderPopUpScreen, setCalenderPopupScreen] = useState(false);
  const dateFormats = new DateFormat();

   const convertDatetoStartDate = (date:any) => {
    const startOfDay = new Date(date);
    startOfDay.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0
    return new Date(startOfDay);
   }

  const [selectedDates, setSelectedDates] = useState<any>({
    startDate: convertDatetoStartDate(new Date()),
    endDate: new Date()
  });

  const [dateRange, setDateRange] = useState<any>({
    startDate: exptectedDateFormat(selectedDates.startDate),
    endDate: exptectedDateFormat(selectedDates.endDate)
   });

   const [updateDateTimeInputValues, setUpdateDateTimeInputValues] = useState<any>({
    startDate: dateRange.startDate,
    endDate: dateRange.endDate
   })

   const [payloadTimeInputValues, setPayloadDateTimeInputValues] = useState<any>({
    startDate: new Date(selectedDates.startDate).getTime()-19800, //substract 5 hrs 30 mins
    endDate: new Date().getTime()-19800 //substract 5 hrs 30 mins
   })

  const applyAction = () => {
    updateDateRange(payloadTimeInputValues);
    setUpdateDateTimeInputValues(() => dateRange);
    setCalenderPopupScreen(false);
  };

  useEffect(() =>  updateDateRange(payloadTimeInputValues), []);

  const handleClick = () => {
    activeInput();
    setCalenderPopupScreen(true);
  }


  const formatDate = (dateObject:any) => {
    const formattedDate = `${dateObject.getDate().toString().padStart(2, '0')}-${(dateObject.getMonth() + 1).toString().padStart(2, '0')}-${dateObject.getFullYear()} ${(dateObject.getHours() % 12 || 12).toString().padStart(2, '0')}:${dateObject.getMinutes().toString().padStart(2, '0')} ${dateObject.getHours() >= 12 ? 'PM' : 'AM'}`;
    return(formattedDate);
  }

  const updateDateDateTime = (state:string, value:any) => {
    let selectedDate = new Date(value);
    selectedDate.setHours(selectedDate.getHours() - 5);
    selectedDate.setMinutes(selectedDate.getMinutes() - 30);
    if(state === "startDate") {
      setPayloadDateTimeInputValues(() => ({startDate: selectedDate.getTime(), endDate:""}));
      setDateRange(() => ({startDate:formatDate(selectedDate), endDate:""}));
      setSelectedDates(() => ({startDate: selectedDate, endDate:""}));
    }else {
      setPayloadDateTimeInputValues((prevDateRange:any) => ({...prevDateRange, [state]: selectedDate.getTime()}));
      setDateRange((prevDateRange:any) => ({...prevDateRange, [state]:formatDate(selectedDate)}));
      setSelectedDates((prevDateRange:any) => ({...prevDateRange, [state]: selectedDate}));
    }
  }

  return (
    <div className="dateTimeInputCardContainer">
      <h4 className="tempInputTitleText">{title}{field === "Y" ? " *" : ""}</h4>
      <div className="styleInp">
        <input type="text" onClick={handleClick} required={true} 
         value={((updateDateTimeInputValues.startDate) + " - " + (updateDateTimeInputValues.endDate))}  className="inputTempTextValue" placeholder={PlaceHolder} />
        <div className='calenderIcon'>
          <FontAwesomeIcon onClick={handleClick} icon={faCalendar} />
        </div>    
      </div>
      {calenderPopUpScreen && state ? (
        <div className="slectDateTimeCard">
          <DateTimeInput selectedDate={selectedDates.startDate} keyName={"startDate"} maxDate={dateFormats.getMaxDate("")} minDate={get365DaysAgo()} title='Start Date Time' value={dateRange.startDate} handleDateChange={updateDateDateTime}/>
          <DateTimeInput selectedDate={selectedDates.endDate} keyName={"endDate"} maxDate={dateFormats.getMaxDate("")} minDate={dateFormats.getMaxDate(selectedDates.startDate)} title='End Date Time' value={dateRange.endDate} handleDateChange={updateDateDateTime}/>
          <div className="bottonButtonsDiv">
            <button onClick={() => setCalenderPopupScreen(false)} className="cancelButton">Cancel</button>
            {
              dateRange.startDate !== "" && dateRange.endDate !== "" ?  <button onClick={applyAction} className="applyButton">Apply</button> :  <button className="applyButtonOpacity">Apply</button>
            }
           
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default DateTimeRangeSelectorInput;
import React, {SyntheticEvent, useEffect, useRef} from "react";
import useState from "react-usestateref";
import {useQuery, useQueryClient} from "react-query";
import {getAssetList} from "./services/getAssets";
import LoaderStyle from "../../a1Components/LoaderStyle/LoaderStyle";
import DisplayError from "../../a1Components/DisplayError/DisplayError";
import * as R from "ramda";
import AssetCard from "../../a1Components/assetCard";
import {Col, Row} from "react-bootstrap";
import "./assets.scss";
import Loadmore from "../../components/Loadmore";
import {useStateValue} from "../../redux/StateProvider";
import {AssetListType, SelectOptionType} from "./types";
import AssetFilterUtils, {AssetFilterDataType} from "../../Utils/AssetFilterUtils";
import {SettingsApiServices} from "../../services/settingsPageServices/SettingsApiServices";
import Select, {components} from 'react-select'
import {Outlet, useLocation} from "react-router-dom";

function Assets({rightOpen, toggleBtn}: any) {

    const [{searchText}, dispatch] = useStateValue();
    const pageSize = 50;
    const pollInterval = 120000; //2 mins
    const pageIndex = useRef(1);
    const [forcePageIndexTrigger, setForcePageIndexTrigger] = useState(1);
    const lastFetchedPage = useRef(0);
    const [assetList, setAssetList] = useState<any[]>([]);
    const [clearReactSelectFilter, setClearReactSelectFilter] = useState(false);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [assetFilterData, setAssetFilterData] = useState<Map<string, AssetFilterDataType[]>>(new Map());
    const [showLoading, setShowLoading] = useState(false)
    const [showError, setShowError] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState<Map<string, SelectOptionType[]>>(new Map());
    const appliedFilter = useRef(new Map()); // : Map<string, SelectOptionType[]>
    const [showClearAll, setShowClearAll] = useState(false)
    const [disableApplyFilter, setDisableApplyFilter] = useState(true)


    const handleApplyFilter = async () => {
        appliedFilter.current = selectedOptions
        console.log("WWWWWW", appliedFilter)
        cancelExistingQuery()
        pageIndex.current = 1;
        setForcePageIndexTrigger((prevState) => prevState + 1);
        setShowLoading(true);
        if (selectedOptions?.size > 0 ){ setShowClearAll(true)} else {setShowClearAll(false) }
    };
    const handleClearFilter = () => {
        const utils = new AssetFilterUtils();
        const filterData = utils.getAssetFilterData();
        const deepCopy: Map<string, AssetFilterDataType[]> = new Map(
            JSON.parse(JSON.stringify(Array.from(filterData.filterData)))
        );

        setClearReactSelectFilter(prevState => !prevState)
        setAssetFilterData(deepCopy);
        setSelectedOptions(new Map());
        appliedFilter.current = new Map();
        pageIndex.current = 1;
        setForcePageIndexTrigger((prevState) => prevState + 1);
        setShowLoading(true);
    };

    const handlePrint = () => {
        console.log("!!!!!! selectedOptions ", selectedOptions);
    };

    useEffect(() => {
        console.log("!!!!!! selectedOptions ", selectedOptions);
        const mapsAreEqual = areFiltersNotEqual(selectedOptions, appliedFilter.current);
        setDisableApplyFilter(mapsAreEqual)
    }, [selectedOptions]);

    const getAssetFilterData = async () => {


        let tokenID = localStorage.getItem("@tokenID");
        const services = new SettingsApiServices();
        const utils = new AssetFilterUtils();
        const response = await services.FacilityStatistics(tokenID);

        console.log("QAQAQA", response)
        if (!R.isEmpty(response) && !(response.statusCode == "BAD_REQUEST" || response.responseMessage == "error")) {
            const filterData = utils.getAssetFilterData();
            const deepCopy1: Map<string, AssetFilterDataType[]> = new Map(
                JSON.parse(JSON.stringify(Array.from(filterData.filterData)))
            );

            setAssetFilterData(deepCopy1);
        }
    };

    const {data, error, isLoading, isFetching, refetch, isRefetching} = useQuery<AssetListType>(
        "assetListData",
        () => getAssetList(pageSize, pageIndex.current, appliedFilter.current, searchText),
        {
            refetchInterval: pollInterval,
            refetchOnWindowFocus: false,
            retry: false,
            onSuccess: (fetchedData) => {
                setShowLoading(false)
                if (pageIndex.current == 1) {
                    setAssetList([]);
                }
                if (pageIndex.current !== lastFetchedPage.current) {
                    setAssetList((prevList) => [...prevList, ...fetchedData?.assets]);
                } else {
                    setAssetList((prevList) => [...prevList?.slice(0, 0 - fetchedData?.assets?.length), ...fetchedData?.assets]);
                }
                lastFetchedPage.current = pageIndex.current;
            },
            onError: () => {
                setShowLoading(false)
                setShowError(true)
            }
        }
    );

    const handleLoadMore = async () => {
        console.log(`-------- pageIndex 157${pageIndex.current}`);
        cancelExistingQuery()
        pageIndex.current = pageIndex.current + 1
        console.log(`-------- pageIndex 160${pageIndex.current}`);

        setForcePageIndexTrigger((prevState) => prevState + 1)
    };

    useEffect(() => {
        // alert(pageIndex)
        console.log(`-------- pageIndex ${pageIndex.current}`);
        fetchDataAndUpdateState();
    }, [forcePageIndexTrigger]);

    const fetchDataAndUpdateState = async () => {
        try {
            await refetch();
        } catch (error) {
            console.error("Error re-fetching data:", error);
        }
    };


    useEffect(() => {
        setShowLoading(true)
        fetchDataAndUpdateState();
        getAssetFilterData();
    }, []);


    useEffect(() => {
        dispatch({
            type: "GET_ASSETS_LENGTH",
            assetsLengthCount: assetList.length,
        });

        dispatch({type: "SHOW_ASSET_COUNT", showAssetCount: !showLoading});

        dispatch({
            type: "SET_TOTAL_ASSET_COUNT",
            totalAssetCount: data?.assetCount,
        });

        const mapsAreEqual = areFiltersNotEqual(selectedOptions, appliedFilter.current);
        setDisableApplyFilter(mapsAreEqual)
        if (selectedOptions?.size > 0 ){ setShowClearAll(true)} else {setShowClearAll(false) }

    }, [isFetching, assetList,showLoading, data]);

    useEffect(() => {
        setShowLoadMore(!R.isEmpty(data) && parseInt(data?.assetCount) > pageIndex.current * pageSize);
    }, [data]);

    const queryClient = useQueryClient();

    function cancelExistingQuery(){
        queryClient.getQueryCache().getAll().forEach(query => {
            if (query.queryKey.includes('assetListData')) {
                queryClient.cancelQueries(query.queryKey);
                console.log("cancelled query")
            }
        });
    }
    useEffect(() => {
        if (searchText !== undefined && searchText !== "") {
            setShowLoading(true)
            pageIndex.current = 1;
            cancelExistingQuery()
            fetchDataAndUpdateState()
            // setForcePageIndexTrigger((prevState) => prevState + 1)
        }
    }, [searchText]);

    const InputOption = ({
                             getStyles,
                             Icon,
                             isDisabled,
                             isFocused,
                             isSelected,
                             children,
                             innerProps,
                             ...rest
                         }) => {
        const [isActive, setIsActive] = useState(false);
        const onMouseDown = () => setIsActive(true);
        const onMouseUp = () => setIsActive(false);
        const onMouseLeave = () => setIsActive(false);

        // styles
        let bg = "transparent";
        if (isFocused) bg = "#eee";
        if (isActive) bg = "#B2D4FF";

        const style = {
            alignItems: "center",
            backgroundColor: bg,
            color: "inherit",
            display: "flex ",
            zIndex: 10,
        };

        // prop assignment
        const props = {
            ...innerProps,
            onMouseDown,
            onMouseUp,
            onMouseLeave,
            style
        };

        return (
            <components.Option
                {...rest}
                isDisabled={isDisabled}
                isFocused={isFocused}
                isSelected={isSelected}
                getStyles={getStyles}
                innerProps={props}
            >
                <input type="checkbox" checked={isSelected}/>
                <div style={{marginLeft: "7px"}}>{children}</div>
            </components.Option>
        );
    };
    let location = useLocation();

    const currentPath = location.pathname;
    let reactSelectState=new Map()

    const [scrollPosition, setScrollPosition] = useState(0);
    const divRef = useRef();
    let pos = 0

    const handleScroll = (e: SyntheticEvent) => {
        const target = e.target as HTMLTextAreaElement;
        console.log('Current scroll position:', target.scrollTop);
        setScrollPosition(target.scrollTop)
    };


    const scrollRef = useRef(null);
    useEffect(() => {
        console.log("Scroll ", currentPath)
        if (currentPath === "/asset-list" && scrollRef){
            console.log("Scroll ", pos)
            scrollRef?.current?.scrollTo(0,scrollPosition)
        }
    }, [currentPath]);

    const customThemeFn = (theme) => ({
        ...theme,
        spacing: {
            ...theme.spacing,
            controlHeight: 35,
            baseUnit: 2
        }
    })
    const customStyles = {
        option: (styles, state) => ({
            ...styles,
            cursor: 'pointer',
        }),
        control: (styles) => ({
            ...styles,
            cursor: 'pointer',
        }),
    }

    if (currentPath !== "/asset-list") {
        return <Outlet/>
    } else {
        return (
            <>
                {assetFilterData?.size !== 0  && (
                    <div className="dropDown_scss">
                        <Col xs={1} md={1} lg={1}>
                            <div className="filterBy">Filter by</div>
                        </Col>
                        <Row style={{width: "90%", alignItems: "center"}}>

                            {
                                ["Status", "Asset Category", "Asset Type", "Facility", ].map((filterKey) => {
                                    const filterData = assetFilterData.get(filterKey);
                                    if (filterData?.length === 0) return
                                    let transformedData = convertToSelectOptions(filterData);
                                    if (filterKey === "Asset Type" && selectedOptions.has("Asset Category")) {
                                        const selectedAssetCategories = selectedOptions.get("Asset Category");
                                        // Filter Asset Types based on all selected Asset Categories
                                        const filteredAssetTypes = assetFilterData.get("Asset Type").filter((assetType) => {
                                            return selectedAssetCategories.some(category => assetType?.parentFixedAssetTypeId === category?.value?.id);
                                        });
                                        transformedData = convertToSelectOptions(filteredAssetTypes);
                                    }

                                return (
                                    <Col xs={0} sm={6} md={6} lg={3}
                                         style={{padding: ".25rem", height: "80%", fontSize: "small"}}>
                                        <Select
                                            classNamePrefix="mySelect"
                                            defaultValue={[]}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            isClearable={false}
                                            value={selectedOptions.get(filterKey)}
                                            onChange={(options) => {
                                                // reactSelectState = options
                                                if (Array.isArray(options)) {
                                                    setSelectedOptions((prevSelectedOptions) => {
                                                        const newOptions = new Map([...prevSelectedOptions]);

                                                        // If options array is empty, it means the user cleared the selection
                                                        if (options.length === 0) {
                                                            newOptions.delete(filterKey);
                                                        } else {
                                                            newOptions.set(filterKey, options);
                                                        }
                                                        return newOptions;
                                                    });


                                                }

                                            }}
                                            options={transformedData}
                                            components={{
                                                Option: InputOption,
                                                IndicatorSeparator: () => null
                                            }}
                                            styles={customStyles}
                                            placeholder=
                                                {<div className="custom-placeholder">
                                                    <span>{`${filterKey} `}</span>
                                                    {selectedOptions.get(filterKey)?.length > 0 && (
                                                        <div
                                                            className="count"> {selectedOptions?.get(filterKey)?.length} </div>
                                                    )}
                                                </div>
                                                }
                                            key={clearReactSelectFilter}
                                            isDisabled={isLoading || showError}
                                            controlShouldRenderValue={false}
                                            theme={customThemeFn}
                                        />
                                    </Col>
                                );
                            })
                            }
                            <button className="apply" onClick={handleApplyFilter} disabled={disableApplyFilter || showLoading}>
                                APPLY
                            </button>
                            { showClearAll && !showLoading &&
                            <span onClick={handleClearFilter} className="link-danger">CLEAR ALL</span>
                            }
                        </Row>
                    </div>
                )}

                {(isLoading || showLoading) && (
                    <div className="loadingBox2">
                        <LoaderStyle/>
                    </div>)
                }

                {(!showError && (assetList?.length === 0 && !(isFetching || isFetching || isLoading))) && (
                    <div className="loadingBox2">
                        <DisplayError type={"err-empty"}/>
                    </div>
                )}

                {showError && (
                    <div className="loadingBox2">
                        <DisplayError type={"err-500/404"}/>
                    </div>
                )
                }
                {(!showLoading) && (assetList?.length > 0) && (!error) && (
                    <div className="assetFlex"  >
                        <Row className="sss" onScroll={handleScroll} ref={scrollRef}>
                            {assetList?.map((el: any, id: any) => (
                                <Col xs={12} md={6} lg={3} className="rrr" key={id}>
                                    <AssetCard data={el} className="assetCard-colum"/>
                                </Col>
                            ))}
                            {!error && !R.isEmpty(assetList) && (showLoadMore) && (
                                <div className="loadMoreAssets">
                                    <Loadmore
                                        className="loadMore"
                                        showLoadMore={showLoadMore}
                                        handleLoadMore={handleLoadMore}
                                        showLoadSpin={isFetching}
                                    />
                                </div>
                            )}
                        </Row>
                    </div>
                )}
            </>
        );
    }
}

const convertToSelectOptions = (filterData: AssetFilterDataType[]): SelectOptionType[] => {
    if (filterData) {
        return filterData.map((data) => ({
            label: data.name,
            value: data,
        }));
    } else {
        return [];
    }
};

function areFiltersNotEqual(map1, map2) {
    // alert("size diff")
    console.log("!! map1", map1)
    console.log("!! map2", map2)
    if (map1.size == 0 && map2.size == 0) {
        return true;
    }

    if (map1.size !== map2.size) {
        return false;
    }

    for (let [key] of map1) {
        if (!map2.has(key)) {
            return false;
        }

        const arr1 = map1.get(key).slice().sort((a, b) => a.value.id.localeCompare(b.value.id));
        const arr2 = map2.get(key).slice().sort((a, b) => a.value.id.localeCompare(b.value.id));

        if (arr1.length !== arr2.length) {
            return false;
        }

        for (let i = 0; i < arr1.length; i++) {
            const obj1 = arr1[i];
            const obj2 = arr2[i];

            if (!obj2 || obj1.value.id !== obj2.value.id) {
                return false;
            }
        }
    }

    return true;
}


export default Assets;

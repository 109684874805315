import React, { useState } from 'react';
import visible from '../../assets/icons/passwordVisibleIcon.svg';
import inVisible from '../../assets/icons/passwordIcon.svg'

type dataType = {
    title:string;
    userInput:string;
    editable:boolean;
    setVariable:(arg:string) => void;
}

const UserTextInput = ({title,editable,userInput, setVariable}:dataType) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [warningMessage, setWarningMessage] = useState(false);
  const validateUserInput = (e:string) => {
    if(e === "") {
      setVariable(e);
      setWarningMessage(true);
    }
   else if(/^[a-zA-Z0-9]+$/.test(e)) {
     setVariable(e);
     setWarningMessage(false);
   }
   else {
    setWarningMessage(true);
   }
  }
  return (
    <div className="userTextInput" >
       <p>{title}</p>
        <div>
           <div  style={{border : warningMessage ? "2px solid #ff0000" : "2px solid #BDCBFB"}}>
            <input readOnly={editable} type={passwordVisible ? "text" : "password"} onBlur={() => setWarningMessage(false)} minLength={5} maxLength={30} onChange={e => validateUserInput(e.target.value.trim())} value={userInput} />
            <div className="visiblePasswordIcon">
              <img onClick={() => setPasswordVisible(!passwordVisible)} src={passwordVisible ? visible: inVisible} alt="icon" loading="lazy" />
            </div>
          </div>
          {title === "New Password" ? <p>Minimum of 5 characters in length</p> : null }
        </div>
    </div>
  )
}

export default UserTextInput
import React, { useEffect, useState } from "react";
import "./FilterComponent.scss";

import DownArrowIcon from "../assets/downArrowIcon.svg";
import CrossIcon from "../assets/crossIcon.svg";
import EventFilterUtils from "../utils/eventFilterUtils";
import { FilterDataItem, FilterDataObj, SelectedValueType } from "../types";

type PropTypes = {
  searchingIndex: number | null;
  setSearchingIndex: (value: number) => void;
  filterDataSet: FilterDataObj[];
  setFilterDataSet: (value: FilterDataObj[]) => void;
  selectedFilterKey: string[];
  setSelectedFilterKey: (value: string[]) => void;
  selectedFilterValue: SelectedValueType[];
  setSelectedFilterValue: (value: SelectedValueType[]) => void;
  showFilter: boolean;
  hideFilter: () => void;
};

function FilterComponent({
  searchingIndex,
  setSearchingIndex,
  filterDataSet,
  setFilterDataSet,
  selectedFilterKey,
  setSelectedFilterKey,
  selectedFilterValue,
  setSelectedFilterValue,
  showFilter,
  hideFilter,
}: PropTypes) {

  const [showSelectedDiv, setShowSelectedDiv] = useState(false);

  const getAssetFilter = () => {
    const utils = new EventFilterUtils();
    const result = utils.getFilterData();
    setFilterDataSet(result);
    setSelectedFilterKey([]);
    setSelectedFilterValue([]);
  };

  useEffect(() => {
    getAssetFilter();
  }, [showFilter]);

  const isChipAlreadySelected = (key: string) => {
    return filterDataSet.some(
      (item) => item.key === key && item.isSelected === true
    );
  };

  const enableFilterDiv = (key: string, index: number) => {
    // Return true if there's only one item in the filterDataSet
    return (
      filterDataSet.length === 1 ||
      key === "" ||
      selectedFilterKey.includes(key)
    );
  };

  const updateSearchingIndex = (index: number) => {
    setSearchingIndex((prev) => {
      if (prev === index) {
        return null;
      } else {
        return index;
      }
    });
  };

  const removeFilter = (key: string, value: string) => {
    if (filterDataSet.length === 1) {
      const temp: FilterDataObj[] = filterDataSet;
      temp[0].isSelected = false;
      temp[0].searchValue = "";
      setFilterDataSet([...temp]);
    } else {
      let tempSelectedFilterKey = [...selectedFilterKey];
      const tempSelectedFilterIndex = tempSelectedFilterKey.findIndex(
        (item) => item === key
      );
      tempSelectedFilterKey = tempSelectedFilterKey.slice(
        0,
        tempSelectedFilterIndex
      );

      let tempSelectedFilterValue = [...selectedFilterValue];
      tempSelectedFilterValue = tempSelectedFilterValue.slice(
        0,
        tempSelectedFilterIndex
      );

      let tempFilterDataSet = [...filterDataSet];

      let index = tempFilterDataSet.findIndex((item) => item.key === key);

      if (index === 0) {
        tempFilterDataSet = tempFilterDataSet.map((item: FilterDataObj) => {
          return {
            ...item,
            searchValue: "",
            isSelected: false,
          };
        });
        let tempData: FilterDataItem[] = tempFilterDataSet[index].data.map(
          (item: FilterDataItem) => {
            return {
              ...item,
              isSelected: false,
              isView: true,
            };
          }
        );
        tempFilterDataSet[index].data = tempData;
        setFilterDataSet([...tempFilterDataSet]);
        setSelectedFilterKey([...tempSelectedFilterKey]);
        setSelectedFilterValue([...tempSelectedFilterValue]);
      } else {
        let selectedParentIdsList = tempFilterDataSet[index].data
          .filter((item: FilterDataItem) => item.isSelected && item.isView)
          .map((item: FilterDataItem) => item.parentId);

        for (
          let tempIndex = index;
          tempIndex < tempFilterDataSet.length;
          tempIndex++
        ) {
          tempFilterDataSet[tempIndex].isSelected = false;
          tempFilterDataSet[tempIndex].searchValue = "";
        }
        let tempData: FilterDataItem[] = tempFilterDataSet[index].data.map(
          (item: FilterDataItem) => {
            return {
              ...item,
              isSelected: false,
              isView: selectedParentIdsList.includes(item.parentId),
            };
          }
        );

        tempFilterDataSet[index].data = tempData;
        setFilterDataSet([...tempFilterDataSet]);
        setSelectedFilterKey([...tempSelectedFilterKey]);
        setSelectedFilterValue([...tempSelectedFilterValue]);
      }
    }
  };

  const handleFilterData = (
    item: FilterDataItem,
    parent: string,
    key: string
  ) => {
    const temp: FilterDataObj[] = filterDataSet;

    const index = temp.findIndex((item) => item.key === key);

    temp[index].searchValue = item.name;
    temp[index].isSelected = true;
    const res = temp[index].data.map((ele) => ({
      ...ele,
      isSelected: item.name === ele.name ? true : false,
    }));

    temp[index].data = res;

    if (index + 1 !== temp.length) {
      let parentId = [item.id];

      for (let i = index + 1; i < temp.length; i++) {
        temp[i].data = temp[i].data.map((ele) => ({
          ...ele,
          isSelected: parentId.includes(ele.parentId),
          isView: parentId.includes(ele.parentId),
        }));

        temp[i].searchValue = "";
        temp[i].isSelected = false;

        parentId = temp[i].data.map((child) => child.id);
      }
    }

    setFilterDataSet([...temp]);
    setSelectedFilterKey((prev) => {
      if (prev.includes(key)) {
        return prev;
      } else {
        return [...prev, key];
      }
    });
    setSelectedFilterValue((prev: any) => {
      // Check if any object in prev has key === key
      if (prev.some((obj: any) => obj.key === key)) {
        const temp = prev.filter((obj: any) => obj.key === key);
        const data = {
          key: key,
          name: item.id,
        };
        return [...temp, data];
      } else {
        const data = {
          key: key,
          name: item.id,
        };
        return [...prev, data];
      }
    });

    setSearchingIndex(null);
  };

  const getSelectedFilterValue = (key: string) => {
    const temp = filterDataSet.find((item: FilterDataObj) => item.key === key);
    if (temp === undefined) {
      return "";
    } else {
      return temp.searchValue;
    }
  };

  const searchValue = (value: string, key: string) => {
    const index = filterDataSet.findIndex((item) => item.key === key);
    const temp: FilterDataObj[] = filterDataSet;

    if (value === "") {
      temp[index].searchValue = "";
    } else {
      const result = temp[index].data.map((ele) => ({
        ...ele,
        isView:
          ele.isView && ele.name.toLowerCase().includes(value.toLowerCase()),
      }));

      temp[index].data = result;
    }
    temp[index].searchValue = value;
    setFilterDataSet([...temp]);
  };

  const checkFilterIsSelected = () => {
    let flag = false;
    for (let index = 0; index < filterDataSet.length; index++){
      const item = filterDataSet[index];
      if (item.searchValue !== "" && item.isSelected) {
        flag = true;
        break;
      }
    };
    setShowSelectedDiv(flag);
  };

  useEffect(() => {
    checkFilterIsSelected();
  }, [filterDataSet]);

  return (
    <div className="filterComponentParentDiv">
    <div className="filterComponentMainDiv">
      <div className="selectedFilterListDiv" style={{display: showSelectedDiv ? 'flex' : 'none'}}>
        {filterDataSet.map((item: FilterDataObj, index) => (
          <>
            {item.searchValue !== "" && item.isSelected && (
              <div
                className="selectedOptionDiv"
                onClick={() => removeFilter(item.key, item.searchValue)}
              >
                <p className="selectedOptionText">{item.searchValue}</p>
                <img src={CrossIcon} />
              </div>
            )}
          </>
        ))}
      </div>
      <div className="filterListMainDiv">
        {filterDataSet.map((item: FilterDataObj, index) => (
          <>
            {enableFilterDiv(item.parent, index) && item.data.length !== 0 && (
              <>
                {searchingIndex === index ? (
                  <>
                    <div className="filterSelectorMainContainer">
                      <div className="chipFilterSelector">
                        <input
                          type="text"
                          placeholder="Search..."
                          value={item.searchValue}
                          className="searchFilterInput"
                          onChange={(e) =>
                            searchValue(e.target.value, item.key)
                          }
                        />
                        <img
                          src={DownArrowIcon}
                          onClick={() => updateSearchingIndex(index)}
                        />
                      </div>
                      <ul className="chipFilterList">
                        {item.data.map((obj: FilterDataItem) => (
                          <>
                            {obj.isView && (
                              <li
                                onClick={() =>
                                  handleFilterData(obj, item.parent, item.key)
                                }
                              >
                                {obj.name}
                              </li>
                            )}
                          </>
                        ))}
                      </ul>
                    </div>
                  </>
                ) : (
                  <>
                    {isChipAlreadySelected(item.key) ? (
                      <div
                        className="selectedOptionDiv"
                        onClick={() => updateSearchingIndex(index)}
                      >
                        <p className="selectedFilterText">
                          {getSelectedFilterValue(item.key)}
                        </p>
                        <img
                          src={DownArrowIcon}
                          style={{ width: "15px", height: "15px" }}
                        />
                      </div>
                    ) : (
                      <div
                        className="unSelectedOptionDiv"
                        onClick={() => updateSearchingIndex(index)}
                      >
                        <p className="unSelectedOptionText">
                          {item.placeholder}
                        </p>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        ))}
      </div>
    </div>
    <img src={CrossIcon} onClick={hideFilter} className="closeIcon" />
    </div>
  );
}

export default FilterComponent;

import React, { useState, useEffect } from "react";
import "./MachineDetailsPopUp.scss";

import Modal from "react-bootstrap/Modal";

import ChevronIcon from "../../assets/icons/icon-feather-chevron-down.svg";
import MachineDetailsChildComponent from "./MachineDetailsChildComponent";
import DataHeadingComponent from "../../Utils/sortComponent/SortDataComponent";
import closeIcon from "../../assets/icons/IconClose.svg";

type MachineDataType = {
  id: string;
  totalCount: number;
  totalQc: number;
  qcFail: number;
  nyqcCount: number;
  status: string;
};

export type SelectedDataCategory = {
  category: string;
  sort: string;
};

function MachineDetailsPopUp({ show, close }) {
  const machineData = [
    {
      id: "M33847445",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347123",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
    {
      id: "M33847447",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347124",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
    {
      id: "M33847448",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347125",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
    {
      id: "M33847449",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347126",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
    {
      id: "M33847450",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347127",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
    {
      id: "M33847451",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347128",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
    {
      id: "M33847452",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347129",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
    {
      id: "M33847453",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347130",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
    {
      id: "M33847454",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347131",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
    {
      id: "M33847455",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347132",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
    {
      id: "M33847456",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347133",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
    {
      id: "M33847457",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347134",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
    {
      id: "M33847458",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347135",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
    {
      id: "M33847459",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347136",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
    {
      id: "M33847460",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347137",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
    {
      id: "M33847461",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347138",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
    {
      id: "M33847462",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347139",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
    {
      id: "M33847463",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347140",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
    {
      id: "M33847464",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347141",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
    {
      id: "M33847442",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347145",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
    {
      id: "M33847466",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347146",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
    {
      id: "M33847467",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347147",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
    {
      id: "M33847468",
      totalCount: 100,
      totalQc: 988,
      qcFail: 347,
      nyqcCount: 12,
      status: "IN-PROGRESS",
    },
    {
      id: "M45347148",
      totalCount: 500,
      totalQc: 334,
      qcFail: 166,
      nyqcCount: 0,
      status: "COMPLETED",
    },
  ];

  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const [selectedDataSort, setSelectedDataSort] =
    useState<null | SelectedDataCategory>(null);

  function handleRowClick(id: string) {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  }

  return (
    <>
      {
        <Modal
          className="modalmainContainer"
          show={show}
          onHide={close}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          animation
        >
          <div className="modalmainContainerHeader">
            <h5 className="title">Machine Details </h5>

            <div className="popUpCloseButton" onClick={close}>
              <img className="closeIcon" src={closeIcon} alt="close" />
            </div>
          </div>

          <Modal.Body className="modalmainContainerBody">
            <div className="machineDetailsBodyContainer">
              <div className="tableRows tableHeader">
                <div className="qrChevron"></div>
                <div className="machineId ">
                  <DataHeadingComponent
                    selectedDataSort={selectedDataSort}
                    setSelectedDataSort={setSelectedDataSort}
                    heading="Machine Id"
                    categoryId="machineId"
                  />
                </div>
                <div className="machineTotalCount ">
                  <DataHeadingComponent
                    selectedDataSort={selectedDataSort}
                    setSelectedDataSort={setSelectedDataSort}
                    heading="Total Count"
                    categoryId="totalCount"
                  />
                </div>
                <div className="machineTotalQc ">
                  <DataHeadingComponent
                    selectedDataSort={selectedDataSort}
                    setSelectedDataSort={setSelectedDataSort}
                    heading="Total QC"
                    categoryId="totalQc"
                  />
                </div>
                <div className="machineQcFail ">
                  <DataHeadingComponent
                    selectedDataSort={selectedDataSort}
                    setSelectedDataSort={setSelectedDataSort}
                    heading="QC Fail"
                    categoryId="qcFail"
                  />
                </div>
                <div className="MachineNyqc ">
                  <DataHeadingComponent
                    selectedDataSort={selectedDataSort}
                    setSelectedDataSort={setSelectedDataSort}
                    heading="NYQC"
                    categoryId="nyqc"
                  />
                </div>
                <div className="machineStatus heading">QC Status</div>
              </div>

              {machineData.map((row: MachineDataType, index) => (
                <div>
                  <div key={row.id} onClick={() => handleRowClick(row.id)}>
                    <div className="tableRows showPointer">
                      <div className="qrChevron">
                        <img
                          src={ChevronIcon}
                          className={`chevronImg ${
                            selectedRows.includes(row.id)
                              ? "rotate-90"
                              : "rotate-0"
                          }`}
                          alt="open"
                        />
                      </div>

                      <div className="machineId dataText">{row.id}</div>
                      <div className="machineTotalCount dataText">
                        {row.totalCount}
                      </div>
                      <div className="machineTotalQc dataText">
                        {row.totalQc}
                      </div>
                      <div className="machineQcFail failed">{row.qcFail}</div>
                      <div className="MachineNyqc dataText">
                        {row.nyqcCount}
                      </div>
                      <div className="machineStatus">
                        <div
                          className={`${
                            row.status == "COMPLETED"
                              ? "statusBg completed"
                              : "statusBg inProgress"
                          }`}
                        >
                          {row.status}
                        </div>
                      </div>
                    </div>
                  </div>
                  {selectedRows.includes(row.id) && (
                    <div>
                      <MachineDetailsChildComponent />
                    </div>
                  )}

                  {index != machineData.length - 1 ? (
                    <div className="bottomLine" />
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            </div>
          </Modal.Body>
        </Modal>
      }
    </>
  );
}

export default MachineDetailsPopUp;

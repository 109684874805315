/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import CardWrapper from "../../../a1Components/cardWrapper";
import DonutChartComp from "../../../a1Components/donutChart";
import SankeyChartDev from "../../../a1Components/E-Charts/SankeyChartDev";
import SnackCard from "../../../a1Components/SnackCards";
import healthscoreicongood from "../../assets/icons/healthscore-icon-good.svg";
import healthscoreiconexcellent from "../../assets/icons/healthscore-icon-excellent.svg";
import healthscoreiconbad from "../../assets/icons/healthscore-icon-bad.svg";
import * as echarts from "echarts/core";
import axios from "axios";
import TabTitleBox from "../../../a1Components/tabTitleBox";
import TileCard from "../../../a1Components/iconTileCard";
import Sortable from "../../../a1Components/sort";
import "./OperationalDashboard.scss";
import styled from "styled-components";
import * as R from "ramda";
import AreaChart from "../../../a1Components/areaChart";
import Emissions from "../../../a1Components/EmissionsComponent";
import TileTypeOne from "../../../a1Components/tileTypeOne";
import StackedHrBarChart from "../../../a1Components/StackedHrBarChart";
import assetIcon from "../../../assets/image/asset-icon.svg";
import StackedLineChart from "../../../a1Components/lineChart";
import { DataFetching } from "../../../a1Components/Servies/DataFetching";
import StackedHorozontalBar from "../../../a1Components/E-Charts/stackedHorizontalBar";
import GaugeChart from "../../../a1Components/Gauge";
import { useStateValue } from "../../../redux/StateProvider";
import ScoreCard from "../../../a1Components/FacilityScoreCard/ScoreCard";
import OptExpandBarChart from "../../../a1Components/E-Charts/OperationalDasboard/OptExpandBarChart";
import ServiceTicketsCards from "../../../a1Components/ServiceTicketsCards/ServiceTicketsCards";
import DataComplianAsset from "../../../a1Components/DataComplianAsset/DataComplianAsset";
import CustomerSuccessTeam from "../../../a1Components/CustomerSuccess/CustomerSuccessTeam";
import TempComplianceOpps from "../../../a1Components/TemperatureCompliance/TempComplianceOpps";
import RepeatedTicketsCard from "../../../a1Components/OperationalDashboardComp/RepeatedTickets/RepeatedTicketsCard";
import PieDoughnutChart from "../../../a1Components/OperationalDashboardComp/PieDoughnut/PieDoughnutChart";
import StackedBarOperational from "../../../a1Components/StackedHrBarChart/StackedBarOperational";

const series = [
  {
    name: "Savings",
    type: "line",
    stack: "Total",
    smooth: true,
    lineStyle: {
      width: 2,
      color: "#36D7B7",
    },
    showSymbol: false,
    areaStyle: {
      opacity: 0.8,
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: "rgba(54, 215, 183, 0.20)",
        },
        {
          offset: 1,
          color: "rgba(255, 255, 255)",
        },
      ]),
    },
    emphasis: {
      focus: "series",
    },
    data: [],
  },
  {
    name: "Consumption",
    type: "line",
    stack: "Total",
    smooth: true,
    lineStyle: {
      width: 2,
      color: "#4cb9f2",
    },
    showSymbol: false,
    areaStyle: {
      opacity: 0.8,
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: "rgb(93, 157, 239)",
        },
        {
          offset: 1,
          color: "rgb(255, 255, 255)",
        },
      ]),
    },
    emphasis: {
      focus: "series",
    },
    data: [],
  },
];

const forecast = [
  {
    name: "Utilization",
    type: "line",
    stack: "Total",
    smooth: true,
    lineStyle: {
      width: 2,
      color: "#DCC6E0",
    },
    showSymbol: false,
    areaStyle: {
      opacity: 0.8,
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: "rgba(220, 198, 224, 0.20)",
        },
        {
          offset: 1,
          color: "rgba(255, 255, 255)",
        },
      ]),
    },
    emphasis: {
      focus: "series",
    },
    data: [],
  },
];

const facilityHealth = [
  {
    name: "Facility Health",
    type: "line",
    stack: "Total",
    smooth: true,
    lineStyle: {
      width: 2,
      color: "#DCC6E0",
    },
    showSymbol: false,
    areaStyle: {
      opacity: 0.8,
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: "rgb(93, 157, 239)",
        },
        {
          offset: 1,
          color: "rgb(255, 255, 255)",
        },
      ]),
    },
    emphasis: {
      focus: "series",
    },
    data: [],
  },
];

const Tabh5 = styled.h5`
  padding: 4%;
  font-size: 18px;
  width: 8vw;
  height: 6vh;
  text-align: center;
  color: black;
  border-radius: 5px;
  ${({ active }) =>
    active &&
    `
  color: white;
  background: #426BFA;
  opacity: 1;
`}
`;
const Tab = styled.button`
  font-size: 20px;
  padding-top: 8px;
  cursor: pointer;
  opacity: 0.6;
  border: 0;
  outline: 0;
  background: white;
  ${({ active }) =>
    active &&
    `
    border-bottom: 2px solid #426BFA;
    opacity: 1;
  `}
`;
const ButtonGroup = styled.div`
  display: flex;
`;

const stackedAreaChartVal = {
  color: ["#80FFA5"],
  title: {
    text: "Facility Health trend",
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
      label: {
        backgroundColor: "#4cb9f2",
      },
    },
  },
  legend: {
    data: ["Line 1"],
    bottom: 0,
  },
  toolbox: {
    showDownload: false,
  },
  grid: {
    left: "1%",
    right: "1%",
    bottom: "1%",
    top: "2%",
    containLabel: true,
  },
  xAxis: [
    {
      type: "category",
      boundaryGap: false,
      data: [
        "Day/Time/Hr",
        "Day/Time/Hr",
        "Day/Time/Hr",
        "Day/Time/Hr",
        "Day/Time/Hr",
      ],
      axisTick: { show: false },
      axisLine: {
        show: false,
      },
      splitLine: { show: false },
    },
  ],
  yAxis: [
    {
      type: "value",
      axisTick: { show: false },
      axisLine: {
        show: false,
      },
      splitLine: { show: false },
    },
  ],
  series: [
    {
      name: "Line 1",
      type: "line",
      stack: "Total",
      smooth: true,
      lineStyle: {
        width: 2,
        color: "#36D7B7",
      },
      showSymbol: false,
      areaStyle: {
        opacity: 0.8,
        color: "#36D7B7",
      },
      emphasis: {
        focus: "series",
      },
      data: [20, 40, 30, 60, 70],
    },
  ],
};

const gaugeChartData = {
  height: 200,
  series: [
    {
      type: "gauge",
      center: ["50%", "60%"],
      startAngle: 200,
      endAngle: -20,
      min: 0,
      max: 100,
      splitNumber: 12,
      itemStyle: {
        color: "#4CAF50",
      },
      progress: {
        show: true,
        width: 10,
      },
      pointer: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          width: 10,
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
      anchor: {
        show: false,
      },
      title: {
        show: false,
      },
      detail: {
        show: false,
      },
      data: [
        {
          value: 81.8,
        },
      ],
    },
  ],
};

const pieChartDev = {
  tooltip: {
    trigger: "item",
  },
  legend: {
    show: true,
    bottom: "100px",
  },

  series: [
    {
      name: "Access From",
      top: "-66px",
      width: "171px",
      height: "275px",
      position: "absolute",
      left: "0px",
      type: "pie",
      radius: ["70%", "50%"],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: "center",
      },

      emphasis: {
        label: {
          show: true,
          fontSize: "40",
          fontWeight: "bold",
        },
      },
      labelLine: {
        show: false,
      },
      data: [
        { value: 301, name: "Active Assets" },
        { value: 69, name: "InActive Assets" },
      ],
    },
  ],
};
async function fetchSummary() {
  const url = "https://snakeoil-api.atsuyatech.com/Cluster";
  const resp = await fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return resp.json();
}

async function assetOverviewFun() {
  const url = "https://snakeoil-api.atsuyatech.com/Operation_Dashboard";
  const resp = await fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return resp.json();
}

async function serviceTicketsFun() {
  const url = "https://snakeoil-api.atsuyatech.com/Operation_Dashboard";
  const resp = await fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return resp.json();
}

async function DataPointsFun() {
  const url = "https://snakeoil-api.atsuyatech.com/Operation_Dashboard";
  const resp = await fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return resp.json();
}

async function CustomerSuccessFun() {
  const url = "https://snakeoil-api.atsuyatech.com/Operation_Dashboard";
  const resp = await fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return resp.json();
}

async function TemperatureComplianceFun() {
  const url = "https://snakeoil-api.atsuyatech.com/Operation_Dashboard";
  const resp = await fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return resp.json();
}

async function RepeatedTicketsFun() {
  const url = "https://snakeoil-api.atsuyatech.com/Operation_Dashboard";
  const resp = await fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return resp.json();
}

const piChart = {
  total_assets: "100",
  Online_Assets: "80",
  Offline_Assets: "20",
  ticketsResolved: "51",
  ticketsNotResolved: "28",
  resTilte: "Tickets Resolved",
  notResTitle: "Tickets Not Resolved",
  active_variance: "3% more than yesterday",
  active_context: "POSITIVE",
  inactive_variance: "2.5% less than yesterday",
  inactive_context: "NEGATIVE",
};

async function fetchUtilization() {
  const url = "https://snakeoil-api.atsuyatech.com/Cluster";
  const resp = await fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return resp.json();
}

async function distributionTabFun() {
  const url = "https://snakeoil-api.atsuyatech.com/Cluster";
  const resp = await fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return resp.json();
}
async function shankeyLive() {
  const url = "https://snakeoil-api.atsuyatech.com/Cluster";
  const resp = await fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return resp.json();
}
async function dieselGeneratorFun() {
  const url = "https://snakeoil-api.atsuyatech.com/Cluster";
  const resp = await fetch(url, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return resp.json();
}

function OperationalDashboard({
  isDark,
  theme,
  rightOpen,
  toggleBtn,
  themeColors,
}:any) {
  const headingColor = isDark
    ? { color: themeColors?.headingColor, fontSize: "10px" }
    : {};
  const [utilizationTab, setUtilizationTab] = useState([]);
  const [assetUtilisation, setAssetUtilisation] = useState([]);
  const [summary, setSummary] = useState([]);
  const [facilityHealthData, setFacilityHealthData] = useState([]);
  const [facilityGaugeData, setFacilityGaugeData] = useState([]);
  const [utilisationTrendOption, setUtilisationTrendOption] = useState([]);
  const [utilisationForecastOption, setUtilisationForecastOption] = useState(
    []
  );
  const [facilityHealthAreaData, setFacilityHealthAreaData] = useState([]);
  const [yAxisData, setYAxisData] = useState([]);
  const [col1Data, setCol1Data] = useState([]);
  const [col2Data, setCol2Data] = useState([]);
  const [col3Data, setCol3Data] = useState([]);
  const [yourAssetData, setYourAssetData] = useState(null);
  const [usageData, setUsageData] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [lowPerformance, setLowperformance] = useState(false);
  const [assetTabActive, setAssetTabActive] = useState("Asset Performance");
  const [assetTabMenu, setAssetTabMenu] = useState([
    "Asset Performance",
    "Score Trend",
  ]);
  const [assetScores, setAssetScores] = useState([]);
  const [scoreLine, setScoreLine] = useState([]);
  const [distributionTab, setDistributionTab] = useState([]);
  const [distributionactiveTab, setDistributionactiveTab] = useState(1);
  const [shankeyData, setShankeyData] = useState({});
  const [dieselGenerator, setDieselGenerator] = useState([]);
  const [assetOverview, setAssetOverview] = useState();
  const [recentTickets, setRecentTickets] = useState();
  const [compliantAssets, setCompliantAssets] = useState();
  const [customerSuccess, setCustomerSuccess] = useState();
  const [tempComplianceFun, setTempComplianceFun] = useState();
  const [repeatedTickets, setRepeatedTickets] = useState();
  const [{ loginData }, dispatch] = useStateValue();
  const [portlets, setPortlets] = useState([]);
  const [snackCard, setsnackCard] = useState([]);

  useEffect(() => {
    if (!R.isEmpty(loginData)) {
      const assetPortlets = loginData.portalDetails.filter(
        (el) => el.portalPageId === "OperationalViewDB"
      );
      const portletIds = assetPortlets[0].portlets.map(
        (j) => j.portalPortletId
      );
      setPortlets(portletIds);
    }
  }, [loginData]);

  useEffect(async () => {
    var data = new DataFetching();
    var applyData = await data.getSnackCard();
    setsnackCard(applyData.data);
  }, []);

  function handleTabClick(id) {
    setActiveTab(id);
  }
  //function written for the distribution card tab click
  function handleTabClickDist(id) {
    setDistributionactiveTab(id);
  }

  useEffect(() => {
    fetchSummary().then((result) => {
      if (result) {
        setSummary(result?.ClusterAssetSummary);
      }
    });
    assetOverviewFun().then((result) => {
      if (result) {
        var asset_view_data = result?.Asset_Overview;
        setAssetOverview(asset_view_data?.drillDown_BarChart);
      }
    });

    serviceTicketsFun().then((result) => {
      if (result) {
        var asset_view_data = result?.Service_Tickets;
        setRecentTickets(asset_view_data?.Recent_Tickets);
      }
    });

    DataPointsFun().then((result) => {
      if (result) {
        setCompliantAssets(result?.Data_Points?.Low_Compliant_Assets);
      }
    });

    CustomerSuccessFun().then((result) => {
      if (result) {
        setCustomerSuccess(result?.Customer_Success_Team?.Data_Cards);
      }
    });

    TemperatureComplianceFun().then((result) => {
      if (result) {
        setTempComplianceFun(
          result?.temperature_Compliance?.Top_Compliant_Assets
        );
      }
    });

    RepeatedTicketsFun().then((result) => {
      if (result) {
        setRepeatedTickets(
          result?.Asset_For_Repeated_Tickets?.Repeated_Tickets
        );
      }
    });
    dispatch({
      type: "PATH_NAME",
      pathName: "/",
    });
  }, []);

  useEffect(() => {
    async function getYourAssets() {
      try {
        const response = await axios.get(
          "https://snakeoil-api.atsuyatech.com/utilisation"
        );
        // Your asset data start
        let yourAssets = response?.data?.yourAssetsDB;
        setYourAssetData(yourAssets);
        setYAxisData(yourAssets.map((el) => el.assetName));
        setCol1Data(yourAssets.map((el) => el.performace.stackValue[0]));
        setCol2Data(yourAssets.map((el) => el.performace.stackValue[1]));
        setCol3Data(yourAssets.map((el) => el.performace.stackValue[2]));
        setScoreLine(yourAssets.map((el) => el.score.score));
      } catch (error) {
        // console.error(error, "getYourAssets");
      }
    }
    getYourAssets();
  }, []);

  useEffect(() => {
    fetchUtilization().then((result) => {
      if (result) {
        setUtilizationTab(result?.ClusterUtilisation?.utilizationTab);
        setActiveTab(result?.ClusterUtilisation?.utilizationTab[0]?.id);
        setAssetUtilisation(result?.ClusterUtilisation?.assetUtilisation);
        setAssetScores(result?.ClusterUtilisation?.assetScores);
        const savings = {
          ...series[0],
          data: result?.ClusterUtilisation?.utilisationTrendData[0].savings,
        };
        const consumption = {
          ...series[1],
          data: result?.ClusterUtilisation?.utilisationTrendData[0].consumption,
        };
        const forecastData = {
          ...forecast[0],
          data: result?.ClusterUtilisation?.forecast[0].data,
        };
        setUtilisationTrendOption([savings, consumption]);
        setUtilisationForecastOption([forecastData]);
        setUsageData(result?.ClusterUtilisation?.forecast[0].usage);
      }
    });
  }, []);

  useEffect(() => {
    distributionTabFun().then((result) => {
      if (result) {
        setDistributionTab(result?.ClusterDistribution);
      }
    });
    // dieselGeneratorFun().then((result) => {
    //     if (result) {
    //         let dgResult = result?.ClusterDieselGenerator;
    //         for (var i = 0; i < dgResult.length; i++) {
    //             dgResult[i].icon = dgData[i]
    //         }
    //         setDieselGenerator(result?.ClusterDieselGenerator);
    //     }
    // });
    shankeyLive().then((result) => {
      if (result) {
        setShankeyData(result?.ClusterData?.shankeyData?.[0]);
      }
    });
  }, []);

  function scoreSort(a, b) {
    let aProperty = a.score.score;
    let bProperty = b.score.score;
    if (
      !R.isEmpty(aProperty) &&
      !R.isNil(aProperty) &&
      !R.isEmpty(bProperty) &&
      !R.isNil(bProperty)
    ) {
      let aInt = parseInt(aProperty[aProperty.length - 1]);
      let bInt = parseInt(bProperty[bProperty.length - 1]);
      if (lowPerformance) {
        return aInt - bInt;
      } else {
        return bInt - aInt;
      }
    }
  }

  function handlePerformers(val) {
    setLowperformance(val.target.value === "low" ? true : false);
    if (!R.isEmpty(yourAssetData)) {
      let sortAsset = yourAssetData?.sort(scoreSort);
    }
  }

  return (
    <>
      <div className="row">
        <div
          className={
            !rightOpen ? "col-xl-6 col-12" : toggleBtn ? "col-12" : "col-8"
          }
        >
          {!R.isEmpty(portlets) && portlets.includes("ASSET_OVERVIEW") && (
            <CardWrapper
              headerLabel="Asset OverView"
              footerLabel="13 of your assets are over utilized. "
              infoTip="Your facility health is calcilated by Lorem ipsum dolor sit amet, consectetur
                           adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor magna aliqua."
              themeColors={isDark ? theme.dark.colors : theme.light.colors}
            >
              <div className="facilityScore">
                {assetOverview && <OptExpandBarChart />}
              </div>
            </CardWrapper>
          )}

          {!R.isEmpty(portlets) && portlets.includes("SERVICE_TICKETS") && (
            <CardWrapper
              headerLabel="Service Tickets"
              footerLabel="13 of your assets are over utilized. "
              infoTip="Your facility health is calcilated by Lorem ipsum dolor sit amet, consectetur
                           adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor magna aliqua."
              themeColors={isDark ? theme.dark.colors : theme.light.colors}
            >
              <DonutChartComp data={piChart} />
              <ServiceTicketsCards data={recentTickets} />
            </CardWrapper>
          )}

          {!R.isEmpty(portlets) && portlets.includes("DATA_POINTS") && (
            <CardWrapper
              headerLabel="Data Points"
              footerLabel="13 of your assets are over utilized. "
              infoTip="Your facility health is calcilated by Lorem ipsum dolor sit amet, consectetur
                           adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor magna aliqua."
              themeColors={isDark ? theme.dark.colors : theme.light.colors}
            >
              <DataComplianAsset data={compliantAssets} />
            </CardWrapper>
          )}

          {/* <CardWrapper
                        headerLabel="Diesel Generator"
                        footerLabel="Run hours are 70% higher than yesterday."
                        infoTip="Diesel Generator Usage.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                         sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                        themeColors={isDark ? theme.dark.colors : theme.light.colors}
                    >
                        <div
                            className="d-flex-titleCard"
                            style={{ paddingTop: "40px", paddingBottom: "25px" }}
                        >
                            {dieselGenerator?.map((el, i) => (
                                <TileCard
                                    key={i}
                                    icon={el.icon}
                                    title={el.title}
                                    timeConsumed={el.timeConsumed}
                                    trendVal={el.variance}
                                />
                            ))}
                        </div>
                    </CardWrapper> */}

          {/* <CardWrapper
                        headerLabel="Distribution Across Facilities"
                        footerLabel="Run hours are 70% higher than yesterday."
                        infoTip="Sankey diagram shows the distribution of each parameter.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                        themeColors={isDark ? theme.dark.colors : theme.light.colors}
                    >
                        <div className="cardWrapper-distTab">
                            {!R.isEmpty(distributionTab) && (
                                <div className="tabTitleBox-wrapper">
                                    {distributionTab.map((el, i) => (
                                        <div key={i} className={`${distributionactiveTab === el.id ? "active-Border" : "border-sty"}`} onClick={() => handleTabClickDist(el.id)}>
                                            <h6 className={`${distributionactiveTab === el.id ? "distributionTab-active tabTitleBox-wrapper-active" : "distributionTab"}`} onClick={() => handleTabClickDist(el.id)}>{el.title}</h6>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {!R.isEmpty(distributionTab) && (
                                <div className="tabTitleBox-wrapper mobileDrop">
                                    <select className="mobileDropSelect">
                                        {distributionTab.map((el, i) => (
                                            <option key={i} value={el.title}>
                                                {el.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            <SankeyChartDev data={shankeyData} />
                        </div>
                    </CardWrapper> */}

          {/* {
                          !R.isEmpty(portlets) && portlets.includes("CLUSTER_KEY_UTILISATION") && 
                          <CardWrapper
                          headerLabel="Key Utilisation"
                          footerLabel="Shift 1 has the highest utilisation (85%). "
                          infoTip="Key utilization by each shifts.
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                          themeColors={isDark ? theme.dark.colors : theme.light.colors}
                          >

                          </CardWrapper>

                    } */}
          {/* {
                          !R.isEmpty(portlets) && portlets.includes("CLUSTER_EMISSIONS") && 
                          <CardWrapper
                          headerLabel="Key Utilisation"
                          footerLabel="Shift 1 has the highest utilisation (85%). "
                          infoTip="Key utilization by each shifts.
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                          themeColors={isDark ? theme.dark.colors : theme.light.colors}
                      >
                          <StackedHorozontalBar />
                      </CardWrapper>
                    } */}

          {/* {
                          !R.isEmpty(portlets) && portlets.includes("CLUSTER_EMISSIONS") && 
                          <CardWrapper
                          headerLabel="CLUSTER_EMISSIONS Reduced"
                          footerLabel="Emissions 10% more then past week. "
                          infoTip="Emissions in the facility 
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                          themeColors={isDark ? theme.dark.colors : theme.light.colors}
                      >
                          <Emissions Contributing="Top Assets Contributing to Emissions" />
                      </CardWrapper>
                    } */}
        </div>
        <div
          className={
            !rightOpen ? "col-xl-6 col-12" : toggleBtn ? "col-12" : "col-8"
          }
        >
          {!R.isEmpty(portlets) && portlets.includes("ASSET_SUMMARY") && (
            <CardWrapper
              headerLabel="Asset Summary"
              footerLabel="13 of your assets are over utilized. "
              infoTip="Your facility health is calcilated by Lorem ipsum dolor sit amet, consectetur
                           adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor magna aliqua."
              themeColors={isDark ? theme.dark.colors : theme.light.colors}
            >
              <StackedBarOperational
                yAxisData={yAxisData}
                col1Data={col1Data}
                col2Data={col2Data}
                col3Data={col3Data}
                data={[]}
                legendBottom={10}
                legendLeft="12%"
              />
            </CardWrapper>
          )}

          {!R.isEmpty(portlets) && portlets.includes("OFFLINE_ASSETS") && (
            <CardWrapper
              headerLabel="Offline Assets"
              footerLabel="13 of your assets are over utilized. "
              infoTip="Your facility health is calcilated by Lorem ipsum dolor sit amet, consectetur
                           adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor magna aliqua."
              themeColors={isDark ? theme.dark.colors : theme.light.colors}
            >
              <PieDoughnutChart />
            </CardWrapper>
          )}

          {!R.isEmpty(portlets) &&
            portlets.includes("CUSTOMER_SUCCESS_TEAM") && (
              <CardWrapper
                headerLabel="CUSTOMER SUCCESS TEAM"
                footerLabel="13 of your assets are over utilized. "
                infoTip="Your facility health is calcilated by Lorem ipsum dolor sit amet, consectetur
                           adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor magna aliqua."
                themeColors={isDark ? theme.dark.colors : theme.light.colors}
              >
                <CustomerSuccessTeam data={customerSuccess} />
              </CardWrapper>
            )}
          {!R.isEmpty(portlets) &&
            portlets.includes("TEMPERATURE_COMPLIANCE") && (
              <CardWrapper
                headerLabel="TEMPERATURE COMPLIANCE"
                footerLabel="13 of your assets are over utilized. "
                infoTip="Your facility health is calcilated by Lorem ipsum dolor sit amet, consectetur
                           adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor magna aliqua."
                themeColors={isDark ? theme.dark.colors : theme.light.colors}
              >
                {/* TempComplianceFun     */}
                <TempComplianceOpps data={tempComplianceFun} />
              </CardWrapper>
            )}
          {!R.isEmpty(portlets) &&
            portlets.includes("ASSETS_WITH_REPEATED_TICKETS") && (
              <CardWrapper
                headerLabel="Assets With Most Number Of Repeated Tickets"
                footerLabel="13 of your assets are over utilized. "
                infoTip="Your facility health is calcilated by Lorem ipsum dolor sit amet, consectetur
                           adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolor magna aliqua."
                themeColors={isDark ? theme.dark.colors : theme.light.colors}
              >
                <RepeatedTicketsCard data={repeatedTickets} />
              </CardWrapper>
            )}

          {/* <CardWrapper
                        headerLabel="Asset Summary"
                        footerLabel="20 assets are inactive for more than 5 days."
                        infoTip="Total asset details in the facility. 
                        Lorem ipsum dolor sit amet, consectetur 
                        adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                        themeColors={isDark ? theme.dark.colors : theme.light.colors}
                    >
                        {!R.isEmpty(summary) ? (
                            <DonutChartComp data={summary[0]} />
                        ) : (
                            <p className="placeholder-glow">
                                <span className="placeholder col-12" />
                            </p>
                        )}
                    </CardWrapper> */}
          {/* {
                   !R.isEmpty(portlets) && portlets.includes("CLUSTER_PERFORMANCE") && 
                   <CardWrapper
                   headerLabel=" CLUSTER_PERFORMANCE"
                   footerLabel="More than 40% assets are running greater than 80% efficiency."
                   infoTip="Top performing assets 
                   Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                   sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                   themeColors={isDark ? theme.dark.colors : theme.light.colors}
               >
                   {!R.isEmpty(assetTabMenu) && assetTabMenu.map((el, i) =>
                       <div key={i} className={assetTabActive === el ? "active assetTabMenu" : "assetTabMenu"} onClick={() => setAssetTabActive(el)}>
                           <h6>{el}</h6>
                       </div>)
                   }
                   {assetTabActive === "Asset Performance" ? <>
                       <div className="scoreCard">
                           {!R.isEmpty(assetScores) && assetScores.map((el, i) =>
                               <div key={i} className="col-md-4 col-12">
                                   <div className="scoreCard-item">
                                       <img src={assetIcon} alt="" />
                                       <div>
                                           <h2 className={el.variance.varianceContext === "POSITIVE" ? "positive" : "negative"}>{el.assetCount} Assets <FontAwesomeIcon icon={el.variance.varianceContext === "NEGATIVE" ? faArrowDown : faArrowUp} /> <span>{el.variance.value + "" + el.variance.varianceAbbreviation}</span></h2>
                                           <p>{el.scoreMsg}</p>
                                       </div>
                                   </div>
                               </div>)
                           }
                       </div>
                       <div style={{ paddingLeft: "20px" }}>
                           <div className="radio-input-warpper">
                               <input className="form-check-input" type="radio" id="top" name="performers" value="top" onClick={handlePerformers} checked />
                               <label htmlFor="top">Top Performers</label>
                               <input className="form-check-input" type="radio" id="low" name="performers" value="low" onClick={handlePerformers} />
                               <label htmlFor="low">Low Performers</label>
                           </div>
                           <div className="hrBarWrapper">
                               <StackedHrBarChart yAxisData={yAxisData} col1Data={col1Data} col2Data={col2Data} col3Data={col3Data} data={[]} legendBottom={10} legendLeft="12%" />
                               <a href="">View All Assets <FontAwesomeIcon icon={faAngleRight} /></a>
                           </div>
                       </div>
                   </> : <div className="hrBarWrapper noHeight">
                       <StackedLineChart yAxisData={yAxisData} data={scoreLine} />
                       <a href="">View All Assets <FontAwesomeIcon icon={faAngleRight} /></a>
                   </div>}
               </CardWrapper>
                    } */}
          {/* {
                   !R.isEmpty(portlets) && portlets.includes("CLUSTER_PERFORMANCE") && 
                   <CardWrapper
                   headerLabel="CLUSTER_UTILISATION"
                   footerLabel="You are expected to consume more energy tomorrow compared to today."
                   infoTip="Utilization and consumption of each parameters 
                   Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                   sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                   themeColors={isDark ? theme.dark.colors : theme.light.colors}
               >
                   {!R.isEmpty(utilizationTab) && (
                       <div className="tabTitleBox-wrapper">
                           {utilizationTab.map((el, i) => (
                               <TabTitleBox
                                   key={i}
                                   handleTabClick={(e) => handleTabClick(e)}
                                   active={activeTab === el.id}
                                   data={utilizationTab}
                                   loopData={el}
                               />
                           ))}
                       </div>
                   )}
                   <h4 className="mb-20">Utilization trend</h4>
                   {!R.isEmpty(utilisationTrendOption) && <div style={{ width: "100%", height: "230px", marginBottom: "20px" }}>
                       <AreaChart series={utilisationTrendOption} height="230px" /></div>}
                   {!R.isEmpty(assetUtilisation) && <Sortable collection={assetUtilisation} />}
                   <h4 className="mb-15">Forecast</h4>
                   <div className="row mb-20">
                       <div className="col-sm-8">
                           <div style={{ width: "100%", height: "200px", marginBottom: "20px" }}>
                               <AreaChart series={utilisationForecastOption} height="200px" />
                           </div>
                       </div>
                       {!R.isEmpty(usageData) && <div className="col-sm-4 pl-1">
                           <TileTypeOne data={usageData[0]} />
                           <TileTypeOne data={usageData[1]} />
                       </div>}
                   </div>
               </CardWrapper>
} */}
        </div>
        {/* <div className={!rightOpen ? " pl-2" : toggleBtn ? "p-3" : "p-4"}>
                    <FooterAone />
                </div> */}
      </div>
    </>
  );
}

export default OperationalDashboard;

/* eslint-disable no-const-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck

import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import IconClose from "../../../../assets/icons/IconClose.svg";
import FacilityIcon from "../../../../assets/icons/FacilityIcon.svg";
import DeleteFacilityIcon from "../../../../assets/icons/DeleteFacilityIcon.svg";
import FacilityTypeIcon from "../../../../assets/icons/FacilityTypeIcon.svg";
import FacilityTypeCountIcon from "../../../../assets/icons/FacilityTypeCountIcon.svg";
import FacilityCardAssetIcon from "../../../../assets/icons/FacilityCardAssetIcon.svg";
import CardHoverIcon from "../../../../assets/icons/CardHoverIcon.svg";
import ShiftAssetIcon from "../../../../assets/icons/ShiftAssetIcon.svg";
import AddIcon from "../../../../assets/icons/AddIcon.svg";
import deleteIcon from "../../../../assets/icons/addFacDeleteIcon.png";
import FacilityViewPopIcon from "../../../../assets/icons/FacilityViewPopIcon.svg";
import IconEdit from "../../../../assets/icons/IconEdit.svg";
import IconDelete from "../../../../assets/icons/IconDelete.svg";
import CountryIcon from "../../../../assets/icons/CountryIcon.svg";
import EditAddressIcon from "../../../../assets/icons/EditAddressIcon.svg";

import "./FacilityCard.scss";
import FacilitypopUpScreen from "../../facilitypopUpScreen/FacilitypopUpScreen";
import CustomSwitch from "../../../Users/usersCardContainer/customSwitch/CustomSwitch";
import { SettingsApiServices } from "../../../../services/settingsPageServices/SettingsApiServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormValidator from "../../../../Utils/FormValidations/FormValidations";
import ReactTooltip from "react-tooltip";

// import FormValidator from "../../Utils/FormValidations/FormValidations";

function FacilityCard(props: any) {
  const { addToSelectedList, data, selected, refreshList } = props;
  const [viewFacilty, setViewFacility] = useState(false);
  const [delFacilityPopup, setDeleteFacility] = useState(false);
  // const [activeDelete, setActiveDelete] = useState('');
  const [show, setShow] = useState(false);
  const deleteFacility = (cardName: any) => {
    // setActiveDelete(cardName)
    setDeleteFacility(true);
  };
  const [cardViewShow, setCardViewshow] = useState(true);
  const setViewFacilityTrue = () => {
    if (cardViewShow) {
      setViewFacility(true);
    }
  };

  const openPopUpScreen = () => {
    setShow(true);
  };

  const closePopUpScreen = () => {
    setShow(false);
  };

  const [showDeleteFacility, setShowDeleteFacility] = useState(false);

  // function assign() {
  //   if (data) {
  //     const phone = data.phoneVM[0];
  //     const address = data.addressVM[0];

  //     setAddAfac ({
  //       facilityName: data.facility.facilityName || "",
  //       facilityTypeId: data.facility.facilityTypeId || "",
  //       facilityBrandName: data.facilityBrandName || "",
  //       ownerPartyId: localStorage.getItem("@partyID") || "",
  //       countryGeoId: address.postalAddress !== undefined ? address.postalAddress.countryGeoId :  "",
  //       emailAddress: data.emailVM[0].contactMech.infoString || "",
  //       emailPurpose: `[${data.emailVM[0].facilityContactMechPurposes[0].contactMechPurposeTypeId}]`,
  //       phonePurpose: `[${phone.facilityContactMechPurposes[0].contactMechPurposeTypeId}]` || "",
  //       phoneNumberPrefix: phone.telecomNumber.countryCode || "",
  //       phoneNumber: phone.telecomNumber.contactNumber || "",
  //       externalId: data.facilityId || "",
  //       addressPurpose: `[${address.facilityContactMechPurposes[0].contactMechPurposeTypeId}]` || "",
  //       toName: address.postalAddress.toName || "",
  //       attnName: address.postalAddress.attnName || "",
  //       address1: address.postalAddress.address1 || "",
  //       address2: address.postalAddress.address2 || "",
  //       city: address.postalAddress.city || "",
  //       stateGeoCodeId: address.postalAddress.stateProvinceGeoId || "",
  //       postalCode: address.postalAddress.postalCode || "",
  //       facilitySizeUomId: data.facility.facilitySizeUomId || "",
  //       facilitySize: data.facility.facilitySize || "",
  //       latitude: data.facility.latitude  !== undefined ? data.facility.latitude : "",
  //       longitude: data.facility.longitude !== undefined ? data.facility.longitude : "",
  //     })
  //   }
  // }
  const phone = data.phoneVM[0];
  const address = data.addressVM[0];
  const email = data.emailVM[0];
  console.log(data, "888test");
  const addFacility = {
    facilityName: data.facility.facilityName || "",
    facilityTypeId: data.facility.facilityTypeId || "",
    facilityBrandName: data.facilityBrandName || "",
    facilityId: data.facility.facilityId,
    ownerPartyId: localStorage.getItem("@partyID") || "",
    countryGeoId:
      address !== undefined ? address.postalAddress.countryGeoId : "",
    emailAddress:
      email !== undefined ? data.emailVM[0].contactMech.infoString : "",
    emailPurpose:
      email !== undefined
        ? `[${data.emailVM[0].facilityContactMechPurposes[0]?.contactMechPurposeTypeId}]`
        : "",
    phonePurpose:
      phone !== undefined
        ? `[${phone.facilityContactMechPurposes[0]?.contactMechPurposeTypeId}]`
        : "",
    phoneNumberPrefix:
      phone !== undefined ? phone.telecomNumber.countryCode : "",
    phoneNumber: phone !== undefined ? phone.telecomNumber.contactNumber : "",
    externalId: data.facility.facilityId || "",
    addressPurpose:
      address !== undefined
        ? `[${address.facilityContactMechPurposes[0]?.contactMechPurposeTypeId}]`
        : "",
    toName: address !== undefined ? address.postalAddress.toName : "",
    attnName: address !== undefined ? address.postalAddress.attnName : "",
    address1: address !== undefined ? address.postalAddress.address1 : "",
    address2: address !== undefined ? address.postalAddress.address2 : "",
    city: address !== undefined ? address.postalAddress.city : "",
    stateGeoCodeId:
      address !== undefined ? address.postalAddress.stateProvinceGeoId : "",
    postalCode: address !== undefined ? address.postalAddress.postalCode : "",
    facilitySizeUomId: data.facility.facilitySizeUomId || "",
    facilitySize: data.facility.facilitySize || 0.0,
    latitude:
      data.facility.latitude !== undefined ? data.facility.latitude : 0.1,
    longitude:
      data.facility.longitude !== undefined ? data.facility.longitude : 0.1,
    addressContactMechId: "10331",
    emailContactMechId: "10329",
    phoneContactMechId: "10321",
  };

  // useEffect=(()=>{
  //   assign();
  // },[data])

  // Asset Deactivate..
  const deActivateAsset = async () => {
    let tokenID = localStorage.getItem("@tokenID");
    try {
      const token = tokenID;
      const endpoint =
        "https://dev-tnt.app.aone.ai/api/1.0/facility/deactivateAssets";
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      const payload = {
        facilityId: data.facility.facilityId,
      };
      const response = await fetch(endpoint, {
        method: "DELETE",
        headers: headers,
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const resp = await response.json();
      setShowDeleteFacility(false);
      setDeleteFacility(false);
      setViewFacility(false);
      setShowEditFacility(false);
      // alert(resp.responseMessage);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const singleDisableAsset = async () => {
    let tokenID = localStorage.getItem("@tokenID");
    try {
      const token = tokenID;
      const endpoint = "https://dev-tnt.app.aone.ai/api/1.0/facility/disable";
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      const payload = {
        facilityId: data.facility.facilityId,
      };
      const response = await fetch(endpoint, {
        method: "DELETE",
        headers: headers,
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const resp = await response.json();
      setShowDeleteFacility(false);
      setDeleteFacility(false);
      setViewFacility(false);
      setShowEditFacility(false);
      // call faciity list..
      refreshList();
      // alert(resp.responseMessage);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // Shift Assets
  const [shiftAssetsPop, setShiftAssetsPop] = useState(false);

  // setShift asset popup..
  const shiftAsset = () => {
    // setDeleteFacility(false);
    setDisableFacility(false);
    setShiftAssetsPop(true);
    getListForShiftAsset();
  };

  const closeShiftAsset = () => {
    setShiftAssetsPop(false);
    // setDeleteFacility(true);
    setDisableFacility(true);
  };
  // Add User Popup
  function generateID(length: any) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const [addUserPopup, setAddUserPopup] = useState(false);
  const [mapUsertoFaclityArr, setMapUsertoFaclityArr] = useState([]);
  const addFacilityRole = () => {
    setMapUsertoFaclityArr((prevState) => [
      ...prevState,
      {
        id: generateID(4),
        facilityList: ["Select User"],
        rolesList: ["Select Role"],
      },
    ]);
  };
  const deleteAddFaciityRole = (id, index) => {
    handleDelete(index);
    setMapUsertoFaclityArr((prevState) =>
      prevState.filter((el) => el.id !== id)
    );
  };
  const [disableFacility, setDisableFacility] = useState(false);

  // Edit Facility Popup
  const [showEditFacility, setShowEditFacility] = useState(false);
  const [activeTab, onclickActivePopup] = useState("basic");

  // Phone Set Purpose
  // const [showPhonePurpose, setShowPhonePurpose] = useState(false);
  // const [showEmailPurpose, setShowEmailPurpose] = useState(false);
  // Toggle
  const [callBack, setToggle] = useState(true);
  const setCallBack = (name) => {
    if (!callBack) {
      // Disable or delete or shift facility Popup has to call here............................................
      // disableFacility
      setDisableFacility(true);
    }
    setToggle(!callBack);
  };

  const setDisableFacfalse = () => {
    setDisableFacility(false);
    if (data.facility.closedDate == null) {
      setToggle(false);
    } else {
      setToggle(true);
    }
  };

  useEffect(() => {
    if (data.facility.closedDate == null) {
      setToggle(false);
    } else {
      setToggle(true);
    }
  }, [data]);
  // Edit Address Popup
  // const [showEditAddress, setShowEditAddress] = useState(false);

  // assiging API response to Object..
  const emailActiveList = useRef([]);
  // setEmailActive
  const phoneActiveList = useRef([]);
  // setPhoneActiveList
  const addressPurpose = useRef([]);
  // setAddressPurposes
  const setShowCreateFac = () => {
    let emailPurp =
      email == undefined
        ? ""
        : email.hasOwnProperty("facilityContactMechPurposes")
        ? email.facilityContactMechPurposes.map(
            (obj) => obj.contactMechPurposeTypeId
          )
        : "";
    let phonePurp =
      phone == undefined
        ? ""
        : phone.hasOwnProperty("facilityContactMechPurposes")
        ? phone.facilityContactMechPurposes.map(
            (obj) => obj.contactMechPurposeTypeId
          )
        : "";
    let addPurpose =
      address == undefined
        ? ""
        : address.hasOwnProperty("facilityContactMechPurposes")
        ? address.facilityContactMechPurposes.map(
            (obj) => obj.contactMechPurposeTypeId
          )
        : "";
    // console.log(emailPurp,"final77777");
    emailActiveList.current = emailPurp;
    // setEmailActive(emailPurp);
    phoneActiveList.current = phonePurp;
    // setPhoneActiveList(phonePurp);
    addressPurpose.current = addPurpose;
    // setAddressPurposes(addPurpose);

    setCreateFacilityObj({
      facilityName: data.facility.facilityName || "",
      facilityTypeId: data.facility.facilityTypeId || "",
      facilityBrandName: data.facilityBrandName || "",
      facilityId: data.facility.facilityId,
      ownerPartyId: data.facility.ownerPartyId || "",
      countryGeoId:
        address !== undefined ? address.postalAddress.countryGeoId : "",
      emailAddress:
        email !== undefined ? data.emailVM[0].contactMech.infoString : "",
      emailPurpose: JSON.stringify(emailActiveList.current)
        .replace(/"/g, "")
        .replace(/,/g, ", "),
      phonePurpose: JSON.stringify(phoneActiveList.current)
        .replace(/"/g, "")
        .replace(/,/g, ", "),
      phoneNumberPrefix:
        phone !== undefined ? phone.telecomNumber.countryCode : "",
      phoneNumber: phone !== undefined ? phone.telecomNumber.contactNumber : "",
      externalId: data.facility.facilityId || "",
      addressPurpose: JSON.stringify(addressPurpose.current)
        .replace(/"/g, "")
        .replace(/,/g, ", "),
      toName: address !== undefined ? address.postalAddress.toName : "",
      attnName: address !== undefined ? address.postalAddress.attnName : "",
      address1: address !== undefined ? address.postalAddress.address1 : "",
      address2: address !== undefined ? address.postalAddress.address2 : "",
      city: address !== undefined ? address.postalAddress.city : "",
      stateGeoCodeId:
        address !== undefined ? address.postalAddress.stateProvinceGeoId : "",
      postalCode: address !== undefined ? address.postalAddress.postalCode : "",
      facilitySizeUomId: data.facility.facilitySizeUomId || "",
      facilitySize: data.facility.facilitySize || 0.0,
      latitude:
        data.facility.latitude !== undefined ? data.facility.latitude : 0.0,
      longitude:
        data.facility.longitude !== undefined ? data.facility.longitude : 0.0,
      addressContactMechId:
        address !== undefined ? address.contactMech.contactMechId : "",
      emailContactMechId:
        email !== undefined ? email.contactMech.contactMechId : "",
      phoneContactMechId:
        phone !== undefined ? phone.contactMech.contactMechId : "",
    });
    fetchExistingUsers();
    setShowEditFacility(true);

    if (data.facility.closedDate == null) {
      setToggle(false);
    } else {
      setToggle(true);
    }
    onclickActivePopup("basic");
  };

  // migarting create facility functionality to edit facility for facility settings

  let [createFacilityObj, setCreateFacilityObj] = useState({
    facilityName: data.facility.facilityName || "",
    facilityTypeId: data.facility.facilityTypeId || "",
    facilityBrandName: data.facilityBrandName || "",
    facilityId: data.facility.facilityId,
    ownerPartyId: data.facility.ownerPartyId || "",
    countryGeoId:
      address !== undefined ? address.postalAddress.countryGeoId : "",
    emailAddress: email !== undefined ? email.contactMech.infoString : "",
    emailPurpose:
      emailActiveList.length !== 0
        ? JSON.stringify(emailActiveList.current)
            .replace(/"/g, "")
            .replace(/,/g, ", ")
        : "",
    phonePurpose: JSON.stringify(phoneActiveList.current)
      .replace(/"/g, "")
      .replace(/,/g, ", "),
    phoneNumberPrefix:
      phone !== undefined ? phone.telecomNumber.countryCode : "",
    phoneNumber: phone !== undefined ? phone.telecomNumber.contactNumber : "",
    externalId: data.facility.facilityId || "",
    addressPurpose: JSON.stringify(addressPurpose.current)
      .replace(/"/g, "")
      .replace(/,/g, ", "),
    toName: address !== undefined ? address.postalAddress.toName : "",
    attnName: address !== undefined ? address.postalAddress.attnName : "",
    address1: address !== undefined ? address.postalAddress.address1 : "",
    address2: address !== undefined ? address.postalAddress.address2 : "",
    city: address !== undefined ? address.postalAddress.city : "",
    stateGeoCodeId:
      address !== undefined ? address.postalAddress.stateProvinceGeoId : "",
    postalCode: address !== undefined ? address.postalAddress.postalCode : "",
    facilitySizeUomId: data.facility.facilitySizeUomId || "",
    facilitySize: data.facility.facilitySize || 0.0,
    latitude:
      data.facility.latitude !== undefined ? data.facility.latitude : 0.0,
    longitude:
      data.facility.longitude !== undefined ? data.facility.longitude : 0.0,
    addressContactMechId:
      address !== undefined ? address.contactMech.contactMechId : "",
    emailContactMechId:
      email !== undefined ? email.contactMech.contactMechId : "",
    phoneContactMechId:
      phone !== undefined ? phone.contactMech.contactMechId : "",
  });

  const [storeAddressVal, setAddressVal] = useState({});
  const [tempAddressPurpose, setTempAddress] = useState([]);
  // useEffect(()=>{
  //   if(showAddAddress){
  //     let tempAddObj =  { ...createFacilityObj };
  //     setAddressVal(tempAddObj)
  //   }
  // },[showAddAddress])

  const setAddressVisible = () => {
    setShowAddAddress(true);
    let tempAddObj = { ...createFacilityObj };
    setAddressVal(tempAddObj);

    let tempPurp = [...addressPurpose.current];
    setTempAddress(tempPurp);
  };
  // Address Cancel Functionality..

  const setShowAddAddressCancel = () => {
    let stashedObj = { ...storeAddressVal };
    setCreateFacilityObj(stashedObj);
    setShowAddAddress(false);
    addressPurpose.current = tempAddressPurpose;
    // setAddressPurposes(tempAddressPurpose);
  };

  const [updateFacResp, setUpdateFacResp] = useState("");
  // const callToast = () => { };
  const createFacSettings = async () => {
    setShowEditFacility(false);

    let mandatory = new FormValidator();
    if (
      !mandatory.checkMandatoryFields(createFacilityObj, [
        "facilityName",
        "facilityTypeId",
        "emailAddress",
        "toName",
        "attnName",
        "address1",
        "address2",
        "city",
        "stateGeoCodeId",
        "postalCode",
        "phoneNumber",
        "externalId",
      ]) ||
      invalidArr.length !== 0
    ) {
      toast("Please Fill Mandatory Fields");
      // setShowCreateFacility(false)
      onclickActivePopup("basic");
    } else {
      let tokenID = localStorage.getItem("@tokenID");
      console.log(createFacilityObj, "edit777777");
      let resp = await new SettingsApiServices().updateFacility(
        tokenID,
        createFacilityObj
      );
      console.log(resp, "edit47474");
      // if (resp.reponseCode != 200 || resp.responseCode == 200) {
      //   toast(
      //     resp.reponseCode == 200
      //       ? "Facility Updated Successfully"
      //       : "Request Failed"
      //   );
      // }
      if (resp.reponseCode == 200) {
        toast("Facility Updated Successfully");
        refreshList();
      } else {
        toast("Request Failed");
        let errorArr = await resp.json();
        console.log(errorArr, "apiErrorsResp");
        let invalidErrors = [
          "validateFacilityName",
          "validateEmail",
          "validateMobileNumber",
          "validateFacilitySize",
          "validateLongitude",
          "validateLatitude",
          "validateAttName",
          "validatePostalCode",
          "validateToName",
        ];
        invalidErrors.forEach((el) => {
          let matchedError = errorArr?.apiErrors?.find((error) => {
            if (
              !(
                el.substring(8).substring(0, 4).toLocaleLowerCase() == "faci"
              ) &&
              error.substring(0, 4).toLowerCase() ==
                el.substring(8).substring(0, 4).toLocaleLowerCase()
            ) {
              return true;
            }
            if (
              el.substring(8).substring(0, 4).toLocaleLowerCase() == "faci" &&
              error.substring(0, 10).toLowerCase() ==
                el.substring(8).substring(0, 10).toLocaleLowerCase()
            ) {
              return true;
            }
          });
          if (matchedError !== undefined) {
            console.log("Matched error:", el);
            setInvalidArr((prev) => [...prev, el]);
            // Do something with the matched error
          }
        });
      }
    }

    if (data.facility.closedDate !== null && !callBack) {
      // alert("Enable API triggered");
      let respEnable = await new SettingsApiServices().facilityEnable(tokenID, {
        facilityId: data.facility.facilityId,
      });
      console.log(respEnable, "enable7878");
    }
  };

  const setFacVal = (e: any, name: string) => {
    if (
      ((name == "phoneNumber" || name == "postalCode") &&
        /[a-zA-Z~`!@#$%^&*()+=\-[\]\\';,./{}|":<>?_]/.test(e.target.value)) ||
      e.target.value.replace(/\D/g, "").length > (name == "postalCode" ? 6 : 10)
    ) {
      return;
    }
    let tempObj = { ...createFacilityObj };

    if (name == "facilitySize") {
      tempObj[name] = e.target.value.replace(/[^0-9.]/g, "");
      const decimalCount = tempObj[name].split(".").length - 1;
      if (decimalCount > 1) {
        tempObj[name] = tempObj[name].slice(0, tempObj[name].lastIndexOf("."));
      }
    } else if (name == "latitude" || name == "longitude") {
      let tempS = parseFloat(e.target.value);
      tempObj[name] = tempS;
    } else {
      tempObj[name] = e.target.value;
    }
    setCreateFacilityObj(tempObj);
  };
  // const [warning, setWarning] = useState("");
  // const checkName = (name) => {
  //   if (name == "name" && createFacilityObj.facilityName.length > 0) {
  //     setWarning("");
  //   } else if (
  //     name == "email" &&
  //     createFacilityObj?.emailAddress?.includes("@")
  //   ) {
  //     setWarning("");
  //   } else if (
  //     name == "phone" &&
  //     (createFacilityObj?.phoneNumber).length == 10
  //   ) {
  //     setWarning("");
  //   } else {
  //     setWarning(name);
  //   }
  // };
  const [createStats, setCreateStats] = useState({});
  console.log(createStats, "@statsResponse");

  useEffect(async () => {
    let tokenID = localStorage.getItem("@tokenID");
    let stats = await new SettingsApiServices().FacilityStatistics(tokenID);
    console.log(stats, "fac77777777");
    setCreateStats(stats);
    let emailPurp =
      email == undefined
        ? ""
        : email.hasOwnProperty("facilityContactMechPurposes")
        ? email.facilityContactMechPurposes.map(
            (obj) => obj.contactMechPurposeTypeId
          )
        : "";
    let phonePurp =
      phone == undefined
        ? ""
        : phone.hasOwnProperty("facilityContactMechPurposes")
        ? phone.facilityContactMechPurposes.map(
            (obj) => obj.contactMechPurposeTypeId
          )
        : "";
    let addPurpose =
      address == undefined
        ? ""
        : address.hasOwnProperty("facilityContactMechPurposes")
        ? address.facilityContactMechPurposes.map(
            (obj) => obj.contactMechPurposeTypeId
          )
        : "";
    // console.log(emailPurp,"final77777");
    emailActiveList.current = emailPurp;
    // setEmailActive(emailPurp);
    // setPhoneActiveList(phonePurp);
    phoneActiveList.current = phonePurp;
    addressPurpose.current = addPurpose;
    // setAddressPurposes(addPurpose);
  }, []);
  // Add Address Popup
  const [showAddAddress, setShowAddAddress] = useState(false);

  const setEmailActiveList = (stat) => {
    // alert(stat)
    if (!emailActiveList.current.includes(stat)) {
      let tempList = [...emailActiveList.current];
      tempList.push(stat);
      // setEmailActive(tempList);
      emailActiveList.current = tempList;
    } else {
      let tempList = [...emailActiveList.current];
      tempList = tempList.filter((obj) => obj !== stat);
      // setEmailActive(tempList);
      emailActiveList.current = tempList;
    }
    let tempObj = { ...createFacilityObj };
    tempObj["emailPurpose"] = JSON.stringify(emailActiveList.current)
      .replace(/"/g, "")
      .replace(/,/g, ", ");
    setCreateFacilityObj(tempObj);
  };
  const setPhoneActive = (stat) => {
    // alert(stat)
    // console.log(JSON.stringify(phoneActiveList.current).replace(/"/g, ""),"check7777")
    // console.log(phoneActiveList.current, 'check777')
    if (!phoneActiveList.current.includes(stat)) {
      let tempList = [...phoneActiveList.current];
      tempList.push(stat);
      phoneActiveList.current = tempList;
      // setPhoneActiveList(tempList);
    } else {
      let tempList = [...phoneActiveList.current];
      tempList = tempList.filter((obj) => obj !== stat);
      phoneActiveList.current = tempList;
      // setPhoneActiveList(tempList);
    }

    let tempObj = { ...createFacilityObj };
    tempObj["phonePurpose"] = JSON.stringify(phoneActiveList.current)
      .replace(/"/g, "")
      .replace(/,/g, ", ");
    setCreateFacilityObj(tempObj);
  };

  const contactPurpose = (stat) => {
    // console.log(phoneActiveList.current, 'check777')
    if (!addressPurpose.current.includes(stat)) {
      let tempList = [...addressPurpose.current];
      tempList.push(stat);
      addressPurpose.current = tempList;
      // setAddressPurposes(tempList);
    } else {
      let tempList = [...addressPurpose.current];
      tempList = tempList.filter((obj) => obj !== stat);
      addressPurpose.current = tempList;
      // setAddressPurposes(tempList);
    }
    let tempObj = { ...createFacilityObj };
    tempObj["addressPurpose"] = JSON.stringify(addressPurpose.current)
      .replace(/"/g, "")
      .replace(/,/g, ", ");
    setCreateFacilityObj(tempObj);

    console.log(
      JSON.stringify(addressPurpose.current)
        .replace(/"/g, "")
        .replace(/,/g, ", "),
      "dumub8888888"
    );
  };
  // const notify = () =>

  const [shifAssettList, setShiftAssetList] = useState([
    {
      lastUpdatedStamp: 1684897050181,
      facilityId: "10145",
      defaultInventoryItemTypeId: null,
      parentFacilityId: null,
      createdTxStamp: 1681836711803,
      createdStamp: 1681836712271,
      description: null,
      lastUpdatedTxStamp: 1684897048099,
      defaultWeightUomId: null,
      primaryFacilityGroupId: null,
      openedDate: 1681836711803,
      ownerPartyId: "Company",
      facilitySize: 0.9,
      facilitySizeUomId: null,
      closedDate: null,
      facilityTypeId: "PLANT",
      defaultDaysToShip: null,
      defaultDimensionUomId: null,
      facilityName: "aeqwe",
      productStoreId: null,
      geoPointId: "10380",
      oldSquareFootage: null,
    },
  ]);
  const getListForShiftAsset = async () => {
    let tokenID = localStorage.getItem("@tokenID");
    let opt = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: tokenID,
      },
    };
    let respObj = await fetch(
      "https://dev-tnt.app.aone.ai/api/1.0/facility/list?userPartyId=Company&extraDetails=false",
      opt
    );
    let finalObj = await respObj.json();

    if (finalObj !== undefined) {
      setShiftAssetList(finalObj.facilities);
      // console.log(finalObj.facilities,"ShiftList7878787")
    }
  };
  const facilityUsersData = ["Chennai", "Banglore", "Delhi", "Kolkata"];
  const facilityUsersRoleData = [
    "Facility Manager",
    "Cluster Manager",
    "City Manager",
    "Warehouse Manager",
  ];

  // Form Validation Code

  const [invalidArr, setInvalidArr] = useState([]);
  const validate = new FormValidator();
  const checkName = (name, val) => {
    const validArr = [
      "validateFacilityName",
      "validateEmail",
      "validateMobileNumber",
      "validateFacilitySize",
      "validateLongitude",
      "validateLatitude",
      "validateAttName",
      "validatePostalCode",
      "validateToName",
    ];
    if (!validArr.includes(name)) {
      return;
    }

    let functionName =
      name === "validateFacilitySize" ||
      name === "validateLongitude" ||
      name === "validateLatitude"
        ? "validateFloatValue"
        : name;
    functionName =
      name === "validateAttName" ||
      name === "validateFacilityName" ||
      name === "validateToName"
        ? "validateFacilityName"
        : functionName;
    const isValid = validate[functionName](val);

    setInvalidArr((prevInvalidArr) => {
      if (isValid) {
        return prevInvalidArr.filter((obj) => obj !== name);
      } else {
        return [...prevInvalidArr, name];
      }
    });

    // console.log(name, val, invalidArr, "Valid7878");
  };

  // User List Call
  const [userList, setUsersList] = useState([]);
  console.log(userList, "@userListResp");
  const fetchUserList = async () => {
    let token = localStorage.getItem("@tokenID");
    let services = new SettingsApiServices();
    let resp = await services.usersList(token);
    setUsersList(resp);
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  // Already Existing user API list....
  const [existuserLoader, setExistingUserLoader] = useState(true);
  const existingUsersList = useRef([]);
  const existingRolesList = useRef([]);
  const fetchExistingUsers = async () => {
    setMapUsertoFaclityArr([
      {
        id: generateID(4),
        facilityList: ["Select User", "fac-1", "fac-2", "fa-3", "fac-4"],
        rolesList: ["Select Role", "role-1", "role-2", "role-3", "role-4"],
      },
    ]);
    try {
      setExistingUserLoader(true);
      let token = localStorage.getItem("@tokenID");
      // /assignUser/facility/list
      const option = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      };
      const resp = await fetch(
        `https://dev-tnt.app.aone.ai/api/1.0/assignUser/facility/list?facilityId=${data.facility.facilityId}`,
        option
      );
      let finalResp = await resp.json();
      if (finalResp.data.length !== 0) {
        existingUsersList.current = finalResp.data.map((obj) => obj.partyId);
        existingRolesList.current = finalResp.data.map((obj) => obj.roleTypeId);
        // Adding existing user spaces to the Users..
        existingUsersList.current.forEach((obj) => {
          addFacilityRole();
        });
      }
      console.log(finalResp, "Facility Existing");
      // console.log(existingUsersList.current, "Facility Existing");
      setExistingUserLoader(false);
    } catch (error) {
      toast("Facility Existing User API Error..");
    }
  };

  // Add User Function...
  const addUserFunction = async () => {
    setMapUsertoFaclityArr([]);
    addFacilityRole();
  };

  const [selectedUsers, setSelectedUsers] = useState([]);
  const handleUserSelection = (event, index) => {
    const { value } = event.target;
    const updatedUsers = [...selectedUsers];
    updatedUsers[index] = {
      ...updatedUsers[index],
      partyId: value,
    };
    setSelectedUsers(updatedUsers);
  };
  const handleRoleSelection = (event, index) => {
    const { value } = event.target;
    const updatedUsers = [...selectedUsers];
    updatedUsers[index] = {
      ...updatedUsers[index],
      roleTypeId: value,
    };
    setSelectedUsers(updatedUsers);
  };
  const handleDelete = (index) => {
    const updatedUsers = [...selectedUsers];
    updatedUsers.splice(index, 1);
    setSelectedUsers(updatedUsers);
  };
  const handleAddUser = () => {
    const newUser = {
      partyId: "",
      roleTypeId: "",
    };
    setSelectedUsers([...selectedUsers, newUser]);
    console.log(selectedUsers, "ADDUserFacility7878");
  };

  // Facility Assign user API
  const facilityAssignUserAPI = async (id) => {
    try {
      // activeCardRef.current
      // alert(id);
      let finalArr = [...selectedUsers];
      finalArr = finalArr
        .map((user) => {
          if (user.partyId !== "" && user.roleTypeId !== "") {
            return { ...user, facilityId: id };
          }
          return null;
        })
        .filter((user) => user !== null);

      // Calling API for Assignuser..
      let token = localStorage.getItem("@tokenID");
      const endpoint = `https://dev-tnt.app.aone.ai/api/1.0/assignUser/facility`;
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      const payload = {
        toAdd: finalArr,
        toRemove: [],
      };
      const response = await fetch(endpoint, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      // const resp = await response.json();
      if (response.status == 200) {
        toast(`Facility ID: ${id} Added for Users`);
        refreshList();
      } else {
        toast("Problem in Added Facility to users API");
      }
      // console.log(finalArr, "finalAddUserArr");
    } catch (error) {
      toast("Facility Add User Popup Error");
    }
  };

  return (
    <>
      {/* <button onClick={notify}>Load</button> */}

      {show && (
        <FacilitypopUpScreen
          propsData={addFacility}
          type={"edit"}
          closePopUpScreen={closePopUpScreen}
        />
      )}
      <div
        className={
          selected ? "facilityCardContainerActive" : "facilityCardContainer"
        }
        onClick={setViewFacilityTrue}
      >
        <div className="facilityCardInputCont">
          <input
            type="checkbox"
            checked={true}
            className="facilityCheckbox"
            onChange={() => addToSelectedList(data.facility.facilityId)}
            onMouseOver={() => setCardViewshow(false)}
            onMouseLeave={() => setCardViewshow(true)}
          />
          <div className="facilityCardStatusCont">
            {data.facility.closedDate == null ? (
              <p className="enableTxt">enabled</p>
            ) : (
              <p className="diseableTxt">disabled</p>
            )}
            <div className="facilityCardHoverImgCont">
              <img src={CardHoverIcon} alt="icon" />
              <ul
                className="facilityCardHoverCont"
                onMouseOver={() => setCardViewshow(false)}
                onMouseLeave={() => setCardViewshow(true)}
              >
                <li onClick={() => setViewFacility(true)}>View Facility</li>
                <li
                  onClick={() => {
                    setAddUserPopup(true);
                    addUserFunction();
                  }}
                >
                  Add User
                </li>
                <li
                  onClick={setShowCreateFac}
                  // onClick={openPopUpScreen}
                >
                  Edit Facility
                </li>
                <li onClick={() => setDisableFacility(true)}>
                  Disable Facility
                </li>
                <li onClick={() => deleteFacility(data)}>Delete Facility</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="facilityCardDetailsCont">
          <p className="facilityIdTxt">{data.facility.facilityId}</p>
          <p className="facilityNameTxt">{data.facility.facilityName}</p>
          <p className="facilityAddressTxt">
            {/* Periamet, Chennai */}
            {address !== undefined ? `${address.postalAddress.address1},` : "-"}
            {addFacility.city}
          </p>
          <div className="facilityCardTypeDetailsCont">
            <div className="facilityCardTypeDetails">
              <img src={FacilityTypeIcon} alt="icon" />
              <p>
                {data.facility.facilityTypeDescription
                  ?.toLowerCase()
                  .replace(/_/g, " ")
                  .split(" ")
                  .map((word) => {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                  })
                  .join(" ")}
              </p>
            </div>
            <div className="facilityCardCountCont">
              <p>{data.userCount}</p>
              <img src={FacilityTypeCountIcon} alt="icon" />
            </div>
          </div>
        </div>
        <div className="facilityCardFotterCont">
          <div className="assetsIconCont">
            <img src={FacilityCardAssetIcon} alt="icon" />
          </div>
          <p>{data.assetCount} assets</p>
          <ToastContainer position="bottom-left" />
        </div>
      </div>

      {/*Edit Facility Popup */}
      <Modal
        show={showEditFacility}
        onHide={() => setShowEditFacility(false)}
        centered
        backdrop="static"
        className="editFacilityPopupModal"
      >
        <Modal.Header className="editFacilityPopHeader">
          <h4 className="editFacilityHeader">Edit facility</h4>
          <div
            className="closeIconDiv"
            onClick={() => setShowEditFacility(false)}
          >
            <img
              src={IconClose}
              alt="icon"
              onClick={() => setShowEditFacility(false)}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="editFacilityPopBody">
          <div className="editFacilityBody">
            <div className="editFacilityHeaderNav">
              <p
                className={
                  activeTab == "basic"
                    ? "activeEditFacilityNavTxt"
                    : "editFacilityNavTxt"
                }
                onClick={() => onclickActivePopup("basic")}
              >
                Basic Information
              </p>
              <p
                className={
                  activeTab == "attrib"
                    ? "activeEditFacilityNavTxt"
                    : "editFacilityNavTxt"
                }
                onClick={() => onclickActivePopup("attrib")}
              >
                Attributes
              </p>
              <p
                className={
                  activeTab == "users"
                    ? "activeEditFacilityNavTxt"
                    : "editFacilityNavTxt"
                }
                onClick={() => onclickActivePopup("users")}
              >
                Users
              </p>
            </div>
            {activeTab == "basic" ? (
              <div className="facilityBasicInfoCont">
                <div className="basicInfoCont">
                  <div className="basicInfoInputsCont">
                    <div className="textInputsCont">
                      <label>Name *</label>
                      <input
                        value={createFacilityObj.facilityName}
                        type="text"
                        style={{
                          border: `${
                            invalidArr.includes("validateFacilityName")
                              ? "1px solid red"
                              : ""
                          }`,
                        }}
                        onChange={(e) => setFacVal(e, "facilityName")}
                        onBlur={() =>
                          checkName(
                            "validateFacilityName",
                            createFacilityObj.facilityName
                          )
                        }
                        data-tip="Name can only contain letters (A-Z, a-z), numbers (0-9) and the following special characters: ', _, -, # and whitespace"
                        data-for="name-tooltip"
                      />
                      <ReactTooltip
                        id="name-tooltip"
                        effect="solid"
                        place="bottom"
                        className="tooltip"
                      />

                      {invalidArr.includes("validateFacilityName") && (
                        <label style={{ color: "red", fontSize: "8px" }}>
                          {new FormValidator().validateValue(
                            createFacilityObj.facilityName,
                            /^[A-Za-z\s_#]+$/,
                            100
                          )}
                        </label>
                      )}
                    </div>

                    <div className="selectorInputCont">
                      <label>Facility Type *</label>
                      <select
                        value={createFacilityObj.facilityTypeId}
                        onChange={(e) => setFacVal(e, "facilityTypeId")}
                      >
                        <option value="" disabled selected>
                          Select Type
                        </option>
                        {createStats?.facilityTypes?.map((el) => (
                          <option
                            key={el.facilityTypeId}
                            value={el.facilityTypeId}
                          >
                            {el.description}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="textInputsCont">
                      <label>Email *</label>
                      <input
                        value={createFacilityObj.emailAddress}
                        type="text"
                        onChange={(e) => setFacVal(e, "emailAddress")}
                        style={{
                          border: `${
                            invalidArr.includes("validateEmail")
                              ? "1px solid red"
                              : ""
                          }`,
                        }}
                        onBlur={() =>
                          checkName(
                            "validateEmail",
                            createFacilityObj.emailAddress
                          )
                        }
                        data-tip="Email can only contain letters (A-Z, a-z), numbers (0-9) and following special characters: ., _, -, + and @"
                        data-for="name-tooltip"
                      />
                      <ReactTooltip
                        id="name-tooltip"
                        effect="solid"
                        place="bottom"
                        className="tooltip"
                      />

                      {invalidArr.includes("validateEmail") && (
                        <label style={{ color: "red", fontSize: "8px" }}>
                          {new FormValidator().validateValue(
                            createFacilityObj.emailAddress,
                            /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            255
                          )}
                        </label>
                      )}
                      {/* <p
                        className="setPurpose"
                        onClick={() => setShowEmailPurpose(true)}
                      >
                        {emailActiveList.current.length == 0
                          ? ""
                          : emailActiveList.current.length}{" "}
                        {emailActiveList.current.length == 0
                          ? "Set purpose"
                          : "purposes selected"}
                      </p>
                      {showEmailPurpose && (
                        <div className="emailSetPurpose">
                          <div className="setPurposeHeader">
                            <img
                              src={IconClose}
                              alt="icon"
                              onClick={() => setShowEmailPurpose(false)}
                            />
                          </div>
                          <div className="setPurposeDataCont">
                            {createStats?.contactPurposes?.EMAIL_ADDRESS?.map(
                              (el) => (
                                <div
                                  onClick={() =>
                                    setEmailActiveList(
                                      el.contactMechPurposeTypeId
                                    )
                                  }
                                  key={el.contactMechPurposeTypeId}
                                  className="setPurposeData"
                                >
                                  <input
                                    checked={emailActiveList.current.includes(
                                      el.contactMechPurposeTypeId
                                    )}
                                    type="checkbox"
                                  />
                                  <label>{el.description}</label>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )} */}
                    </div>

                    <div className="addressInputCont">
                      <label>Address *</label>
                      <div className="addressInputIconCont">
                        <input
                          type="text"
                          placeholder="Add address"
                          readOnly
                          value={`${createFacilityObj.city}${
                            createFacilityObj.city ? "," : ""
                          } ${createFacilityObj.address1}${
                            createFacilityObj.address2 ? "," : ""
                          } ${createFacilityObj.address2}${
                            createFacilityObj.postalCode ? "-" : ""
                          } ${createFacilityObj.postalCode}`}
                        />
                        <img
                          className="editAddressIcon"
                          src={EditAddressIcon}
                          alt="icon"
                          onClick={setAddressVisible}
                        />
                      </div>
                    </div>

                    <div className="phoneNumberInputCont">
                      <label>Phone Number *</label>
                      <div className="selectAndInputCont">
                        <div className="imgAndSelectCont">
                          <img src={CountryIcon} alt="icon" />
                          <select>
                            <option>+91</option>
                          </select>
                        </div>

                        <input
                          value={createFacilityObj.phoneNumber}
                          type="number"
                          style={{
                            border: `${
                              invalidArr.includes("validateMobileNumber")
                                ? "1px solid red"
                                : ""
                            }`,
                          }}
                          onChange={(e) => setFacVal(e, "phoneNumber")}
                          onBlur={() =>
                            checkName(
                              "validateMobileNumber",
                              createFacilityObj.phoneNumber
                            )
                          }
                        />
                      </div>
                      {invalidArr.includes("validateMobileNumber") && (
                        <label style={{ color: "red", fontSize: "8px" }}>
                          {" "}
                          Enter valid Phone Number*
                        </label>
                      )}
                      {/* <p
                        className="setPurpose"
                        onClick={() => setShowPhonePurpose(true)}
                      >
                        {phoneActiveList.current.length == 0
                          ? ""
                          : phoneActiveList.current.length}{" "}
                        {phoneActiveList.current.length == 0
                          ? "Set purpose"
                          : "purposes selected"}
                      </p>
                      {showPhonePurpose && (
                        <div className="phoneNumberSetPurpose">
                          <div className="setPurposeHeader">
                            <img
                              src={IconClose}
                              alt="icon"
                              onClick={() => setShowPhonePurpose(false)}
                            />
                          </div>
                          <div className="setPurposeDataCont">
                            {createStats?.contactPurposes?.TELECOM_NUMBER?.map(
                              (el) => (
                                <div
                                  onClick={() =>
                                    setPhoneActive(el.contactMechPurposeTypeId)
                                  }
                                  key={el.contactMechPurposeTypeId}
                                  className="setPurposeData"
                                >
                                  <input
                                    checked={phoneActiveList.current.includes(
                                      el.contactMechPurposeTypeId
                                    )}
                                    // onClick={() =>
                                    //   setPhoneActive(
                                    //     el.contactMechPurposeTypeId
                                    //   )
                                    // }
                                    type="checkbox"
                                  />
                                  <label>{el.description}</label>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )} */}
                    </div>

                    <div className="sizeFacilityInputCont">
                      <label>Size of Facility</label>
                      <div className="inputAndSelectCont">
                        <input
                          type="text"
                          style={{
                            border: `${
                              invalidArr.includes("validateFacilitySize")
                                ? "1px solid red"
                                : ""
                            }`,
                          }}
                          value={createFacilityObj.facilitySize}
                          onChange={(e) => setFacVal(e, "facilitySize")}
                          onBlur={() =>
                            checkName(
                              "validateFacilitySize",
                              createFacilityObj.facilitySize
                            )
                          }
                        />
                        <select
                          value={createFacilityObj.facilitySizeUomId}
                          onChange={(e) => setFacVal(e, "facilitySizeUomId")}
                        >
                          {createStats?.unitsOfMeasures?.AREA_MEASURE?.map(
                            (obj) => (
                              <option key={obj.uomId} value={obj.uomId}>
                                {obj.description}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                      {invalidArr.includes("validateFacilitySize") && (
                        <label style={{ color: "red", fontSize: "8px" }}>
                          {" "}
                          Enter Float Values Only*
                        </label>
                      )}
                    </div>

                    <div className="textInputsCont">
                      <label>External ID *</label>
                      <input
                        type="text"
                        value={createFacilityObj.externalId}
                        onChange={(e) => setFacVal(e, "externalId")}
                        style={{
                          border: `${
                            invalidArr.includes("externalId")
                              ? "1px solid red"
                              : ""
                          }`,
                        }}
                        onBlur={() =>
                          checkName("externalId", createFacilityObj.externalId)
                        }
                        data-tip="External ID can only contain letters (A-Z, a-z), numbers (0-9) and following special character: '"
                        data-for="name-tooltip"
                      />
                      <ReactTooltip
                        id="name-tooltip"
                        effect="solid"
                        place="bottom"
                        className="tooltip"
                      />
                      {invalidArr.includes("externalId") && (
                        <label style={{ color: "red", fontSize: "8px" }}>
                          {new FormValidator().validateValue(
                            createFacilityObj.externalId,
                            /^[A-Za-z']+$/,
                            255
                          )}
                        </label>
                      )}
                    </div>

                    <div className="selectorInputCont">
                      <label>Facility Group</label>
                      <select>
                        <option value="" disabled selected>
                          Select Group
                        </option>
                        <option>Chennai Zone1</option>
                        <option>Chennai Zone 2</option>
                        <option>Bangalore</option>
                      </select>
                    </div>
                  </div>
                  {/* <div className="mapContainer">Map</div> */}
                  <div className="latAndLongInputsCont">
                    <div className="textInputsCont">
                      <label>Latitude</label>
                      <input
                        value={createFacilityObj.latitude}
                        onChange={(e) => setFacVal(e, "latitude")}
                        type="number"
                        style={{
                          border: `${
                            invalidArr.includes("validateLatitude")
                              ? "1px solid red"
                              : ""
                          }`,
                        }}
                        onBlur={() =>
                          checkName(
                            "validateLatitude",
                            createFacilityObj.latitude
                          )
                        }
                      />
                      {invalidArr.includes("validateLatitude") && (
                        <label style={{ color: "red", fontSize: "8px" }}>
                          {" "}
                          Enter Float Values Only*
                        </label>
                      )}
                    </div>

                    <div className="textInputsCont">
                      <label>Longitude</label>
                      <input
                        value={createFacilityObj.longitude}
                        onChange={(e) => setFacVal(e, "longitude")}
                        type="number"
                        style={{
                          border: `${
                            invalidArr.includes("validateLongitude")
                              ? "1px solid red"
                              : ""
                          }`,
                        }}
                        onBlur={() =>
                          checkName(
                            "validateLongitude",
                            createFacilityObj.longitude
                          )
                        }
                      />
                      {invalidArr.includes("validateLongitude") && (
                        <label style={{ color: "red", fontSize: "8px" }}>
                          {" "}
                          Enter Float Values Only*
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : activeTab == "attrib" ? (
              <div className="facilityAttributesCont">
                <div className="textInputsCont">
                  <label>Energy Provider</label>
                  <input type="text" />
                </div>

                <div className="unitOfEnergyInputCont">
                  <label>Cost per unit of energy</label>
                  <div className="selectAndInputCont">
                    <div className="imgAndSelectCont">
                      <select>
                        <option>INR (₹)</option>
                      </select>
                    </div>
                    <input type="number" />
                  </div>
                </div>

                <div className="textInputsCont">
                  <label>Energy Consumer No.</label>
                  <input type="number" />
                </div>

                <div className="selectorInputCont">
                  <label>Unit of energy</label>
                  <select>
                    <option>kWh</option>
                  </select>
                </div>

                <div className="contractDemandInputCont">
                  <label>Contract Demand</label>
                  <div className="inputAndSelectCont">
                    <input type="number" />
                    <select>
                      <option>KVA</option>
                    </select>
                  </div>
                </div>

                <div className="selectorInputCont">
                  <label>Is DG present in the facility</label>
                  <select>
                    <option>No</option>
                  </select>
                </div>

                <div className="selectorInputCont">
                  <label>Is DG self owned?</label>
                  <select>
                    <option>No</option>
                  </select>
                </div>

                <div className="selectorInputCont">
                  <label>DG location</label>
                  <select>
                    <option>Basement</option>
                  </select>
                </div>
              </div>
            ) : activeTab == "users" ? (
              <div className="facilityUsersCont">
                {!existuserLoader && (
                  <div className="facilityUsersHeightCont">
                    <div className="facilityUsersAddBtnCont">
                      <button
                        className="facilityUsersAddBtn"
                        onClick={() => addFacilityRole()}
                      >
                        <img src={AddIcon} alt="icon" />
                        Add
                      </button>
                    </div>
                    <div className="facilityUsersAndRoleContainer">
                      <div className="facilityUsersAndRoleHeaders">
                        <p>User</p>
                        <p>Role</p>
                      </div>
                      {mapUsertoFaclityArr.map((obj, index) => (
                        <div className="facilityUsersAndRoleSelectorsCont">
                          <select
                            className="facilityUsersAndRoleSelector"
                            defaultValue={
                              existingUsersList.current.length > index
                                ? existingUsersList.current[index]
                                : ""
                            }
                          >
                            <option value="" disabled selected>
                              Select User
                            </option>
                            {userList &&
                              userList?.data?.map((obj) => (
                                <option key={obj.partyId} value={obj.partyId}>
                                  {obj.firstName + " " + obj.lastName}
                                </option>
                              ))}
                          </select>
                          <select
                            className="facilityUsersAndRoleSelector"
                            defaultValue={
                              existingRolesList.current.length > index
                                ? existingRolesList.current[index]
                                : ""
                            }
                          >
                            <option value="" disabled selected>
                              Select Role
                            </option>
                            {createStats?.roleTypes?.map((obj) => (
                              <option
                                key={obj.roleTypeId}
                                value={obj.roleTypeId}
                              >
                                {obj.description}
                              </option>
                            ))}
                          </select>
                          <img
                            src={deleteIcon}
                            alt="delete-icon"
                            onClick={() => deleteAddFaciityRole(obj.id)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="editFacilityPopFooter">
          <div className="editFacilityFooter">
            <div className="enableAndDisableCont">
              <CustomSwitch callBack={setCallBack} check={callBack} />
              <p>{callBack ? "Disabled" : "Enabled"}</p>
            </div>
            <div className="editFacilityBtnsCont">
              <button
                className="facilityCancelBtn"
                onClick={() => setShowEditFacility(false)}
              >
                Cancel
              </button>
              {activeTab !== "users" ? (
                <button
                  className="facilityNextBtn"
                  onClick={() =>
                    onclickActivePopup(
                      activeTab == "basic"
                        ? "attrib"
                        : activeTab === "attrib"
                        ? "users"
                        : ""
                    )
                  }
                >
                  Next
                </button>
              ) : (
                <button
                  className="facilityUpdateBtn"
                  onClick={createFacSettings}
                >
                  Update Facility
                </button>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* view facility Popup Modal */}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        show={viewFacilty}
        onHide={() => setViewFacility(false)}
        className="viewFacilityPopupModal"
      >
        <Modal.Header className="viewFacilityHeaderCont">
          <div className="facilityDetailsHeaderCont">
            <img
              src={FacilityViewPopIcon}
              alt="icon"
              className="facilityIcon"
            />
            <div className="facilityDetailsContainer">
              <div className="facilityDetails">
                <p className="facilityNameTxt">{data.facility.facilityName}</p>
                <p className="facilityAddressTxt">
                  {/* Rs Puram,Coimbatore - 641002 */}
                  {address !== undefined
                    ? `${address.postalAddress.address1},`
                    : ""}
                  {addFacility.city}-{addFacility.postalCode}
                </p>
              </div>
              <p className="facilityUseTxt">{data.facility.facilityTypeId}</p>
            </div>
          </div>
          <div className="closeIconDiv" onClick={() => setViewFacility(false)}>
            <img className="viewFacilityCloseIcon" src={IconClose} alt="icon" />
          </div>
        </Modal.Header>
        <Modal.Body className="viewFacilityPopupBody">
          <div className="viewFacilityBody">
            <div className="facilityInfoCont">
              <p className="facilityInfoHeader">Facility Information</p>
              <div className="facilityInfoHeaderBorder"></div>
              <div className="facilityInformationDetailsCont">
                <div className="facilityInfoDetails">
                  <div className="facilityInfoHeaderDiv">
                    <p className="facilityInfoHeader">Email Address</p>
                  </div>
                  <div className="facilityInfoParaDiv">
                    <p className="facilityInfoPara">
                      {addFacility.emailAddress?.length <= 23
                        ? addFacility.emailAddress
                        : `${addFacility.emailAddress?.substring(0, 23)}
                      ${addFacility.emailAddress?.substring(23)}`}
                    </p>
                  </div>
                </div>

                <div className="facilityInfoDetails">
                  <div className="facilityInfoHeaderDiv">
                    <p className="facilityInfoHeader">Facility Group</p>
                  </div>
                  <div className="facilityInfoParaDiv">
                    <p className="facilityInfoPara">
                      {data.facility.primaryFacilityGroupId}
                    </p>
                  </div>
                </div>

                <div className="facilityInfoDetails">
                  <div className="facilityInfoHeaderDiv">
                    <p className="facilityInfoHeader">Phone Number</p>
                  </div>
                  <div className="facilityInfoParaDiv">
                    <p className="facilityInfoPara">
                      {addFacility.phoneNumberPrefix} {addFacility.phoneNumber}
                    </p>
                  </div>
                </div>

                <div className="facilityInfoDetails">
                  <div className="facilityInfoHeaderDiv">
                    <p className="facilityInfoHeader">Size</p>
                  </div>
                  <div className="facilityInfoParaDiv">
                    <p className="facilityInfoPara">
                      {data.facility.facilitySize} sq.ft
                    </p>
                  </div>
                </div>

                <div className="facilityInfoDetails">
                  <div className="facilityInfoHeaderDiv">
                    <p className="facilityInfoHeader">Address</p>
                  </div>
                  <div className="facilityInfoParaDiv">
                    <p className="facilityInfoPara">
                      {address !== undefined
                        ? `${address.postalAddress.address1},`
                        : ""}{" "}
                      {address !== undefined
                        ? `${address.postalAddress.address2},`
                        : ""}
                      {addFacility.city}-{addFacility.postalCode}
                    </p>
                  </div>
                </div>

                <div className="facilityInfoDetails">
                  <div className="facilityInfoHeaderDiv">
                    <p className="facilityInfoHeader">External ID</p>
                  </div>
                  <div className="facilityInfoParaDiv">
                    <p className="facilityInfoPara">
                      {data.facility.facilityId}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="attributesInfoCont">
              <p className="attributesInfoHeader">Attributes</p>
              <div className="attributesInfoHeaderBorder"></div>
              <div className="attributesInfoDetailsCont">
                <div className="attributeInfoDetails">
                  <div className="attributeInfoHeaderDiv">
                    <p className="attributeInfoHeader">Energy Provider</p>
                  </div>
                  <div className="attributeInfoParaDiv">
                    <p className="attributeInfoPara"></p>
                  </div>
                </div>

                <div className="attributeInfoDetails">
                  <div className="attributeInfoHeaderDiv">
                    <p className="attributeInfoHeader">Consumer No.</p>
                  </div>
                  <div className="attributeInfoParaDiv">
                    <p className="attributeInfoPara"></p>
                  </div>
                </div>

                <div className="attributeInfoDetails">
                  <div className="attributeInfoHeaderDiv">
                    <p className="attributeInfoHeader">
                      Cost per unit of energy
                    </p>
                  </div>
                  <div className="attributeInfoParaDiv">
                    <p className="attributeInfoPara"></p>
                  </div>
                </div>

                <div className="attributeInfoDetails">
                  <div className="attributeInfoHeaderDiv">
                    <p className="attributeInfoHeader">Unit of Energy</p>
                  </div>
                  <div className="attributeInfoParaDiv">
                    <p className="attributeInfoPara"></p>
                  </div>
                </div>

                <div className="attributeInfoDetails">
                  <div className="attributeInfoHeaderDiv">
                    <p className="attributeInfoHeader">Contract Demand</p>
                  </div>
                  <div className="attributeInfoParaDiv">
                    <p className="attributeInfoPara"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="viewFacilityPopupFooter">
          <button
            className="viewFacilityEditBtn"
            // onClick={openPopUpScreen}
            onClick={setShowCreateFac}
          >
            <img src={IconEdit} alt="icon" />
            Edit
          </button>
          <button
            className="viewFacilityDelete"
            onClick={() => deleteFacility(data)}
          >
            <img src={IconDelete} alt="icon" /> Delete
          </button>
        </Modal.Footer>
      </Modal>

      {/* Delete Facility Popup... */}
      <Modal
        show={delFacilityPopup}
        onHide={() => setDeleteFacility(false)}
        centered
        backdrop="static"
        className="deleteFacilityPopupContainer"
      >
        <Modal.Header className="deleteFacilityPopHeader">
          <h4 className="deleteFacilityHeader">
            Delete {data.facility.facilityName}?
          </h4>
          <div
            className="closeIconDiv"
            onClick={() => setDeleteFacility(false)}
          >
            <img
              className="deleteFacilityCloseIcon"
              src={IconClose}
              alt="icon"
            />
          </div>
        </Modal.Header>
        <Modal.Body className="deleteFacilityPopBody">
          <ul className="deleteFacilityBody">
            <li className="deleteFacilityBodyTxt">
              This action will permanently delete{" "}
              <b>{data.facility.facilityName}</b> and all the assets associated
              with it. Do you want to proceed?
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer className="deleteFacilityFooterCont">
          <button
            className="deleteFacilitycancelButton"
            onClick={() => setDeleteFacility(false)}
          >
            Cancel
          </button>
          <button
            className="deleteFacilityBtn"
            onClick={() => setDeleteFacility(false)}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>

      {/* Add User Popup */}
      <Modal
        show={addUserPopup}
        onHide={() => setAddUserPopup(false)}
        centered
        backdrop="static"
        className="facilityAddUserPopup"
      >
        <Modal.Header className="facilityAddUserHeaderCont">
          <h4 className="facilityAddUserHeader">Add facility to users</h4>
          <div className="closeIconDiv" onClick={() => setAddUserPopup(false)}>
            <img src={IconClose} alt="icon" />
          </div>
        </Modal.Header>
        <Modal.Body className="facilityAddUserPopupBody">
          <div className="facilityAddUserBodyCont">
            <h4 className="facilityAddUserBodyHeader">
              Adding <b>Facility-{data.facility.facilityId}</b> to following
              users
            </h4>
            {
              <div className="facilityAddUserDetailsCont">
                <div className="facilityAddUserDetails">
                  <p className="popUserTxt">User</p>
                  <p className="popRoleTxt">Role</p>
                </div>
                <div className="facilityAddUserSelectorsDiv">
                  {mapUsertoFaclityArr.map((obj, index) => (
                    <div className="facilityAddUserSelectorsCont">
                      <select
                        className="popUserSelect"
                        onChange={(event) => handleUserSelection(event, index)}
                      >
                        <option value="" disabled selected>
                          Select User
                        </option>
                        {userList &&
                          userList?.data?.map((obj) => (
                            <option key={obj.partyId} value={obj.partyId}>
                              {obj.firstName + " " + obj.lastName}
                            </option>
                          ))}
                      </select>
                      <select
                        className="popRoleSelect"
                        onChange={(event) => handleRoleSelection(event, index)}
                      >
                        <option value="" disabled selected>
                          Select Role
                        </option>
                        {createStats?.roleTypes?.map((obj) => (
                          <option key={obj.roleTypeId} value={obj.roleTypeId}>
                            {obj.description}
                          </option>
                        ))}
                      </select>
                      <img
                        src={deleteIcon}
                        alt="delete-icon"
                        onClick={() => deleteAddFaciityRole(obj.id, index)}
                      />
                    </div>
                  ))}
                </div>

                <div className="facilityAddUserDetailsBtnCont">
                  <button
                    className="facilityAddUserDetailsBtn"
                    onClick={() => {
                      addFacilityRole();
                      handleAddUser();
                    }}
                  >
                    <img src={AddIcon} alt="icon" />
                    Add
                  </button>
                </div>
              </div>
            }
          </div>
        </Modal.Body>
        <Modal.Footer className="facilityAddUserFooterCont">
          <button
            onClick={() => setAddUserPopup(false)}
            className="facilityAddUserCancelBtn"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              setAddUserPopup(false);
              handleAddUser();
              facilityAssignUserAPI(data.facility.facilityId);
            }}
            className="facilityAddUserMapUsersBtn"
          >
            {" "}
            Map Users
          </button>
        </Modal.Footer>
      </Modal>

      {/* Shift Assets Popup */}
      <Modal show={shiftAssetsPop} backdrop="static" centered className="shiftAssetsModalPopup">
        <Modal.Header className="shiftAssetsHeaderContainer">
          <div className="shiftAssetsImgAndHeaderContainer">
            <div className="shiftHoverImgCont" onClick={closeShiftAsset}>
              <img src={ShiftAssetIcon} alt="icon" className="shiftAssetIcon" />
              <img src={IconClose} alt="icon" className="shiftAssetHoverIcon" />
            </div>
            <h4 className="shiftAssetsHeader">Shift Assets</h4>
          </div>

          <div
            className="closeIconDiv"
            onClick={() => setShiftAssetsPop(false)}
          >
            <img src={IconClose} alt="icon" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="shiftAssetsBodyCont">
            <p className="shiftAssetsBodyHeader">
              Shift assets associated to Facility-02 to{" "}
            </p>
            <select className="shiftAssetSelect">
              <option value="" disabled selected>
                Select Facility
              </option>
              {shifAssettList.map((obj) => (
                <option value={obj.facilityId}> {obj.facilityName}</option>
              ))}
            </select>
            <p>
              Can’t find the facility you are looking for?{" "}
              <span>Create here</span>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer className="shiftAssetsFooterCont">
          <button className="shiftAssetsCancelBtn" onClick={closeShiftAsset}>
            Cancel
          </button>
          <button className="shiftAssetsBtn">Shift Assets</button>
        </Modal.Footer>
      </Modal>

      {/* Disable Facility Popup */}
      <Modal
        show={disableFacility}
        centered
        backdrop="static"
        className="disableFacilityPopupContainer"
      >
        <Modal.Header className="disableFacilityHeaderCont">
          <h4 className="disableFacilityHeader">
            Disable {data.facility.facilityName}?
          </h4>
          <div className="closeIconDiv" onClick={setDisableFacfalse}>
            <img
              className="disableFacilityCloseIcon"
              src={IconClose}
              alt="icon"
            />
          </div>
        </Modal.Header>
        <Modal.Body className="disableFacilityPopupBody">
          <ul className="disableFacilityBody">
            <li className="disableFacilityBodyTxt">
              This action will disable <b>{data.facility.facilityName}</b>.
              Please choose an appropriate option from the dropdown to handle
              the assets.
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer className="disableFacilityPopupFooter">
          <button
            className="disableFacilityCancelBtn"
            onClick={setDisableFacfalse}
          >
            Cancel
          </button>
          <button
            className="disableFacilityBtn"
            onClick={() => setShowDeleteFacility(true)}
          >
            Disable Facility
            <img src={DeleteFacilityIcon} alt="icon" />
          </button>
          {showDeleteFacility && (
            <div
              className="disableFacilityFeaturesCont"
              onMouseLeave={() => setShowDeleteFacility(false)}
            >
              <div className="disableFacilityFeatures" onClick={shiftAsset}>
                <p className="disableFacilityFeaturesHeader">Shift Assets</p>
                <p className="disableFacilityFeatureTxt">
                  Assets will be relocated to a selected facility
                </p>
              </div>

              <div
                className="disableFacilityFeatures"
                onClick={singleDisableAsset}
              >
                <p className="disableFacilityFeaturesHeader">Retain Assets</p>
                <p className="disableFacilityFeatureTxt">
                  Assets will be retained in the same facility while the
                  facility is deleted
                </p>
              </div>

              <div
                className="disableFacilityFeatures"
                onClick={deActivateAsset}
              >
                <p className="disableFacilityFeaturesHeader">
                  Deactivate Assets
                </p>
                <p className="disableFacilityFeatureTxt">
                  Assets will be deactivated while the facility is deleted
                </p>
              </div>
            </div>
          )}
        </Modal.Footer>
      </Modal>

      {/* Edit Address Popup */}
      <Modal
        show={showAddAddress}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="editAddressPopup"
      >
        <Modal.Header className="editAddressHeaderCont">
          <h4 className="editAddressTxt">Edit Address</h4>
          <div className="closeIconDiv" onClick={setShowAddAddressCancel}>
            <img src={IconClose} alt="icon" style={{ cursor: "pointer" }} />
          </div>
        </Modal.Header>
        <Modal.Body className="editAddressBody">
          <div className="editAddressBodyCont">
            <div className="editAddressInputsCont">
              <div className="addressTextInputsCont">
                <label>To Name *</label>
                <input
                  type="text"
                  value={createFacilityObj.toName}
                  onChange={(e) => setFacVal(e, "toName")}
                  style={{
                    border: `${
                      invalidArr.includes("validateToName")
                        ? "1px solid red"
                        : ""
                    }`,
                  }}
                  onBlur={() =>
                    checkName("validateToName", createFacilityObj.toName)
                  }
                  data-tip="To Name can only contain letters (A-Z, a-z) and following special characters: ' and whitespace"
                  data-for="name-tooltip"
                />
                <ReactTooltip
                  id="name-tooltip"
                  effect="solid"
                  place="bottom"
                  className="tooltip"
                />
                {invalidArr.includes("validateToName") && (
                  <label style={{ color: "red", fontSize: "8px" }}>
                    {new FormValidator().validateValue(
                      createFacilityObj.toName,
                      /^[A-Za-z ']+$/,
                      100
                    )}
                  </label>
                )}
              </div>

              <div className="addressTextInputsCont">
                <label>Attn Name *</label>
                <input
                  type="text"
                  value={createFacilityObj.attnName}
                  onChange={(e) => setFacVal(e, "attnName")}
                  style={{
                    border: `${
                      invalidArr.includes("validateAttName")
                        ? "1px solid red"
                        : ""
                    }`,
                  }}
                  onBlur={() =>
                    checkName("validateAttName", createFacilityObj.attnName)
                  }
                  data-tip="Attn Name can only contain letters (A-Z, a-z) and following special characters: ' and whitespace"
                  data-for="name-tooltip"
                />
                <ReactTooltip
                  id="name-tooltip"
                  effect="solid"
                  place="bottom"
                  className="tooltip"
                />
                {invalidArr.includes("validateAttName") && (
                  <label style={{ color: "red", fontSize: "8px" }}>
                    {new FormValidator().validateValue(
                      createFacilityObj.attnName,
                      /^[A-Za-z ']+$/,
                      100
                    )}
                  </label>
                )}
              </div>

              <div className="addressTextInputsCont">
                <label>Address Line 1 *</label>
                <input
                  type="text"
                  value={createFacilityObj.address1}
                  onChange={(e) => setFacVal(e, "address1")}
                  style={{
                    border: `${
                      invalidArr.includes("address1") ? "1px solid red" : ""
                    }`,
                  }}
                  onBlur={() =>
                    checkName("address1", createFacilityObj.address1)
                  }
                  data-tip="Address Line 1 can only contain letters (A-Z, a-z), numbers (0-9) and following special characters: #, ., ', -, commas and whitespace"
                  data-for="name-tooltip"
                />
                <ReactTooltip
                  id="name-tooltip"
                  effect="solid"
                  place="bottom"
                  className="tooltip"
                />
                {invalidArr.includes("address1") && (
                  <label style={{ color: "red", fontSize: "8px" }}>
                    {new FormValidator().validateValue(
                      createFacilityObj.address1,
                      /^[\w\s,#-]+$/,
                      255
                    )}
                  </label>
                )}
              </div>

              <div className="addressTextInputsCont">
                <label>Address Line 2 *</label>
                <input
                  type="text"
                  value={createFacilityObj.address2}
                  onChange={(e) => setFacVal(e, "address2")}
                  style={{
                    border: `${
                      invalidArr.includes("address2") ? "1px solid red" : ""
                    }`,
                  }}
                  onBlur={() =>
                    checkName("address2", createFacilityObj.address2)
                  }
                  data-tip="Address Line 2 can only contain letters (A-Z, a-z), numbers (0-9) and following special characters: #, ., ', -, commas and whitespace"
                  data-for="name-tooltip"
                />
                <ReactTooltip
                  id="name-tooltip"
                  effect="solid"
                  place="bottom"
                  className="tooltip"
                />
                {invalidArr.includes("address2") && (
                  <label style={{ color: "red", fontSize: "8px" }}>
                    {new FormValidator().validateValue(
                      createFacilityObj.address2,
                      /^[\w\s,#-]+$/,
                      255
                    )}
                  </label>
                )}
              </div>

              <div className="addressTextInputsCont">
                <label>City *</label>
                <input
                  type="text"
                  value={createFacilityObj.city}
                  onChange={(e) => setFacVal(e, "city")}
                  style={{
                    border: `${
                      invalidArr.includes("city") ? "1px solid red" : ""
                    }`,
                  }}
                  onBlur={() => checkName("city", createFacilityObj.city)}
                  data-tip="City can only contain letters (A-Z, a-z) and following special characters: ' and whitespace"
                  data-for="name-tooltip"
                />
                <ReactTooltip
                  id="name-tooltip"
                  effect="solid"
                  place="bottom"
                  className="tooltip"
                />
                {invalidArr.includes("city") && (
                  <label style={{ color: "red", fontSize: "8px" }}>
                    {new FormValidator().validateValue(
                      createFacilityObj.city,
                      /^[A-Za-z ']+$/,
                      100
                    )}
                  </label>
                )}
              </div>

              <div className="addressSelectorCont">
                <label>State/Province *</label>
                <select
                  value={createFacilityObj.stateGeoCodeId}
                  onChange={(e) => setFacVal(e, "stateGeoCodeId")}
                >
                  {createStats?.countries
                    ?.filter((obj) => obj.geoName == "India")[0]
                    .states.map((stat) => (
                      <option key={stat.geoId} value={stat.geoId}>
                        {stat.geoName
                          .toLowerCase()
                          .split(" ")
                          .map((word) => {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          })
                          .join(" ")}
                      </option>
                    ))}
                </select>
              </div>

              <div className="addressTextInputsCont">
                <label>Postal Code *</label>
                <input
                  type="text"
                  value={createFacilityObj.postalCode}
                  onChange={(e) => setFacVal(e, "postalCode")}
                  style={{
                    border: `${
                      invalidArr.includes("validatePostalCode")
                        ? "1px solid red"
                        : ""
                    }`,
                  }}
                  onBlur={() =>
                    checkName(
                      "validatePostalCode",
                      createFacilityObj.postalCode
                    )
                  }
                />
                {invalidArr.includes("validatePostalCode") && (
                  <label style={{ color: "red", fontSize: "8px" }}>
                    {" "}
                    Enter only six digit code*
                  </label>
                )}
              </div>

              <div className="addressSelectorCont">
                <label>Country</label>
                <select>
                  <option>India</option>
                </select>
              </div>
            </div>

            {/* <div className="editAddressPurposeCont">
              {createStats?.contactPurposes?.POSTAL_ADDRESS?.map((ad) => (
                <div
                  className="addressPurposeCont"
                  key={ad.contactMechPurposeTypeId}
                >
                  <input
                    type="checkbox"
                    checked={addressPurpose.current.includes(
                      ad.contactMechPurposeTypeId
                    )}
                    onClick={() => contactPurpose(ad.contactMechPurposeTypeId)}
                    className="purposeCheckbox"
                  />
                  <label>{ad.description}</label>
                </div>
              ))}
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="editAddressFooter">
          <button className="cancelBtn" onClick={setShowAddAddressCancel}>
            Cancel
          </button>
          <button
            className="updateAddressBtn"
            onClick={() => setShowAddAddress(false)}
          >
            Update Address
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FacilityCard;

import React, { Component, useEffect, useState } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "./DatePicker.scss";
import A1Button from "../../Button";
import DatePicker from "./DatePicker";
import { useStateValue } from "../../../redux/StateProvider";
import { useLocation } from "react-router-dom";

function DateBtnRow() {
  const { pathname } = useLocation();

  // console.log("useParams",pathname)
  const [today, setToday] = useState([{ label: "Today", active: true }]);
  const [week, setWeek] = useState([{ label: "This Week", active: false }]);
  const [month, setMonth] = useState([{ label: "This Month", active: false }]);
  const [remove, setRemove] = useState(false);
  const [{ }, dispatch] = useStateValue();


  const convertUtc = (data: any) => {
    var dateUTC = new Date(data);
    dateUTC.getTime();
    var dateIST = new Date(dateUTC);
    //date shifting for IST timezone (+5 hours and 30 minutes)
    dateIST.setHours(dateIST.getHours() + 5);
    dateIST.setMinutes(dateIST.getMinutes() + 30);
    return dateIST;
  };

  useEffect(() => {
    if (today[0].active) {
      dispatch({ type: "SET_DATE_CHIP", dateChip: "Today" });
    } else if (week[0].active) {
      dispatch({ type: "SET_DATE_CHIP", dateChip: "This Week" });
    } else if (month[0].active) {
      dispatch({ type: "SET_DATE_CHIP", dateChip: "This Month" });
    } else {
      dispatch({ type: "SET_DATE_CHIP", dateChip: "Custom" });
    }
  }, [today, week, month]);

  // useEffect(() => {
  //   // TO_DATE_VALUE , toDateValue
  //   if (window.location.pathname.includes("asset-list")) {
  //     //dispatch({ type: "DATE_RANGE", dateRange: [0, 0] });
  //     // console.log("the start dateRange::1")
  //     setToday([{ label: "Today", active: true }]);
  //     setWeek([{ label: "This Week", active: false }]);
  //     if (window.location.pathname === "/download-list") {
  //       setMonth([{ label: "This Month", active: false }]);
  //     } else {
  //       setMonth([{ label: "Previous Month", active: false }]);
  //     }
  //     setRemove(true);

  //     const startTime = moment()
  //       .utc()
  //       .startOf("day")
  //       .subtract(5, "h")
  //       .subtract(30, "minutes")
  //       .valueOf();
  //     //   const end = new Date();
  //     const endTime = moment()
  //       .utc()
  //       .endOf("day")
  //       .subtract(5, "h")
  //       .subtract(30, "minutes")
  //       .valueOf();

  //     dispatch({ type: "DATE_RANGE", dateRange: [startTime, endTime] });
  //     dispatch({ type: "SET_DATE_CHIP", dateChip: "Today" });
  //     dispatch({ type: "TO_DATE_VALUE", toDateValue: "" });
  //   }
  //   else if (window.location.pathname.includes('download-list')) {
  //     setToday([{ label: "Today", active: false }]);
  //     setWeek([{ label: "This Week", active: false }]);
  //     let startDate: any;
  //     let endDate: any;
  //     let startUtc: any;
  //     let endUtc: any;
  //     startDate = moment().utc().startOf("month");
  //     endDate = moment(new Date()).endOf("day").utc();
  //     startUtc = moment().startOf("month").format("YYYY-MM-DD HH:mm:ss");
  //     endUtc = moment(new Date).format("YYYY-MM-DD HH:mm:ss");
  //     setMonth([
  //       {
  //         label: moment(startDate).format("MMMM") + "-" + moment(startUtc).format("DD/MM/YYYY") + " - " + moment(endUtc).format("DD/MM/YYYY"),
  //         active: true,
  //       },
  //     ]);

  //     dispatch({ type: "DATE_RANGE", dateRange: [startDate, endDate] });
  //     dispatch({ type: "SET_DATE_CHIP", dateChip: "This Month" });
  //   }
  //   else if (window.location.pathname === "/") {
  //     let startDate: any;
  //     let endDate: any;
  //     if (moment().startOf("month").isSame(moment().startOf("day"))) {
  //       startDate = moment().subtract(1, "month").utc().startOf("month");
  //       endDate = moment().subtract(1, "days").endOf("day").utc();
  //       setMonth([{ label: moment(startDate).format("MMMM") + "-" + moment(startDate).format("DD/MM/YYYY") + " - " + moment(endDate).format("DD/MM/YYYY"), active: true }]);
  //     } else {
  //       startDate = moment().startOf("month").format("YYYY-MM-DD HH:mm:ss");
  //       endDate = moment().subtract(1, "days").endOf("day").format("YYYY-MM-DD HH:mm:ss");
  //       setMonth([{ label: "This Month: " + moment(startDate).format("DD/MM/YYYY") + " - " + moment(endDate).format("DD/MM/YYYY"), active: true }]);
  //     }

  //     setToday([{ label: "Today", active: false }]);
  //     setWeek([{ label: "This Week", active: false }]);
  //     dispatch({ type: "DATE_RANGE", dateRange: [startDate, endDate] });
  //     dispatch({ type: "SET_DATE_CHIP", dateChip: "This Month" });
  //     dispatch({ type: "TO_DATE_VALUE", toDateValue: "MTD" });
  //     // console.log("date range in datecomp",dateRange)
  //   }
  //   // else {
  //   //   handleToday();
  //   // }
  //   return () => {
  //     let startTime = moment(new Date()).startOf("day").format("YYYY-MM-DD HH:mm:ss");
  //     let endTime = moment(new Date()).endOf("day").format("YYYY-MM-DD HH:mm:ss");
  //     console.log("the inventory", startTime, endTime);
  //     dispatch({ type: "DATE_RANGE", dateRange: [startTime, endTime] });
  //     dispatch({ type: "SET_DATE_CHIP", dateChip: "Today" });
  //     dispatch({ type: "TO_DATE_VALUE", toDateValue: "Daily" });
  //   }
  // }, [pathname]);

  useEffect(() => {
    if (window.location.pathname.includes('download-list')) {
      setToday([{ label: "Today", active: false }]);
      setWeek([{ label: "This Week", active: false }]);
      let startDate: any;
      let endDate: any;
      let startUtc: any;
      let endUtc: any;
      startDate = moment().utc().startOf("month");
      endDate = moment(new Date()).endOf("day").utc();
      startUtc = moment().startOf("month").format("YYYY-MM-DD HH:mm:ss");
      endUtc = moment(new Date).format("YYYY-MM-DD HH:mm:ss");
      setMonth([
        {
          label: moment(startDate).format("MMMM") + "-" + moment(startUtc).format("DD/MM/YYYY") + " - " + moment(endUtc).format("DD/MM/YYYY"),
          active: true,
        },
      ]);

      dispatch({ type: "DATE_RANGE", dateRange: [startDate, endDate] });
      dispatch({ type: "SET_DATE_CHIP", dateChip: "This Month" });
    }

    else if (window.location.pathname === "/") {
      let startDate: any;
      let endDate: any;
      if (moment().startOf("month").isSame(moment().startOf("day"))) {
        startDate = moment().subtract(1, "month").utc().startOf("month");
        endDate = moment().subtract(1, "days").endOf("day").utc();
        setMonth([{ label: moment(startDate).format("MMMM") + "-" + moment(startDate).format("DD/MM/YYYY") + " - " + moment(endDate).format("DD/MM/YYYY"), active: true }]);
      } else {
        startDate = moment().startOf("month").format("YYYY-MM-DD HH:mm:ss");
        endDate = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
        setMonth([{ label: "This Month: " + moment(startDate).format("DD/MM/YYYY") + " - " + moment(endDate).format("DD/MM/YYYY"), active: true }]);
      }

      setToday([{ label: "Today", active: false }]);
      setWeek([{ label: "This Week", active: false }]);
      dispatch({ type: "DATE_RANGE", dateRange: [startDate, endDate] });
      dispatch({ type: "SET_DATE_CHIP", dateChip: "This Month" });
      dispatch({ type: "TO_DATE_VALUE", toDateValue: "MTD" });
    }
    else {
      handleToday()
    }
    return () => {
      let startTime = moment(new Date()).startOf("day").format("YYYY-MM-DD HH:mm:ss");
      let endTime = moment(new Date()).endOf("day").format("YYYY-MM-DD HH:mm:ss");
      console.log("the inventory", startTime, endTime);
      dispatch({ type: "DATE_RANGE", dateRange: [startTime, endTime] });
      dispatch({ type: "SET_DATE_CHIP", dateChip: "Today" });
      dispatch({ type: "TO_DATE_VALUE", toDateValue: "Daily" });
    }
  }, [pathname, window.location.pathname]);

  const handleToday = () => {
    // dispatch({ type: "DATE_RANGE", dateRange: [0, 0] });
    setToday([{ label: "Today", active: true }]);
    setWeek([{ label: "This Week", active: false }]);
    if (moment().startOf("month").isSame(moment().startOf("day"))) {
      if (window.location.pathname === "/download-list") {
        setMonth([{ label: "This Month", active: false }]);
      } else {
        setMonth([{ label: "Previous Month", active: false }]);
      }
    } else {
      setMonth([{ label: "This Month", active: false }]);
    }
    setRemove(true);
    // const startTime = moment()
    //   .utc()
    //   .startOf("day")
    //   .subtract(5, "h")
    //   .subtract(30, "minutes")
    //   .valueOf();
    //   //   const end = new Date();
    // const endTime = moment()
    //   .utc()
    //   .endOf("day")
    //   .subtract(5, "h")
    //   .subtract(30, "minutes")
    //   .valueOf();
    let startTime = moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
    console.log("the inventory", startTime, endTime);
    dispatch({ type: "DATE_RANGE", dateRange: [startTime, endTime] });
    dispatch({ type: "SET_DATE_CHIP", dateChip: "Today" });
    dispatch({ type: "TO_DATE_VALUE", toDateValue: "Daily" });
  };

  const handleWeek = () => {
    // dispatch({ type: "DATE_RANGE", dateRange: [0, 0] });
    let startDate = moment().startOf("week");
    let endDate = moment().endOf("week");

    let labelStartDate = moment().utc().startOf("week");

    let labelEndDate = moment().utc().endOf("day");

    setToday([{ label: "Today", active: false }]);
    setWeek([
      {
        label:
          "This Week: " +
          moment(labelStartDate).format("DD/MM/YYYY") +
          " - " +
          moment(labelEndDate).format("DD/MM/YYYY"),
        active: true,
      },
    ]);
    if (moment().startOf("month").isSame(moment().startOf("day"))) {
      if (window.location.pathname === "/download-list") {
        setMonth([{ label: "This Month", active: false }]);
      } else {
        setMonth([{ label: "Previous Month", active: false }]);
      }
    } else {
      setMonth([{ label: "This Month", active: false }]);
    }

    setRemove(true);
    let startUtc = moment().startOf("week").format("YYYY-MM-DD HH:mm:ss");
    let endUtc = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
    // let  = moment()
    //   .utc()
    //   .startOf("week")
    //   .subtract(5, "h")
    //   .subtract(30, "minutes")
    //   .valueOf();

    // let  = moment()
    //   .utc()
    //   .endOf("week")
    //   .subtract(5, "h")
    //   .subtract(30, "minutes")
    //   .valueOf();

    // console.log("start time", startUtc);
    // console.log("end time", endUtc);

    dispatch({ type: "DATE_RANGE", dateRange: [startUtc, endUtc] });
    dispatch({ type: "SET_DATE_CHIP", dateChip: "This Week" });
    dispatch({ type: "TO_DATE_VALUE", toDateValue: "WTD" });
  };

  const handleMonth = () => {
    if (window.location.pathname.includes('download-list')) {
      // dispatch({ type: "DATE_RANGE", dateRange: [0, 0] });
      setToday([{ label: "Today", active: false }]);
      setWeek([{ label: "This Week", active: false }]);
      let startDate: any;
      let endDate: any;
      let startUtc: any;
      let endUtc: any;
      startDate = moment().utc().startOf("month");
      endDate = moment(new Date()).endOf("day").utc();
      startUtc = moment().startOf("month").format("YYYY-MM-DD HH:mm:ss");
      endUtc = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      setMonth([
        {
          label: moment(startDate).format("MMMM") + "-" + moment(startUtc).format("DD/MM/YYYY") + " - " + moment(endUtc).format("DD/MM/YYYY"),
          active: true,
        },
      ]);
      dispatch({ type: "DATE_RANGE", dateRange: [startDate, endDate] });
      dispatch({ type: "SET_DATE_CHIP", dateChip: "This Month" });
    }
    else {
      // dispatch({ type: "DATE_RANGE", dateRange: [0, 0] });
      let startDate;
      let endDate;
      let startUtc;
      let endUtc;
      setToday([{ label: "Today", active: false }]);
      setWeek([{ label: "This Week", active: false }]);
      if (moment().startOf("month").isSame(moment().startOf("day"))) {
        startDate = moment().subtract(1, "month").utc().startOf("month");
        endDate = moment().subtract(1, "days").endOf("day").utc();
        startUtc = moment()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
        endUtc = moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss");
        setMonth([
          {
            label:
              moment(startDate).format("MMMM") +
              "-" +
              moment(startUtc).format("DD/MM/YYYY") +
              " - " +
              moment(endUtc).format("DD/MM/YYYY"),
            active: true,
          },
        ]);
      } else {
        startDate = moment().utc().startOf("month");
        endDate = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
        startUtc = moment().startOf("month").format("YYYY-MM-DD HH:mm:ss");
        endUtc = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
        setMonth([
          {
            label:
              "This Month: " +
              moment(startUtc).format("DD/MM/YYYY") +
              " - " +
              moment(endDate).format("DD/MM/YYYY"),
            active: true,
          },
        ]);
      }

      setRemove(true);
      dispatch({ type: "DATE_RANGE", dateRange: [startUtc, endUtc] });
      dispatch({ type: "SET_DATE_CHIP", dateChip: "This Month" });
      dispatch({ type: "TO_DATE_VALUE", toDateValue: "MTD" });
    }
  };

  const handleCustom = (custom: any, label: any) => {
    //dispatch({ type: "DATE_RANGE", dateRange: [0, 0] });
    if (!custom && label !== undefined) {
      setToday([{ label: "Today", active: false }]);
      setWeek([{ label: "This Week", active: false }]);
      setMonth([{ label: "This Month", active: false }]);
    }
    else {
      setToday([{ label: "Today", active: true }]);
      dispatch({ type: "SET_DATE_CHIP", dateChip: "Today" });
    }
    setRemove(false);
  };

  const handleCustomClick = (custom: any) => {

    if (custom) {
      setToday([{ label: "Today", active: true }]);
      dispatch({ type: "SET_DATE_CHIP", dateChip: "Today" });
    }
    setRemove(false);
  };

  const handleRemove = () => {
    let startTime = moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
    setToday([{ label: "Today", active: true }]);
    dispatch({ type: "SET_DATE_CHIP", dateChip: "Today" });
    setWeek([{ label: "This Week", active: false }]);
    setMonth([{ label: moment().startOf("month").isSame(moment().startOf("day")) ? "Previous Month" : "This Month", active: false }]);
    dispatch({ type: "DATE_RANGE", dateRange: [startTime, endTime] });
    //handleToday();
  };

  //call state callback
  const stateID = (value: any) => {
    console.log(value, "va;ue");
  };
  return (
    <div className={"btnRow"}>
      <A1Button onClick={handleToday} active={today[0].active}>
        {today[0].label}
      </A1Button>
      <A1Button
        onClick={week[0].active ? handleRemove : handleWeek}
        active={week[0].active}
      >
        {week[0].label} {week[0].active && <FontAwesomeIcon icon={faXmark} />}
      </A1Button>
      <A1Button
        onClick={month[0].active ? handleRemove : handleMonth}
        active={month[0].active}
      >
        {month[0].label} {month[0].active && <FontAwesomeIcon icon={faXmark} />}
      </A1Button>
      <DatePicker
        callBackToday={handleRemove}
        handleCustom={handleCustom}
        handleCustomClick={handleCustomClick}
        remove={remove}
        stateID={stateID}
      />
    </div>
  );
}

export default DateBtnRow;
import { addDays, subDays, subMonths, subWeeks } from "date-fns";
import moment from "moment";
import React, { useState } from "react";
import { DateRange } from "react-date-range";
import "./TrendDatePicker.scss";

import TrendDateIcon from "../../../assets/icons/trendDatePickerIcon.svg";
import MonthRangerPicker from "../CustomMonthPicker/MonthRangerPicker";
import DateFormat from "../DateFormatters/DateFormat";

function TrendDatePicker({
  frequenciesData,
  showDatePicker,
  selectedRange,
  selectedChip,
  handleInputClick,
  handleCancelClick,
  handleApplyClick,
  handleChipClick,
  handleFrequencyClick,
  handleRangeChange,
  state,
  selectedFrequency,
}: any) {

  const dateFormatter = new DateFormat();

  return (
    <div className="trendDateInputContainer">
      <div className="trendDateInputDetails">
        <h4 className="trendDateHeader">Trend Date *</h4>
        <div className="trendDateInputCont" onClick={handleInputClick}>
          <img
            src={TrendDateIcon}
            alt="icon"
            style={{ width: "20px", height: "16px" }}
          />
          <input
            type="text"
            placeholder="Select Date Range"
            value={selectedRange}
            className="inputTempTextValue"
            readOnly
          />
        </div>
      </div>
      {showDatePicker && (
        <div className="trendCustomDatePickerContainer">
          <div className="datePickerContainer">
            <div className="customDateChipsDev">
              {Object.keys(frequenciesData).map((chip) => (
                <div
                  key={chip}
                  className={
                    selectedChip === chip
                      ? "customActiveChip"
                      : "customInActiveChip"
                  }
                  onClick={() => handleChipClick(chip)}
                >
                  <p className="dateChipText">{chip}</p>
                </div>
              ))}
            </div>
            <p className="customDateRangeText">
              {selectedChip === "Day" && (
                <>
                  {moment(state[0].startDate).format("MMM DD, YYYY")} -{" "}
                  {moment(state[0].endDate).format("MMM DD, YYYY")}
                </>
              )}
              {selectedChip === "Week" && (
                <>
                  {moment(state[0].startDate).format("MMM DD, YYYY")} (
                  {`W${moment(state[0].startDate).isoWeek()}`}) -{" "}
                  {moment(state[0].endDate).format("MMM DD, YYYY")} (
                  {`W${moment(state[0].endDate).isoWeek()}`})
                </>
              )}
              {selectedChip === "Month" && (
                <>
                  {moment(state[0].startDate).format("MMM YYYY")} -{" "}
                  {moment(state[0].endDate).format("MMM YYYY")}
                </>
              )}
            </p>
          </div>
          <div className="frequencyContainer">
            <div className="dateChipsFrequencyCont">
              <p className="frequencyHeader">Frequency</p>
              <div className="frequencyDataCont">
                {frequenciesData[selectedChip].map((ele, index) => (
                  <div
                    className={
                      selectedFrequency === ele
                        ? "selectedFrequencies"
                        : "unselectedFrequencies"
                    }
                    key={index}
                    onClick={() => handleFrequencyClick(ele)}
                  >
                    <p className="frequencyText">{ele}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="calendersContainer">
              {selectedChip === "Month" ? (
                <MonthRangerPicker
                  containerWidth={"400px"}
                  darkTheme={false}
                  onDateRangeChange={(selectedRanges, frequencyType) => {
                    const [selectedRange] = selectedRanges;
                    if (frequencyType === "Month") {
                      handleFrequencyClick("Custom Range", selectedRange);
                    }
                  }}
                  ranges={state}
                />
              ) : (
                <DateRange
                  onChange={handleRangeChange}
                  ranges={state}
                  months={2}
                  minDate={dateFormatter.get365DaysAgo()}
                  maxDate={new Date()}
                  direction="horizontal"
                />
              )}
            </div>
          </div>
          <div className="trendDateButtonsCont">
            <div className="trendDateCancelBtn" onClick={handleCancelClick}>
              Cancel
            </div>
            <div className="trendDateApplyBtn" onClick={handleApplyClick}>
              Apply
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TrendDatePicker;

/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import CardWrapper from "../../a1Components/cardWrapper";
import DonutChartComp from "../../a1Components/donutChart";
import SankeyChartDev from "../../a1Components/E-Charts/SankeyChartDev";
import SnackCard from "../../a1Components/SnackCards";
import healthscoreicongood from "../../assets/icons/healthscore-icon-good.svg";
import healthscoreiconexcellent from "../../assets/icons/healthscore-icon-excellent.svg";
import healthscoreiconbad from "../../assets/icons/healthscore-icon-bad.svg";
import * as echarts from "echarts/core";
import axios from "axios";

import ClockIcon from "../../assets/image/clock-icon.svg";
import FuelIcon from "../../assets/image/fuel-icon.svg";
import GunIcon from "../../assets/image/gun-icon.svg";
import TabTitleBox from "../../a1Components/tabTitleBox";
import TileCard from "../../a1Components/iconTileCard";
import Sortable from "../../a1Components/sort";
import "./dashboardNew.scss";
import styled from "styled-components";
import * as R from "ramda";
import AreaChart from "../../a1Components/areaChart";
import Emissions from "../../a1Components/EmissionsComponent";
import TileTypeOne from "../../a1Components/tileTypeOne";
import StackedHrBarChart from "../../a1Components/StackedHrBarChart";
import assetIcon from "../../assets/image/asset-icon.svg";
import StackedLineChart from "../../a1Components/lineChart";
import { DataFetching } from "../../a1Components/Servies/DataFetching";
import StackedHorozontalBar from "../../a1Components/E-Charts/stackedHorizontalBar";
import GaugeChart from "../../a1Components/Gauge";
import { useStateValue } from "../../redux/StateProvider";
import Heatmap from "../../a1Components/E-Charts/HeatmapComponent/HeatMap";
import { Link } from "react-router-dom";
import ScoreCard from "../../a1Components/FacilityScoreCard/ScoreCard";
import { DashBoardServices } from "../../services/DasboardServices/DashboardService";
import LoaderEffect from "../../a1Components/LoaderEffects/LoaderEffect";
import useGetPortletByPageId from "../../Hooks/useGetPortletByPageId";
import useGetinfoTip from "../../Hooks/useGetinfoTip";
import DisplayNote from "../../a1Components/DisplayNote/DisplayNote";
import { dataLoader } from "@amcharts/amcharts4/core";

const dgData = [ClockIcon, FuelIcon, GunIcon];

const series = [
  {
    name: "Savings",
    type: "line",
    stack: "Total",
    smooth: true,
    lineStyle: {
      width: 2,
      color: "#36D7B7",
    },
    showSymbol: false,
    areaStyle: {
      opacity: 0.8,
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: "rgba(54, 215, 183, 0.20)",
        },
        {
          offset: 1,
          color: "rgba(255, 255, 255)",
        },
      ]),
    },
    emphasis: {
      focus: "series",
    },
    data: [],
  },
  {
    name: "Consumption",
    type: "line",
    stack: "Total",
    smooth: true,
    lineStyle: {
      width: 2,
      color: "#4cb9f2",
    },
    showSymbol: false,
    areaStyle: {
      opacity: 0.8,
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: "rgb(93, 157, 239)",
        },
        {
          offset: 1,
          color: "rgb(255, 255, 255)",
        },
      ]),
    },
    emphasis: {
      focus: "series",
    },
    data: [],
  },
];

const forecast = [
  {
    name: "Utilization",
    type: "line",
    stack: "Total",
    smooth: true,
    lineStyle: {
      width: 2,
      color: "#DCC6E0",
    },
    showSymbol: false,
    areaStyle: {
      opacity: 0.8,
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: "rgba(220, 198, 224, 0.20)",
        },
        {
          offset: 1,
          color: "rgba(255, 255, 255)",
        },
      ]),
    },
    emphasis: {
      focus: "series",
    },
    data: [],
  },
];

const facilityHealth = [
  {
    name: "Facility Health",
    type: "line",
    stack: "Total",
    smooth: true,
    lineStyle: {
      width: 2,
      color: "#DCC6E0",
    },
    showSymbol: false,
    areaStyle: {
      opacity: 0.8,
      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: "rgb(93, 157, 239)",
        },
        {
          offset: 1,
          color: "rgb(255, 255, 255)",
        },
      ]),
    },
    emphasis: {
      focus: "series",
    },
    data: [],
  },
];

const Tabh5 = styled.h5`
  padding: 4%;
  font-size: 18px;
  width: 8vw;
  height: 6vh;
  text-align: center;
  color: black;
  border-radius: 5px;
  ${({ active }) =>
    active &&
    `
  color: white;
  background: #426BFA;
  opacity: 1;
`}
`;
const Tab = styled.button`
  font-size: 20px;
  padding-top: 8px;
  cursor: pointer;
  opacity: 0.6;
  border: 0;
  outline: 0;
  background: white;
  ${({ active }) =>
    active &&
    `
    border-bottom: 2px solid #426BFA;
    opacity: 1;
  `}
`;
const ButtonGroup = styled.div`
  display: flex;
`;

const stackedAreaChartVal = {
  color: ["#80FFA5"],
  title: {
    text: "Facility Health trend",
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
      label: {
        backgroundColor: "#4cb9f2",
      },
    },
  },
  legend: {
    data: ["Line 1"],
    bottom: 0,
  },
  toolbox: {
    showDownload: false,
  },
  grid: {
    left: "1%",
    right: "1%",
    bottom: "1%",
    top: "2%",
    containLabel: true,
  },
  xAxis: [
    {
      type: "category",
      boundaryGap: false,
      data: [
        "Day/Time/Hr",
        "Day/Time/Hr",
        "Day/Time/Hr",
        "Day/Time/Hr",
        "Day/Time/Hr",
      ],
      axisTick: { show: false },
      axisLine: {
        show: false,
      },
      splitLine: { show: false },
    },
  ],
  yAxis: [
    {
      type: "value",
      axisTick: { show: false },
      axisLine: {
        show: false,
      },
      splitLine: { show: false },
    },
  ],
  series: [
    {
      name: "Line 1",
      type: "line",
      stack: "Total",
      smooth: true,
      lineStyle: {
        width: 2,
        color: "#36D7B7",
      },
      showSymbol: false,
      areaStyle: {
        opacity: 0.8,
        color: "#36D7B7",
      },
      emphasis: {
        focus: "series",
      },
      data: [20, 40, 30, 60, 70],
    },
  ],
};

const gaugeChartData = {
  height: 200,
  series: [
    {
      type: "gauge",
      center: ["50%", "60%"],
      startAngle: 200,
      endAngle: -20,
      min: 0,
      max: 100,
      splitNumber: 12,
      itemStyle: {
        color: "#4CAF50",
      },
      progress: {
        show: true,
        width: 10,
      },
      pointer: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          width: 10,
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
      anchor: {
        show: false,
      },
      title: {
        show: false,
      },
      detail: {
        show: false,
      },
      data: [
        {
          value: 81.8,
        },
      ],
    },
  ],
};

const pieChartDev = {
  tooltip: {
    trigger: "item",
  },
  legend: {
    show: true,
    bottom: "100px",
  },

  series: [
    {
      name: "Access From",
      top: "-66px",
      width: "171px",
      height: "275px",
      position: "absolute",
      left: "0px",
      type: "pie",
      radius: ["70%", "50%"],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: "center",
      },

      emphasis: {
        label: {
          show: true,
          fontSize: "40",
          fontWeight: "bold",
        },
      },
      labelLine: {
        show: false,
      },
      data: [
        { value: 301, name: "Active Assets" },
        { value: 69, name: "InActive Assets" },
      ],
    },
  ],
};

async function fetchFacilityHealth() {
  const fetchDataFH = new DashBoardServices();
  const data = fetchDataFH.fetchFacilityHealthPI();
  return data;
}

async function fetchUtilization() {
  const fetchDataFH = new DashBoardServices();
  const data = fetchDataFH.fetchUtilizationPI();
  return data;
}

async function distributionTabFun() {
  const fetchDataFH = new DashBoardServices();
  const data = fetchDataFH.distributionTabFunPI();
  return data;
}
async function shankeyLive() {
  const fetchDataFH = new DashBoardServices();
  const data = fetchDataFH.shankeyLivePI();
  return data;
}
async function dieselGeneratorFun() {
  const fetchDataFH = new DashBoardServices();
  const data = fetchDataFH.dieselGeneratorFunPI();
  return data;
}

function PanIndiaDB({ isDark, theme, rightOpen, toggleBtn, themeColors }:any) {
  const headingColor = isDark
    ? { color: themeColors?.headingColor, fontSize: "10px" }
    : {};

  const [utilizationTab, setUtilizationTab] = useState([]);
  const [assetUtilisation, setAssetUtilisation] = useState([]);
  const [summary, setSummary] = useState([]);
  const [facilityHealthData, setFacilityHealthData] = useState([]);
  const [facilityGaugeData, setFacilityGaugeData] = useState([]);
  const [utilisationTrendOption, setUtilisationTrendOption] = useState([]);
  const [utilisationForecastOption, setUtilisationForecastOption] = useState(
    []
  );
  const [facilityHealthAreaData, setFacilityHealthAreaData] = useState([]);
  const [yAxisData, setYAxisData] = useState([]);
  const [col1Data, setCol1Data] = useState([]);
  const [col2Data, setCol2Data] = useState([]);
  const [col3Data, setCol3Data] = useState([]);
  const [yourAssetData, setYourAssetData] = useState(null);
  const [usageData, setUsageData] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [lowPerformance, setLowperformance] = useState(false);
  const [assetTabActive, setAssetTabActive] = useState("Asset Performance");
  const [assetTabMenu, setAssetTabMenu] = useState([
    "Performance",
    "Score Trend",
  ]);
  const [assetScores, setAssetScores] = useState([]);
  const [scoreLine, setScoreLine] = useState([]);
  const [scoreOfValue, setScoreOfValue] = useState([]);
  const [distributionTab, setDistributionTab] = useState([]);
  const [distributionactiveTab, setDistributionactiveTab] = useState(1);
  const [shankeyData, setShankeyData] = useState({});
  const [dieselGenerator, setDieselGenerator] = useState([]);
  const [{ aoneLoginData }, dispatch] = useStateValue();
  const [snackCard, setsnackCard] = useState([]);
  const [facilityHealthList, setFacilityHealthList] = useState();
  const [portlets] = useGetPortletByPageId("PAN_INDIA_DB");
  const [infoTipData] = useGetinfoTip("PAN_INDIA_DB");
  const [routePath, setRoutePath] = useState(window.location.pathname);

  useEffect(() => {
    let currentPath =
      window.location.pathname === "/"
        ? "Pan-India-db"
        : window.location.pathname;
    setRoutePath(currentPath);
  }, []);

  useEffect(async () => {
    var data = new DataFetching();
    var applyData = await data.getSnackCard();
    setsnackCard(applyData.data);
  }, []);

  useEffect(() => {}, [portlets]);

  useEffect(() => {
    fetchFacilityHealth().then((result) => {
      if (result) {
        setFacilityHealthData(result?.PanFacilityHealth);
        const faciltyAreaData = {
          ...facilityHealth[0],
          data: result?.PanFacilityHealth[0].areaData,
        };
        setFacilityHealthAreaData([faciltyAreaData]);
      }
    });
    dispatch({
      type: "PATH_NAME",
      pathName: "/",
    });
  }, []);

  useEffect(() => {
    async function getHealthScore() {
      const userName = await localStorage.getItem("userName");
      const password = await localStorage.getItem("password");
      try {
        const response = await axios.get(
          `https://aone.atsuyatech.com//api/1.0/fixedAsset/healthScore?username=${userName}&password=${password}&filterBy=SI_INDIA&view=COUNTRY`
        );
        if (response.status === 200) {
          let list = response?.data?.health?.subGroupList;
          list = list.reverse();
          list = list.filter((val, i) => i < 5);
          setFacilityHealthList(list);
          setFacilityGaugeData(response?.data?.health?.gaugeData);
        }
      } catch (error) {
        // console.error(error, "getHealthScore");
      }
    }
    getHealthScore();
  }, []);

  function handleTabClick(id: React.SetStateAction<number>) {
    setActiveTab(id);
  }
  //function written for the distribution card tab click
  function handleTabClickDist(id: React.SetStateAction<number>) {
    setDistributionactiveTab(id);
  }

  useEffect(() => {
    async function getYourAssets() {
      try {
        const response = await axios.get(
          "https://snakeoil-api.atsuyatech.com/utilisation"
        );
        // Your asset data start
        let yourAssets = response?.data?.yourAssetsDB;
        setYourAssetData(yourAssets);
        setYAxisData(yourAssets.map((el: { assetName: any }) => el.assetName));
        setCol1Data(
          yourAssets.map(
            (el: { performace: { stackValue: any[] } }) =>
              el.performace.stackValue[0]
          )
        );
        setCol2Data(
          yourAssets.map(
            (el: { performace: { stackValue: any[] } }) =>
              el.performace.stackValue[1]
          )
        );
        setCol3Data(
          yourAssets.map(
            (el: { performace: { stackValue: any[] } }) =>
              el.performace.stackValue[2]
          )
        );
        setScoreLine(
          yourAssets.map((el: { score: { score: any } }) => el.score.score)
        );
        setScoreOfValue(yourAssets.map((el) => el.score.values));
      } catch (error) {
        // console.error(error, "getYourAssets");
      }
    }
    getYourAssets();
  }, []);

  useEffect(() => {
    fetchUtilization().then((result) => {
      if (result) {
        setUtilizationTab(result.utilizationTab);
        setActiveTab(result.utilizationTab[0]?.id);
        setAssetUtilisation(result.assetUtilisation);
        setAssetScores(result.assetScores);
        const savings = {
          ...series[0],
          data: result.utilisationTrendData[0].savings,
        };
        const consumption = {
          ...series[1],
          data: result.utilisationTrendData[0].consumption,
        };
        const forecastData = { ...forecast[0], data: result.forecast[0].data };
        setUtilisationTrendOption([savings, consumption]);
        setUtilisationForecastOption([forecastData]);
        setUsageData(result.forecast[0].usage);
      }
    });
  }, []);

  useEffect(() => {
    distributionTabFun().then((result) => {
      if (result) {
        setDistributionTab(result);
      }
    });
    dieselGeneratorFun().then((result) => {
      if (result) {
        let dgResult = result;
        for (var i = 0; i < dgResult.length; i++) {
          dgResult[i].icon = dgData[i];
        }
        setDieselGenerator(result);
      }
    });
    shankeyLive().then((result) => {
      if (result) {
        setShankeyData(result.shankeyData?.[0]);
      }
    });
  }, []);

  function scoreSort(
    a: { score: { score: any } },
    b: { score: { score: any } }
  ) {
    let aProperty = a.score.score;
    let bProperty = b.score.score;
    if (
      !R.isEmpty(aProperty) &&
      !R.isNil(aProperty) &&
      !R.isEmpty(bProperty) &&
      !R.isNil(bProperty)
    ) {
      let aInt = parseInt(aProperty[aProperty.length - 1]);
      let bInt = parseInt(bProperty[bProperty.length - 1]);
      if (lowPerformance) {
        return aInt - bInt;
      } else {
        return bInt - aInt;
      }
    }
  }

  function handlePerformers(val: { target: { value: string } }) {
    setLowperformance(val.target.value === "low" ? true : false);
    if (!R.isEmpty(yourAssetData)) {
      let sortAsset = yourAssetData?.sort(scoreSort);
    }
  }
  const infotipFunction = (val: string) => {
    let retData;
    let datatoFilter = infoTipData;

    !R.isEmpty(datatoFilter) &&
      !R.isNil(datatoFilter) &&
      datatoFilter?.portlets.map(
        (ele: { portalPortletId: any; description: any }) => {
          if (ele.portalPortletId === val) {
            retData = ele.description;
          }
        }
      );

    return retData;
  };

  const leftCol = document.getElementById("leftCol");

  const heatMapData: string | any[] = [];
  const horizontalBar: string | any[] = [];

  return (
    <>
      <div
        className={`${
          leftCol?.childElementCount === 0
            ? "row-reverse smb-100"
            : "row smb-100"
        }`}
      >
        <div
          id="leftCol"
          className={
            !rightOpen
              ? "col-xl-6 col-12 col-12 col-lg-6 col-md-12"
              : toggleBtn
              ? "col-12"
              : "col-8"
          }
        >
          {!R.isNil(portlets) &&
            !R.isEmpty(portlets) &&
            portlets.includes("PAN_INDIA_HEALTH") && (
              <CardWrapper
                headerLabel="Overall Health"
                footerLabel="13 of your assets are over utilized. "
                infoTip={infotipFunction("PAN_INDIA_HEALTH")}
                themeColors={isDark ? theme.dark.colors : theme.light.colors}
              >
                <div className="row snackchart-card">
                  <div className="col-lg-5 col-12">
                    <GaugeChart data={facilityGaugeData} rank />
                  </div>
                  <div className="snackCardCss col-lg-7 col-12">
                    {snackCard.map((snack) => (
                      <SnackCard
                        isDark={isDark}
                        image={
                          snack.message === "Utilization"
                            ? healthscoreiconexcellent
                            : snack.message === "Emissions"
                            ? healthscoreiconbad
                            : healthscoreicongood
                        }
                        label={snack.label}
                        message={snack.message}
                      />
                    ))}
                  </div>
                </div>
                <hr className="border-hr" />
                <h4>Region wise score</h4>
                <div className="facilityScore">
                  {facilityHealthList &&
                    facilityHealthList.map(
                      (
                        ele: {
                          value: any;
                          name: any;
                          postName: any;
                          abbreviation: any;
                        },
                        id: any
                      ) => {
                        return (
                          <Link to={routePath + "/" + ele.groupId}>
                            <ScoreCard
                              key={id}
                              id={ele.groupId}
                              value={ele.value}
                              name={ele.name}
                              postName={ele.postName}
                              abbreviation={ele.abbreviation}
                            />
                          </Link>
                        );
                      }
                    )}
                </div>
              </CardWrapper>
            )}

          {!R.isNil(portlets) &&
            !R.isEmpty(portlets) &&
            portlets.includes("DIESEL_PAN_INDIA") && (
              <CardWrapper
                headerLabel="Diesel Generator"
                footerLabel="Run hours are 70% higher than yesterday."
                infoTip={infotipFunction("DIESEL_PAN_INDIA")}
                themeColors={isDark ? theme.dark.colors : theme.light.colors}
              >
                <div
                  className="d-flex-titleCard"
                  style={{ paddingTop: "40px", paddingBottom: "25px" }}
                >
                  {dieselGenerator?.map((el, i) => (
                    <TileCard
                      key={i}
                      icon={el.icon}
                      title={el.title}
                      timeConsumed={el.timeConsumed}
                      trendVal={el.variance}
                    />
                  ))}
                </div>
              </CardWrapper>
            )}

          {!R.isNil(portlets) &&
            !R.isEmpty(portlets) &&
            portlets.includes("DIST_PAN_INDIA") && (
              <CardWrapper
                headerLabel="Distribution Across Asset Types"
                footerLabel="Run hours are 70% higher than yesterday."
                infoTip={infotipFunction("DIST_PAN_INDIA")}
                themeColors={isDark ? theme.dark.colors : theme.light.colors}
              >
                <div className="cardWrapper-distTab">
                  {!R.isEmpty(distributionTab) && (
                    <div className="tabTitleBox-wrapper">
                      {distributionTab.map((el, i) => (
                        <div
                          key={i}
                          className={`${
                            distributionactiveTab === el.id
                              ? "active-Border"
                              : "border-sty"
                          }`}
                          onClick={() => handleTabClickDist(el.id)}
                        >
                          <h6
                            className={`${
                              distributionactiveTab === el.id
                                ? "distributionTab-active tabTitleBox-wrapper-active"
                                : "distributionTab"
                            }`}
                            onClick={() => handleTabClickDist(el.id)}
                          >
                            {el.title}
                          </h6>
                        </div>
                      ))}
                    </div>
                  )}
                  {!R.isEmpty(distributionTab) && (
                    <div className="tabTitleBox-wrapper mobileDrop">
                      <select className="mobileDropSelect">
                        {distributionTab.map((el, i) => (
                          <option key={i} value={el.title}>
                            {el.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {Object.keys(shankeyData).length != 1 ? (
                    <SankeyChartDev data={shankeyData} />
                  ) : (
                    <DisplayNote isErr={false} />
                  )}
                </div>
              </CardWrapper>
            )}

          {!R.isNil(portlets) &&
            !R.isEmpty(portlets) &&
            portlets.includes("REGION_KEY_UTIL") && (
              <CardWrapper
                headerLabel="Shift-wise Utilisation"
                footerLabel="Shift 1 has the highest utilisation (85%). "
                infoTip={infotipFunction("REGION_KEY_UTIL")}
                themeColors={isDark ? theme.dark.colors : theme.light.colors}
              >
                {horizontalBar && horizontalBar.length ? (
                  <StackedHorozontalBar data={horizontalBar} />
                ) : (
                  <DisplayNote isErr={false} />
                )}
              </CardWrapper>
            )}

          {!R.isNil(portlets) &&
            !R.isEmpty(portlets) &&
            portlets.includes("PAN_INDIA_EMISSION") && (
              <CardWrapper
                headerLabel="Emissions Reduced"
                footerLabel="Emissions 10% more then past week. "
                infoTip={infotipFunction("PAN_INDIA_EMISSION")}
                themeColors={isDark ? theme.dark.colors : theme.light.colors}
              >
                <Emissions Contributing="Top Assets Contributing to Emissions" />
              </CardWrapper>
            )}
        </div>

        <div
          id="leftCol"
          className={
            !rightOpen
              ? "col-xl-6 col-12 col-12 col-lg-6 col-md-12"
              : toggleBtn
              ? "col-12"
              : "col-8"
          }
        >
          {!R.isNil(portlets) &&
            !R.isEmpty(portlets) &&
            portlets.includes("CITY_WISE_SCORE") && (
              <CardWrapper
                headerLabel="City-wise Performance Rating"
                footerLabel="20 assets are inactive for more than 5 days."
                infoTip={infotipFunction("CITY_WISE_SCORE")}
                themeColors={isDark ? theme.dark.colors : theme.light.colors}
              >
                {heatMapData && heatMapData.length != 0 ? (
                  <Heatmap data={heatMapData} />
                ) : (
                  <DisplayNote isErr={false} />
                )}
              </CardWrapper>
            )}

          {!R.isNil(portlets) &&
            !R.isEmpty(portlets) &&
            portlets.includes("FACILITY_ASSET_SMRY") && (
              <CardWrapper
                headerLabel="Asset Summary"
                footerLabel="20 assets are inactive for more than 5 days."
                infoTip={infotipFunction("FACILITY_ASSET_SMRY")}
                themeColors={isDark ? theme.dark.colors : theme.light.colors}
              >
                <DonutChartComp />
              </CardWrapper>
            )}

          {!R.isNil(portlets) &&
            !R.isEmpty(portlets) &&
            portlets.includes("PAN_INDIA_PERFORM") && (
              <CardWrapper
                headerLabel="Region-wise Performance"
                footerLabel="More than 40% assets are running greater than 80% efficiency."
                infoTip={infotipFunction("PAN_INDIA_PERFORM")}
                themeColors={isDark ? theme.dark.colors : theme.light.colors}
              >
                {!R.isEmpty(assetTabMenu) &&
                  assetTabMenu.map((el, i) => (
                    <div
                      key={i}
                      className={
                        assetTabActive === el
                          ? "active assetTabMenu"
                          : "assetTabMenu"
                      }
                      onClick={() => setAssetTabActive(el)}
                    >
                      <h6>{el}</h6>
                    </div>
                  ))}
                {assetTabActive === "Asset Performance" ? (
                  <>
                    {scoreOfValue.flat(scoreOfValue.length).length > 0 ? (
                      <>
                        <div className="scoreCard"></div>
                        <div style={{ paddingLeft: "20px" }}>
                          <div className="radio-input-warpper">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="top"
                              name="performers"
                              value="top"
                              onClick={handlePerformers}
                              checked
                            />
                            <label htmlFor="top">Top Performers</label>
                            <input
                              className="form-check-input"
                              type="radio"
                              id="low"
                              name="performers"
                              value="low"
                              onClick={handlePerformers}
                            />
                            <label htmlFor="low">Low Performers</label>
                          </div>
                          <div className="hrBarWrapper">
                            {yAxisData && col1Data && col2Data && col3Data ? (
                              <StackedHrBarChart
                                yAxisData={yAxisData}
                                col1Data={col1Data}
                                col2Data={col2Data}
                                col3Data={col3Data}
                                data={[]}
                                legendBottom={10}
                                legendLeft="12%"
                              />
                            ) : (
                              <DisplayNote isErr={false} />
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <DisplayNote isErr={false} />
                    )}
                    <div className="arrow-link">
                      <span className="arrowStatus">
                        <Link
                          to={routePath + "/region-list"}
                          className="viewAll"
                        >
                          View all regions{" "}
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            className="viewall-icon"
                          />
                        </Link>
                      </span>
                    </div>
                  </>
                ) : (
                  <div className="hrBarWrapper noHeight">
                    {scoreLine.length === 0 ? (
                      <StackedLineChart
                        yAxisData={yAxisData}
                        data={scoreLine}
                      />
                    ) : (
                      <DisplayNote isErr={false} />
                    )}
                    <Link to={routePath + "/region-list"} className="viewAll">
                      View all regions{" "}
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="viewall-icon"
                      />
                    </Link>
                  </div>
                )}
              </CardWrapper>
            )}

          {!R.isNil(portlets) &&
            !R.isEmpty(portlets) &&
            portlets.includes("REGION_ENERGY_UTIL") && (
              <CardWrapper
                headerLabel="Region-wise Energy Utilisation"
                footerLabel="You are expected to consume more energy tomorrow compared to today."
                infoTip={infotipFunction("REGION_ENERGY_UTIL")}
                themeColors={isDark ? theme.dark.colors : theme.light.colors}
              >
                {scoreOfValue.flat(scoreOfValue.length).length > 0 ? (
                  <>
                    {!R.isEmpty(assetUtilisation) && (
                      <Sortable collection={assetUtilisation} />
                    )}
                  </>
                ) : (
                  <DisplayNote isErr={false} />
                )}
              </CardWrapper>
            )}
        </div>
      </div>
    </>
  );
}

export default PanIndiaDB;

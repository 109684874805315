import React from "react";
import "./IncidentLoadMoreCardHeader.scss";

function IncidentLoadMoreCardHeader() {
  return (
    <div className="incidentLoadMoreCardHeaderCont">
      <h4 className="incidentLoadMoreAttachementTxt">ATTACHMENTS</h4>
      <h4 className="incidentLoadMoreTimeTxt">INCIDENT TIME</h4>
      <h4 className="incidentLoadMoreTypeTxt">TYPE</h4>
      <h4 className="incidentLoadMoreCountTxt">OCCURENCE COUNT</h4>
      <h4 className="incidentLoadMoreLocationTxt">LOCATION</h4>
    </div>
  );
}

export default IncidentLoadMoreCardHeader;

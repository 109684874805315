import { API_BASE_URL } from "../../Constants/constants";
import { PartyGroupResponse } from "../../SettingsComponents/Organisations/Types/PartyGroupResponse";
import LocalStorageService from "../../Utils/LocalStorageService";

const baseURL = "https://dev-tnt.app.aone.ai/api/1.0";

export class SettingsApiServices {
  //ToDo save auth token here and use it in headers instead of passing it from the function
  async TokenGenerate() {
    try {
      const response = await fetch(
        `${API_BASE_URL}Login?username=admin&password=ofbiz`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // add any additional headers here if required
          },
        }
      );
      if (response.status === 200) {
        return await response.json();
      } else {
        return {};
      }
    } catch (error) {
      return {};
    }
  }

  async FacilityStatistics(tokenID: any) {
    const cacheKey = "facility_statistics_cache";
    const cachedData = localStorage.getItem(cacheKey);

    if (cachedData) {
      const { data } = JSON.parse(cachedData);
      console.log("FacilityStatistics :: Using cached data");
      return data;
    }

    try {
      const response = await fetch(`${API_BASE_URL}statics`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenID}`,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        LocalStorageService.setFacilityStaticsData(JSON.stringify({ timestamp: Date.now(), data }));
        return data;
      }

      return {};
    } catch (error) {
      return {};
    }
  }

  async CreateFacility(tokenID: any, addressObject: any) {
    console.log("@addressObject", JSON.stringify(addressObject));
    //remove the empty ones
    try {
      const response = await fetch(
        "https://dev-tnt.app.aone.ai/api/1.0/facility/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            token: tokenID,
          },
          body: JSON.stringify(addressObject),
        }
      );
      if (!response.ok) {
        return response;
      }
      const reponseData = await response.json();
      const reponseStatus = response.status;
      return { reponse: reponseData, reponseCode: reponseStatus };
    } catch (error) {
      return "error";
    }
  }

  async updateFacility(tokenID: any, addressObject: any) {
    console.log("@addressObject", JSON.stringify(addressObject));
    //remove the empty ones
    try {
      const response = await fetch(
        "https://dev-tnt.app.aone.ai/api/1.0/facility/update",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            token: tokenID,
          },
          body: JSON.stringify(addressObject),
        }
      );
      if (!response.ok) {
        return response;
      }
      const reponseData = await response.json();
      const reponseStatus = response.status;
      return { reponse: reponseData, reponseCode: reponseStatus };
    } catch (error) {
      return "error";
    }
  }

  async ListOfFacilitys(tokenID: any) {
    try {
      const options = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: tokenID,
        },
        body: JSON.stringify({
          userPartyId: "Company",
          viewIndex: 0,
          viewSize: 20,
        }),
      };
      const response = await fetch(
        "https://dev-tnt.app.aone.ai/api/1.0/facility/list",
        options
      );
      const responseData = await response.json();
      console.log(responseData, "resp777::");
      return responseData;
    } catch (error) {
      console.error(error);
    }
  }

  async createOrganisation(tokenID: string, orgObject: any) {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token: tokenID,
        },
        body: JSON.stringify(orgObject),
      };
      const response = await fetch(
        "https://dev-tnt.app.aone.ai/api/1.0/partyGroup",
        requestOptions
      );

      if (!response.ok) {
        return response.status;
      }

      const responseData = await response.json();
      const responseStatus = response.status;
      console.log("Response received:", responseData);
      return { response: responseData, responseCode: responseStatus };
    } catch (error) {
      return "error";
    }
  }

  async facilityEnable(token: any, body: any) {
    try {
      // /facility/enable
      const requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify(body),
      };
      const response = await fetch(
        `https://dev-tnt.app.aone.ai/api/1.0/facility/enable`,
        requestOptions
      );

      if (!response.ok) {
        return response.status;
      }
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      return "Eroor";
    }
  }

  async relocateAsset(tokenID: any, body: any) {
    try {
      const option = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenID}`,
        },
        body: JSON.stringify(body),
      };
      const relocateUrl = await fetch(`${API_BASE_URL}assets/relocate`, option);
      return relocateUrl;
      // relocateUrl;
    } catch (error) {
      return "error";
    }
  }

  async updateAssetBulkStatus(token: any, body: any) {
    try {
      const option = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
        body: JSON.stringify(body),
      };
      return await fetch(`${API_BASE_URL}assets/updateBulkAssetStatus`, option);
    } catch (error) {
      return error;
    }
  }

  async bulkRelocateAsset(tokenID: any, body: any) {
    try {
      const relocateOption = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenID}`,
        },
        body: JSON.stringify(body),
      };
      const bulkRelocate = await fetch(`${API_BASE_URL}assets/bulkRelocate`, relocateOption);
      return bulkRelocate;
    } catch (error) {
      return error;
    }
  }

  async fetchOrganisationList(
    pageIndex: number,
    pageSize: number
  ): Promise<PartyGroupResponse> {
    try {
      const partyIdFrom = localStorage.getItem("@partyID");
      const roleTypeID = localStorage.getItem("@roleTypeID");
      const tokenID = localStorage.getItem("@tokenID");
      // `${API_BASE_URL}partyGroup/list?partyGroup=${partyIdFrom}&roleTypeId=${roleTypeID}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
      // Removed roleTypeID for Fetching All the org details..
      const url = `${API_BASE_URL}partyGroup/list?partyGroup=${partyIdFrom}&pageIndex=${pageIndex}&pageSize=${pageSize}`;

      const headers = new Headers();
      headers.append("token", tokenID || "");

      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });

      if (response.ok) {
        const data: PartyGroupResponse = await response.json();
        console.log(data);
        return data;
      } else {
        console.error("Request failed with status:", response.status);
        throw new Error("Request failed");
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async CreateAsset(tokenID: any, assetObject: any) {
    console.log("asset:createAssetApi:-");
    try {
      const response = await fetch(`${API_BASE_URL}assets`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenID}`,
        },
        body: JSON.stringify(assetObject),
      });
      const responseData = await response.json();
      console.log("asset:createAsset:Line304:", responseData);
      const responseStatus = response.status;
      console.log("CreateAsset", responseStatus, responseData, assetObject);

      return { response: responseData, responseCode: responseStatus };
    } catch (error) {
      return "error";
    }
  }

  async UpdatedAsset(tokenID: any, assetObject: any) {
    try {
      const response = await fetch(`${API_BASE_URL}assets`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenID}`,
        },
        body: JSON.stringify(assetObject),
      });
      const responseData = await response.json();
      console.log("asset:updateAsset:", responseData);
      const responseStatus = response.status;
      console.log("UpdateAsset", responseStatus, responseData, assetObject);

      return { response: responseData, responseCode: responseStatus };
    } catch (error) {
      return "error";
    }
  }

  async usersList(tokenID: any) {
    try {
      const option = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: tokenID,
        },
      };
      const response = await fetch(
        "https://dev-tnt.app.aone.ai/api/1.0/user/list",
        option
      );
      const responseListData = await response.json();
      console.log(responseListData, "responseList::");
      return responseListData;
    } catch (error) {
      console.error(error);
    }
  }


  async getAssetAttributes(assetId: any) {
    const token = localStorage.getItem("@tokenID");
    const url = `${API_BASE_URL}fixedAsset/listAttr?fixedAssetId=${assetId}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.assetAttr;
  }

  async updateAssetAttributes(body: any) {
    const apiUrl = `${API_BASE_URL}/assets/assetUpdateAttribute`;
    const token = localStorage.getItem("@tokenID");

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${token}`);

    try {
      const response = await fetch(apiUrl, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(body)
      });

      if (response.ok) {
        return await response.json()
      } else {
        throw new Error('Network response was not ok.');;
      }
    } catch (error) {
      throw new Error('An error occured.');
    }
  };
}

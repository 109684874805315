import React from 'react' 

export class SortingTable {
    sortTableAscendingOrder(data:any, key:any) {
    let list = [...data].sort((a,b) =>  a[key] - b[key]);
    return list
   }

   sortTableDescending(data:any, key:any) {
    let list = [...data].sort((a,b) =>  b[key] - a[key]);
    return list
}
}








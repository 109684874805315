import scrollBottom from '../assets/scrollBottom.svg'
import PageFiltersUtils from '../utils/PageFiltersUtils'

function ScrollButton({type,scrollableContainerRef, setIsAtBottom, setIsAtTop}) {
    const pageFiltersUtils = new PageFiltersUtils();
    return (
        <button className={type === 'down' ? 'buttonBottom' : 'buttonTop'}
            onClick={() => pageFiltersUtils.handleScroll(type, scrollableContainerRef, setIsAtBottom, setIsAtTop)}
        >
            <img src={scrollBottom } className="scrollbtnIcon" />
        </button>
    )
}

export default ScrollButton
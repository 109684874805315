import {API_BASE_URL} from "../../../../Constants/constants";

const abortControllers: { [key: string]: AbortController } = {};

const getAbortController = (key: string): AbortController => {
    if (!abortControllers[key]) {
        abortControllers[key] = new AbortController();
    }
    return abortControllers[key];
};

export const scheduleReportGenerationApi = async (body: object) => {
    const url = `${API_BASE_URL}reports/scheduleReportGeneration`;
    const key = "scheduleReportGeneration";

    const abortController = getAbortController(key);

    try {
        const resp = await fetch(url, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
            },
            signal: abortController.signal,
        });

        return resp;
    } catch (error) {
        return error;
    }
};

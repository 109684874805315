/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import useState from "react-usestateref";
import "./FaultDetailsCradPopup.scss";
import ReactEcharts from "echarts-for-react";
import ModalClose from "../../../assets/icons/ModalClose.svg";
import { Col, Row } from "react-bootstrap";
function FaultDetailsCradPopup({ show, close }: any) {
  const [status, setStatus] = useState(true);

  const option = {
    xAxis: {
      type: "category",
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: [820, 932, 901, 934, 1290, 1330, 1320],
        type: "line",
        smooth: true,
      },
    ],
  };

  const [openOptionDiv, setOpenOptionDiv] = useState(false);

  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.keyCode === 27) {
        close(1);
        setOpenOptionDiv(false);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  const onclose = () => {
    close();
    setOpenOptionDiv(false);
  };
  let count = 0;

  return (
    <>
      <div className={show ? "background" : ""}>
        <div
          className="modal-wrapper"
          style={{
            transform: show ? "translateY(0vh)" : "translateY(-100vh)",
            opacity: show ? "1" : "0",
          }}
        >
          <div className="faultDetailsPopCont">
            <div className="faulDetailsPopHeaderCont">
              <div className="faulDetailsPopHeaderTxtCont">
                <p className="faulDetailsPopHeaderTxt">MOTOR-07</p>
                <p className="faulDetailsPopHeaderPara">
                  Broken rotor bar fault
                </p>
              </div>
              <button onClick={onclose} className="faultPopupCloseBtn">
                <img className="popupClose" src={ModalClose} alt="popupClose" />
              </button>
            </div>
            <div className="popupRadioBtnsContainer">
              <div className="popupRadioBtns">
                <input
                  type="radio"
                  onClick={() => setStatus(true)}
                  name="ticketDetails"
                  value="current"
                  checked={status}
                />
                <label className="radiobtnsLable">Current</label>
                <input
                  type="radio"
                  onClick={() => setStatus(false)}
                  name="ticketDetails"
                  value="vibration"
                />
                <label className="radiobtnsLable">Vibration</label>
                <input
                  type="radio"
                  onClick={() => setStatus(false)}
                  name="ticketDetails"
                  value="speed"
                />
                <label className="radiobtnsLable"> Speed (RPM)</label>
              </div>
            </div>
            <div className="popupchartContainer">
              <ReactEcharts option={option} />
            </div>
            <div className="popupChartDetailsContsiner">
              <div className="popupChartReadingsCont">
                <div className="popupChartReadings">
                  <ul className="readingsContainer">
                    <li className="readingsTxt">Current</li>
                    <li className="readingsCount">24 A</li>
                  </ul>
                  <ul className="readingsContainer">
                    <li className="readingsTxt">Current</li>
                    <li className="readingsCount">24 A</li>
                  </ul>
                  <ul className="readingsContainer">
                    <li className="readingsTxt">Current</li>
                    <li className="readingsCount">24 A</li>
                  </ul>
                </div>

                <div className="popupChartReadings">
                  <ul className="readingsContainer">
                    <li className="readingsTxt">Current</li>
                    <li className="readingsCount">24 A</li>
                  </ul>
                  <ul className="readingsContainer">
                    <li className="readingsTxt">Current</li>
                    <li className="readingsCount">24 A</li>
                  </ul>
                  <ul className="readingsContainer">
                    <li className="readingsTxt">Current</li>
                    <li className="readingsCount">24 A</li>
                  </ul>
                </div>

                <div className="popupChartReadings">
                  <ul className="readingsContainer">
                    <li className="readingsTxt">Current</li>
                    <li className="readingsCount">24 A</li>
                  </ul>
                  <ul className="readingsContainer">
                    <li className="readingsTxt">Current</li>
                    <li className="readingsCount">24 A</li>
                  </ul>
                  <ul className="readingsContainer">
                    <li className="readingsTxt">Current</li>
                    <li className="readingsCount">24 A</li>
                  </ul>
                </div>
              </div>
              <div className="popupChartFrequencyCont">
                <div className="frequencyCont">
                  <p className="frequencyTxt">-62.171 dB</p>
                  <p className="frequencyStatus">FSB Lower</p>
                </div>
                <div className="frequencyCont">
                  <p className="frequencyTxt">-62.171 dB</p>
                  <p className="frequencyStatus">FSB Upper</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FaultDetailsCradPopup;

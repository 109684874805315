import * as React from "react";
import {HVACAssetData, Pagination, PumpAssetData} from "../types/HVACSummaryLandingDataType";
import {LiveMeter} from "../../assetList/types";

export const HVACAssetHooks = () => {
    const assetCategoryTab = [
        {
            id: 'CHILLER',
            name: 'Chiller',
        },
        {
            id: 'PUMP',
            name: 'Pump',
        },
        {
            id: 'COOLING_TOWER',
            name: 'Cooling Tower',
        },
    ];
    const [selectedTab, setSelectedTab] = React.useState('CHILLER');
    const [totalAssetCount, setTotalAssetCount] = React.useState<number>(0);

    const paginationRef = React.useRef<Pagination>({
        totalPage: 0,
        nextPage: 0,
        pageSize: 0,
        prevPage: 0,
        totalCount: 0,
        currentPage: 0,
    });

    const responseBodyObj: React.MutableRefObject<object> = React.useRef<object>({});

    const [chillerAssetData, setChillerAssetData] = React.useState<HVACAssetData>({
        data: [],
        pagination: {
            totalPage: 0,
            nextPage: 0,
            pageSize: 0,
            prevPage: 0,
            totalCount: 0,
            currentPage: 0,
        }
    });

    const [pumpAssetData, setPumpAssetData] = React.useState<PumpAssetData>({
        chilled: {
            id: '',
            title: '',
            data: [],
        },
        condensor: {
            id: '',
            title: '',
            data: [],
        },
        pagination: {
            totalPage: 0,
            nextPage: 0,
            pageSize: 0,
            prevPage: 0,
            totalCount: 0,
            currentPage: 0,
        },
    });

    const [coolingTowerAssetData, setCoolingTowerAssetData] = React.useState<HVACAssetData>({
        data: [],
        pagination: {
            totalPage: 0,
            nextPage: 0,
            pageSize: 0,
            prevPage: 0,
            totalCount: 0,
            currentPage: 0,
        }
    });

    const [categoryAssetData, setCategoryAssetData] = React.useState<HVACAssetData>({
        data: [],
        pagination: {
            totalPage: 0,
            nextPage: 0,
            pageSize: 0,
            prevPage: 0,
            totalCount: 0,
            currentPage: 0,
        }
    });

    const [initialApiCall, setInitialApiCall] = React.useState<boolean>(true);

    const [tripAlarmStatus, setTripAlarmStatus] = React.useState<LiveMeter | null>(null);

    const [liveMeterList, setLiveMeterList] = React.useState<LiveMeter[] | null>(null);

    const updateSelectedTab = React.useCallback((value: string) => {
        setSelectedTab(value);
    }, [setSelectedTab]);

    const updateTotalAssetCount = React.useCallback((value: number) => {
        setTotalAssetCount(value);
    }, [setTotalAssetCount]);

    const updateChillerAssetData = React.useCallback((data: HVACAssetData) => {
        setChillerAssetData(data);
    }, [setChillerAssetData]);

    const updatePumpAssetData = React.useCallback((data: PumpAssetData) => {
        setPumpAssetData(data);
    }, [setPumpAssetData]);

    const updateTripAlarmStatus = React.useCallback((data: LiveMeter | null) => {
        setTripAlarmStatus(data);
    }, [setTripAlarmStatus]);

    const updateCoolingTowerAssetData = React.useCallback((data: HVACAssetData) => {
        setCoolingTowerAssetData(data);
    }, [setCoolingTowerAssetData]);

    const updateLiveMeterList = React.useCallback((data: LiveMeter[] | null) => {
        setLiveMeterList(data);
    }, [setLiveMeterList]);

    const updateCategoryAssetData = React.useCallback((data: HVACAssetData) => {
        setCategoryAssetData(data);
    }, [setCategoryAssetData]);

    const updateInitialApiCall = React.useCallback((value: boolean) => {
        setInitialApiCall(value);
    }, [setInitialApiCall]);

    return {
        assetCategoryTab,
        selectedTab,
        totalAssetCount,
        updateSelectedTab,
        updateTotalAssetCount,
        chillerAssetData,
        updateChillerAssetData,
        paginationRef,
        responseBodyObj,
        pumpAssetData,
        updatePumpAssetData,
        tripAlarmStatus,
        updateTripAlarmStatus,
        coolingTowerAssetData,
        updateCoolingTowerAssetData,
        liveMeterList,
        updateLiveMeterList,
        categoryAssetData,
        updateCategoryAssetData,
        initialApiCall,
        updateInitialApiCall,
    };

}
import React, {useEffect, useRef} from 'react';
import './HVACSummaryDetailsDashboard.scss';
import {Link, useParams} from "react-router-dom";
import BackArrow from "../../../eventList/assets/backArrow.svg";
import FilterIcon from '../../assets/icon-button-filter.svg';
import HVACSummaryDashboardCard from "./HVACSummaryDashboardCard";
import ArrowHoverIcon from '../../assets/arrow-hover.svg';
import HVACSummaryDashboardFooter from "./HVACSummaryDashboardFooter";
import {useQuery, useQueryClient} from "react-query";
import {fetchHVACSummary} from "../../service/HVACSummaryService";
import {cancelExistingQuery} from "../../../../Utils/utils";
import LoaderStyle from "../../../../a1Components/LoaderStyle/LoaderStyle";
import DisplayError from "../../../../a1Components/DisplayError/DisplayError";
import {AssetData} from "../../types/HVACSummaryLandingDataType";
import {HVACAssetHooks} from "../../hooks/HVACAssetHooks";

const HVACSummaryDetailsDashboard = () => {
    const {id} = useParams();

    const queryClient = useQueryClient();
    const queryKey: string = 'HVACAssetSummaryDashboard';
    const assetDetailsKey: string = 'HVACAssetSummaryAssetDashboard';

    const responseBodyObj: React.MutableRefObject<object> = useRef<object>({
        "viewIndex": 1,
        "viewSize": 20,
        "hvacFixedAssetTypeId": id,
    });

    const selectedAsset = useRef<AssetData | null>(null);

    const {
        categoryAssetData,
        updateCategoryAssetData,
        initialApiCall,
        updateInitialApiCall,
    } = HVACAssetHooks();

    const {data, error, isLoading, refetch, isFetching} = useQuery(
        queryKey,
        () => fetchHVACSummary(responseBodyObj.current),
        {
            enabled: false,
            keepPreviousData: false,
            onSuccess: (fetchedData) => {
                if (typeof fetchedData === "object" && fetchedData.hasOwnProperty("assets")) {
                    const assetList = fetchedData?.assets;
                    const pagination = fetchedData?.pagination;

                    if (initialApiCall) {
                        updateCategoryAssetData({
                            data: assetList,
                            pagination: pagination
                        });
                        updateInitialApiCall(false);
                        selectedAsset.current = assetList[0];
                        fetchAssetDetailsData();
                    } else {
                        updateCategoryAssetData({
                            data: [...categoryAssetData.data, ...assetList],
                            pagination: pagination
                        });
                    }
                }
            },
        });

    const {
        data: assetDetailsData,
        error: assetDetailsError,
        isLoading: assetDetailsIsLoading,
        refetch: assetDetailsRefetch,
        isFetching: assetDetailsIsFetching
    } = useQuery(
        assetDetailsKey,
        () => fetchHVACSummary({"hvacAssetId": selectedAsset?.current.assetId}),
        {
            enabled: false,
            keepPreviousData: false,
            onSuccess: (fetchedData) => {
                if (typeof fetchedData === "object" && fetchedData.hasOwnProperty("assets")) {
                    // Todo for the Asset Location SVG Path
                    console.log(fetchedData)
                }
            },
        });

    const fetchAssetDetailsData = async () => {
        try {
            cancelExistingQuery(assetDetailsKey, queryClient);
            await assetDetailsRefetch();
        } catch (exception) {
            console.error("Error re-fetching data:", exception);
        }
    };

    const fetchListData = async () => {
        try {
            cancelExistingQuery(queryKey, queryClient);
            await refetch();
        } catch (exception) {
            console.error("Error re-fetching data:", exception);
        }
    };

    const handleSelectedAsset = (assetId: string) => {
        const selectedAssetData = categoryAssetData.data.find((item: AssetData) => item.assetId === assetId);
        selectedAsset.current = selectedAssetData === undefined ? null : selectedAssetData;
        fetchAssetDetailsData();
    }

    useEffect(() => {
        fetchListData();
        return (() => {
            cancelExistingQuery(queryKey, queryClient);
        })
    }, []);

    return (
        <div className="hvacDashboardMainDiv">
            {isLoading ? (
                <div className="loadingBox1">
                    <LoaderStyle/>
                </div>
            ) : error ? (
                <div className="loadingBox1">
                    <DisplayError type="err_1"/>
                </div>
            ) : data !== undefined && typeof data === "number" ? (
                <div className="loadingBox1">
                    <DisplayError type="err-500/404"/>
                </div>
            ) : (
                <>
                    <div className="hvacDashboardHeaderMainDiv">
                        <div className="hvacDashboardHeaderDiv">
                            <Link to="/" style={{width: 'fit-content'}}>
                                <div className="goBackDivs">
                                    <img src={BackArrow}/>
                                    <p className="goBackDivTitles">Dashboard</p>
                                </div>
                            </Link>
                            <p className="componentHeadings">{typeof id === "string" && `${id.toUpperCase()} `}Summary</p>
                        </div>
                        <div className='hvacDashboardFilterButtonDiv'>
                            <img src={FilterIcon}/>
                            <p className='filterText'>Filter</p>
                        </div>
                    </div>

                    {/*  Summary List  */}
                    <div className='summaryListMainDiv'>
                        <div className='summaryCardListDiv'>
                            {
                                categoryAssetData.data.map((item: AssetData, index: number) => (
                                    <HVACSummaryDashboardCard
                                        data={item}
                                        key={index}
                                        index={index}
                                        id={id}
                                        isSelected={selectedAsset?.current.assetId === item.assetId}
                                        handleSelectedAsset={handleSelectedAsset}
                                    />
                                ))
                            }
                        </div>
                        {/*<div className='loadMoreAssetDiv'>*/}
                        {/*    <img src={ArrowHoverIcon} alt='Load More'/>*/}
                        {/*    <p className='loadMoreAssetText'>4 more assets</p>*/}
                        {/*</div>*/}
                    </div>

                    <HVACSummaryDashboardFooter id={id} data={selectedAsset?.current} />
                </>
            )}


        </div>
    );
};

export default HVACSummaryDetailsDashboard;

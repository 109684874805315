/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck

import React, { useEffect, useRef, useState } from "react";
import IconClose from "../../../../assets/icons/IconClose.svg";
import Modal from "react-bootstrap/Modal";
import AssetImageIcon from "../../../../assets/icons/AssetImageIcon.svg";
import StatusArrow from "../../../../assets/icons/StatusArrow.svg";
import { SettingsApiServices } from "../../../../services/settingsPageServices/SettingsApiServices";

import "./AssetSettingsCreatedEditModal.scss";
import { number } from "prop-types";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as R from "ramda";
import Select from "react-select";
import { FieldValidatorUtil } from "./../../../../Utils/fieldValidation/FieldValidatorUtil";
import ReactTooltip from "react-tooltip";
import FormValidator from "../../../../Utils/FormValidations/FormValidations";
import DropdownSelect from "./DropdownFilter";


const AssetSettingsCreatedEditModal = (props: any) => {
  const {
    data,
    type,
    closePopUp,
    statusId,
    statusDiscription,
    callAssetListApi,
    setAssetDetailEmpty,
    showSuccessToast,
    showErrorToast,
    callCreateFacilityFunction,
  } = props;
  const [dataObject, setDataObject] = React.useState(Object.assign({}, data));

  console.log("asset:data:Line21:", data);

  const [visible, setVisible] = React.useState(false);
  const [responseText, setResponseText] = React.useState("");
  const [response, setResponse] = React.useState("Please Wait ...");
  const [loader, setLoader] = React.useState(false);
  const [assetType, setAssetType] = React.useState<null | []>(null);

  const [invalidArr, setInvalidArr] = useState<string[]>([]);
  const validate = new FieldValidatorUtil();

  // Asset Delete Popup
  const [deleteImg, setDeleteImg] = React.useState(false);

  const [isOpen, setIsOpen] = React.useState(false);
  const today = new Date().toISOString().split('T')[0];
  const [enableCreateEditButton, setEnableCreateEditButton] = useState(false);
  const [toastEnabled, setToastEnabled] = useState(true);

  //ToDO use keys and not values
  const getStatusColor = (status: string) => {
    if (status == "Active") {
      return {
        background: "#5AA15D4D",
        button: "#5AA15D",
      };
    } else if (status == "Inactive") {
      return {
        background: "#F447474D",
        button: "#F44747",
      };
    } else if (status == "Lost") {
      return {
        background: "#DF43204D",
        button: "#DF4320",
      };
    } else if (status == "Maintenance") {
      return {
        background: "#E9BE034D",
        button: "#E9BE03",
      };
    } else if (status == "Accident/ Breakdown") {
      return {
        background: "#D5000033",
        button: "#D50000",
      };
    } if (status == "Online") {
      return {
        background: "#4CAF5033",
        button: "#4CAF50",
      };
    } else if (status == "Offline") {
      return {
        background: "#F4433633",
        button: "#F44336",
      };
    } else if (status == "Slow") {
      return {
        background: "#FFC10733",
        button: "#FFC107",
      };
    } else {
      return {
        background: "#FF967E",
        button: "#F44747",
      };
    }
  };

  const [selectedOption, setSelectedOption] = React.useState({
    value: statusId,
    label: statusDiscription,
    color: getStatusColor(statusDiscription),
  });
  const [show, setShow] = React.useState(true);
  const [assetNameError, setAssetNameError] = React.useState("");
  const [assetSerialNumberError, setAssetSerialNumberError] =
    React.useState("");


  const checkName = (name: string, val: string) => {
    const validArr = [
      "validateAssetName",
      "validateAssetTypeId",
      "validateSerialNumber",
      "validateFacilityId",
      "validateProductName",
      "validatePurchaseDate",
      "validateClassEnumTypeId"
    ];

    if (!validArr.includes(name)) {
      return;
    }

    let functionName;

    switch (name) {
      case "validateAssetName":
        functionName = "validateAssetName";
        break;
      case "validateSerialNumber":
        functionName = "validateSerialNumber";
        break;
      case "validateAssetTypeId":
      case "validateFacilityId":
        functionName = "isValidAssetData";
        break;
      case "validateClassEnumTypeId":
        functionName = "isValidAssetData";
        break;
      case "validatePurchaseDate":
        functionName = "isDateLessThanEqualsToday";
        break;
      default:
        functionName = name;
    }


    let isValid = false;

    if (functionName === "validateAssetName") {
      isValid = validate.validateAssetName(val);
    } else if (functionName === "validateSerialNumber") {
      isValid = validate.validateSerialNumber(val);
    } else if (functionName === "isDateLessThanEqualsToday") {
      isValid = validate.validateDateLessThanToday(val);
    } else {
      isValid = validate.isValidAssetData(val);
    }

    setInvalidArr((prevInvalidArr) => {
      if (isValid) {
        return prevInvalidArr.filter((obj) => obj !== name);
      } else {
        if (!invalidArr.includes(name)) {
          return [...prevInvalidArr, name];
        } else {
          return [...prevInvalidArr];
        }
      }
    });
  };


  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (option: any) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const menuref=useRef();
  useEffect(()=>{
    const handler = (e) =>{
      if(!menuref?.current?.contains(e.target)){
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown",handler);
  })

 
  const getClassname = (condition: any) => {
    switch (condition) {
      case "LOST":
        return "assetLostBtn";
      case "DISABLED":
        return "assetDisabledBtn";
      case "MAINTENANCE":
        return "assetMaintainBtn";
      default:
        return "assetEnabledBtn";
    }
  };

  const convertDate = (timeStamp: any) => {
    if (timeStamp == null) {
      return "";
    }

    var dateFormat = new Date(timeStamp);
    let date = dateFormat.getDate().toString();

    if (date.length == 1) {
      date = `0${date}`;
    }

    let month = (dateFormat.getMonth() + 1).toString();

    if (month.toString().length == 1) {
      month = `0${month}`;
    }
    const year = dateFormat.getFullYear();

    const result = year + "-" + month + "-" + date;
    return result;
  };

  const [createAsset, setCreateAsset] = React.useState({
    fixedAssetId: type === "Create" ? "" : data.fixedAssetId,
    fixedAssetName: type === "Create" ? "" : data.fixedAssetName,
    fixedAssetTypeId: type === "Create" ? "" : { value: data.fixedAssetTypeId, label: data.fixedAssetTypeDescription},
    serialNumber: type === "Create" ? "" : data.serialNumber,
    instanceOfProductId: type === "Create" ? "" : { value: data.instanceOfProductId, label: data.productName},
    locatedAtFacilityId: type === "Create" ? "" : { value: data.locatedAtFacilityId, label: data.facility.facilityName},
    dateAcquired: type === "Create" ? "" : convertDate(data.dateAcquired),
    classEnumId: type === "Create" ? "" : { value: data.classEnumId, label: data.description},
    statusId: selectedOption,
  });
  const [isDateValid, setIsDateValid] = useState(true);
  const [isSerialNumberValid, setIsSerialNumberValid] = useState(true);  
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

   const validateDate = (date) => {
    if (date !== "") {
      const isValid = validate.validateDateLessThanToday(date);
      setIsDateValid(isValid);
    } else {
      setIsDateValid(true);
    }
  };

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setCreateAsset((prev) => ({
      ...prev,
      dateAcquired: newDate,
    }));
    validateDate(newDate);
  };

  const validateSerialNumber = (serialNumber) => {
    if (serialNumber !== "") {
      const isValid = validate.validateSerialNumber(serialNumber);
      setIsSerialNumberValid(isValid);
    } else {
      setIsSerialNumberValid(true);
    }
  };

  const handleSerialNumberChange = (event) => {
    const newSerialNumber = event.target.value;
    setCreateAsset((prev) => ({
      ...prev,
      serialNumber: newSerialNumber,
    }));
    validateSerialNumber(newSerialNumber);
  };

  useEffect(() => {
    const data = {
      fixedAssetName: createAsset.fixedAssetName,
      fixedAssetTypeId: createAsset.fixedAssetTypeId,
      instanceOfProductId: createAsset.instanceOfProductId,
      locatedAtFacilityId: createAsset.locatedAtFacilityId,
      classEnumId: createAsset.classEnumId,
    };

    let mandatory = new FieldValidatorUtil();
    const mandatoryStatus = mandatory.checkMandatoryFields(data, [
      "fixedAssetName",
      "fixedAssetTypeId",
      "instanceOfProductId",
      "locatedAtFacilityId",
    ]);
    const optional = isDateValid && isSerialNumberValid
    setEnableCreateEditButton(mandatoryStatus && optional);
  }, [createAsset]);

  const [statusData, setStatusData] = useState([
    { value: "", label: "", color: {} },
  ]);

  let localData: any = localStorage.getItem("facilityIds")
  const facilityList = JSON.parse(localData);
  

  const [productList, setProductList] = useState([
    {
      fromDate: 1634136947557,
      lastUpdatedStamp: 1685351689441,
      roleTypeId: "ACCOUNT",
      comments: null,
      productId: "WG-9943",
      sequenceNum: 1,
      createdTxStamp: 1683822090995,
      createdStamp: 1683822091657,
      lastUpdatedTxStamp: 1685351689439,
      partyId: "Company",
      thruDate: null,
      productName: "",
    },
  ]);


  const handleSelectChange = (name, e) => {
    setCreateAsset({
      ...createAsset,
      [name]: e,
    });
  };

  const handleChange = (event: any) => {
    setCreateAsset({
      ...createAsset,
      [event.target.name]: event.target.value,
    });
  };

  const setAssetStatusData = async () => {
    let tokenId = localStorage.getItem("@tokenID");
    const services = new SettingsApiServices();
    const response = await services.FacilityStatistics(tokenId);
    console.log("response:line42:", response);
    if (!R.isEmpty(response) && response.statusCode != "BAD_REQUEST") {
      const data = response.statusTypes.FXD_ASSET_STATUS;
      const result = [];
      data.forEach((element: any) => {
        const colorData = getStatusColor(element.description);
        result.push({
          value: element.statusCode,
          label: element.description,
          color: colorData,
        });
      });
      console.log("Asset:Status:Line105:", result);
      setStatusData(result);
    }
  };

  const numberOfClicks = useRef(0);

  const collapseToast =() =>{
    setToastEnabled(true)
    numberOfClicks.current = 0
  }

  const createPostData = async () => {

    if(numberOfClicks.current !== 1){
      return null;
    }

    const data = {
      fixedAssetName: createAsset.fixedAssetName,
      fixedAssetTypeId: createAsset.fixedAssetTypeId.value,
      serialNumber: createAsset?.serialNumber?.length === 0 ? null : createAsset?.serialNumber,
      instanceOfProductId: createAsset.instanceOfProductId.value,
      locatedAtFacilityId: createAsset.locatedAtFacilityId.value,
      dateAcquired:
        createAsset.dateAcquired == ""
          ? ""
          : `${createAsset.dateAcquired} 00:00:00.000`,
      classEnumId: createAsset.classEnumId.value,
    };

    console.log("asset:createAsset:Line171:", data);

    checkName("validateAssetName", data.fixedAssetName);
    // checkName("validateSerialNumber", data.serialNumber);
    checkName("validateAssetTypeId", data.fixedAssetTypeId);
    checkName("validateFacilityId", data.locatedAtFacilityId);
    checkName("validateProductName", data.instanceOfProductId);
    checkName("validatePurchaseDate", data.dateAcquired);
    // checkName("validateClassEnumTypeId", data.classEnumId)

    let mandatory = new FieldValidatorUtil();
    const mandatoryStatus = mandatory.checkMandatoryFields(data, [
      "fixedAssetName",
      "fixedAssetTypeId",
      // "serialNumber",
      "instanceOfProductId",
      "locatedAtFacilityId",
    ]);

    if (!mandatoryStatus || invalidArr.length > 0) {
      toast.error("Please Fill Mandatory Fields",{
        onOpen: () => setToastEnabled(false),
        onClose: () =>  collapseToast()
      });
      return;
    }

    let tokenID = localStorage.getItem("@tokenID");
    //button disable
    toast.dismiss();
    const createAssets = await new SettingsApiServices().CreateAsset(
      tokenID,
      data
    );

    if (
      createAssets.response.statusCode == "BAD_REQUEST" &&
      createAssets?.response.apiErrors[0] == "Fixed Asset Name Already exist"
    ) {
      toast.error("Asset name already exist.",{
        onOpen: () => setToastEnabled(false),
        onClose: () =>  collapseToast()
      });
      return;
    }

    if (createAssets?.responseCode === 200) {
      setVisible(true);
      closePopUp();
      toast.success("Created new Asset successfully!",{
        onOpen: () => setToastEnabled(false),
        onClose: () =>  collapseToast()
      });
      setAssetDetailEmpty();
      setVisible(false);
      callAssetListApi();
      // setTimeout(() => {
      setShow(false);
      // }, 700);
    } else {
      toast.error("Failed to Create Asset",{
        onOpen: () => setToastEnabled(false),
        onClose: () =>  collapseToast()
      });
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 2000);
      setLoader(false);
    }
  };

  const updatePutData = async () => {

    if(numberOfClicks.current !== 1){
      return null;
    }

    const data = {
      fixedAssetId: createAsset.fixedAssetId,
      fixedAssetName: createAsset.fixedAssetName,
      fixedAssetTypeId: createAsset.fixedAssetTypeId.value,
      serialNumber: createAsset?.serialNumber?.length === 0 ? null : createAsset?.serialNumber,
      instanceOfProductId: createAsset.instanceOfProductId.value,
      locatedAtFacilityId: createAsset.locatedAtFacilityId.value,
      dateAcquired:
        createAsset.dateAcquired == ""
          ? ""
          : `${createAsset.dateAcquired} 00:00:00.000`,
      statusId: selectedOption.value,
      classEnumId: createAsset.classEnumId.value,
    };
    console.log("sharan",data.classEnumId.value)
    checkName("validateAssetName", data.fixedAssetName);
    // checkName("validateSerialNumber", data.serialNumber);
    checkName("validateAssetTypeId", data.fixedAssetTypeId);
    checkName("validateFacilityId", data.locatedAtFacilityId);
    checkName("validateProductName", data.instanceOfProductId);
    // checkName("validateClassEnumTypeId", data.classEnumId)

    if (invalidArr.length != 0) {
      toast.error("Please Fill Mandatory Fields",{
        onOpen: () => setToastEnabled(false),
        onClose: ()=> collapseToast()
      });
      return;
    }

    let tokenID = localStorage.getItem("@tokenID");
    //button disable
    toast.dismiss();
    const createAssets = await new SettingsApiServices().UpdatedAsset(
      tokenID,
      data
    );

    if (createAssets?.responseCode === 200) {
      setResponse(
        `
        responseMessage: "success",
        successMessage: "FixedAsset updated successfully"`
      );
      setVisible(true);
      closePopUp();
      toast.success("Updated Asset successfully!",{
        onOpen: () => setToastEnabled(false),
        onClose: () =>  collapseToast()
      });
      setAssetDetailEmpty();
      setVisible(false);
      callAssetListApi();

      setTimeout(() => {
        setShow(false);
      }, 700);
    } else {
      toast.error("Failed to update Asset",{
        onOpen: () => setToastEnabled(false),
        onClose: () =>  collapseToast()
      });
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
      }, 2000);
      setLoader(false);
    }

  };
  const [classEnumList, setClassEnumList] = React.useState<null | []>(null);

  const getAssetType = async () => {
    let tokenId = localStorage.getItem("@tokenID");
    const services = new SettingsApiServices();
    const response = await services.FacilityStatistics(tokenId);
    if (!R.isEmpty(response) && response.responseMessage != "error") {
      // For asset type list
      // console.log(response?.enumeration?.FXAST_CLASS.map((obj) => ({ label: obj.description, value: obj.enumId })), 'classEnumID')
      setClassEnumList(response?.enumeration?.FXAST_CLASS.map((obj) => ({ label: obj?.description, value: obj?.enumId })))
      const data = response.assetTypes;
      const result = [];
      data.forEach((element: any) => {
        result.push({
          value: element.fixedAssetTypeId,
          label: element.description,
        });
      });
      console.log("asset:Line224:", result, createAsset);
      setAssetType(result);

      // For product name list
      const productNameList = response.productRoleTypes;

      console.log("asset:productName:Line268:", productNameList);
      setProductList(productNameList);
    }
  };

  useEffect(() => {
    getAssetType();
    setAssetStatusData();
  }, []);

  return (
    <>
      <Modal
        show={show}
        onHide={closePopUp}
        centered
        backdrop="static"
        className="addAndEditAssetModalPopup"
      >
        <Modal.Header className="addAndEditAssetModalHeader">
          <h4 className="addAndEditAssetHeader">
            {type === "Edit" ? " Edit Asset " : "Create new Asset"}
          </h4>
          <div className="closeIconDiv" onClick={closePopUp}>
            <img src={IconClose} alt="icon" />
          </div>
        </Modal.Header>
        <Modal.Body className="addAndEditAssetModalBody">
          <div className="edit-body-cont">
            <div className="editAssetParentRow">
              <div className="editAssetRow">
                <label className="editAssetRowKey">Asset Name *</label>
                <input
                  className="editAssetRowValue"
                  type="text"
                  name="fixedAssetName"
                  value={createAsset.fixedAssetName}
                  onChange={handleChange}
                  style={{
                    border: `${invalidArr.includes("validateAssetName")
                      ? "1px solid red"
                      : ""
                      }`,
                  }}
                  onBlur={() => {
                    checkName("validateAssetName", createAsset.fixedAssetName);
                  }}
                  data-tip="Asset Name can only contain letters (A-Z, a-z), numbers (0-9) and following special characters: #, ., ', -, commas and whitespace"
                  data-for="name-tooltip"
                />
                <ReactTooltip
                  id="name-tooltip"
                  effect="solid"
                  place="bottom"
                  className="tooltip"
                />
                {invalidArr.includes("validateAssetName") && (
                  <label className="labelStyle">
                    {new FormValidator().validateValue(
                      createAsset.fixedAssetName,
                      /^[A-Za-z0-9-#.,\/' ()&]+$/,
                      20
                    )}
                  </label>
                )}
              </div>

              <div className="editAssetRow Right">
                  <label className="editAssetRowKey">Asset Type *</label>
                    <DropdownSelect
                        placeholder="Search AssetType"
                        value={createAsset.fixedAssetTypeId}
                        onChange={(e) => handleSelectChange('fixedAssetTypeId', e)}
                        options={assetType}
                     />
                     
               </div>
            </div>
            <div className="editAssetParentRow">
              <div className="editAssetRow">
                <label className="editAssetRowKey">Asset Class *</label>
                <DropdownSelect
                        placeholder="Search AssetClass"
                        value={createAsset.classEnumId}
                        onChange={(e) => handleSelectChange('classEnumId', e)}
                        options={classEnumList}
                />
              </div>
              <div className="editAssetRow Right">
                <label className="editAssetRowKey">Product Name *</label>
                <DropdownSelect
                        placeholder="Search ProductName"
                        value={createAsset.instanceOfProductId}
                        onChange={(e) => handleSelectChange('instanceOfProductId', e)}
                        options={
                          productList.map(el=>({
                            label:el.productName,
                            value:el.productId
                          }))
                        }
                />
              </div>
            </div>
            <div className="editAssetParentRow">
               <div className="editAssetRow">
                 <label className="editAssetRowKey">Facility Name *</label>
                   <DropdownSelect
                        placeholder="Search FacilityName"
                        value={createAsset.locatedAtFacilityId}
                        onChange={(e) => handleSelectChange('locatedAtFacilityId', e)}
                        options={
                          facilityList.map(el=>({
                            label:el.facilityName,
                            value:el.facilityId,
                          }))
                        }
                   />
               </div>
              <div className="editAssetRow Right">
                <label className="editAssetRowKey">Serial Number</label>
                <input
                  className="editAssetRowValue"
                  type="text"
                  name="serialNumber"
                  value={createAsset.serialNumber}
                  onChange={handleSerialNumberChange}
                  style={{
                    border: `${invalidArr.includes("validateSerialNumber")
                      ? "1px solid red"
                      : ""
                      }`,
                  }}
                  onBlur={() => {
                    checkName("validateSerialNumber", createAsset.serialNumber);
                  }}
                  data-tip="Serial Number can only contain letters (A-Z, a-z), numbers (0-9) and following special characters: # and -"
                  data-for="name-tooltip"
                />
                <ReactTooltip
                  id="name-tooltip"
                  effect="solid"
                  place="bottom"
                  className="tooltip"
                />
                {invalidArr.includes("validateSerialNumber") && (
                  <label className="labelStyle">
                    {new FormValidator().validateValue(
                      createAsset.serialNumber,
                      /^[A-Za-z0-9-#]+$/,
                      20
                    )}
                  </label>
                )}
              </div>
            </div>
            <div className="editAssetParentRow">
              <div className="editAssetRow ">
                <label className="editAssetRowKey ">Purchase Date</label>
                <input
                  className="editAssetRowValue"
                  type="date"
                  name="dateAcquired"
                  value={createAsset.dateAcquired}
                  max={today}
                  onChange={handleDateChange}
                  style={{
                    border: `${invalidArr.includes("validatePurchaseDate")
                      ? "1px solid red"
                      : ""
                      }`,
                  }}
                  onBlur={() => {
                    checkName(
                      "validatePurchaseDate",
                      createAsset.dateAcquired
                    );
                  }}
                ></input>
                {invalidArr.includes("validatePurchaseDate") && (

                  <label className="labelStyle">
                    Enter a valid date.
                  </label>
                )}
              </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer className="addAndEditAssetModalFooter">
          {type === "Edit" ? (
            <div className="editAssetFooterCont">
              <div
                className="assetStatusDropDownCont"
                onClick={handleToggleDropdown}
                ref={menuref}
              >
                <p
                  className="statusTextCont"
                  style={{ backgroundColor: selectedOption.color.background }}
                >
                  <div
                    className="statusDot"
                    style={{
                      backgroundColor: selectedOption.color.button,
                    }}
                  ></div>{" "}
                  {selectedOption.label}
                </p>
                <img
                  src={StatusArrow}
                  alt="icon"
                  className={isOpen ? "rotateIcon" : ""}
                />
                {isOpen && (
                  <div className="statusTextsCont">
                    {statusData.map((ele) => (
                      <>
                        {selectedOption.label != ele.label ? (
                          <p
                            className="statusTextCont"
                            onClick={() => handleSelectOption(ele)}
                            key={ele.value}
                            style={{ backgroundColor: ele.color.background }}
                          >
                            <div
                              className="statusDot"
                              style={{
                                backgroundColor: ele.color.button,
                              }}
                            ></div>
                            {ele.label}
                          </p>
                        ) : (
                          <></>
                        )}
                      </>
                    ))}
                  </div>
                )}
              </div>
              <div className="editAssetFooterBtnsCont">
                <button className="editAssetCancelBtn" onClick={closePopUp}>
                  Cancel
                </button>
                <button
                  className="editAssetUpdateBtn"
                  style={{ opacity: enableCreateEditButton && toastEnabled ? 1 : 0.3 }}
                  onClick={()=>{ numberOfClicks.current = numberOfClicks.current + 1; updatePutData(); }}
                  disabled={!toastEnabled || !enableCreateEditButton}
                >
                  Update
                </button>
              </div>
            </div>
          ) : (
            <div className="createAssetFooterCont">
              <button className="createAssetCancelBtn" onClick={closePopUp}>
                Cancel
              </button>
              <button
                className="createAssetBtn"
                style={{ opacity: enableCreateEditButton && toastEnabled ? 1 : 0.3 }}
                onClick={()=>{ numberOfClicks.current = numberOfClicks.current + 1; createPostData();}}
                disabled={!toastEnabled || !enableCreateEditButton}
              >
                Create Asset
              </button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
      {/* <ToastContainer position="top-left" theme="light" /> */}
    </>
  );
};

export default AssetSettingsCreatedEditModal;

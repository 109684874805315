import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App_one";
import reportWebVitals from "./reportWebVitals";
import { StateProvider } from "./redux/StateProvider";
import reducer, { initialState } from "./redux/reducer";

import "bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(
  // <React.StrictMode>   {/* </React.StrictMode>, */}

  <StateProvider initialState={initialState} reducer={reducer}>
    <App />
  </StateProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const showConsole = process.env.REACT_APP_SHOW_CONSOLE;
if (showConsole !== "1") {
  console.log = () => {};
  console.error = () => {};
  console.warn = () => {};
}

import React from "react";
import "./IncidentSummarySubCardHead.scss";

const IncidentSummarySubCardHead = () => {
  return (
    <div className="incidentSummarySubCardHeadCont">
      <h4>{}</h4>
      <h4 className="incidentTimeTxt">INCIDENT TIME</h4>
      <h4 className="incidentTimeType">TYPE</h4>
      <h4 className="incidentOccurenceTxt">OCCURENCE COUNT</h4>
      <h4 className="incidentLocationTxt">LOCATION</h4>
    </div>
  );
};

export default IncidentSummarySubCardHead;

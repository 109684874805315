import * as R from "ramda";
import { useState, useEffect } from "react";



const useGetinfoTip = (key) => {
  const [datainfoTip, setDataInfoTip] = useState(null);
  useEffect(() => {
    const getData = async () => {
        const localStorageLoginData = await localStorage.getItem("aoneLoginData");
        if( !R.isEmpty(localStorageLoginData) && !R.isNil(localStorageLoginData) ){
            const parsedData = JSON.parse(localStorageLoginData);
            const viewData = parsedData?.loginDetails;
            const assetPortlets = viewData[0]?.portalDetails.filter(el => el.portalPageId === key);
          const portlets = assetPortlets[0]
        //    const portletIds = assetPortlets[0].portlets.map(j => j.portalPortletId);
           setDataInfoTip(portlets)
        }
    }
    getData();
  }, [key]);

  return [datainfoTip];
};

export default  useGetinfoTip;
import { roundToTwoDecimalNumber } from "../../../Utils/utils";
import { EventDuration, HeatMapType } from "../type.tsx";

const convertData = (value: number) => {
  return value.toFixed(2).replace(".", "").padStart(4, "0");
};

export const getMapList = (data: EventDuration[]) => {
  const heatMapObj: HeatMapType = [];
  const dataPosition = {
    AVERAGE: 0,
    MINIMUM: 1,
    MAXIMUM: 2,
    MEDIAN: 3,
  };
  const size = data.length;
  const descriptionList: string[] = [];
  let dataIndex = 0;

  for (let index = size - 1; index >= 0; index--){
    const item: EventDuration = data[index];

    descriptionList.push(item.parentNoteTypeDesc);
   
     // For Average
     const average = roundToTwoDecimalNumber(item.average);
     heatMapObj.push([dataPosition.AVERAGE, dataIndex, convertData(average)]);
 
     // For Minimum
     const minimum = roundToTwoDecimalNumber(item.minimum);
     heatMapObj.push([dataPosition.MINIMUM, dataIndex, convertData(minimum)]);
 
     // For Maximum
     const maximum = roundToTwoDecimalNumber(item.maximum);
     heatMapObj.push([dataPosition.MAXIMUM, dataIndex, convertData(maximum)]);
 
     // For Median
     const median = roundToTwoDecimalNumber(item.median);
    heatMapObj.push([dataPosition.MEDIAN, dataIndex, convertData(median)]);

    dataIndex += 1;

  }

  const result = {
    description: descriptionList,
    data: heatMapObj,
  };

  return result;
};

import React, { useEffect, useState } from 'react';

/**
 * Checks for horizontal overflow in the AHU Cards layout.
 * This utility function compares the element's scrollWidth and clientWidth (in pixels)
 * to determine if there is horizontal overflow.
 * If overflow is detected, it sets showScrollIcon to true, indicating the presence of overflow.
 *
 * The function can be used with any element, and it can be later extended to check
 * for both vertical and horizontal overflow.
 *
 * @param {React.RefObject<HTMLDivElement>} externalRef - Ref to an external element
 *                                                        for which the check is to be done.
 * @param {any} data - Data that, when changed, triggers the overflow check.
 *
 * @returns {Object} - An object with a boolean property 'showScrollIcon' indicating
 *                    whether horizontal overflow is present.
 */
const useOverflowCheck = (externalRef: React.RefObject<HTMLDivElement>, data: any): { showScrollIcon: boolean } => {
  const [showScrollIcon, setShowScrollIcon] = useState(false);
  const elementRef = externalRef;

  useEffect(() => {
    const checkOverflow = () => {
      const element = elementRef.current;
      if (element) {
        setShowScrollIcon(element.scrollWidth > element.clientWidth);
      }
    };

    // Trigger the overflow check when data changes
    checkOverflow();

    // Subscribe to window resize
    const handleResize = () => {
      checkOverflow();
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [elementRef, data]);

  return { showScrollIcon };
};

export default useOverflowCheck;

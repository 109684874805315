import {
  ASSET_CATEGORY_HIERARCHY,
  FACILITY_HIERARCHY,
} from "../../../Constants/constants";
import { DropdownType } from "../../../Utils/enumData";
import LocalStorageService from "../../../Utils/LocalStorageService";
import { AreaDataObj } from "../../eventList/types/EventListTypes";
import { FilterDataObj, FilterDataType } from "../types";

export default class AssetFilterData {
  getStatusFilterData = () => {
    let responseString = localStorage.getItem("facility_statistics_cache");
    const response = JSON.parse(responseString);
    const assetStatus = response?.data?.statusTypes?.FXD_ASSET_STATUS;
    const result: FilterDataObj[] =
      assetStatus?.map((element: any) => ({
        id: element.statusCode + "-" + "Status",
        name: element.description,
        group: "Status",
        isSelected: false,
        isVisible: true,
        parentId: "",
      })) || [];
    return result;
  };

  getAssetCategoryFilterData = () => {
    const assetCategoryList: {
      parentDescription: string;
      fixedAssetTypeId: string;
      description: string;
      parentFixedAssetTypeId: string;
    }[] = LocalStorageService.getUserSpecificAssetTypeData();

    const result: FilterDataObj[] = Array.from(
      new Set(assetCategoryList?.map((type) => type.parentFixedAssetTypeId))
    ).map((parentFixedAssetTypeId) => {
      const firstMatch = assetCategoryList.find(
        (type) => type.parentFixedAssetTypeId === parentFixedAssetTypeId
      );
      return {
        id: parentFixedAssetTypeId + "-" + "Asset Category Live",
        name: firstMatch?.parentDescription,
        group: "Asset Category Live",
        isSelected: false,
        isVisible: true,
        parentId: "",
      };
    });
    return result;
  };

  getAssetTypeFilterData = () => {
    const assets: {
      parentDescription: string;
      fixedAssetTypeId: string;
      description: string;
      parentFixedAssetTypeId: string;
    }[] = LocalStorageService.getUserSpecificAssetTypeData();

    const result: FilterDataObj[] =
      assets?.map((element) => ({
        id: element.fixedAssetTypeId + "-" + "Asset Type",
        name: element.description || element.fixedAssetTypeId,
        group: "Asset Type",
        isSelected: false,
        isVisible: true,
        parentId: element.parentFixedAssetTypeId + "-" + "Asset Category Live" ,
      })) || [];
    return result;
  };

  getRegionFilterData = () => {
    let response: {
      facilityGroupTypeId: string;
      parentFacilityGroupType: string;
      facilityGroupId: string;
      facilityGroupName: string;
      parentFacilityGroupId: string;
    }[] = LocalStorageService.getRegionData();

    const result: FilterDataObj[] =
      response.map((element) => ({
        id: element.facilityGroupId + "-" + "Region",
        name: element.facilityGroupName,
        group: "Region",
        isSelected: false,
        isVisible: true,
        parentId: element.parentFacilityGroupId + "-" + " ",
      })) || [];
    return result;
  };

  getCityFilterData = () => {
    let response: {
      facilityGroupTypeId: string;
      parentFacilityGroupType: string;
      facilityGroupId: string;
      facilityGroupName: string;
      parentFacilityGroupId: string;
    }[] = LocalStorageService.getCityData();

    const result: FilterDataObj[] =
      response.map((element) => ({
        id: element.facilityGroupId + "-" + "City",
        name: element.facilityGroupName,
        group: "City",
        isSelected: false,
        isVisible: true,
        parentId: element.parentFacilityGroupId + "-" + "Region",
      })) || [];
    return result;
  };

  getClusterFilterData = () => {
    let response: {
      facilityGroupTypeId: string;
      parentFacilityGroupType: string;
      facilityGroupId: string;
      facilityGroupName: string;
      parentFacilityGroupId: string;
    }[] = LocalStorageService.getClusterData();

    const result: FilterDataObj[] =
      response.map((element) => ({
        id: element.facilityGroupId + "-" + "Cluster",
        name: element.facilityGroupName,
        group: "Cluster",
        isSelected: false,
        isVisible: true,
        parentId: element.parentFacilityGroupId + "-" + "City",
      })) || [];
    return result;
  };

  getFacilityFilterData = () => {
    let response: {
      facilityId: string;
      parentFacilityGroupType: string;
      facilityName: string;
      parentFacilityGroupId: string;
    }[] = LocalStorageService.getFacilityData();

    const result: FilterDataObj[] =
      response.map((element) => ({
        id: element.facilityId + "-" + "Facility",
        name: element.facilityName,
        group: "Facility",
        isSelected: false,
        isVisible: true,
        parentId: element.parentFacilityGroupId + "-" + "Cluster",
      })) || [];
    return result;
  };

  getAreaFilterData = () => {
    let response: AreaDataObj[] = LocalStorageService.getAreaData();
    const result: FilterDataObj[] =
      response.map((element: AreaDataObj) => ({
        id: element.locationSeqId + "-" + "Area",
        name: element.locationName,
        group: "Area",
        isSelected: false,
        isVisible: true,
        parentId: element.facilityId + "-" + "Facility",
      })) || [];
    return result;
  };

  getLocations = () =>{
    let resp = LocalStorageService.getLocationData();
    const result =
      resp.map((element: any) => ({
        id: element.locationId + "-" +'Location',
        name: element.name,
        group: "Location",
        isSelected: false,
        isVisible: true,
        parentId: element.locationSeqId + "-" + "Area",
      })) || [];
    return result;

  }

  getSubLocations = () =>{
    let resp = LocalStorageService.getSubLocationData();
    const result = resp.map((element: any) => ({
      id: element.subLocationId + "-" + "Sub Locations",
      name: element.name,
      group: "Sub Locations",
      isSelected: false,
      isVisible: true,
      parentId: element.locationId + "-" +'Location'
    })) || [];
  return result;
  }

  getAssetFilterData = () => {
    const newFilterList: FilterDataType[] = [];

    // Asset Status
    const statusList = this.getStatusFilterData();
    newFilterList.push({
      key: "Status",
      data: statusList,
      placeholder: "Asset Status",
      dropdown: DropdownType.SINGLE,
      parent: "",
      searchValue: "",
      selectedCount: 0,
      hierarchyList: [],
      selectedChildren: [],
    });

    // Asset Category -> Asset Type

    // For Asset Category
    const assetCategory = this.getAssetCategoryFilterData();

    // For Asset Type
    const assetTypeList = this.getAssetTypeFilterData();
    const assetTypeData: FilterDataObj[] = [...assetCategory, ...assetTypeList];
    const distinctAssetType = [
      ...new Set(assetTypeData.map((item) => item.group)),
    ];

    newFilterList.push({
      key: "Asset Category/Type",
      data: assetTypeData,
      placeholder: "Asset Category/Type",
      dropdown:
        distinctAssetType.length === 1
          ? DropdownType.SINGLE
          : DropdownType.MULTIPLE,
      parent: "",
      searchValue: "",
      selectedCount: 0,
      hierarchyList: ASSET_CATEGORY_HIERARCHY,
      selectedChildren: [],
    });

    // Region -> City -> Cluster -> Facility

    // For Region
    const regionList = this.getRegionFilterData();

    // For City
    const cityList = this.getCityFilterData();

    // For Cluster
    const clusterList = this.getClusterFilterData();

    // For Facility
    const facilityList = this.getFacilityFilterData();

    const areas = this.getAreaFilterData();

    const getLocations = this.getLocations();

    const subLocations = this.getSubLocations();

    const facilityGroupData = [
      ...regionList,
      ...cityList,
      ...clusterList,
      ...facilityList,
      ...areas,
      ...getLocations,
      ...subLocations
    ];
    const distinctFacilityGroup = [
      ...new Set(facilityGroupData.map((item) => item.group)),
    ];

    newFilterList.push({
      key: "Facility Groups/Facilities",
      data: facilityGroupData,
      placeholder: "Facility Groups/Facilities",
      dropdown:
        distinctFacilityGroup.length === 1
          ? DropdownType.SINGLE
          : DropdownType.MULTIPLE,
      parent: "",
      searchValue: "",
      selectedCount: 0,
      hierarchyList: FACILITY_HIERARCHY,
      selectedChildren: [],
    });

    return newFilterList;
  };
}

import React, { useEffect } from "react";
import "./EventSummary.scss";
import DownIcon from "../assets/negative-download.svg";
import { useStateValue } from "../../../redux/StateProvider";
import { TrendChartDataType } from "../../../a1Pages/AssetDetailPage/types/TrendChartDataType";
import FilterComponent from "./FilterComponent";
import { useQuery, useQueryClient } from "react-query";
import { getEventSummary } from "../service/EventSummaryService";
import { EventCategoryListType } from "../types";
import DisplayError from "../../../a1Components/DisplayError/DisplayError";
import LoaderStyle from "../../../a1Components/LoaderStyle/LoaderStyle";
import EventCategoryList from "./EventCategoryList";
import {
  cancelExistingQuery,
  roundToTwoDecimalNumber,
} from "../../../Utils/utils";
import SelectedEventCategory from "./SelectedEventCategory";
import EventSummaryTrendChart from "./EventSummaryTrendChart";
import EventSummaryState from "../state/EventSummaryState";
import moment from "moment";
import UpSideIcon from "../assets/icon-variance-positive-up.svg";

interface PropTypes {
  showFilter: boolean;
  hideFilter: () => void;
}

export function EventSummary(props: PropTypes) {
  const queryClient = useQueryClient();
  const { showFilter, hideFilter } = props;
  const [{ dateRange, dateChip }, dispatch] = useStateValue();

  const {
    searchingIndex,
    setSearchingIndex,
    filterDataSet,
    setFilterDataSet,
    selectedFilterKey,
    setSelectedFilterKey,
    showEventCountFreq,
    setShowEventCountFreq,
    trendRange,
    setTrendRange,
    selectedTrendRange,
    setSelectedTrendRange,
    hoveredIndex,
    setHoveredIndex,
    selectedIndex,
    setSelectedIndex,
    queryParams,
    setQueryParams,
    trendChartData,
    setTrendChartData,
    trendChartHeading,
    setTrendChartHeading,
    showRefreshButton,
    setShowRefreshButton,
    selectedEventData,
    setSelectedEventData,
    selectedEventDetailsData,
    setSelectedEventDetailsData,
    selectedSubEventId,
    setSelectedSubEventId,
    apiLoadingState,
    setApiLoadingState,
    responseData,
    setResponseData,
    selectedFilterValue,
    setSelectedFilterValue,
    eventFreqPerText,
    setEventFreqPerText,
    lineChart,
    setLineChart,imagePosition, setImagePosition
  } = EventSummaryState();

  const { data, isLoading, isFetching, error, refetch, dataUpdatedAt } =
    useQuery(
      "getEventTrendSummaryApi",
      () =>
        getEventSummary(
          queryParams.filterByType,
          queryParams.filterById,
          queryParams.filterByDate,
          queryParams.areaId,
          queryParams.startDate,
          queryParams.endDate,
          queryParams.extraDetails,
          queryParams.trendKey,
          dateChip === "Today"
            ? "day"
            : dateChip === "This Week"
            ? "week"
            : dateChip === "This Month"
            ? "month"
            : "_NA_"
        ),
      {
        enabled: false,
        keepPreviousData: false,
      }
    );

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleEventSelection = (index: number, item: EventCategoryListType) => {
    setSelectedEventDetailsData([]);
    setSelectedIndex((prev) => {
      if (prev === index) {
        return null;
      } else {
        return index;
      }
    });
    setSelectedEventData((prev) => {
      if (prev?.eventTypePrntType === item.eventTypePrntType) {
        clearSelectedEventCategory();
        return null;
      } else {
        return item;
      }
    });
    const eventTypeList = responseData.all.eventTypeList;
    const filterEvent = eventTypeList.filter(
      (obj) => obj.eventTypePrntType === item.eventTypePrntType
    );
    const total = item.eventCountPerCategory;

    const result = filterEvent.map((ele) => ({
      name: ele.eventTypeDesc,
      percent: roundToTwoDecimalNumber((ele.typeWiseEventCount / total) * 100),
      value: ele.typeWiseEventCount,
      eventTypePrntType: ele.eventTypePrntType,
      parentNoteTypeId: ele.parentNoteTypeId,
    }));

    setSelectedEventDetailsData(result);
  };

  const clearSelectedEventCategory = () => {
    setSelectedEventDetailsData([]);
    setSelectedEventData(null);
    setHoveredIndex(null);
    setSelectedIndex(null);
    const temp = { ...queryParams, trendKey: "_NA_", extraDetails: false };
    setQueryParams(temp);
    setTrendChartHeading("Total");
    setShowRefreshButton(false);
  };

  const handleRefreshButton = () => {
    if (selectedEventData !== null) {
      setSelectedSubEventId(null);
      setTrendChartHeading(selectedEventData.eventTypePrntType);
      const temp = {
        ...queryParams,
        trendKey: selectedEventData.eventTypePrntType,
        extraDetails: false,
      };
      setQueryParams(temp);
      setShowRefreshButton(false);
    }
  };

  const handleSelectedSubEvent = (id: string, name: string) => {
    setSelectedSubEventId(id);
    const temp = { ...queryParams, trendKey: id, extraDetails: false };
    setQueryParams(temp);
    setTrendChartHeading(name);
    setShowRefreshButton(true);
  };

  useEffect(() => {
    if (selectedEventData !== null) {
      setTrendChartHeading(selectedEventData.eventTypePrntType);
      const temp = {
        ...queryParams,
        trendKey: selectedEventData.eventTypePrntType,
        extraDetails: false,
      };
      setQueryParams(temp);
      setShowRefreshButton(false);
    }
  }, [selectedEventData]);

  /**
   *  If date range will be changed then the legend will be changed
   */
  useEffect(() => {
    if (dateChip === "Today") {
      setTrendRange([
        "Last 7 days",
        "Last 14 days",
        "Last 30 days",
        "Last 60 days",
        "Last 90 days",
      ]);
      setSelectedTrendRange("Last 7 days");
    } else if (dateChip === "This Week") {
      setTrendRange(["Last 4 weeks", "Last 8 weeks", "Last 12 weeks"]);
      setSelectedTrendRange("Last 4 weeks");
    } else if (dateChip === "This Month") {
      setTrendRange([
        "Last 3 months",
        "Last 6 months",
        "Last 9 months",
        "Last 12 months",
      ]);
      setSelectedTrendRange("Last 3 months");
    } else if (dateChip === "Custom") {
      setTrendRange([""]);
      setSelectedTrendRange("");
    }
    setHoveredIndex(null);
    setSelectedIndex(null);
    setSelectedEventData(null);
    setSelectedSubEventId(null);
  }, [dateChip]);

  useEffect(() => {
    const startDate = moment(dateRange[0]).valueOf();
    const endDate = moment(dateRange[1]).valueOf();
    let rangeStartDate: number = startDate;
    let filterByDate = "";
    if (dateChip === "Custom") {
      filterByDate = `day,${startDate},${endDate}`;
    } else {
      const trendRangeData = selectedTrendRange.split(" ");
      let range = trendRangeData[1];
      const frequency =
        trendRangeData[2] === "days"
          ? "day"
          : trendRangeData[2] === "months"
          ? "month"
          : trendRangeData[2] === "weeks"
          ? "week"
          : "";
          range = JSON.stringify(Number(range) - 1)
      if (dateChip === "Today") {
        rangeStartDate = moment(endDate).subtract("days", range).startOf("day").valueOf();
      } else if (dateChip === "This Week") {
        rangeStartDate = moment(endDate).subtract("weeks", range).startOf("week").valueOf();
      } else if (dateChip === "This Month") {
        rangeStartDate = moment(endDate).subtract("months", range).startOf("month").valueOf();
      }
      filterByDate = `${frequency},${rangeStartDate},${endDate}`;
    }
    const extraDetails = queryParams.startDate !== startDate;
    const temp = {
      ...queryParams,
      filterByDate: filterByDate,
      startDate: startDate,
      endDate: endDate,
      extraDetails: extraDetails,
      trendKey: extraDetails ? "_NA_" : queryParams.trendKey,
    };
    if (extraDetails) {
      setTrendChartHeading("Total");
    }
    setQueryParams(temp);
  }, [selectedTrendRange]);

  useEffect(() => {
    cancelExistingQuery("getEventTrendSummaryApi", queryClient);
    return () => {
      cancelExistingQuery("getEventTrendSummaryApi", queryClient);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (queryParams.extraDetails) {
          setApiLoadingState({
            isSummaryLoading: true,
            isTrendLoading: false,
          });
        } else {
          setApiLoadingState({
            isSummaryLoading: false,
            isTrendLoading: true,
          });
        }
        cancelExistingQuery("getEventTrendSummaryApi", queryClient);
        await refetch();
      } catch (error) {
        setApiLoadingState({
          isSummaryLoading: false,
          isTrendLoading: false,
        });
        console.error("Error in refetching api:", error);
      }
    };
    fetchData();
  }, [queryParams]);

  useEffect(() => {
    if (data !== undefined && data.hasOwnProperty("eventSummary")) {
      setApiLoadingState({
        isSummaryLoading: false,
        isTrendLoading: false,
      });
      if (queryParams.extraDetails) {
        setResponseData(data?.eventSummary);
      } else {
        const response = data?.eventSummary?.data;
        const temp = {
          ...responseData,
          data: response,
        };
        setResponseData(temp);
      }
    }
  }, [data, dataUpdatedAt]);

  useEffect(() => {
    if (error !== null) {
      setApiLoadingState({
        isSummaryLoading: false,
        isTrendLoading: false,
      });
    }
  }, [error]);

  useEffect(() => {
    const getTrendData = () => {
      const data = responseData.data;
      const dateValues = data.map((item) => item.dateValue);
      const totalEvents = data.map((item) => item.totalEvent);
      const yAsix = [
        {
          name: "Event Count",
          key: "Event Count",
          data: totalEvents,
        },
      ];
      const result: TrendChartDataType = {
        xAsix: dateValues,
        yAsix: yAsix,
      };
      setTrendChartData(result);
      if (
        responseData.all.previousTotalEvent !== null &&
        typeof responseData.all.previousTotalEvent === "number"
      ) {
        const previousTotalEvent = responseData.all.previousTotalEvent;
        const totalEvent = responseData.all.totalEvent;
        const freq =
          dateChip === "Today"
            ? "yesterday"
            : dateChip === "This Week"
            ? "last week"
            : dateChip === "This Month"
            ? "last month"
            : "_NA_";
        let changePercentage = 0;
        let changeFreqText = "";

        if (totalEvent > previousTotalEvent) {
          changePercentage =
            ((totalEvent - previousTotalEvent) / previousTotalEvent) * 100;
          changePercentage = roundToTwoDecimalNumber(changePercentage);
          changeFreqText = `${changePercentage}% more than ${freq}`;
          setImagePosition("more");
        } else {
          changePercentage =
            ((previousTotalEvent - totalEvent) / previousTotalEvent) * 100;
          changePercentage = roundToTwoDecimalNumber(changePercentage);
          changeFreqText = `${changePercentage}% less than ${freq}`;
          setImagePosition("less");
        }
        setShowEventCountFreq(true);
        setEventFreqPerText(changeFreqText);
      } else {
        setShowEventCountFreq(false);
        setImagePosition("");
      }
    };
    getTrendData();
  }, [responseData]);

  useEffect(() => {
    if (showFilter && selectedFilterKey.length !== 0) {
      let temp: any = {
        ...queryParams,
        extraDetails: true,
      };

      if (selectedFilterKey.includes("AREA")) {
        const facilityId = selectedFilterValue[selectedFilterValue.length - 2];
        const areaId = selectedFilterValue[selectedFilterValue.length - 1];
        temp = {
          ...temp,
          filterByType: "facility",
          filterById: facilityId.name,
          areaId: areaId.name,
        };
      } else {
        const type = selectedFilterKey[selectedFilterKey.length - 1];
        const value = selectedFilterValue[selectedFilterValue.length - 1];
        temp = {
          ...temp,
          filterByType: type.toLowerCase(),
          filterById: value.name,
          areaId: "_NA_",
        };
      }

      setQueryParams(temp);
    } else {
      const temp = {
        ...queryParams,
        filterByType: "_NA_",
        filterById: "_NA_",
        areaId: "_NA_",
        extraDetails: true,
        trendKey: '_NA_',
      };
      setSelectedEventDetailsData([]);
      setSelectedEventData(null);
      setHoveredIndex(null);
      setSelectedIndex(null);
      setTrendChartHeading("Total");
      setQueryParams(temp);
    }
  }, [selectedFilterKey, selectedFilterValue, filterDataSet, showFilter]);

  return (
    <>
      {showFilter && (
        <FilterComponent
          searchingIndex={searchingIndex}
          setSearchingIndex={setSearchingIndex}
          filterDataSet={filterDataSet}
          setFilterDataSet={setFilterDataSet}
          selectedFilterKey={selectedFilterKey}
          setSelectedFilterKey={setSelectedFilterKey}
          selectedFilterValue={selectedFilterValue}
          setSelectedFilterValue={setSelectedFilterValue}
          showFilter={showFilter}
          hideFilter={hideFilter}
        />
      )}

      {apiLoadingState.isSummaryLoading ? (
        <div className="loadingBox1">
          <LoaderStyle />
        </div>
      ) : error ? (
        <div className="loadingBox1">
          <DisplayError type="err_1" />
        </div>
      ) : data !== undefined && typeof data === "number" ? (
        <div className="loadingBox1">
          <DisplayError type="err-500/404" />
        </div>
      ) : (
        <div className="eventSummaryMainCont">
          <div className="totalEventMainCont">
            <p className="totalEventText">Total Events</p>
            <div className="totalEventDetailsDiv">
              <p className="totalEventCount">{responseData.all.totalEvent}</p>
              {showEventCountFreq && (
                <div className="statsDataCont">
                  <img src={imagePosition === "more" ? UpSideIcon : DownIcon} className="durationIcon" />
                  <p className="totalEventTimeStamp">{eventFreqPerText}</p>
                </div>
              )}
            </div>
          </div>

          <EventCategoryList
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            handleEventSelection={handleEventSelection}
            selectedIndex={selectedIndex}
            hoveredIndex={hoveredIndex}
            data={responseData.all.eventCategoryList}
          />

          {selectedEventDetailsData.length !== 0 && (
            <SelectedEventCategory
              data={selectedEventDetailsData}
              clearSelectedCategory={clearSelectedEventCategory}
              handleSelectedSubEvent={handleSelectedSubEvent}
            />
          )}

          {apiLoadingState.isTrendLoading ? (
            <div className="loadingBox1">
              <LoaderStyle />
            </div>
          ) : (
            <EventSummaryTrendChart
              data={trendChartData}
              trendRange={trendRange}
              selectedTrendRange={selectedTrendRange}
              setSelectedTrendRange={setSelectedTrendRange}
              trendChartHeading={trendChartHeading}
              showRefreshButton={showRefreshButton}
              handleRefreshButton={handleRefreshButton}
              lineChart={lineChart}
              setLineChart={setLineChart}
            />
          )}
        </div>
      )}
    </>
  );
}

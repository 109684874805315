/* eslint-disable eqeqeq */
import React, { useState } from "react";

import IconAdd from "../../../../assets/icons/IconAdd.svg";
import AddUserIcon from "../../../../assets/icons/AddUserIcon.svg";
import EditFacilityCardIcon from "../../../../assets/icons/EditFacilityCardIcon.svg";
import DeleteFacilityCardIcon from "../../../../assets/icons/DeleteFacilityCardIcon.svg";
import MoveFacilityCardIcon from "../../../../assets/icons/MoveFacilityCardIcon.svg";
import DragIcon from "../../../../assets/icons/DragIcon.svg";
import IconRightArrow from "../../../../assets/icons/IconRightArrow.svg";
import FacilityProfileFrameIcon from "../../../../assets/icons/FacilityProfileFrameIcon.svg";
import IconClose from "../../../../assets/icons/IconClose.svg";
import MoveCardIcon from "../../../../assets/icons/MoveCardIcon.svg";
import FacilityGroupDisable from "../../../../assets/icons/FacilityGroupDisable.svg";
import IconDownArrow from "../../../../assets/icons/Icon feather-chevron-down (1).svg";
import FacilityIcon from "../../../../assets/icons/Frame 8016.svg";

import "./FacilityDropCards.scss";
import { width } from "@amcharts/amcharts4/.internal/core/utils/Utils";

function FacilityDropCards(props: any) {
  const {
    showAndHideFunction,
    type,
    data,
    setIsDraggingEnabled,
    setAddUserPopup,
    setAddSubGroups,
    deleteFacilityGroup,
    setEditGroupPop,
    setDisablePopup,
    activeList
    // moveFacilityCard,
  } = props;

  const [moveCard, setMoveCard] = useState(false);

  // Tool Tip States
  const [showEditTip, setShowEditTip] = useState(false);

  const [showDisableTip, setShowDisableTip] = useState(false);

  const [showDeleteTip, setShowDeleteTip] = useState(false);

  const [showMoveTip, setShowMoveTip] = useState(false);

  return (
    <div className="facilityDropsCardsCont">
      <div
        className="facilityCompanyCont"
        style={{
          width: `${
            type == "city"
              ? "24.6%"
              : type == "cluster"
              ? "19.6%"
              : type == "company"
              ? "28.5%"
              : ""
          }`,
        }}
      >
       {
        
        type == 'facility' ? <img
        src={FacilityIcon}
        alt="icon"
        style={{ cursor: "pointer" , height: '1.3em' }}
        onClick={() => showAndHideFunction(type, data.id)}
      /> :
       activeList.includes(data.id) ?  <img
          src={IconDownArrow}
          alt="icon"
          style={{ cursor: "pointer" , width: '4.1%' }}
          onClick={() => showAndHideFunction(type, data.id)}
        /> :
        <img
          src={IconRightArrow}
          alt="icon"
          style={{ cursor: "pointer" }}
          onClick={() => showAndHideFunction(type, data.id)}
        />
        }
        <p>{data.name == null ? data.type : data.name}</p>
      </div>
      <div
        className="regionAndCacilitiesCont"
        style={{
          width: `${
            type == "city"
              ? "20.7%"
              : type == "cluster"
              ? "22.2%"
              : type == "facility"
              ? "18.6%"
              : type == "company"
              ? "20%"
              : ""
          }`,
        }}
      >
        {type !== "facility" && type !== "cluster" && (
          <p>
            {type == "company"
              ? `${data.regions} Regions`
              : type == "region"
              ? `${data.cities} Cities`
              : `${data?.clustersList?.length} Clusters`}
          </p>
        )}
        {type !== "facility" && (
          <p>
            {" "}
            {(type == "region" || type == "city") && <span>{"."} </span>}{" "}
            {data.facilities} Facilities
          </p>
        )}
      </div>
      <div
        className="subGroupsAndUsersBtnsCont"
        style={{
          width: `${
            type == "city"
              ? "29.9%"
              : type == "cluster"
              ? "31.8%"
              : type == "facility"
              ? "23.3%"
              : type == "company"
              ? "28.2%"
              : ""
          }`,
          marginLeft: `${type == "facility" ? "2%" : ""}`,
        }}
      >
        {type !== "facility" && type !== "company" && (
          <button
            className="subGroupsBtn"
            onClick={() => setAddSubGroups(data.id)}
          >
            <img src={IconAdd} alt="icon" />
            Add subgroup
          </button>
        )}

        {type !== "company" && (
          <button
            className="addUsersBtn"
            onClick={() => setAddUserPopup(data.id)}
          >
            <img src={AddUserIcon} alt="icon" />
            Add users
          </button>
        )}
      </div>
      <div
        className="facilityUsersProfiles"
        style={{
          width: `${
            type == "city"
              ? "9.3%"
              : type == "cluster"
              ? "9.8%"
              : type == "facility"
              ? "10.8%"
              : ""
          }`,
        }}
      >
        <img src={FacilityProfileFrameIcon} alt="icon" />
      </div>
      <div
        className="cardFunctionsComponent"
        style={{
          width: `${
            type == "city"
              ? "13.7%"
              : type == "cluster"
              ? "14.7%"
              : type == "facility"
              ? "16.2%"
              : ""
          }`,
        }}
      >
        {type !== "company" && (
          <img
            src={EditFacilityCardIcon}
            alt="icon"
            onClick={() => setEditGroupPop(data.id)}
            className="facilityGroupIcons"
            onMouseOver={() => setShowEditTip(true)}
            onMouseLeave={() => setShowEditTip(false)}
            style={{ cursor: "pointer" }}
          />
        )}

        {showEditTip && <p className="editToolTip">Edit Group</p>}

        {type !== "company" && (
          <img
            src={FacilityGroupDisable}
            alt="icon"
            className="facilityGroupIcons"
            onClick={setDisablePopup}
            onMouseOver={() => setShowDisableTip(true)}
            onMouseLeave={() => setShowDisableTip(false)}
            style={{ cursor: "pointer" }}
          />
        )}
        {showDisableTip && <p className="disableToolTip">Disable Group</p>}

        {type !== "company" && (
          <img
            src={DeleteFacilityCardIcon}
            alt="icon"
            onClick={() => deleteFacilityGroup(type)}
            className="facilityGroupIcons"
            onMouseOver={() => setShowDeleteTip(true)}
            onMouseLeave={() => setShowDeleteTip(false)}
            style={{ cursor: "pointer" }}
          />
        )}
        {showDeleteTip && <p className="deleteToolTip">Delete Group</p>}

        {type !== "company" && (
          <img
            src={MoveFacilityCardIcon}
            alt="icon"
            onClick={() => setMoveCard(true)}
            className="facilityGroupIcons"
            onMouseOver={() => setShowMoveTip(true)}
            onMouseLeave={() => setShowMoveTip(false)}
            style={{ cursor: "pointer" }}
          />
        )}
        {showMoveTip && <p className="moveToolTip">Move Group</p>}
        {moveCard && (
          <div className="moveCardCont">
            <div className="moveCardHeaderCont">
              <p className="moveCardHeader">Move group to</p>
              <div className="closeIconDiv">
                <img
                  className="moveCardCloseIcon"
                  src={IconClose}
                  alt="icon"
                  onClick={() => setMoveCard(false)}
                />
              </div>
            </div>
            <select className="moveCardSelectCont">
              <option>Search</option>
            </select>
            <div className="moveCardBtnCont">
              <button
                className="moveCardBtn"
                onClick={() => setMoveCard(false)}
              >
                <img src={MoveCardIcon} alt="iocn" className="moveCardImg" />
                Move
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="dropCardImgCont">
        {type !== "company" && (
          <img
            src={DragIcon}
            alt="icon"
            onMouseEnter={() => setIsDraggingEnabled(type)}
            onMouseLeave={() => setIsDraggingEnabled(null)}
          />
        )}
      </div>
    </div>
  );
}

export default FacilityDropCards;

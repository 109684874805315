import React from "react";
import "./TemperatureComplianceBar.scss";
import StackedHorizontalBar from "../E-Charts/stackedHorizontalBar/StackedHorizontalBar";

function TemperatureComplianceBar({ data }: any) {
  return (
    <div className="barContainer">
      <div className="subDivOne">
        <h5>{data.name}</h5>
        {/* <p>{data.id}</p> */}
      </div>
      <div className="subDivTwo">
        <StackedHorizontalBar percentage={data.compliancePercentage} name={ data.name} />
      </div>
    </div>
  );
}

export default TemperatureComplianceBar;

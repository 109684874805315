import {API_BASE_URL} from "../../../Constants/constants";

export const fetchHVACSummary = async (body: object) => {
    try {
        const url = `${API_BASE_URL}assets/hvacAssetList`;
        const response = await fetch(url, {
            method: "POST",
            body: JSON.stringify(body),
        });

        if (response.status !== 200) {
            return response.status;
        }

        return await response.json();
    } catch (error) {
        return error;
    }
};
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faSortDown } from "@fortawesome/free-solid-svg-icons";
import "../../App_one.scss";

function getWindowWidth() {
  const { innerWidth: width } = window;
  return {
    width,
  };
}

type TabProps = {
  active: boolean;
  data: object;
  loopData: object;
  handleTabClick: any;
};

function TabTitleBox({ active, data, loopData, handleTabClick }: any) {
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());
  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowWidth());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  function tabCtaHandle(id:any) {
    handleTabClick(id);
  }
  return (
    <div
      className={`tabTitleBox ${active ? "active" : ""}`}
      onClick={() => {
        windowWidth.width > 640
          ? tabCtaHandle(loopData.id)
          : console.log(windowWidth, "mobile view");
      }}
    >
      {/* Form mobile view tab cta */}
      <div className="xs-tab">
        <div className="xs-tab-cta">
          <FontAwesomeIcon icon={faSortDown} style={{ color: "#000" }} />
        </div>
        <ul className="xs-tab-list">
          {data.map((el:any, ind:any) => (
            <li key={ind} onClick={() => tabCtaHandle(el.id)}>
              {el.id}
            </li>
          ))}
        </ul>
      </div>
      {/* Form mobile view tab cta end */}
      <h5>{loopData.id}</h5>
      <h2>
        {loopData?.consumption?.value + loopData?.consumption?.abbreviation}
        <p>
          <FontAwesomeIcon icon={faArrowDown} style={{ color: "#fff" }} />
          {loopData.consumptionVariance.variance +
            loopData.consumptionVariance.abbreviation}
        </p>
      </h2>
      <div className="rangePart">
        <h4>{loopData?.utilisation?.utilization + "% Utilization"}</h4>
        <div>
          <input
            type="range"
            value={loopData?.utilisation?.utilization}
            className={
              loopData.utilisation.utilisationContext === "NEGATIVE"
                ? "high"
                : ""
            }
            disabled
          />
          <div className="thresoldChange">
            <p style={{ left: loopData?.utilisation?.utilMin - 6 + "%" }}>
              {loopData?.utilisation?.utilMin + "%"}
            </p>
            <p style={{ left: loopData?.utilisation?.utilMax - 6 + "%" }}>
              {loopData?.utilisation?.utilMax + "%"}
            </p>
          </div>
        </div>
      </div>
      <div style={{ paddingBottom: "10px" }}>
        {loopData?.list.map((el:any, i:any) => (
          <h6 key={i}>
            {Object.keys(el)}
            <span>{Object.values(el)}</span>
          </h6>
        ))}
      </div>
      <div
        className="activeBar"
        style={{ background: active ? "#426BFA" : "transparent" }}
      />
    </div>
  );
}

export default TabTitleBox;

import React, { useState } from "react";
import "./SelectedEventDetails.scss";

type PropTypes = {
  id: string;
  name: string;
  percent: number;
  value: number;
  handleSelectedSubEvent: (id: string, name: string) => void;
};

function SelectedEventDetails({
  id,
  name,
  percent,
  value,
  handleSelectedSubEvent,
}: PropTypes) {
  const [showHover, setShowHover] = useState(false);
  return (
    <div className="selectedEventChartCard">
      <p className="eventHeadingText">{name}</p>
      <div
        className="selectedEventDataDiv"
        onMouseEnter={() => {
          setShowHover(true);
        }}
        onMouseLeave={() => {
          setShowHover(false);
        }}
      >
        <div className="selectedEventColor" style={{ width: `${percent}%` }}>
          <p className="selectedEventPer">{percent}%</p>
        </div>
        {showHover && (
          <div className="hoverMainDiv">
            <div className="hoverTitle">
              <p className="hoverName">{name}</p>
              <p className="hoverValue">{value}</p>
            </div>
            <p className="hoverPercentage">{percent}%</p>
            <div
              className="hoverViewTrend"
              onClick={() => handleSelectedSubEvent(id, name)}
            >
              View trend
            </div>
          </div>
        )}
        <p className="selectedEventValue">{value}</p>
      </div>
    </div>
  );
}

export default SelectedEventDetails;

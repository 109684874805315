import React, { useState, useEffect } from "react";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkAreaComponent,
  VisualMapComponent,
} from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import LoaderStyle from "../LoaderStyle/LoaderStyle";
import * as R from "ramda";
import moment from "moment";
import { debounce } from 'lodash';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function StackedLineChart({
  data,
  yAxisData,
  xAxisData,
  height,
  left,
  right,
  bottom,
  top,
  zoomLevel,
  show,
  minMaxData,
  legendColor,
  zoomSliderData,
  setZoomSliderData,
}: any) {
  let minData: any;
  let maxData: any;
  if (show) {
    !R.isEmpty(minMaxData) &&
      !R.isNil(minMaxData) &&
      minMaxData?.attributes.map(
        (el: any, index: any) =>
          el.attrName.includes("TEMP_MIN") &&
          (minData = el.hasOwnProperty("attrValue") ? el.attrValue : "")
      );
    !R.isEmpty(minMaxData) &&
      !R.isNil(minMaxData) &&
      minMaxData?.attributes.map(
        (el: any, index: any) =>
          el.attrName.includes("TEMP_MAX") &&
          (maxData = el.hasOwnProperty("attrValue") ? el.attrValue : "")
      );
  }

  function generatePieces(data1, minData, maxData) {
    const data = data1[0];
    const result = [];
    let previous = -1;
    for (let i = 0; i < data?.length; i++) {
      const value = parseFloat(data[i]);
      const withinRange = minData <= value && value <= maxData;
      const color = withinRange ? "green" : "red";

      const entry = { color };
      if (i === 0) {
        entry.lte = i;
      } else if (i === data.length - 1) {
        entry.gt = i;
      } else {
        entry.gt = previous;
        entry.lte = i;
      }
      result.push(entry);
      previous = i;
    }

    return result;
  }

  let valueSet: any = [];
  const pieces = generatePieces(data, minData, maxData);
  if (show) {
    valueSet = pieces;
  }

  const markArea = {
    itemStyle: {
      color: "rgba(102, 191, 65, 0.15)",
    },
    data: [
      [
        {
          yAxis: minData,
        },
        {
          yAxis: maxData,
        },
      ],
    ],
  };
  const itemStyle = {
    color: function (params: any) {
      // Check if the data value is within the mark area
      if (params.value >= minData && params.value <= maxData) {
        return "yellow"; // Inside mark area
      } else {
        return "green"; // Outside mark area
      }
    },
  };

  let seriesData: any = [];
  if (show) {
    yAxisData.forEach((el: any, i: any) => {
      console.log("*****", el)
      seriesData.push({
        ...el,
        name: el,
        data: data[i],
        markArea: el[i] === "Temperature (C)" ? markArea : {},
        itemStyle: el[i] === "Temperature (C)" ? itemStyle : {},
        type: "line",
        connectNulls: true,
      });
    });
  } else {
    // { value: "Supply Temp 1", color: "#E64A19" },
    yAxisData.forEach((el: any, i: any) => {
      console.log("*****", el)
      seriesData.push({
        ...el,
        name: el,
        data: data[i],
        type: "line",
        color: legendColor.find((ele: any) => ele.value === el) === undefined ? '' : legendColor.find((ele: any) => ele.value === el).color,
        connectNulls: true,
      });
    });
  }

  console.log("the series data", seriesData, minMaxData);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  echarts.use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition,
    VisualMapComponent,
    MarkAreaComponent,
  ]);
  var option;
  //   const minDataValue = Math.min(...xAxisData); // 'data' represents your array of data values
  // const maxDataValue = Math.max(...xAxisData);

  // // Calculate padding for the y-axis
  // const padding = (maxDataValue - minDataValue) * 0.1;
  // const minDataValue = Math.min(...seriesData);
  const dataValues = seriesData.flatMap((series: { data: any }) => series.data);
  const minDataValue = Math.min(...dataValues);

  const formattedList: any = [];
  let currentDate: any = null;
  console.log("xAxist", xAxisData);
  xAxisData.forEach((dateTimeStr: any) => {
    const date = moment(dateTimeStr, "MMMM DD, YYYY h:mm A");
    if (!currentDate || !date.isSame(currentDate, "day")) {
      console.log("::::", date);
      formattedList.push(date.format("MMM DD, HH:mm"));
      currentDate = date;
    } else {
      formattedList.push(date.format("HH:mm"));
    }
  });
  // console.log(, "DUMBU 77777")
  let showX_axisData = formattedList;



  if (show) {
    option = {
      tooltip: {
        trigger: "axis",
      },
      height: height,
      // legend: {
      //   data: yAxisData,
      //   top: 0,
      //   itemWidth: 10,
      //   itemHeight: 10,
      //   itemGap: 10,
      // },
      grid: {
        bottom: 30, // Adjust the bottom value to provide space for data zoom
        top: 40,

        left: windowDimensions.width > 640 ? left || "10%" : "5%",
        right: windowDimensions.width > 640 ? right || "20%" : "5%",
        // bottom: windowDimensions.width > 640 ? bottom || "10%" : "18%",
        // top: top || "3%",
        containLabel: true,
      },

      toolbox: {
        feature: {},
        dataZoom: {
          yAxisIndex: "none",
        },
      },
      title: {
        show: false,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: showX_axisData,

        axisLabel: {
          rotate: 65,
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        // min: minDataValue - padding,
        // max: maxDataValue + padding,
        axisLabel: {
          show: true, // Display the y-axis labels
        },

        type: "value",
        min: "dataMin",

        // splitLine: {
        //   show: false,
        // },
        splitNumber: 11,
      },
      visualMap: {
        show: false,
        dimension: 0,
        pieces: valueSet,
      },
      // dataZoom: [
      //   {
      //     type: 'slider',
      //     start: zoomLevel,
      //     end: 100,
      //     show: false,
      //   }
      // ],
      dataZoom: [
        {
          bottom: 0,
          type: "inside",
          start: zoomSliderData.start,
          end: zoomSliderData.end,
        },
        {
          bottom: 5,
          type: "slider",
          height: 15,
          start: zoomSliderData.start,
          end: zoomSliderData.end,
        },
      ],
      series: seriesData,
    };
  } else {
    option = {
      tooltip: {
        trigger: "axis",
      },
      height: height,
      // legend: {
      //   data: yAxisData,
      //   top: 0,
      //   itemWidth: 10,
      //   itemHeight: 10,
      //   itemGap: 10,
      // },
      grid: {
        top: 0, // Adjust the top value to provide space for x-axis name
        bottom: 30, // Adjust the bottom value to provide space for data zoom
        top: 40,

        left: windowDimensions.width > 640 ? left || "10%" : "5%",
        right: windowDimensions.width > 640 ? right || "20%" : "5%",
        // bottom: windowDimensions.width > 640 ? bottom || "10%" : "18%",
        // top: top || "3%",
        containLabel: true,
      },

      toolbox: {
        feature: {},
        dataZoom: {
          yAxisIndex: "none",
        },
      },
      title: {
        show: false,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: showX_axisData,
        axisLabel: {
          rotate: 65,
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        // min: minDataValue - padding,
        // max: maxDataValue + padding,
        axisLabel: {
          show: true, // Display the y-axis labels
        },

        type: "value",
        min: "dataMin",
        // splitLine: {
        //   show: false,
        // },
        splitNumber: 11,
      },
      // dataZoom: [
      //   {
      //     type: 'slider',
      //     start: zoomLevel,
      //     end: 100,
      //     show: false,
      //   }
      // ],
      dataZoom: [
        {
          bottom: 0,
          type: "inside",
          start: zoomSliderData.start,
          end: zoomSliderData.end,
        },
        {
          bottom: 5,
          type: "slider",
          height: 15,
          start: zoomSliderData.start,
          end: zoomSliderData.end,
        },
      ],
      series: seriesData,
    };
  }

  const onChartZoom = (params: any) => {
    if (params.hasOwnProperty("batch")) {
      const start = params?.batch[0].start;
      const end = params?.batch[0].end;
      setZoomSliderData({
        start: parseInt(start),
        end: parseInt(end),
      })
    } else {
      setZoomSliderData({
        start: parseInt(params.start),
        end: parseInt(params.end),
      })
    }
  };

  const debouncedOnDataZoom = debounce(onChartZoom, 200);

  return (
    <div style={{ marginLeft: 20, marginRight: 10 }}>
      <ReactEChartsCore
        echarts={echarts}
        option={option}
        notMerge={true}
        lazyUpdate={true}
        theme={"theme_name"}
        key={JSON.stringify(option)}
        onEvents={{ datazoom: debouncedOnDataZoom }}
      />
    </div>
  );
}

export default StackedLineChart;

import React, {useEffect, useRef} from "react";
import {useQuery} from "react-query";
import {AHUDetailsCard} from "./AHUDetailsCard";
import {useSmoothScroll} from "../hooks/useSmoothScroll";
import ScrollIcon from "../assets/ic_ahu_scroll_next.svg";
import {fetchData} from "../services/getAHUSummary";
import useOverflowCheck from "../hooks/useOverFlowCheck";
import {Summary} from "../types";
import "./AHUSummaryCard.scss"
import LoaderStyle from "../../../a1Components/LoaderStyle/LoaderStyle";
import {useStateValue} from "../../../redux/StateProvider";
import moment from "moment";
import DisplayError from "../../../a1Components/DisplayError/DisplayError";
import {getFirstFacility} from "../../../Utils/utils";

export const AHUSummaryCard = () => {
    const ref = useRef<HTMLDivElement | null>(null);
    const [{dateRange}] = useStateValue();

    const {data, error, isLoading, isFetching, refetch} = useQuery(
        "airHandlingUnitData",
        () => fetchData(
            getFirstFacility(),
            moment(dateRange[0]).valueOf(),
            moment(dateRange[1]).valueOf()),
        {
            enabled: false
        }
    );

    const {showScrollIcon} = useOverflowCheck(ref, data);
    const {scrollToNext} = useSmoothScroll();

    const handleScrollToNext = () => {
        if (ref.current) {
            scrollToNext(ref, 325, 500);
        }
    };

    useEffect(() => {
        const fetchDataAndUpdateState = async () => {
            try {
                await refetch();
            } catch (error) {
                console.error('Error re-fetching data:', error);
            }
        };

        fetchDataAndUpdateState();
    }, [dateRange, refetch]);

    if (isLoading || isFetching) {
        return (
            <div className="loadingBox1">
                <LoaderStyle/>
            </div>)
    }

    if (error) {
        console.error("API Error:", error);
        return (
            <div className="loadingBox1">
                <DisplayError type={"err-empty"}/>
            </div>)
    }

    return (
        <>
            <div className="flexBox">

                <div className="ahuDetailsLayout" ref={ref}>
                    {data?.map((element: Summary) => (
                        <AHUDetailsCard key={element.assetId} ahuDetails={element}/>
                    ))}
                </div>

                {showScrollIcon && (
                    <img
                        className="scrollToNextIcon"
                        src={ScrollIcon}
                        onClick={handleScrollToNext}
                        alt="scroll"
                    />
                )}
            </div>
        </>
    );
};

export default class DevMethodUtil {
  // Input: Two numbers.
  // Output: Sum of the given two numbers

  add(x: number, y: number) {
    let returnVal: any = null;

    return returnVal;
  }

  runDevMethods() {
    this.add(2, 3);
  }
}

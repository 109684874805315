/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./index.scss";

function SearchInput(props) {
  const [searchedData, setSearchedData] = useState("");
  const [searchedOutput, setSearchedOutput] = useState();

  useEffect(() => {
    let data = props.data.map((ele) => {
      setSearchedOutput(ele);
    });
    props.filterAssetTypeData(data);
  }, []);

  useEffect(() => {
    const output = props.data.filter((ele) => {
      if (searchedData == "") {
        return ele;
      } else {
        if (ele.toLowerCase().includes(searchedData.toLowerCase())) {
          return ele;
        }
      }
    });
    setSearchedOutput(output);
  }, [searchedData]);

  useEffect(() => {
    props.filterAssetTypeData(searchedOutput);
  }, [searchedOutput]);

  return (
    <div className="SearchInput">
      <input
        type="text"
        name="searchInput"
        placeholder="Search ..."
        onChange={(e) => setSearchedData(e.target.value)}
      />
      <br />
    </div>
  );
}

export default SearchInput;

import {RefObject} from "react";

/**
 * Provides a utility function for achieving smooth horizontal scrolling to a specified
 * offset within a given HTMLDivElement. It uses the easeInOutQuad easing function
 * for smooth acceleration and deceleration during the scroll animation.
 *
 * @param {RefObject<HTMLDivElement>} ref - Ref to the HTMLDivElement to be scrolled.
 */

export function useSmoothScroll() {
    const scrollToNext = (
        ref: RefObject<HTMLDivElement>,
        scrollOffset: number,
        scrollDuration: number
    ) => {
        if (ref.current) {
            const element = ref.current;
            const start = element.scrollLeft;
            const end = start + scrollOffset;
            const startTime = performance.now();

            const scroll = (currentTime: number) => {
                const elapsed = currentTime - startTime;

                if (elapsed < scrollDuration) {
                    element.scrollLeft = easeInOutQuad(elapsed, start, end - start, scrollDuration);
                    requestAnimationFrame(scroll);
                } else {
                    element.scrollLeft = end;
                }
            };

            requestAnimationFrame(scroll);
        }
    };

    return { scrollToNext };
}

function easeInOutQuad(t: number, b: number, c: number, d: number): number {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
}
import React from "react";
import { SeriesDataType, YAsix } from "../types/TrendChartDataType";
// import the core library.
import ReactEChartsCore from "echarts-for-react/lib/core";
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from "echarts/core";
import { formatNumberData } from "../../../Utils/utils";

type PropType = {
  xAxisData: string[];
  yAxisData: YAsix[];
};

function TrendBarChart({ xAxisData, yAxisData }: PropType) {
  const serisData: SeriesDataType[] = yAxisData.map((item) => ({
    name: item.name,
    type: "bar",
    barGap: 0,
    emphasis: {
      focus: "series",
    },
    data: item.data,
    nameRotate: 90,
    nameGap: 79,
    nameLocation: 'middle',
    axisLabel: {
      formatter: (value: any) => formatNumberData(value, 2)
    }
  }));

  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      formatter: function (params) {
        let tooltipText = `${params[0].axisValue}<br/>`;
        params.forEach((item) => {
          tooltipText += `${item.marker} ${item.seriesName}: ${formatNumberData(item.data, 2)}<br/>`;
        });
        return tooltipText;
      },
    },
    legend: {
      show: false,
      data: yAxisData.map((item) => item.name),
    },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
      feature: {
        mark: { show: false },
        dataView: { show: false, readOnly: false },
        magicType: { show: false, type: ["line", "bar", "stack"] },
        restore: { show: false },
        saveAsImage: { show: false },
      },
    },
    xAxis: [
      {
        type: "category",
        axisTick: { show: false },
        data: xAxisData,
      },
    ],
    yAxis: [
      {
        type: "value",
        minInterval: 1,
      },
    ],
    series: serisData,
  };

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={option}
      notMerge={true}
      lazyUpdate={true}
      theme={"theme_name"}
      key={JSON.stringify(option)}
    />
  );
}

export default TrendBarChart;

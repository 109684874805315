/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";

export class DataFetching {
  AssetgetSnackCard() {
    throw new Error("Method not implemented.");
  }
  async getDataFromAxios() {
    let dataFile: any;

    var axiosFetch = axios.get("https://snakeoil-api.atsuyatech.com/emissions");
    axiosFetch
      .then((res) => {
        return;
      })
      .catch((err) => {
        dataFile = err;
        return dataFile;
      });

    return axiosFetch;
  }
  async getStackedHorChart() {
    let dataFile: any;

    var axiosFetch = axios.get(
      "https://snakeoil-api.atsuyatech.com/stackedHorChart"
    );
    axiosFetch
      .then((res) => {
        return;
      })
      .catch((err) => {
        dataFile = err;
        return dataFile;
      });

    return axiosFetch;
  }
  async getHeader() {
    let dataFile: any;

    var axiosFetch = axios.get(
      "https://snakeoil-api.atsuyatech.com/badgeheader"
    );
    axiosFetch
      .then((res) => {
        return;
      })
      .catch((err) => {
        dataFile = err;
        return dataFile;
      });

    return axiosFetch;
  }
  async getSnackCard() {
    let dataFile: any;

    var axiosFetch = axios.get("https://snakeoil-api.atsuyatech.com/snackCard");
    axiosFetch
      .then((res) => {
        return;
      })
      .catch((err) => {
        dataFile = err;
        return dataFile;
      });

    return axiosFetch;
  }

  async AssetgetSnackCard() {
    let dataFile: any;

    var axiosFetch = axios.get("https://snakeoil-api.atsuyatech.com/getAssets");
    axiosFetch
      .then((res) => {
        return;
      })
      .catch((err) => {
        dataFile = err;
        return dataFile;
      });

    return axiosFetch;
  }
}

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import "./ErrorPage.scss";

import image404 from "../../assets/image/img-error-404.svg";

function ErrorPage({ errorStatus }) {
  const hardRefresh = () => {
    errorStatus(true);
  };
  return (
    <div className="errorpage-container">
      <div className="img-container">
        <img className="img" src={image404} />
        <div className="text-container">
          <h1 className="text">{errorStatus}</h1>
          <p className="paragraph">
            The page you are looking for doesn't exist.
          </p>
          <p className="paragraph">Please try again</p>
          <p>
            <a onClick={hardRefresh} className="gobackAnchorclass">
              Go back
            </a>{" "}
            <span className="span">or start from</span>{" "}
            <a onClick={hardRefresh} className="homepageAnchorclass">
              homepage
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
export default ErrorPage;

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import atsuyalogo from "../../../assets/icons/atsuya-logo.svg";
import activityTicketsIcon from "../../assets/images/activity-tickets-icon.svg";
import "./RepeatedTicketsCard.scss";

function RepeatedTicketsCard({ data }) {
  return (
    <div className="RepeatedTicketsCard">
      {data &&
        data.map((ele, id) => {
          return (
            <div className="repeatedtickets-container">
              <div className="area">
                <div className="atsuya-logo">
                  <img src={atsuyalogo} alt="logo" />
                </div>
                <div>
                  <h6>
                    {ele.Client_Batch} - {ele.Asset_name}
                  </h6>
                  <p>
                    <FontAwesomeIcon icon={faLocationDot} /> {ele.locatedAT}
                  </p>
                </div>
              </div>
              <div className="client-ids">
                <h6>{ele.Asset_Mac_Id}</h6>
              </div>
              <div className="ticket-values">
                <img src={activityTicketsIcon} />
                <h6>08</h6>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default RepeatedTicketsCard;

import React from "react";
// import the core library.
import ReactEChartsCore from "echarts-for-react/lib/core";
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from "echarts/core";
// Import charts, all with Chart suffix
import { LineChart } from "echarts/charts";
// import components, all suffixed with Component
import {
  GridComponent,
  ToolboxComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  DataZoomComponent,
} from "echarts/components";
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
  CanvasRenderer,
  // SVGRenderer,
} from "echarts/renderers";
import { UniversalTransition } from "echarts/features";

function StackedAreaChart({
  series,
  height,
  left,
  right,
  xData,
  legend,
  color,
}) {
  echarts.use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    DataZoomComponent,
  ]);
  var option;
  option = {
    color: color,
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#4cb9f2",
        },
      },
    },
    height: height,
    width: "100%",
    grid: {
      left: left || "4%",
      right: right || "3%",
      bottom: "0%",
      top: "2%",
      containLabel: true,
    },

    legend: {
      data: legend,
      top: 0,

      padding: 5,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: xData,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],

    series: series,
  };

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={option}
      notMerge={true}
      lazyUpdate={true}
      theme={"theme_name"}
    />
  );
}

export default StackedAreaChart;

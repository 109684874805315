import React, { useState } from 'react';
import eyeOff from "../../assets/icons/eyeOff-icon.svg";
import eyeOn from "../../assets/icons/eyeOn-icon.svg";

type dataType = {
    onChangeInput:(e:string) => void;
    value:string;
    title:string;
}

const PasswordInputField = ({onChangeInput, value, title} : dataType) => {
    const [warning, setWarning] = useState(false);
    const [visible, setVisible] = useState(false);
    const checkInput = /^[a-zA-Z0-9@.]{1,30}$/;


    const validateInput = (e:string) => {
      if(e === "") {
        onChangeInput(e);
      }
      else if(checkInput.test(e)){
        onChangeInput(e);
        setWarning(false);
      }else{
        setWarning(true);
       }
    } 

  return (
    <div className='passwordInputField'>
        <input type={visible ? 'text' : 'password'} placeholder={title} maxLength={30} minLength={5} value={value} onChange={(e) => validateInput(e.target.value.trim())} />
        <div className='passwordIcon'>
            <img onClick={() => setVisible(!visible)} src={visible ? eyeOff : eyeOn} alt='icon' loading='lazy' />
        </div>
    </div>
  )
}

export default PasswordInputField
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/alt-text */
import moment from "moment";
import * as R from "ramda";
import { useState, useEffect } from "react";
import temperatureIcon from "../../assets/image/temperature-icon.svg";
import "./style.scss";

function Temp({ data, abbre, minTemp, maxTemp, dailyMeters, energyUtil,lastCommTime }:any) {
  const [latestTime, setLatestTime] = useState();
  const [compPet, setcompPet] = useState()
  useEffect(() => {
    !R.isEmpty(lastCommTime) && !R.isNil(lastCommTime) && lastCommTime !== null && setLatestTime(lastCommTime) ;
  }, [lastCommTime]);
  useEffect(() => {
   
  }, [data]);
  useEffect(() => {
    let complianceS = dailyMeters.find(
      (obj) => obj.meterTypeId === "TEMP_COMP_PCT"
    );
    setcompPet(complianceS);
  }, [dailyMeters])
  
  return (
    <>
      <div className="lastUpdated">
        {!R.isEmpty(latestTime) && !R.isNil(latestTime) ? (
          <h3>Updated {moment(latestTime).fromNow()}</h3>
        ) : (
          <h3>&nbsp;</h3>
        )}
      </div>
      <div className="assetCard-colum">
        {!R.isEmpty(data) ? (
          data.map(
            (el:any) =>
              el.meterTypeId.includes("TEMP") && (
                <div className="assetCard-col">
                  <p>
                    <img src={temperatureIcon} /> Temperature
                  </p>
                  <h2
                    style={{
                      color: el.compliance === false ? "red" : "#000000",
                    }}
                  >
                       {!R.isEmpty(el.meterValue) && !R.isNil(el.meterValue) ?  el.meterValue.toFixed(2) : ""}°{el.readingUomDesc}
                  </h2>
                  <h6>
                    Min {Number(minTemp?.value).toFixed(2)}°
                    {!R.isEmpty(minTemp) &&
                      !R.isNil(minTemp) &&
                      minTemp?.abbreviation}{" "}
                    - Max {Number(maxTemp?.value).toFixed(2)}°
                    {!R.isEmpty(minTemp) &&
                      !R.isNil(minTemp) &&
                      maxTemp?.abbreviation}
                  </h6>
                </div>
              )
          )
        ) : (
          <div className="assetCard-col">
            <p>
              <img src={temperatureIcon} /> Temperature
            </p>
            <h2></h2>
          </div>
        )}
{/* //commented for anovi users */}
        {/* <div className="assetCard-col">
          <p>Asset Score</p>
          {!R.isEmpty(data) ? (
            data.map((el) => {
              el.meterTypeId.includes("Score") && (
                <h2>
                  {el.meterValue}%{" "}
                  <span className={`assetCard-col-badge ${el.grade}`}>
                    {el.grade}
                  </span>
                </h2>
              );
            })
          ) : (
            <h2></h2>
          )}
        </div> */}
        <div className="assetCard-col">
          <p>Compliance</p>
          {/* {!R.isEmpty(data) ? (
            dailyMeters.map((el) => {
              return(
              el.meterTypeId.includes("TEMP_COMP_PCT") && ( */}
             
               {!R.isEmpty(compPet) && !R.isNil(compPet) ? <h2>
                  {(compPet?.average).toFixed(2)} 
                  {compPet?.defaultUom}{" "}
                  <span className={`assetCard-col-badge ${compPet?.grade}`}>
                    {compPet?.grade}
                  </span>
                </h2> : ""}
              {/* ))
            })
          ) : (
            <h2></h2>
          )} */}
        </div>
      </div>
    </>
  );
}

export default Temp;

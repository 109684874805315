/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./ErrorPage.scss";
import imageConnectionerror from "../../assets/image/something-error.svg";

function WronPage() {
  return (
    <div className="errorpage-container">
      <div className="img-container">
        <img className="wrongpage-img" src={imageConnectionerror} />
        <div className="text-container">
          <h1 className="text">Something went wrong</h1>
          <p className="paragraph">
            here was an unexpected server error. Try refreshing the page or try
            again later.
          </p>
          <p>
            <a href="/" className="gobackAnchorclass">
              Refresh this page
            </a>
          </p>
        </div>
      </div>
      <p className="support">
        if problem presists, report to <a href="">support@atsuyatech.com</a>
      </p>
    </div>
  );
}
export default WronPage;

import { useRef, useState, useEffect } from "react";
import AddIcon from "../../assets/icons/add_image.svg";
import ImageCloseIcon from "../../assets/icons/ImageClose.svg";
import "./ImageSelectorComponent.scss";
import { useLocalStorage } from 'react-use';

type ImageType = {
  image: File | null;
  alt: string;
  // onChange: (newImage: File | null) => void;
};
function ImageSelectorComponent({
  image,
  alt,
  onChange,
}: ImageType) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [preview, setPreview] = useLocalStorage<string | null>("imagePreview", image || null);
  const [error, setError] = useState<string | null>(null);

  useEffect(()=> {
    setPreview('');
  },[])

  useEffect(() => {
    if (image && !preview) {
      setPreview(image);
    }
  }, [image, preview]);
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const maxSizeInMB = 2;
      const acceptedFormats = ["image/png", "image/jpeg", "image/jpg"];
      if (file.size > maxSizeInMB * 1024 * 1024) {
        setError(`File size exceeds ${maxSizeInMB}MB.`);
        return;
      }
      if (!acceptedFormats.includes(file.type)) {
        setError("Invalid file format");
        return;
      }
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const recommendedWidth = 300;
        const recommendedHeight = 300;
        if (width !== recommendedWidth || height !== recommendedHeight) {
          setError(`Image resolution should be ${recommendedWidth}x${recommendedHeight} pixels.`);
          URL.revokeObjectURL(objectUrl);
          return;
        }

        setSelectedFile(file);
        setPreview(objectUrl); 
        setError(null);
      };

      img.onerror = () => {
        setError('Error loading image.');
        URL.revokeObjectURL(objectUrl);
      };

      img.src = objectUrl;
    }
  };

  const cancelImage = () => {
    setPreview(null);
    setSelectedFile(null);
  };

  return (
    <div className="imageDiv">
      <div className="file-input-container">
        {preview ? (
          <div>
            <img className="file-input-image" src={preview} alt={alt} />
            <button className="file-input-button" onClick={cancelImage}>
              <img className="cancel-image" src={ImageCloseIcon} alt="Close" />
            </button>
          </div>
        ) : (
          <>
            <button className="file-input-button" onClick={() => fileInputRef.current?.click()}>
              <img className="image" src={AddIcon} alt="Add" />
            </button>
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleFileUpload}
              style={{ display: "none" }}
            />
          </>
        )}
      </div>
      <div className="errorDiv">  {error && <div className="error-message">{error}</div>}</div>

    </div>
  );
}
export default ImageSelectorComponent;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import atsuyalogo from "../../assets/icons/atsuya-logo.svg";
import RingGaugeChart from "../OperationalDashboardComp/RingGauge/RingGaugeChart";

import "./DataComplianAsset.scss";

const piChart = {
  total_assets: "370",
  Online_Assets: "301",
  Offline_Assets: "0",
  active_variance: "",
  active_context: "",
  inactive_variance: "",
  inactive_context: "",
};
function DataComplianAsset({ data }) {
  return (
    <div className="dataComplianAsset">
      {data &&
        data.map((ele, id) => {
          return (
            <div className="datacomplian-container">
              <div className="area-details">
                <div className="atsuya-logo">
                  <img src={atsuyalogo} alt="logo" />
                </div>
                <div>
                  <h6>
                    {ele.Client_Batch} - {ele.Asset_name}
                  </h6>
                  <p>
                    <FontAwesomeIcon icon={faLocationDot} /> {ele.locatedAT}
                  </p>
                </div>
              </div>
              <div className="customer-id">
                <h6>{ele.Asset_Mac_Id}</h6>
              </div>
              <div className="customer-time">
                <div className="customer-Chart">
                  <RingGaugeChart />
                </div>
                <div className="performance-zone">
                  <h6>1012/1140</h6>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default DataComplianAsset;

import React from "react";
import {
  SelectedValueType,
  EventSummaryParams,
  FilterDataObj,
} from "../../eventSummary/types";
import useState from "react-usestateref";
import { ChartDataType, EventDurationDataType } from "../type.tsx";

function EventDurationState() {
  const [searchingIndex, setSearchingIndex] = useState<number | null>(null);
  const [filterDataSet, setFilterDataSet] = useState<FilterDataObj[]>([]);
  const [selectedFilterKey, setSelectedFilterKey] = useState<string[]>([]);
  const [selectedFilterValue, setSelectedFilterValue] = useState<
    SelectedValueType[]
  >([]);

  const [queryParams, setQueryParams] = useState<EventSummaryParams>({
    filterByType: "_NA_",
    filterById: "_NA_",
    filterByDate: "",
    areaId: "_NA_",
    startDate: 1701369000000,
    endDate: 1703961000000,
    extraDetails: true,
    trendKey: "_NA_",
    previousEventRange: "",
  });

  const [responseData, setResponseData] = useState<EventDurationDataType>({
    paginationMap: {
      totalPage: 0,
      nextPage: 0,
      pageSize: 0,
      prevPage: 0,
      totalCount: 0,
      currentPage: 0,
    },
    eventDuration: [],
  });

  const [chartDataObj, setChartDataObj] = useState<ChartDataType>({
    description: [],
    data: [],
  });

  const [pageIndex, setPageIndex, pageIndexRef] = useState(1);

  return {
    searchingIndex,
    setSearchingIndex,
    filterDataSet,
    setFilterDataSet,
    selectedFilterKey,
    setSelectedFilterKey,
    queryParams,
    setQueryParams,
    responseData,
    setResponseData,
    selectedFilterValue,
    setSelectedFilterValue,
    pageIndex,
    setPageIndex,
    pageIndexRef,
    chartDataObj,
    setChartDataObj,
  };
}

export default EventDurationState;

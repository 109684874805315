import "../DateTimeRangeSelectorInput/DateTimeInput/DateTimeInput.scss";
import {TimeInputValueDatType} from '../DataTypes';

const TimeInputField =  ({ title, value,max,min, handleDateChange }: TimeInputValueDatType ) => {
  return (
    <div className="dateTimeCard">
      <p>{title}</p>
      <input type="time" value={value} onChange={(e) => handleDateChange(e.target.value)} />
    </div>
  )
};
export default TimeInputField
import React, { useEffect, useRef, useState } from "react";
import monthRangeStyle from "./MonthRangePicker.module.scss";

function MonthRangerPicker({
  containerWidth,
  darkTheme,
  onDateRangeChange,
  ranges: initialRanges,
}: any) {
  const containerRef = useRef(null);
  const [ranges, setRanges] = useState(initialRanges || []);

  const [manualSelection, setManualSelection] = useState(false);

  useEffect(() => {
    if (!manualSelection && initialRanges.length > 0) {
      const { startDate, endDate } = initialRanges[0];
      setSelectedFirstMonth(startDate.getMonth());
      setSelectedFirstYear(startDate.getFullYear());
      setSelectedLastMonth(endDate.getMonth());
      setSelectedLastYear(endDate.getFullYear());
    }
  }, [initialRanges, manualSelection]);

  const get365DaysAgo = () => {
    const today = new Date();
    const pastDate = new Date();
    pastDate.setDate(today.getDate() - 365);
    return pastDate;
  };

  const isPastMonthBeyondLimit = (month, year) => {
    const pastLimitDate = get365DaysAgo();
    const pastLimitYear = pastLimitDate.getFullYear();
    const pastLimitMonth = pastLimitDate.getMonth();
    return (
      year < pastLimitYear || (year === pastLimitYear && month < pastLimitMonth)
    );
  };

  const isPreviousYearBeyondLimit = () => {
    const pastLimitDate = get365DaysAgo();
    const limitYear = pastLimitDate.getFullYear();

    return year1 <= limitYear;
  };

  const compareMonth = (firstMonth, firstYear, lastMonth, lastYear) => {
    if (lastYear > firstYear) return true;
    if (lastYear === firstYear && lastMonth >= firstMonth) return true;
    return false;
  };

  const isFutureMonth = (month, year) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    return year > currentYear || (year === currentYear && month > currentMonth);
  };

  const isNextYearDisabled = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    return year2 >= currentYear && currentMonth < 11;
  };

  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const [year1, setYear1] = useState(2024);
  const [year2, setYear2] = useState(2025);

  const [hoverMonth, setHoverMonth] = useState(null);
  const [hoverYear, setHoverYear] = useState(null);

  const [selectedFirstMonth, setSelectedFirstMonth] = useState(null);
  const [selectedFirstYear, setSelectedFirstYear] = useState(null);

  const [selectedLastMonth, setSelectedLastMonth] = useState(null);
  const [selectedLastYear, setSelectedLastYear] = useState(null);

  const getStartDate = (month, year) => new Date(year, month, 1);
  const getEndDate = (month, year) => new Date(year, month + 1, 0);

  const getColor = (month, year) => {
    if (isFutureMonth(month, year) || isPastMonthBeyondLimit(month, year)) {
      return `${monthRangeStyle.calenderCellDisabled}`;
    }

    if (selectedFirstMonth === month && selectedFirstYear === year) {
      return monthRangeStyle.calenderCellStart;
    }
    if (selectedLastMonth === month && selectedLastYear === year) {
      return monthRangeStyle.calenderCellEnd;
    }
    if (
      selectedFirstMonth !== null &&
      selectedLastMonth !== null &&
      compareMonth(selectedFirstMonth, selectedFirstYear, month, year) &&
      compareMonth(month, year, selectedLastMonth, selectedLastYear)
    ) {
      return `${monthRangeStyle.calenderCellInbetween} ${
        darkTheme && monthRangeStyle.calenderCellInbetweenDark
      }`;
    }
    return `${monthRangeStyle.calenderCellNormal} ${
      darkTheme && monthRangeStyle.calenderCellNormalDark
    }`;
  };

  const calenderClick = (month, year) => {
    if (isFutureMonth(month, year) || isPastMonthBeyondLimit(month, year)) {
      return;
    }

    if (selectedFirstYear !== null && selectedFirstMonth !== null) {
      if (
        selectedLastYear === null &&
        selectedLastMonth === null &&
        compareMonth(selectedFirstMonth, selectedFirstYear, month, year)
      ) {
        setSelectedLastMonth(month);
        setSelectedLastYear(year);

        const startDate = getStartDate(selectedFirstMonth, selectedFirstYear);
        const endDate = getEndDate(month, year);

        const newRange = [{ startDate, endDate, key: "selection" }];
        setRanges(newRange);

        if (onDateRangeChange) {
          onDateRangeChange(newRange, "Month");
        }

        setManualSelection(false);
      } else {
        setSelectedFirstMonth(month);
        setSelectedFirstYear(year);
        setSelectedLastMonth(null);
        setSelectedLastYear(null);
        setManualSelection(true);
      }
    } else {
      setSelectedFirstMonth(month);
      setSelectedFirstYear(year);
      setManualSelection(true);
    }
  };

  const containerTheme = {
    width: containerWidth,
  };
  return (
    <>
      <div
        ref={containerRef}
        className={`${monthRangeStyle.monthRangeContainer} ${
          darkTheme && monthRangeStyle.monthRangeContainerDark
        }`}
        style={containerTheme}
      >
        <div
          className={`${monthRangeStyle.monthRangePicker} ${
            darkTheme && monthRangeStyle.monthRangePickerDark
          }`}
        >
          {/* Left Calendar */}
          <div
            className={`${monthRangeStyle.leftCalender} ${
              darkTheme && monthRangeStyle.leftCalenderDark
            }`}
          >
            <div className={monthRangeStyle.upperLayer}>
              <button
                className={`${monthRangeStyle.upperButton} ${
                  darkTheme && monthRangeStyle.upperButtonDark
                } ${
                  isPreviousYearBeyondLimit() &&
                  monthRangeStyle.upperButtonDisabled
                }`}
                onClick={() => {
                  if (!isPreviousYearBeyondLimit()) {
                    setYear1(year1 - 1);
                    setYear2(year2 - 1);
                  }
                }}
                disabled={isPreviousYearBeyondLimit()}
              >
                {"<"}
              </button>

              <div
                className={`${monthRangeStyle.yearName} ${
                  darkTheme && monthRangeStyle.yearNameDark
                }`}
              >
                {year1}
              </div>
            </div>
            <div className={monthRangeStyle.blockContainer}>
              {months.map((month, i) => (
                <div
                  key={`${i}-${year1}`}
                  className={`${monthRangeStyle.calenderCell} ${getColor(
                    i,
                    year1
                  )}`}
                  onMouseEnter={() => {
                    setHoverYear(year1);
                    setHoverMonth(i);
                  }}
                  onMouseLeave={() => {
                    setHoverYear(null);
                    setHoverMonth(null);
                  }}
                  onClick={() => calenderClick(i, year1)}
                >
                  {month}
                </div>
              ))}
            </div>
          </div>

          {/* Right Calendar */}
          <div
            className={`${monthRangeStyle.rightCalender} ${
              darkTheme && monthRangeStyle.rightCalenderDark
            }`}
          >
            <div className={monthRangeStyle.upperLayer}>
              <div
                className={`${monthRangeStyle.yearName} ${
                  darkTheme && monthRangeStyle.yearNameDark
                }`}
              >
                {year2}
              </div>
              <button
                className={`${monthRangeStyle.upperButton} ${
                  darkTheme && monthRangeStyle.upperButtonDark
                } ${
                  isNextYearDisabled() && monthRangeStyle.upperButtonDisabled
                }`}
                onClick={() => {
                  if (!isNextYearDisabled()) {
                    setYear1(year1 + 1);
                    setYear2(year2 + 1);
                  }
                }}
                disabled={isNextYearDisabled()}
              >
                {">"}
              </button>
            </div>
            <div className={monthRangeStyle.blockContainer}>
              {months.map((month, i) => (
                <div
                  key={`${i}-${year2}`}
                  className={`${monthRangeStyle.calenderCell} ${getColor(
                    i,
                    year2
                  )}`}
                  onMouseEnter={() => {
                    setHoverYear(year2);
                    setHoverMonth(i);
                  }}
                  onMouseLeave={() => {
                    setHoverYear(null);
                    setHoverMonth(null);
                  }}
                  onClick={() => calenderClick(i, year2)}
                >
                  {month}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MonthRangerPicker;

/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import DonutChartCompDev from "../../a1Components/E-Charts/donutChart";
import { keyFormat } from "../../Utils";
import "./index.scss";
import * as R from "ramda";
import LoaderStyle from "../LoaderStyle/LoaderStyle";
import { useStateValue } from "../../redux/StateProvider";
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import DisplayError from "../DisplayError/DisplayError";

type DonutProps = {
  activeCount: string;
  activeLabel: string;
  inActiveCount: string;
  inActiveLabel: string;
  activeStatus: string;
  inactiveStatus: string;
  actContext: string;
  inactContext: string;
  data: object;
  donutData: object;
  slowCount: object;
  slowLabel: object;
  maintenanceCount: object;
  maintenanceLabel: object;
  offlineCount: object;
  offlineLabel: object;
  lostCount: object;
  lostLabel: object;
};

function DonutChartComp({ data }: DonutProps) {
  const [{ }, dispatch] = useStateValue();
  const [summary, setSummary] = useState([]);
  useEffect(() => {
    setSummary(data);
  }, [data]);

  const downloadxlsx = (data, rowColor) => {
    const worksheet = XLSX.utils.json_to_sheet(data);

    // Add border to cells
    const range = XLSX.utils.decode_range(worksheet["!ref"]);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell = worksheet[XLSX.utils.encode_cell({ r: R, c: C })];
        if (cell && cell.t === "s") {
          cell.s = {
            border: {
              top: { style: "thin", color: { rgb: "FF000000" } },
              right: { style: "thin", color: { rgb: "FF000000" } },
              bottom: { style: "thin", color: { rgb: "FF000000" } },
              left: { style: "thin", color: { rgb: "FF000000" } },
            },
          };
        }
      }
    }

    // Add custom style for row
    const rowStyle = {
      fill: { fgColor: { rgb: rowColor } },
    };
    data.forEach((row, index) => {
      const rowIndex = index + 2; // skip header row
      Object.keys(row).forEach((key, colIndex) => {
        const cellAddress = XLSX.utils.encode_cell({
          r: rowIndex,
          c: colIndex,
        });
        const cell = worksheet[cellAddress];
        if (cell && cell.t === "s") {
          cell.s = {
            border: {
              top: { style: "thin", color: { rgb: "FF000000" } },
              right: { style: "thin", color: { rgb: "FF000000" } },
              bottom: { style: "thin", color: { rgb: "FF000000" } },
              left: { style: "thin", color: { rgb: "FF000000" } },
            },
            ...rowStyle,
          };
        }
      });
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const excelBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    FileSaver.saveAs(excelBlob, "data.xlsx");
  };

  const viewDownload = () => {
    console.log("the alertSy", summary);
    if (!R.isEmpty(summary) && !R.isNil(summary)) {
      const titleColor = "FF0000"; // Red color

      downloadxlsx([summary], titleColor);
      // downloadxlsx([summary])
    }
  };

  // const downloadExcel = async () =>{

  //     try {
  //       let urlsub = 'AssetTemperatureComplianceReport.xls'
  //       const url = `${urlsub}?username=superadmin@si&password=si@superadmin&fromDate=1680373799000&thruDate=1680546599000`;
  //       const resp = await fetch(url, {
  //         method: "GET",
  //         headers: { "Content-Type": "application/json" },
  //       });
  //       console.log("the download values",resp.body)
  //       return resp;
  //     } catch (error) {
  //       let value = "error";
  //       return value;
  //     }

  // }
  // const entries = Object.entries(summary);
  // console.log("the donutData", entries);
  // const donutData = !R.isEmpty(summary)
  //   ? [
  //       { value: entries[0][1], name: keyFormat(entries[0][0]) },
  //       { value: entries[1][1], name: keyFormat(entries[1][0]) },
  //       { value: entries[2][1], name: keyFormat(entries[2][0]) },
  //       { value: entries[3][1], name: keyFormat(entries[3][0]) },
  //       { value: entries[4][1], name: keyFormat(entries[4][0]) },
  //       { value: entries[5][1], name: keyFormat(entries[5][0]) },
  //       { value: entries[6][1], name: keyFormat(entries[6][0]) },
  //       { value: entries[7][1], name: keyFormat(entries[7][0]) },
  //     ]
  //   : [];
  // console.log("the donutData", donutData);
  // return R.isEmpty(summary) ? (
  //   <div className="donut-container">
  //     <div className="chart-container">
  //       <div className="lottieContainer">
  //         <div className="d-flex justify-content-center">
  //           <LoaderStyle small />
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // ) : (
  //   <div className="donut-container">
  //     <div className="chart-container">
  //       {!R.isEmpty(summary) && !R.isNil(summary) && (
  //         <DonutChartCompDev
  //           donutData={summary}
  //           total={summary?.Total}
  //           totalText="Total"
  //         />
  //       )}
  //     </div>

  //     {/* {!R.isEmpty(summary) && (
  //       <DonutDescribe
  //         onlineCount={entries[0][0]}
  //         onlineLabel={keyFormat(entries[0][1])}
  //         maintenanceCount={entries[1][0]}
  //         maintenanceLabel={keyFormat(entries[1][1])}
  //         offlineCount={entries[2][0]}
  //         offlineLabel={keyFormat(entries[2][1])}
  //         inActiveCount={entries[3][1]}
  //         inActiveLabel={keyFormat(entries[3][0])}
  //         slowCount={entries[5][1]}
  //         slowLabel={keyFormat(entries[5][0])}
  //         lostCount={entries[6][1]}
  //         lostLabel={keyFormat(entries[6][0])}
  //       />
  //     )} */}
  //   </div>
  // );



  return R.isNil(summary) ? (
    <div className="donut-container">
      <div className="chart-container">
        <div className="lottieContainer">
          <div className="d-flex justify-content-center">
            <LoaderStyle />
          </div>
        </div>
      </div>
    </div>
  ) : R.isEmpty(summary) ? (
    <div className="donut-container">
      <div className="chart-container">
        <div className="lottieContainer">
          <div className="d-flex justify-content-center">
            <DisplayError type="err-empty" />
          </div>
        </div>
      </div>
    </div>
  ) :
    <div className="donut-container">
      <div className="chart-container">
        {!R.isEmpty(summary) && !R.isNil(summary) && (
          <DonutChartCompDev
            donutData={summary}
            total={summary?.Total}
            totalText="Total"
          />
        )}
      </div>

      {/* {!R.isEmpty(summary) && (
    <DonutDescribe
      onlineCount={entries[0][0]}
      onlineLabel={keyFormat(entries[0][1])}
      maintenanceCount={entries[1][0]}
      maintenanceLabel={keyFormat(entries[1][1])}
      offlineCount={entries[2][0]}
      offlineLabel={keyFormat(entries[2][1])}
      inActiveCount={entries[3][1]}
      inActiveLabel={keyFormat(entries[3][0])}
      slowCount={entries[5][1]}
      slowLabel={keyFormat(entries[5][0])}
      lostCount={entries[6][1]}
      lostLabel={keyFormat(entries[6][0])}
    />
  )} */}
    </div>
}

export default DonutChartComp;

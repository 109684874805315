import "./Slider.scss";

type SliderType = {
  value: number;
  onChange: (value: number) => void;
};

const Slider = ({ value, onChange }: SliderType) => {
  return (
    <input
      value={value}
      className="form-range-input"
      step={1}
      min={0}
      max={100}
      type="range"
      onChange={(e) => onChange(parseInt(e.target.value, 10))}
    />
  );
};

export default Slider;

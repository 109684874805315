import React, { useState, useEffect } from "react";
import "./CustomSelect.scss";
import R from "ramda";

const CustomSelect = ({ callBackValue, options }: any) => {
  const [searchValue, setSearchValue] = useState<any>("");
  const [isOpen, setIsOpen] = useState(false);
  const [filterList, setFilterList] = useState([...options]);
  // const [onChangeInputKey, setOnChangeInputKey] = useState(true);
  const [emptyCheckStatus, setEmptyCheckStatus] = useState(false);

  useEffect(() => {
    let list: any = [...options];
    if (searchValue === "") {
      callBackValue("");
      //setEmptyCheckStatus(false);
      setFilterList([...options]);
    } else {
      let ListFilter = list.filter((ele: any) =>
        ele.description.toLowerCase().includes(searchValue.toLowerCase())
      );
      if (ListFilter === undefined) {
        setEmptyCheckStatus(true);
        setFilterList([]);
        callBackValue("undefined");
      } else {
        // setEmptyCheckStatus(false);
        setFilterList(ListFilter);
      }
    }
  }, [searchValue]);

  // Function to handle selecting an option
  const handleOptionSelect = (value: any) => {
    setSearchValue(value);
    let list: any = [...options];
    let idValue = list.find((ele: any) => ele.description === value);
    callBackValue(idValue.enumId);
    setIsOpen(false);
    // setOnChangeInputKey(true);
  };

  return (
    <div className="CustomSelect">
      {(
        <input
          type="text"
          className="custom-select"
          placeholder="Search Reason"
          maxLength={100}
          onFocus={() => setIsOpen(true)}
          style={{
            borderColor: emptyCheckStatus ? "#ff8282" : "#A7B8F4",
          }}
          //readOnly ={searchValue === 'Others'}
          value={searchValue}
          // onBlur={() => setIsOpen(false)}
          // onMouseEnter={() => setIsOpen(true)}
          onChange={(e) => {
            if (e.target.value.trim() === "") {
              setSearchValue(e.target.value.trim());
            } else if (e.target.value.trim().length > 0) {
              setSearchValue(e.target.value);
            } else {
              setSearchValue(e.target.value);
            }
          }}
          onClick={() => {setIsOpen(true); 
            // setOnChangeInputKey(false);
             setSearchValue(""); }}
        />
      )}

      {filterList.length === 0 && (
        <p
          style={{
            color: "#ff1212",
            fontSize: "12px",
            fontWeight: "500",
          }}
        >
          No Items Found
        </p>
      )}

      {isOpen && searchValue !== "Others" && (
        <div
          onMouseLeave={() => setIsOpen(false)}
          className="options-container"
        >
          {filterList.length > 0 ?
            filterList.map((ele: any, index: any) => (
              <div
                key={index}
                className="option"
                style={{ cursor: "pointer" }}
                onClick={() => handleOptionSelect(ele.description)}
              >
                <p className="optionText">{ele.description}</p>
              </div>
            )) : ''}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;

import "../../App_one.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

function ToggleRight({ handleRightOpen, isDark }:any) {
  return (
    <div className="rightToggleBtn">
      <div className={isDark ? "cta ctaDark" : "cta"} onClick={handleRightOpen}>
        <FontAwesomeIcon icon={faChevronLeft} className="faChevronLeft" />
      </div>
    </div>
  );
}

export default ToggleRight;

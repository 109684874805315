/* eslint-disable no-const-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck

import React, { useEffect, useState } from "react";
import AddIcon from "../../assets/icons/AddIcon.svg";
import IconFilter from "../../assets/icons/IconFilter.svg";
import "./Organisations.scss";
import OrganisationCard from "./OrganisationCard";
import AddEditOrganisationModal from "./AddOrEditOrganisation/AddEditOrganisationModal";
import ExportIcon from "../../assets/icons/ExportIcon.svg";
import DisableIcon from "../../assets/icons/Disable-Icon.svg";
import DeleteIcon from "../../assets/icons/Delete-Icon.svg";
import { ToastContainer } from "react-toastify";
import { SettingsApiServices } from "../../services/settingsPageServices/SettingsApiServices";
import { PartyGroup, PartyGroupResponse } from "./Types/PartyGroupResponse";
import LoaderStyle from "../../a1Components/LoaderStyle/LoaderStyle";
import Lottie from "react-lottie";
import noOrgLoader from "../Lotties/no-organisations.json";
import someThingWentWrongLoader from "../AssetSettings/assetSettingsCardContainer/something-went-wrong.json";

function Organisations() {
  const [addEditModal, setAddEditModal] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedList, setSelectedList] = useState([]);
  const [organisationList, setOrganisationList] = useState<PartyGroup[] | null>(
    null
  );
  const settingsApiServices = new SettingsApiServices();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(false);

  const pageSize = 12;
  useEffect(() => {
    fetchData(currentPage);
  }, []);

  const fetchData = async (page) => {
    try {
      setIsLoading(true);
      const data: PartyGroupResponse =
        await settingsApiServices.fetchOrganisationList(page, pageSize);
      const newList = data.partyGroupList;

      if (newList && newList.length > 0) {
        // Append the new list to the existing list
        if (newList.length < pageSize) {
          setShowLoadMore(false);
        }
        setOrganisationList((prevList) => {
          if (prevList) {
            return [...prevList, ...newList];
          } else {
            return newList;
          }
        });
      } else {
        setShowLoadMore(false);
      }
      setIsLoading(false);
    } catch (error) {
      // Handle error case
      setShowLoadMore(false);
      setIsLoading(false);
      setApiError(true);
    }
  };

  function handleOpenModal() {
    setAddEditModal(true);
  }

  function handleCloseModal() {
    setAddEditModal(false);
  }

  function handleConfirm() {
    handleCloseModal();
  }

  function handelRefresh() {
    setCurrentPage(1);
    setSelectedList([]);
    setOrganisationList([]);
    fetchData(currentPage);
  }

  // function handlePartyRefresh() {
  //   setCurrentPage(1);
  //   setSelectedList([]);
  //   setOrganisationList([]);
  //   fetchData(currentPage);
  // }

  const handleLoadMore = () => {
    fetchData(currentPage + 1);
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    setSelectedCount(selectedList?.length ?? 0);
  }, [selectedList]);

  const selectAll = () => {
    let count = organisationList?.length ?? 0;
    setSelectedCount(count);
    // organisationList.map((obj)=> obj.PartyGroupDetails[0].partyId)
    setSelectedList(
      organisationList.map((obj) => obj.PartyGroupDetails[0].partyId)
    );
  };

  const deselectAll = () => {
    setSelectedCount(0);
    setSelectedList([]);
  };

  // const isObjectInList = (objectToCheck: any) => {
  //   return (
  //     selectedList?.find(
  //       (item) =>
  //         item.PartyGroupDetails[0].partyId ===
  //         objectToCheck.PartyGroupDetails[0].partyId
  //     ) !== undefined
  //   );
  // };

  const addObjectToList = (object: string) => {
    if (!selectedList.includes(object)) {
      setSelectedList((prevList) => [...prevList, object]);
    } else {
      let x = selectedList?.filter((item) => item !== object);
      setSelectedList(x || []);
    }
  };

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: noOrgLoader,
  };

  const someThingWentWrongOption = {
    loop: true,
    autoplay: true,
    animationData: someThingWentWrongLoader,
  };
  return (
    <>
      <div className="settingFilterBtnCont orgFilterDiv">
        <div className="filterSearchCont"></div>
        <div className="filterBtnConteiner">
          {/*EXPORT*/}
          {/*<button className="userExportBtn">*/}
          {/*  <img src={ExportIcon} alt="icon" />*/}
          {/*  Export*/}
          {/*</button>*/}
          {/*FILTER PERMISSION*/}
          {/*<button className="facilityFilterBtn">*/}
          {/*  <img src={IconFilter} alt="icon" />*/}
          {/*  Filter*/}
          {/*</button>*/}
          <button className="addFacilityBtn" onClick={handleOpenModal}>
            <img src={AddIcon} alt="icon" />
            Add Organisation
          </button>
        </div>
      </div>

      {selectedCount > 0 && (
        <div className="facilitySettingsCardsCont1">
          <div className="facilitySettingsCardsNav">
            <p>{selectedCount} selected</p>
            <div className="facilitySettingsNavBtnsCont">
              <button className="selectAllBtn" onClick={selectAll}>
                Select all
              </button>
              <button className="deselectAllBtn" onClick={deselectAll}>
                Deselect all
              </button>
              <button className="disableBtn">
                <img src={DisableIcon} alt="icon" /> Disable
              </button>
              <button className="deleteBtn">
                <img src={DeleteIcon} alt="icon" /> Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="orgCardCont">
        {organisationList === null && isLoading ? (
          <div className="height100 displayGrid">
            <LoaderStyle />
          </div>
        ) : apiError ? (
          <Lottie
            isClickToPauseDisabled={false}
            options={someThingWentWrongOption}
          />
        ) : (
          <div
            className="height100"
            onClick={() => {
              if (organisationList?.length === 0) {
                handleOpenModal();
              }
            }}
          >
            {organisationList?.length === 0 ? (
              <Lottie isClickToPauseDisabled={true} options={lottieOptions} />
            ) : (
              <div className="organisationDiv">
                {organisationList?.map((valueObject) => (
                  <OrganisationCard
                    selected={selectedList.includes(
                      valueObject.PartyGroupDetails[0].partyId
                    )}
                    addToSelectedList={addObjectToList}
                    valueObject={valueObject}
                    refreshList={handelRefresh}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      {organisationList !== null && showLoadMore && (
        <div className="orgFooterContentDiv">
          {isLoading ? (
            <button className="loadingBtn">
              Loading
              <div className="loaderAnimation"></div>
            </button>
          ) : (
            <button className="loadMoreBtn" onClick={handleLoadMore}>
              Load More
            </button>
          )}
        </div>
      )}

      <AddEditOrganisationModal
        type="add"
        valueObject={""}
        addEditModal={addEditModal}
        onConfirm={handleConfirm}
        onClose={handleCloseModal}
        refreshList={handelRefresh}
      />

      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnHover={false}
        theme="colored"
      />
    </>
  );
}

export default Organisations;

import React from "react";
import "./style.scss";

type BtnProps = {
  label: string;
  onClick: Function;
  btnClear: boolean;
};

function ThemeButton({ label, onClick, btnClear }: any) {
  return (
    <button
      className={btnClear ? "btnPrimary btnClear" : "btnPrimary btnApply"}
      onClick={onClick}
    >
      {label}
    </button>
  );
}

export default ThemeButton;

import React, { useEffect } from "react";

function AppAbout() {
  useEffect(() => {
    window.open("http://www.cyltag.in/");
  }, []);

  return <div></div>;
}

export default AppAbout;

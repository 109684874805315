import React, { useEffect, useState } from "react";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

function AssetWise_Compliance(data: any) {
  const [chartdata, setChartdata] = useState([]);

  useEffect(() => {
    console.log("AssetWise_Compliance", data);
    setChartdata(data.data);
  }, [data]);

  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    CanvasRenderer,
  ]);
  let option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      },
    },
    color: ["#374dc6"],
    legend: {
      show: false,
      bottom: 0,
      left: 0,
      textStyle: { color: "#374dc6", fontSize: 12 },
      pointerEvents: "none",
    },
    grid: {
      bottom: 35,
      left: 0,
      right: 15,
      top: 10,
      containLabel: true,
      Show: false,
    },
    xAxis: [
      {
        type: "category",
        data: chartdata.map((el: any) => el.name),
        axisLabel: {
          rotate: 45, // Rotate the labels
          interval: 0, // Show all labels
          fontSize: 10, // Set the font size for x-axis labels
          // Other positioning properties can be used here
          //alignWithLabel: true,
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
    ],

    // dataZoom: [
    //   {
    //     type: "slider",
    //     start: 60,
    //     end: 100,
    //     show: true,
    //     height: 15,
    //   },
    // ],
    series: [
      {
        name: "Compliance Percentage",
        type: "bar",
        data: chartdata.map((el: any) => el.compliancePercentage),
        label: {
          show: true, // This enables the value labels on top of the bars
          position: "top", // You can adjust the label position as needed
        },
      },
      // {
      //   name: "Below Threshold",
      //   type: "bar",
      //   data: chartdata.map((el: any) => el.ncBelowThreshold),
      // },
      // {
      //   name: "Non Compliance Percentage",
      //   type: "bar",
      //   data: chartdata.map((el: any) => el.nonCompliancePercentage),
      // },
      // {
      //   name: "Above Threshold",
      //   type: "bar",
      //   data: chartdata.map((el: any) => el.ncAboveThreshold),
      // },
    ],
  };
  return (
    <div style={{ width: "100%" }}>
      {chartdata && (
        <ReactEChartsCore
          echarts={echarts}
          option={option}
          notMerge={true}
          lazyUpdate={true}
          theme={"theme_name"}
        />
      )}
    </div>
  );
}

export default AssetWise_Compliance;

import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./ProfilePopUpScreen.scss";
import ImageComponent from "../../Utils/imageSelector/ImageSelectorParentComponent";
import users from "./ProfilePopupScreen.json";
import tempProfileIcon from "../../assets/icons/temp_profile_icon.png"

function ProfilePopUpScreen({ show, close }) {
  const [image, setImage] = useState<string | null>(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userName, setUserName] = useState("");
  const [externalId, setExternalId] = useState("");

  const fetchJson = () => {
    const data = users.users;
    setFirstName(data.firstName);
    setLastName(data.lastName);
    setEmail(data.email);
    setPhoneNumber(data.phoneNumber);
    setUserName(data.username);
    setExternalId(data.externalId);
    setImage(data.image);
  };
  useEffect(() => {
    fetchJson();
  }, []);
  return (
    <>
      {
        <Modal
          className="profileModalmainContainer"
          show={show}
          onHide={close}
          size="lg"
          backdrop="static"
        >
          <div className="profileModalmainContainerHeader">
            <h5 className="profileModalmainContainerHeader-title">
              Profile Image
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={close}
            ></button>
          </div>

          <Modal.Body className="profileModalmainContainerBody">
            <div className="container-div">
              <div className="userDetails_container">
                <div className="userDetailsContainer-form">
                  <p className="p_margin">First Name</p>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Rahul"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="userDetailsContainer-form">
                  <p className="p_margin">Last Name</p>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="B"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>

              <div className="userDetails_container">
                <div className="userDetailsContainer-form">
                  <p className="p_margin">Email</p>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="rajesh@email.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="userDetailsContainer-form">
                  <p className="p_margin">Phone Number</p>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="9876542345"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>

              <div className="userDetails_container">
                <div className="userDetailsContainer-form">
                  <p className="p_margin">Username</p>
                  <input
                    type="text"
                    className="form-control noneEditableInputText"
                    placeholder="rajesh@company"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    readOnly
                  />
                </div>
                <div className="userDetailsContainer-form">
                  <p className="p_margin">External ID</p>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="B1134"
                    value={externalId}
                    onChange={(e) => setExternalId(e.target.value)}
                  />
                </div>
              </div>
              <div className="profileImage_div">
                <ImageComponent
                  image={tempProfileIcon}
                  setImage={setImage}
                  heading="Profile Image"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="profileModalmainContainerFooter">
            <button className="button1" onClick={close}>
              Cancel
            </button>
            <button className="button2" onClick={close}>
              Update
            </button>
          </Modal.Footer>
        </Modal>
      }
    </>
  );
}

export default ProfilePopUpScreen;

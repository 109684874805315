import React, { useCallback, useState } from "react";
import "./TempInputFied.scss";
import { TempInputFiedType } from "../DataTypes";



const TempInputFied = ({title,field,abbreviation,activeInput, value,validationType, callBackFunction }: TempInputFiedType) => {
  const [valueTwoWarning, setValueWarning] = useState(false);
  const formatNumber = (val: any) => {
    if(val === "") callBackFunction("");
    const decimalPattern = /^-?\d+(\.\d{1,8})?$|^--$/;
    const isValidInput = decimalPattern.test(val);
    if (isValidInput) callBackFunction(val);
  };

  const validationCheck = (e:any) => {
    let checkInputString = e.toString();
    let checkInput = checkInputString.length;
    if(checkInput > 1 && checkInputString.endsWith("-")) callBackFunction(value);
    else if(validationType === "NUMBER_FLOAT") formatNumber(e);
    else {
      if(e === "") callBackFunction("")
      else if(/^-?\d+$/.test(e)) callBackFunction(Number(e));
      else callBackFunction(value)
    }
  }

  return (
    <div className="TempInputCardContainer">
      <h4 className="tempInputTitleText">{title}{field === "Y" ? " *" : ""}</h4>
      <div className="styleInp" onClick={activeInput} style={{borderColor: valueTwoWarning ? "#ff0303" : "#a7b8f4"}}>
        <input type='number' step={0.01} data-tip={title} data-for='name-tooltip' required value={value} onChange={(e) => validationCheck(e.target.value.trim())}
          className="inputTempTextValue" onBlur={() => setValueWarning(false)} />
          <p>{abbreviation}</p>
      </div>
    </div>
  );
};

export default TempInputFied;

import React from "react";
import {
  AssetDataStatus,
  FilterDataType,
  NewAssetData,
  SearchListType,
} from "../types";
import useState from "react-usestateref";

function AssetListState() {
  const [assetList, setAssetList] = useState<NewAssetData[]>([]);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showClearAll, setShowClearAll] = useState(false);
  const [disableApplyFilter, setDisableApplyFilter] = useState(true);
  const [loadMoreStatus, setLoadMorestatus] = useState(false);
  const [filterDataList, setFilterDataList, filterDataListRef] = useState<
    FilterDataType[]
  >([]);
  const [searchByList, setSearchByList, searchByListRef] = useState<
    SearchListType[]
  >([]);
  const [activeInput, setActiveInput] = React.useState<string>("");
  const [assetCountStatus, setAssetCountStatus] = useState({
    assetsLengthCount: 0,
    showAssetCount: false,
    totalAssetCount: 0,
  });
  const [scrollPosition, setScrollPosition] = useState(0);
  const [assetDataStatus, setAssetDataStatus] = useState<AssetDataStatus>({
    showLoading: false,
    showError: false,
    showEmptyData: false,
    showAsset: false,
    showLoadMoreButton: false,
  });

  return {
    assetList,
    setAssetList,
    showLoadMore,
    setShowLoadMore,
    showLoading,
    setShowLoading,
    showError,
    setShowError,
    showClearAll,
    setShowClearAll,
    disableApplyFilter,
    setDisableApplyFilter,
    loadMoreStatus,
    setLoadMorestatus,
    filterDataList,
    setFilterDataList,
    filterDataListRef,
    searchByList,
    setSearchByList,
    searchByListRef,
    activeInput,
    setActiveInput,
    assetCountStatus,
    setAssetCountStatus,
    scrollPosition,
    setScrollPosition,
    assetDataStatus,
    setAssetDataStatus,
  };
}

export default AssetListState;

import React, { useEffect, useState } from "react";
import "./IncidentLoadMoreCard.scss";
import IncidentSummaryCard from "../incidentSummaryCard/IncidentSummaryCard";
import moment from "moment";
import axios from "axios";
import DisplayNote from "../../../a1Components/DisplayNote/DisplayNote";
import { array } from "@amcharts/amcharts4/core";
import DisplayError from "../../../a1Components/DisplayError/DisplayError";

function IncidentLoadMoreCard(props: any) {
  const { dateRange } = props;
  const [listData, setResponseDetail] = useState([]);
  const [limit, setLimitFunc] = useState(5);
  const [loader, setLoader] = useState(false);
  const [dataLength, setDataLength] = useState(0);

  const getIncidentSummaryDetailsData = async () => {
    setLoader(true);
    try {
      const startDate = moment(dateRange[0]).utc().valueOf();
      const endDate = moment(dateRange[1]).utc().valueOf();
      const url = `https://hawkeye.aone.ai/analytics/videoAnalytics/incident/list/?start=${startDate}&end=${endDate}&page=${limit}&size=5&direction=1`;
      const response = await axios.get(url);
      if (response.status === 200) {
        setResponseDetail(response.data.data);
        setDataLength(response.data.data.length);
        setLoader(false);
      } else {
        setResponseDetail([]);
        setLoader(false);
      }
    } catch (error) {
      setResponseDetail([]);
      setLoader(false);
    }
  };

  useEffect(() => {
    getIncidentSummaryDetailsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, limit]);

  function setLimit() {
    if (limit <= dataLength) {
      setLimitFunc(limit + 5);
      if (limit > dataLength) {
        setLimitFunc(dataLength);
      }
    } else {
      setLimitFunc(dataLength);
    }
  }

  return (
    <>
      {loader ? (
        <DisplayNote />
      ) : (
        <>
          {Object.getOwnPropertyNames(listData).length === 0 ? (
            <DisplayError type="err_1" />
          ) : (
            <>
              {listData.length === 0 ? (
                <DisplayNote />
              ) : (
                <>
                  <div className="incidentLoadMoreCardContainer">
                    {/* <IncidentLoadMoreCardHeader /> */}
                    {listData.slice(0, limit).map((item) => (
                      <IncidentSummaryCard data={item} key={item.description} />
                    ))}

                    {limit !== dataLength && dataLength !== 0 && (
                      <div className="incidentLoadMoreBtnCont">
                        <button
                          className="incidentLoadMoreBtn"
                          onClick={setLimit}
                        >
                          Load more
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default IncidentLoadMoreCard;

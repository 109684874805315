/* eslint-disable no-const-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import logoIcon from "../../../assets/icons/Asset-Blue.png";
import closeIcon from "../../../assets/icons/IconClose.svg";
import EditIcon from "../../../assets/icons/Edit.svg";
import "./AddEditOrganisationModal.scss";
import ImageSelectorParentComponent from "../../../Utils/imageSelector/ImageSelectorParentComponent";
import FacilityAddressPopUpScreen from "../../FacilitySettings/facilitypopUpScreen/FacilityAddressPopUpScreen";
import IconAdd from "../../../assets/icons/IconAdd.svg";
import { SettingsApiServices } from "../../../services/settingsPageServices/SettingsApiServices";
import * as R from "ramda";
import PhoneNumberComponent from "../../phoneNumberComponent/PhoneNumberComponent";
import EmailInputComponent from "../../emailInputComponent/EmailInputComponent";
import { CreateOrgPayloadType } from "../Types/CreateOrgPayloadType";
import CustomSwitch from "../../Users/usersCardContainer/customSwitch/CustomSwitch";
import { API_BASE_URL } from "../../../Constants/constants";
import { toast } from "react-toastify";
import { FieldValidatorUtil } from "../../../Utils/fieldValidation/FieldValidatorUtil";
import { PartyContactMechPurpose } from "../Types/PartyGroupResponse";
import ReactTooltip from "react-tooltip";
import FormValidator from "../../../Utils/FormValidations/FormValidations";
function AddEditOrganisationModal({
  type,
  addEditModal,
  onConfirm,
  onClose,
  editPayloadData,
  refreshList,
}: // partyRefresh,
any) {
  const [orgPayloadData, setOrgPayloadData] = useState<CreateOrgPayloadType>({
    groupName: "",
    emailAddress: "",
    // emailPurpose: [],
    // phonePurpose: [],
    phoneNumberPrefix: "+91",
    phoneNumber: "",
    // addressPurpose: [],
    toName: "",
    attnName: "",
    address1: "",
    address2: "",
    city: "",
    countryGeoId: "",
    postalCode: "",
    partyIdFrom: localStorage.getItem("@partyID") ?? "",
    roleTypeIdTo: localStorage.getItem("@roleTypeID") ?? "",
    partyRelationshipTypeId: "",
    stateProvinceGeoId: "",
    // addressContactMechId: "",
    // emailContactMechId: "",
    // phoneContactMechId: "",
    // partyId: "",
    addressPurpose: "[PRIMARY_LOCATION]",
    emailPurpose: "[PRIMARY_EMAIL]",
    phonePurpose: "[PRIMARY_PHONE]",
  });

  const [invalidArr, setInvalidArr] = useState<string[]>([]);
  const [isDraggingEnabled, setIsDraggingEnab] = useState(false);

  const [orgNameError, setOrgNameError] = useState<string | null>(null);
  const [relationshipError, setRelationshipError] = useState<string | null>(
    null
  );
  const [emailError, setEmailError] = useState<string | null>(null);

  const setIsDraggingEnabled = () => {
    setIsDraggingEnab(!isDraggingEnabled);
  };

  const AddressDataUpdate = (e: any) => {
    let tempObj = { ...orgPayloadData, ...e };
    setOrgPayloadData(tempObj);
  };

  const onChangeAddress = (e: any, key: any) => {
    if (
      ((key == "phoneNumber" || key == "postalCode") &&
        /[a-zA-Z~`!@#$%^&*()+=\-[\]\\';,./{}|":<>?_]/.test(e.target.value)) ||
      e.target.value.replace(/\D/g, "").length > (key == "postalCode" ? 6 : 10)
    ) {
      return;
    }
    let tempObj = { ...orgPayloadData };
    // @ts-ignore
    tempObj[key] = e.target.value;
    //to remove "stateGeoCodeId":"IN-TN"const newObj = { ...orgPayloadData };
    // @ts-ignore
    delete tempObj["stateGeoCodeId"];
    setOrgPayloadData(tempObj);
  };

  let tokenId = localStorage.getItem("@tokenID");

  const myHeaders = new Headers();

  // @ts-ignore
  myHeaders.append("token", tokenId);
  myHeaders.append("Content-Type", "application/json");

  //ToDo move API Call to Services

  // if edit organisation
  useEffect(() => {
    if (editPayloadData) {
      orgPayloadData.groupName = editPayloadData.PartyGroupDetails[0].groupName;
      orgPayloadData.emailAddress =
        editPayloadData?.emailVM[0]?.contactMech.infoString;
      orgPayloadData.emailPurpose =
        editPayloadData.emailVM[0]?.partyContactMechPurposes?.map(
          (obj: PartyContactMechPurpose) => obj.contactMechPurposeTypeId
        );
      orgPayloadData.phoneNumber =
        editPayloadData.phoneVM[0]?.telecomNumber.contactNumber;
      orgPayloadData.phonePurpose =
        editPayloadData.phoneVM[0]?.partyContactMechPurposes?.map(
          (obj: PartyContactMechPurpose) => obj.contactMechPurposeTypeId
        );
      orgPayloadData.addressPurpose =
        editPayloadData.addressVM[0]?.partyContactMechPurposes?.map(
          (obj: PartyContactMechPurpose) => obj.contactMechPurposeTypeId
        );
      orgPayloadData.toName =
        editPayloadData.addressVM[0]?.postalAddress?.toName;
      orgPayloadData.attnName =
        editPayloadData.addressVM[0]?.postalAddress?.attnName;
      orgPayloadData.address1 =
        editPayloadData.addressVM[0]?.postalAddress?.address1;
      orgPayloadData.address2 =
        editPayloadData.addressVM[0]?.postalAddress?.address2;
      orgPayloadData.city = editPayloadData.addressVM[0]?.postalAddress?.city;
      orgPayloadData.countryGeoId =
        editPayloadData.addressVM[0]?.postalAddress?.countryGeoId;
      orgPayloadData.stateProvinceGeoId =
        editPayloadData.addressVM[0]?.postalAddress?.stateProvinceGeoId;
      orgPayloadData.postalCode =
        editPayloadData.addressVM[0]?.postalAddress?.postalCode;
      orgPayloadData.partyRelationshipTypeId =
        editPayloadData.PartyGroupDetails[0]?.partyRelationshipTypeId;
    }
  }, [editPayloadData]);

  useEffect(() => {
    if (type === "edit") {
      let tempObj = { ...orgPayloadData };
      tempObj.partyId =
        editPayloadData.PartyGroupDetails.length !== 0
          ? editPayloadData.PartyGroupDetails[0]?.partyId
          : "";
      tempObj.fromDate =
        editPayloadData.PartyGroupDetails.length !== 0
          ? editPayloadData.PartyGroupDetails[0]?.fromDate
          : "";
      tempObj.addressContactMechId =
        editPayloadData.addressVM.length !== 0
          ? editPayloadData.addressVM[0]?.contactMech?.contactMechId
          : "";
      tempObj.emailContactMechId =
        editPayloadData.emailVM.length !== 0
          ? editPayloadData.emailVM[0]?.contactMech?.contactMechId
          : "";
      tempObj.phoneContactMechId =
        editPayloadData.phoneVM.lenght !== 0
          ? editPayloadData.phoneVM[0]?.contactMech?.contactMechId
          : "";
      setOrgPayloadData(tempObj);
    }
  }, []);

  const handleConfirm = () => {
    checkName("validateOrgName", orgPayloadData.groupName);
    checkName("validateRelationShip", orgPayloadData?.partyRelationshipTypeId);
    checkName("validateEmail", orgPayloadData?.emailAddress);
    checkName("validateCity", orgPayloadData?.city);
    checkName("validatePhoneNumber", orgPayloadData?.phoneNumber);

    if (invalidArr.length > 0) {
      toast.error("Please fill in all the mandatory fields.");
      return;
    }

    fetch(`${API_BASE_URL}partyGroup`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(orgPayloadData),
    })
      .then((response) => {
        const statusCode = response.status;
        return response.text().then((result) => ({ statusCode, result }));
      })
      .then(({ statusCode, result }) => {
        if (statusCode === 200) {
          toast.success("Organization created successfully!");
          handleClose();
          refreshList();
        } else {
          toast.error("Request Failed.");
        }
      })
      .catch((error) => {
        toast("API Failed");
      });
  };

  // function handleConfirm1() {
  //   if (
  //     validateGroupName() &&
  //     validateRelationShipToCompany() &&
  //     validateEmail()
  //   ) {
  //     if (onConfirm) {
  //       fetch(`${API_BASE_URL}partyGroup`, {
  //         method: "POST",
  //         headers: myHeaders,
  //         body: JSON.stringify(orgPayloadData),
  //       })
  //         .then((response) => {
  //           const statusCode = response.status;
  //           return response.text().then((result) => ({ statusCode, result }));
  //         })
  //         .then(({ statusCode, result }) => {
  //           if (statusCode === 200) {
  //             toast.success("Organization created successfully!");
  //             refreshList();
  //           } else {
  //             toast.error("Request Failed.");
  //           }
  //         })
  //         .catch((error) => {
  //           toast("API Failed");
  //         })
  //         .finally(() => {
  //           handleClose();
  //         });
  //     }
  //   } else {
  //     toast.error("Please fill in all the mandatory fields.");
  //   }
  // }

  const handleUpdate = async () => {
    // if (
    //   validateGroupName() &&
    //   validateRelationShipToCompany() &&
    //   validateEmail()
    // ) {

    checkName("validateOrgName", orgPayloadData.groupName);
    checkName("validateRelationShip", orgPayloadData?.partyRelationshipTypeId);
    checkName("validateEmail", orgPayloadData?.emailAddress);
    checkName("validateCity", orgPayloadData?.city);
    checkName("validatePhoneNumber", orgPayloadData?.phoneNumber);

    if (invalidArr.length > 0) {
      toast.error("Please fill in all the mandatory fields.");
      return;
    }

    let modifiedObj = { ...orgPayloadData };
    modifiedObj.addressPurpose = JSON.stringify(orgPayloadData.addressPurpose)
      .replace(/"/g, "")
      .replace(/,/g, ", ");

    modifiedObj.emailPurpose = JSON.stringify(orgPayloadData.emailPurpose)
      .replace(/"/g, "")
      .replace(/,/g, ", ");

    modifiedObj.phonePurpose = JSON.stringify(orgPayloadData.phonePurpose)
      .replace(/"/g, "")
      .replace(/,/g, ", ");

    let finalResp = await fetch(`${API_BASE_URL}partyGroup/updatePartyGroup`, {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(modifiedObj),
    });
    if (finalResp.status === 200) {
      refreshList();
      handleClose();
      toast.success("Organization updated successfully!");
    } else {
      toast.error("Update Failed.");
      // handleClose();
    }
    // } else {
    //   toast.error("Please fill in all the mandatory fields.");
    // }
  };

  function handleClose() {
    if (!isEditOrg()) {
      setInvalidArr([]);
      setOrgPayloadData({
        groupName: "",
        emailAddress: "",
        // emailPurpose: [],
        // phonePurpose: [],
        phoneNumberPrefix: "+91",
        phoneNumber: "",
        // addressPurpose: [],
        toName: "",
        attnName: "",
        address1: "",
        address2: "",
        city: "",
        countryGeoId: "",
        postalCode: "",
        stateProvinceGeoId: "",
        partyIdFrom: localStorage.getItem("@partyID") ?? "",
        roleTypeIdTo: localStorage.getItem("@roleTypeID") ?? "",
        partyRelationshipTypeId: "",
        // "partyIdFrom": "Company",
        // "roleTypeId": "PARTNER",
        addressContactMechId: "",
        emailContactMechId: "",
        phoneContactMechId: "",
        partyId: "",
        addressPurpose: "[PRIMARY_LOCATION]",
        emailPurpose: "[PRIMARY_EMAIL]",
        phonePurpose: "[PRIMARY_PHONE]",
      });
    }
    if (onClose) {
      onClose();
    }
  }

  const [image, setImage] = useState<string | null>(null);

  const isEditOrg = () => {
    return type == "edit";
  };

  const [addressPopUpScreen, setAddressPopUpScreen] = useState(false);

  const onAddressPopUpClose = () => {
    setAddressPopUpScreen(false);
  };

  const [facilityStatistics, setFacilityStatistics] = useState({
    EmailPuprposeList: [],
    PhonePurposeList: [],
    facilityTypesList: [],
    AreaMeasures: [],
    CountrysList: [],
    ContactPurposeList: [],
    PartyRelationshipTypeList: [],
  });

  async function FacilityStatistics() {
    let tokenId = localStorage.getItem("@tokenID");
    const fetchData = new SettingsApiServices();
    const data = await fetchData.FacilityStatistics(tokenId);
    if (!R.isEmpty(data)) {
      setFacilityStatistics({
        ...facilityStatistics,
        facilityTypesList: data?.facilityTypes,
        EmailPuprposeList: data?.contactPurposes?.EMAIL_ADDRESS,
        PhonePurposeList: data?.contactPurposes?.TELECOM_NUMBER,
        AreaMeasures: data?.unitsOfMeasures?.AREA_MEASURE,
        CountrysList: data?.countries,
        ContactPurposeList: data?.contactPurposes?.WEB_ADDRESS,
        PartyRelationshipTypeList: data?.partyRelationshipTypes,
      });
    } else {
    }
  }

  const updatePurposeList = (keyId: any, dataList: any) => {
    setOrgPayloadData({
      ...orgPayloadData,
      [keyId]: "[" + dataList.join(", ") + "]",
    });
    console.log(dataList, "@dataList");
  };

  useEffect(() => {
    FacilityStatistics();
  }, []);

  // @ts-ignore

  const validate = new FieldValidatorUtil();
  const checkName = (name: string, val: string) => {
    const validArr = [
      "validateOrgName",
      "validateRelationShip",
      "validateEmail",
      "validateCity",
      "validatePhoneNumber",
    ];

    if (!validArr.includes(name)) {
      return;
    }

    let isValid = false;

    if (name === "validateOrgName") {
      isValid = validate.validateOrgDetail(val);
    } else if (name === "validateEmail") {
      isValid = validate.validateOrgEmail(val);
    } else if (name === "validatePhoneNumber") {
      isValid = validate.isValidPhoneNumber(val);
    } else {
      isValid = validate.isValidAssetData(val);
    }

    setInvalidArr((prevInvalidArr) => {
      if (isValid) {
        return prevInvalidArr.filter((obj) => obj !== name);
      } else {
        if (!invalidArr.includes(name)) {
          return [...prevInvalidArr, name];
        } else {
          return [...prevInvalidArr];
        }
      }
    });
  };

  return (
    <Modal
      show={addEditModal}
      //      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
      scrollable={true}
      style={{ zIndex: 1050 }} //ToDo
      // onHide={setOrgDetailsModal(false)}
      className="addEditOrgModal"
      backdrop="static"
    >
      <Modal.Header className="noBorderNoMargin">
        <div className="addOrgCardMiddle">
          <div className="orgMiddleContent">
            <h6 className="orgNameDetails">
              {isEditOrg() ? "Edit Organisation" : "Create new organisation"}
            </h6>
          </div>

          <div className="popUpCloseButton" onClick={handleClose}>
            <img className="closeIcon" src={closeIcon} alt="close" />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="addEditOrgBody">
          <div className="addEditOrgParentRow">
            <div className="addEditOrgRow addEditOrgRowLeft">
              <h6 className="addEditOrgKey">Name *</h6>
              <input
                type="text"
                value={orgPayloadData.groupName}
                required
                onChange={(e) =>
                  setOrgPayloadData({
                    ...orgPayloadData,
                    groupName: e.target.value,
                  })
                }
                // onBlur={validateGroupName}
                onBlur={() =>
                  checkName("validateOrgName", orgPayloadData.groupName)
                }
                className="addEditOrgInput"
                style={{
                  border: `${
                    invalidArr.includes("validateOrgName")
                      ? "1px solid red"
                      : ""
                  }`,
                }}
                data-tip="Name can only contain letters (A-Z, a-z) and following special characters: ' and whitespace"
                data-for="name-tooltip"
              />
              <ReactTooltip
                id="name-tooltip"
                effect="solid"
                place="bottom"
                className="tooltip"
              />

              {invalidArr.includes("validateOrgName") && (
                <p className="invalid-feedback inputFieldError">
                  {new FormValidator().validateValue(
                    orgPayloadData.groupName,
                    /^[A-Za-z ]+$/,
                    100
                  )}
                </p>
              )}
            </div>
            <div className="addEditOrgRow">
              <h6 className="addEditOrgKey">Relationship to Company *</h6>
              {/*<input*/}
              {/*    type="text"*/}
              {/*    value={orgPayloadData.partyRelationshipTypeId}*/}
              {/*    onChange={(e) => setOrgPayloadData(*/}
              {/*        {...orgPayloadData, partyRelationshipTypeId: e.target.value})*/}
              {/*    }*/}
              {/*    className="addEditOrgInput"*/}
              {/*/>*/}

              <select
                defaultValue=""
                value={orgPayloadData?.partyRelationshipTypeId}
                onChange={(e) => onChangeAddress(e, "partyRelationshipTypeId")}
                className="addEditOrgInput"
                style={{
                  border: `${
                    invalidArr.includes("validateRelationShip")
                      ? "1px solid red"
                      : ""
                  }`,
                }}
                // onBlur={validateRelationShipToCompany}
                onBlur={() =>
                  checkName(
                    "validateRelationShip",
                    orgPayloadData?.partyRelationshipTypeId
                  )
                }
              >
                <option value="" disabled selected className="greyedOut">
                  Select Relationship
                </option>
                {facilityStatistics?.PartyRelationshipTypeList?.map(
                  (obj, i) => {
                    return (
                      <option
                        key={i}
                        // onClick={() => alert()}
                        // @ts-ignore
                        value={obj?.partyRelationshipTypeId}
                      >
                        {
                          // @ts-ignore
                          obj?.partyRelationshipName
                        }
                      </option>
                    );
                  }
                )}
              </select>

              {invalidArr.includes("validateRelationShip") && (
                <p className="invalid-feedback inputFieldError">
                  This field is required*
                </p>
              )}
            </div>
          </div>

          <div className="addEditOrgParentRow">
            <div className="addEditOrgRow addEditOrgRowLeft">
              <EmailInputComponent
                onChangeAddress={onChangeAddress}
                emailAddress={orgPayloadData?.emailAddress}
                popUpScreenData={facilityStatistics?.EmailPuprposeList}
                updataDataListPurposes={updatePurposeList}
                emailError={emailError}
                invalidArr={invalidArr}
                setInvalidArr={setInvalidArr}
              />
            </div>
            <div className="addEditOrgRow">
              <h6 className="addEditOrgKey">Address</h6>
              <div
                className="flexDiv addEditOrgInput cursorPointer"
                style={{
                  border: `${
                    invalidArr.includes("validateCity") ? "1px solid red" : ""
                  }`,
                }}
                // onClick={() => setAddressPopUpScreen(true)}
              >
                <input
                  value={
                    orgPayloadData?.city + " " + orgPayloadData?.postalCode
                  }
                  type="text"
                  readOnly
                  placeholder={orgPayloadData ? "" : "Add address"} //ToDo
                  className="noBorderNoMargin width86 marginLeft4"
                  onBlur={() =>
                    checkName(
                      "validateCity",
                      orgPayloadData?.partyRelationshipTypeId
                    )
                  }
                />
                <img
                  src={isEditOrg() ? EditIcon : IconAdd}
                  alt="icon"
                  className="iconAdd"
                  onClick={() => setAddressPopUpScreen(true)}
                />
                {invalidArr.includes("validateCity") && (
                  <p className="invalid-feedback inputFieldError">
                    This field is required*
                  </p>
                )}
              </div>
              {addressPopUpScreen ? (
                <FacilityAddressPopUpScreen
                  updataData={AddressDataUpdate}
                  propsData={orgPayloadData}
                  type={type}
                  closeFacilityAddressPopUpScreen={onAddressPopUpClose}
                  countryListData={facilityStatistics.CountrysList}
                  contactPurposeListData={facilityStatistics.ContactPurposeList}
                />
              ) : null}
            </div>
          </div>

          <div className="addEditOrgParentRow">
            <div className="addEditOrgRow addEditOrgRowLeft">
              {/*<h6 className="addEditOrgKey">Phone Number</h6>*/}
              {/*<input*/}
              {/*    // value={addressObject.facilityName}*/}
              {/*    type="text"*/}
              {/*    // onChange={(e) => onChangeAddress(e, "facilityName")}*/}
              {/*    className="addEditOrgInput"*/}
              {/*/>*/}

              <PhoneNumberComponent
                onChangeAddress={onChangeAddress}
                phoneNumber={orgPayloadData?.phoneNumber}
                popUpScreenData={facilityStatistics?.PhonePurposeList}
                updataDataListPurposes={updatePurposeList}
                invalidArr={invalidArr}
                setInvalidArr={setInvalidArr}
              />
            </div>
          </div>

          {!isEditOrg() && (
            <>
              <div className="viewOrgDetailsParentRow">
                <div className="infoHeaderBorder">
                  <h6 className="adminInfoHeader marginBottom0px">
                    Admin Information
                  </h6>
                  <div className="dividerLineBlack marginBottom20px adminInfoDivider"></div>
                </div>
              </div>

              <div className="addEditOrgParentRow">
                <div className="addEditOrgRow addEditOrgRowLeft">
                  <h6 className="addEditOrgKey">UserName *</h6>
                  <input
                    // value={addressObject.facilityName}
                    type="text"
                    // onChange={(e) => onChangeAddress(e, "facilityName")}
                    className="addEditOrgInput"
                  />
                </div>
              </div>

              <div className="addEditOrgParentRow">
                <div className="addEditOrgRow addEditOrgRowLeft">
                  <h6 className="addEditOrgKey">Email *</h6>
                  <input
                    // value={addressObject.facilityName}
                    type="text"
                    // onChange={(e) => onChangeAddress(e, "facilityName")}
                    className="addEditOrgInput"
                  />
                </div>

                <div className="addEditOrgRow">
                  <h6 className="addEditOrgKey">Phone</h6>
                  <input
                    // value={addressObject.facilityName}
                    type="text"
                    // onChange={(e) => onChangeAddress(e, "facilityName")}
                    className="addEditOrgInput"
                  />
                </div>
              </div>
            </>
          )}

          <div>
            <ImageSelectorParentComponent
              image={logoIcon}
              setImage={setImage}
              heading="Organisation Logo"
            />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="noBorderNoMargin addEditFooter">
        <div className="createOrganisationPopFooter">
          {isEditOrg() && (
            <div className="editSwitchCont">
              <CustomSwitch
                callBack={setIsDraggingEnabled}
                check={isDraggingEnabled}
              />
              <p>{isDraggingEnabled ? "Disabled" : "Enabled"}</p>
            </div>
          )}
          <div className="createOrganisationFooterBtns">
            <button onClick={handleClose} className="secondaryButton">
              Cancel
            </button>
            <button
              onClick={isEditOrg() ? handleUpdate : handleConfirm}
              className="buttonPrimary"
            >
              {isEditOrg() ? "Update" : "Create Organisation"}
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );

  function validateGroupName(): boolean {
    if (!orgPayloadData.groupName) {
      setOrgNameError("Organisation Name is mandatory.");
      return false;
    } else if (!FieldValidatorUtil.isAlphabetical(orgPayloadData.groupName)) {
      setOrgNameError("Please enter a valid organisation name.");
      return false;
    } else {
      setOrgNameError(null);
      return true;
    }
  }

  function validateRelationShipToCompany(): boolean {
    // if(!orgPayloadData.partyRelationshipTypeId){
    //   setRelationshipError("Relationship to company is mandatory.")
    //   return false
    // } else
    if (orgPayloadData.partyRelationshipTypeId.trim() == "") {
      setRelationshipError("Relationship to company is mandatory.");
      return false;
    } else {
      setRelationshipError(null);
      return true;
    }
  }

  function validateEmail(): boolean {
    if (
      !orgPayloadData?.emailAddress ||
      orgPayloadData?.emailAddress.trim() == ""
    ) {
      setEmailError("Email is mandatory.");
      return false;
    } else if (!FieldValidatorUtil.isValidEmail(orgPayloadData?.emailAddress)) {
      setEmailError("Please enter a valid email.");
      return false;
    } else {
      setEmailError(null);
      return true;
    }
  }
}

export default AddEditOrganisationModal;

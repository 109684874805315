// utils/FormValidator.js
class FormValidator {
  // Phone number Validator.
  validateMobileNumber(phoneNumber) {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  }

  // Email Validation.
  validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email) && email.length <= 255;
  }
  // Facility Name.
  validateFacilityName(facilityName) {
    const nameRegex = /^[A-Za-z-\s'_#]+$/;
    return nameRegex.test(facilityName) && facilityName.length <= 100;
  }

  externalId(val) {
    return /^[a-zA-Z0-9']{1,255}$/.test(val);
  }

  validateToName(val) {
    const nameRegex = /^[A-Za-z ']+$/;
    return nameRegex.test(val) && val.length <= 100;
  }
  

  //Validate Decimal Values
  validateFloatValue(value) {
    const floatRegex = /^\d{1,18}\.\d{0,1}$/;
    return floatRegex.test(value);
  }

  //Checks Mandatory fields from object
  checkMandatoryFields(obj, fields) {
    return fields.every((field) => obj[field] && obj[field].trim() !== "");
  }

  address1(value) {
    const addressRegex = /^[\w\s,#-]+$/;
    return addressRegex.test(value) && value.length <= 255;
  }

  city(val) {
    const cityRegex = /^[A-Za-z ']+$/;
    return cityRegex.test(val) && val.length <= 100;
  }

  // Validation Att Name
  //   validateAttName(attnName) {
  //     const attNameRegex = /^[A-Za-z]+$/;
  //     return attNameRegex.test(attnName) && attnName.length <= 100;
  //   }

  // Validation Postal Code
  validatePostalCode(postalCode) {
    const postalCodeRegex = /^\d{6}$/;
    return postalCodeRegex.test(postalCode);
  }

  //Convert API response to Custom structure...

  convertFacilityGroupCustomStructure(jsonData) {
    // Convert the JSON object to the desired array structure
    const convertedArray = [];

    jsonData.regionIds.forEach((region) => {
      const regionObj = {
        id: region.facilityGroupId,
        type: "region",
        name: region.facilityGroupName,
        cities: null,
        fromDate: region.fromDate,
        facilities: null,
        users: [],
        citiesList: [],
      };

      const citiesList = jsonData.cityIds
        .filter((city) => city.parentFacilityGroupId === region.facilityGroupId)
        .map((city) => {
          const cityObj = {
            id: city.facilityGroupId,
            type: "city",
            name: city.facilityGroupName,
            facilities: null,
            fromDate: city.fromDate,
            users: [],
            clustersList: [],
          };

          const clusterList = jsonData.clusterIds
            .filter(
              (cluster) =>
                cluster.parentFacilityGroupId === city.facilityGroupId
            )
            .map((cluster) => {
              const clusterObj = {
                id: cluster.facilityGroupId,
                type: "cluster",
                name: cluster.facilityGroupName,
                facilities: null,
                users: [],
                fromDate: cluster.fromDate,
                facilityList: [],
              };

              const facilityList = jsonData.facilityIds
                .filter(
                  (facility) =>
                    facility.parentFacilityGroupId === cluster.facilityGroupId
                )
                .map((facility) => {
                  const matchingFacility = jsonData.facilityIds.find(
                    (f) => f.facilityId === facility.facilityId
                  );

                  const facilityObj = {
                    id: facility.facilityId,
                    type: "facility",
                    fromDate: facility.fromDate,
                    name: matchingFacility
                      ? matchingFacility.facilityName
                      : null,
                    users: [],
                  };

                  return facilityObj;
                });

              clusterObj.facilities = facilityList.length;
              clusterObj.facilityList = facilityList;

              return clusterObj;
            });

          cityObj.facilities = clusterList.reduce(
            (total, cluster) => total + cluster.facilities,
            0
          );
          cityObj.clustersList = clusterList;

          return cityObj;
        });

      regionObj.cities = citiesList.length;
      regionObj.facilities = citiesList.reduce(
        (total, city) => total + city.facilities,
        0
      );
      regionObj.citiesList = citiesList;

      convertedArray.push(regionObj);
    });

    // console.log(JSON.stringify(convertedArray, null, 2), 'formatted7878');
    return convertedArray;
  }

  // convertFacilityGroupCustomStructure(jsonData) {
  //   // Convert the JSON object to the desired array structure
  //   const convertedArray = [];

  //   if (jsonData.regionIds.length === 0) {
  //     const citiesList = jsonData.cityIds.map(city => {
  //       const cityObj = {
  //         id: city.facilityGroupId,
  //         type: "city",
  //         name: city.facilityGroupName,
  //         facilities: null,
  //         users: [],
  //         clustersList: []
  //       };

  //       const clusterList = jsonData.clusterIds.filter(cluster => cluster.parentFacilityGroupId === city.facilityGroupId).map(cluster => {
  //         const clusterObj = {
  //           id: cluster.facilityGroupId,
  //           type: "cluster",
  //           name: cluster.facilityGroupName,
  //           facilities: null,
  //           users: [],
  //           facilityList: []
  //         };

  //         const facilityList = jsonData.facilityIds.filter(facility => facility.parentFacilityGroupId === cluster.facilityGroupId).map(facility => {
  //           const matchingFacility = jsonData.facilityIds.find(f => f.facilityId === facility.facilityId);

  //           const facilityObj = {
  //             id: facility.facilityId,
  //             type: "facility",
  //             name: matchingFacility ? matchingFacility.facilityName : null,
  //             users: []
  //           };

  //           return facilityObj;
  //         });

  //         clusterObj.facilities = facilityList.length;
  //         clusterObj.facilityList = facilityList;

  //         return clusterObj;
  //       });

  //       cityObj.facilities = clusterList.reduce((total, cluster) => total + cluster.facilities, 0);
  //       cityObj.clustersList = clusterList;

  //       return cityObj;
  //     });

  //     convertedArray.push(...citiesList);
  //   } else {
  //     jsonData.regionIds.forEach(region => {
  //       const regionObj = {
  //         id: region.facilityGroupId,
  //         type: "region",
  //         name: region.facilityGroupName,
  //         cities: null,
  //         facilities: null,
  //         users: [],
  //         citiesList: []
  //       };

  //       const citiesList = jsonData.cityIds.filter(city => city.parentFacilityGroupId === region.facilityGroupId).map(city => {
  //         const cityObj = {
  //           id: city.facilityGroupId,
  //           type: "city",
  //           name: city.facilityGroupName,
  //           facilities: null,
  //           users: [],
  //           clustersList: []
  //         };

  //         const clusterList = jsonData.clusterIds.filter(cluster => cluster.parentFacilityGroupId === city.facilityGroupId).map(cluster => {
  //           const clusterObj = {
  //             id: cluster.facilityGroupId,
  //             type: "cluster",
  //             name: cluster.facilityGroupName,
  //             facilities: null,
  //             users: [],
  //             facilityList: []
  //           };

  //           const facilityList = jsonData.facilityIds.filter(facility => facility.parentFacilityGroupId === cluster.facilityGroupId).map(facility => {
  //             const matchingFacility = jsonData.facilityIds.find(f => f.facilityId === facility.facilityId);

  //             const facilityObj = {
  //               id: facility.facilityId,
  //               type: "facility",
  //               name: matchingFacility ? matchingFacility.facilityName : null,
  //               users: []
  //             };

  //             return facilityObj;
  //           });

  //           clusterObj.facilities = facilityList.length;
  //           clusterObj.facilityList = facilityList;

  //           return clusterObj;
  //         });

  //         cityObj.facilities = clusterList.reduce((total, cluster) => total + cluster.facilities, 0);
  //         cityObj.clustersList = clusterList;

  //         return cityObj;
  //       });

  //       regionObj.cities = citiesList.length;
  //       regionObj.facilities = citiesList.reduce((total, city) => total + city.facilities, 0);
  //       regionObj.citiesList = citiesList;

  //       convertedArray.push(regionObj);
  //     });
  //   }

  //   console.log(JSON.stringify(convertedArray, null, 2), 'formatted7878');
  //   return convertedArray;
  // }

  getOnlyIdsofEachGroup = (data) => {
    const regionIds = [];
    const cityIds = [];
    const clusterIds = [];

    data.forEach((region) => {
      regionIds.push(region.id); // Add region ID to regionIds array
      region.citiesList.forEach((city) => {
        cityIds.push(city.id); // Add city ID to cityIds array
        city.clustersList.forEach((cluster) => {
          clusterIds.push(cluster.id); // Add cluster ID to clusterIds array
        });
      });
    });

    let allGroupIDs = [...regionIds, ...cityIds, ...clusterIds];
    return allGroupIDs;
  };

  validateValue(value, regex, maxLength) {
    if (!value) {
      return "This field is mandatory.";
    }

    if (!regex.test(value)) {
      return "Field contains invalid values.";
    }

    if (value.length > maxLength) {
      return "Max character length exceeded.";
    }

    return "";
  }

  validateMobileNumberValue(value, regex, maxLength) {
    if (!value) {
      return "This field is mandatory.";
    }

    if (!regex.test(value)) {
      return "Field contains invalid values.";
    }

    if (value.length != maxLength) {
      return "Enter valid phone number.";
    }

    return "";
  }
}

export default FormValidator;

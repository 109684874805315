/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";

import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";

import { HeatmapChart } from "echarts/charts";

import "./HeatMap.scss";

import {
  TooltipComponent,
  TitleComponent,
  CalendarComponent,
  VisualMapComponent,
} from "echarts/components";

import { CanvasRenderer } from "echarts/renderers";
import { HeatMapType } from "../type.tsx";

type PropTypes = {
  description: string[];
  chartData: HeatMapType;
};

function Heatmap({ description, chartData }: PropTypes) {
  echarts.use([
    TitleComponent,
    CalendarComponent,
    TooltipComponent,
    VisualMapComponent,
    HeatmapChart,
    CanvasRenderer,
  ]);

  const hours = ["Average", "Minimum", "Maximum", "Median"];

  const option = {
    tooltip: {
      position: "top",
      formatter: (params) => {
        let val = params.value[2];
        const timeValue = val.toString();
        if (timeValue.length >= 4) {
          const hours = timeValue.substring(0, 2);
          const minutes = timeValue.substring(2);
          return `${hours}:${minutes} min`;
        }
        return timeValue;
      },
    },
    grid: {
      top: "10%",
      left: "30%",
      height: "75%",
    },
    xAxis: {
      type: "category",
      position: "top",
      data: hours,
      splitArea: {
        show: true,
      },
      axisLabel: {
        color: '#000000',
        fontWeight: '400',
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: "category",
      data: description.map((value) => {
        return value;
      }),
      axisLabel: {
        align: "right",
        fontSize: "11px",
        color: '#000000',
        fontWeight: '500',
      },
      splitArea: {
        show: true,
      },
      splitLine: {
        show: false,
      },
    },
    visualMap: {
      min: 1000,
      max: 2200,
      calculable: true,
      orient: "horizontal",
      left: "center",
      top: "85%",
      hoverLink: false,
      formatter: function (value) {
        if (value === 1000) {
          return "Low";
        } else if (value === 2200) {
          return "High";
        } else {
          return parseFloat(value).toFixed(2);
        }
      },
      inRange: {
        color: [
          "#79B8F6",
          "#83A8E5",
          "#AD79B1",
          "#D94577",
          "#E1285A",
          "#FD1A48",
        ],
      },
    },
    series: [
      {
        name: "Event Duration",
        type: "heatmap",
        data: chartData.map((item) => [item[0], item[1], item[2]]),
        label: {
          show: true,
          formatter: (params) => {
            let val = params.value[2];
            const timeValue = val.toString();
            if (timeValue.length >= 4) {
              const hours = timeValue.substring(0, 2);
              const minutes = timeValue.substring(2);
              return `${hours}:${minutes} min`;
            }
            return timeValue;
          },
        },
        itemStyle: {
          borderWidth: 2,
          borderColor: 'rgba(255, 255, 255, 1)',
          borderRadius: 5,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  return (
    <div className="heatMapContainer">
      <div style={{}}>
        <ReactEChartsCore
          echarts={echarts}
          option={option}
          notMerge={true}
          lazyUpdate={true}
          theme={"theme_name"}
        />
      </div>
    </div>
  );
}

export default Heatmap;

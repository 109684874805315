/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "./FacilityGroupsContainer.scss";
import FacilityDropCards from "./facilityDropCards/FacilityDropCards";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";

import IconWarning from "../../../assets/icons/IconWarning.svg";
import IconClose from "../../../assets/icons/IconClose.svg";
import deleteIcon from "../../../assets/icons/addFacDeleteIcon.png";
import AddIcon from "../../../assets/icons/AddIcon.svg";
import ChipCloseIcon from "../../../assets/icons/ChipCloseIcon.svg";
import ActiveChipCloseIcon from "../../../assets/icons/ActiveChipCloseIcon.svg";
import LoaderEffect from "../../../a1Components/LoaderEffects/LoaderEffect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormValidator from "../../../Utils/FormValidations/FormValidations";

function FacilityGroupsContainer(props: any) {
  const {
    showAllGroups,
    formattedStructureFgroupsList,
    faciltGroupsRawData,
    facilityListForSubGroup,
    userRolesList,
    facilityGroupUsersList,
    facilityGroupAPIsNeedTocall,
  } = props;
  const facilityGroupsDummyjson = formattedStructureFgroupsList;
  // localStorage.setItem("activefacilityGroupId", []);

  let initialState = localStorage.getItem('activefacilityGroupId') !== null ? JSON.parse(localStorage.getItem('activefacilityGroupId')) : []

  const [activeList, setFacility] = useState(initialState);

  // Collapsing All Active group List...
  useEffect(() => {
    if (!showAllGroups.isShowAll && showAllGroups.number !== 123) {
      setFacility([])
      activeId.current = [];
      localStorage.setItem("activefacilityGroupId", JSON.stringify(activeId.current));
    } else {
      if(showAllGroups.number !== 123){
        let allIdArr = new FormValidator().getOnlyIdsofEachGroup(
          formattedStructureFgroupsList
        );
        allIdArr.push("company");
        localStorage.setItem("activefacilityGroupId", allIdArr);
        setFacility(allIdArr);
        activeId.current = allIdArr;
        localStorage.setItem("activefacilityGroupId", JSON.stringify(activeId.current));
      }
    }
    // console.log(JSON.stringify(formattedStructureFgroupsList,null,2),'formatted890')
    console.log(JSON.parse(localStorage.getItem('activefacilityGroupId')), 'checkActive ID')

  }, [showAllGroups.number])

  const activeId = useRef(initialState);

  const showAndHideFunction = (type, activeName) => {
    if (!activeList.includes(activeName)) {
      let newArr = activeId.current;
      newArr.push(activeName)
      activeId.current = newArr;

      setFacility((item) => [...item, activeName]);
      localStorage.setItem("activefacilityGroupId", JSON.stringify(activeId.current));
    } else {
      let newArr = activeId.current;
      newArr = newArr.filter((item) => item !== activeName);
      activeId.current = newArr;
      setFacility(activeList.filter((item) => item !== activeName));
      localStorage.setItem("activefacilityGroupId", JSON.stringify(activeId.current));
    }
    console.log(JSON.parse(localStorage.getItem('activefacilityGroupId')), 'checkActive ID')
  };
  const [isDraggingEnabled, setIsDraggingEnab] = useState(false);
  const [isDraggingEnabledCity, setIsDraggingEnabCity] = useState(false);
  const [isDraggingEnabledFaci, setIsDraggingEnabFaci] = useState(false);

  const setIsDraggingEnabled = (type: any) => {
    if (type == null) {
      setIsDraggingEnab(false);
      setIsDraggingEnabCity(false);
      setIsDraggingEnabFaci(false);
      return;
    }
    if (type == "region") {
      setIsDraggingEnab(true);
      return;
    }
    if (type == "city") {
      setIsDraggingEnabCity(true);
      return;
    }
    setIsDraggingEnabFaci(true);
  };
  // const [regionOrder, setRegionOrder] = useState(facilityGroupsDummyjson);
  const [showConfirm, setConfirmationPopup] = useState(false);
  const handleDragEnd = (result: DropResult) => {
    setIsDraggingEnab(false);
    setIsDraggingEnabCity(false);
    setIsDraggingEnabFaci(false);

    setConfirmationPopup(true);
    const { source, destination } = result;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;
  };

  // Add Users Popup..
  function generateID(length: any) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const [addUserPopup, setAddUserPopup1] = useState(false);
  const activeCardRef = useRef("");
  const setAddUserPopup = (id) => {
    setMapUsertoFaclityroupsArr([
      {
        id: generateID(4),
        facilityList: facilityGroupUsersList,
        rolesList: userRolesList,
      },
    ]);
    activeCardRef.current = id;
    setAddUserPopup1(true);
    setSelectedUsers([]);
  };

  // Add Subgroups Popup
  const [addSubGroups, setAddSubGroups1] = useState(false);

  // find existing subgroup List...
  const findTypeAndNameById = (array, id) => {
    for (let obj of array) {
      if (obj.id === id) {
        return {
          type: obj.type,
          name: obj.citiesList.map((obj, index) => {
            return {
              ...obj,
              name: obj.name == null ? obj.type + (index + 1) : obj.name,
            };
          }),
        };
      }
      if (obj.citiesList) {
        for (let city of obj.citiesList) {
          if (city.id === id) {
            return {
              type: city.type,
              name: city.clustersList.map((obj, index) => {
                return {
                  ...obj,
                  name: obj.name == null ? obj.type + (index + 1) : obj.name,
                };
              }),
            };
          }
          if (city.clustersList) {
            for (let cluster of city.clustersList) {
              if (cluster.id === id) {
                return {
                  type: cluster.type,
                  name: cluster.facilityList.map((obj, index) => {
                    return {
                      ...obj,
                      name:
                        obj.name == null ? obj.type + (index + 1) : obj.name,
                    };
                  }),
                };
              }
            }
          }
        }
      }
    }

    return null; // Return null if the id is not found
  };
  const [activeGroupID, setActiveGroupID] = useState("");
  const existedSubGroups = useRef([]);
  const setAddSubGroups = (id) => {
    let chips = findTypeAndNameById(facilityGroupsDummyjson, id);
    console.log(chips, "testSubG");
    let filterKey =
      chips.type == "region"
        ? "CITY"
        : chips?.type == "city"
        ? "CLUSTER"
        : "facility";
    setSubGroupChipData(chips.name);
    existedSubGroups.current = chips.name;
    setActiveGroupID(id);
    let dataChipsList = [];
    if (filterKey !== "facility") {
      dataChipsList = faciltGroupsRawData
        .filter((obj) => obj.facilityGroupTypeId == filterKey)
        .map((obj, index) => {
          return {
            id: obj.facilityGroupId,
            name:
              obj.facilityGroupName == null
                ? obj.facilityGroupTypeId + (index + 1)
                : obj.facilityGroupName,
            type: obj.facilityGroupTypeId,
          };
        });
    } else {
      // ={obj.facilityId}> {obj.facilityName}
      dataChipsList = facilityListForSubGroup.map((obj, index) => {
        return { id: obj.facilityId, name: obj.facilityName, type: "Facility" };
      });
    }
    setsubGroupDataChips(dataChipsList);
    apiChipsData.current = dataChipsList;
    setAddSubGroups1(true);
    console.log(apiChipsData.current, "addSubCheck23");
    console.log(dataChips, "addSubCheck23");
  };
  const [showSelectSubGroup, setShowSelectSubGroup] = useState(false);

  const [chipData, setSubGroupChipData] = useState([]);

  const [dataChips, setsubGroupDataChips] = useState([]);
  const apiChipsData = useRef([]);
  const [inputSubGroup, setInputSubGroup] = useState("");
  const filterData = (e) => {
    setInputSubGroup(e.target.value);
    let tempArr = [...apiChipsData.current];
    tempArr = tempArr.filter((obj) =>
      obj.name.toLowerCase().startsWith(e.target.value.toLowerCase())
    );
    setsubGroupDataChips(tempArr);
  };

  const [addGroupList, setAddGroupsList] = useState([]);
  const removeAndAddToChips = (key, el) => {
    if (key == "Add") {
      if (
        //  (existedSubGroups.current.find((obj)=> obj.id == el.id) == undefined ) &&
        chipData.find((obj) => obj.id == el.id) == undefined
      ) {
        setSubGroupChipData((element) => [...element, el]);
        setAddGroupsList((element) => [...element, el]);
        setInputSubGroup("");
        return;
      }
      return;
    }

    let tempArr = [...chipData];
    tempArr = tempArr.filter((ele) => ele.id !== el.id);
    setSubGroupChipData(tempArr);
    let tempArr1 = [...addGroupList];
    tempArr1 = tempArr1.filter((ele) => ele.id !== el.id);
    setAddGroupsList(tempArr1);
    return;
  };

  // Existed users in group...
  const getExistingUsers = async (id) => {
    // alert(id);
    toast(`Active ID : ${id}`);
    let tokenID = localStorage.getItem("@tokenID");
    try {
      const token = tokenID;
      const endpoint = `https://dev-tnt.app.aone.ai/api/1.0/assignUser/facilityGroup/list?facilityGroupId=${id}`;
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      let response = await fetch(endpoint, {
        method: "GET",
        headers: headers,
      });
      console.log(response, "Existing Users");
      if (response.status !== 200) {
        toast("Error in Assign User Facility Group List API");
        return;
      } else {
        let finalArr = await response.json();
        console.log(finalArr.data, "Existing Users");
      }
    } catch (error) {
      console.log(error, "Existing Users");
      toast("Error in Assign User Facility Group List API");
    }
  };

  // Edit Group Popup
  const [editGroup, setEditGroup] = useState(false);
  const setEditGroupPop = (id) => {
    if (id == "company") {
      id = "10012";
      onclickActivePopup("subGroups");
    }

    activeCardRef.current = id;
    if (id !== "10012") {
      onclickActivePopup("basicInfo");
      let chips = findTypeAndNameById(facilityGroupsDummyjson, id);
      console.log(chips, "testSubG");
      let filterKey =
        chips.type == "region"
          ? "CITY"
          : chips?.type == "city"
          ? "CLUSTER"
          : "facility";
      setSubGroupChipData(chips.name);
      setActiveGroupID(id);
      let dataChipsList = [];
      if (filterKey !== "facility") {
        dataChipsList = faciltGroupsRawData
          .filter((obj) => obj.facilityGroupTypeId == filterKey)
          .map((obj, index) => {
            return {
              id: obj.facilityGroupId,
              name:
                obj.facilityGroupName == null
                  ? obj.facilityGroupTypeId + (index + 1)
                  : obj.facilityGroupName,
              type: obj.facilityGroupTypeId,
            };
          });
      } else {
        // ={obj.facilityId}> {obj.facilityName}
        dataChipsList = facilityListForSubGroup.map((obj, index) => {
          return {
            id: obj.facilityId,
            name: obj.facilityName,
            type: "Facility",
          };
        });
      }
      setsubGroupDataChips(dataChipsList);
      apiChipsData.current = dataChipsList;
      getExistingUsers(id);
    } else {
      onclickActivePopup("subGroups");
      let chips = facilityGroupsDummyjson.map((obj) => {
        return { name: obj.name, id: obj.id, fromDate: obj.fromDate };
      });
      setSubGroupChipData(chips);
      setActiveGroupID(id);
      let dataChipsList = faciltGroupsRawData
        .filter((obj) => obj.facilityGroupTypeId == "REGION")
        .map((obj, index) => {
          return {
            id: obj.facilityGroupId,
            name:
              obj.facilityGroupName == null
                ? obj.facilityGroupTypeId + (index + 1)
                : obj.facilityGroupName,
            type: obj.facilityGroupTypeId,
          };
        });
      setsubGroupDataChips(dataChipsList);
      apiChipsData.current = dataChipsList;
    }
    // setAddSubGroups1(true);
    setEditGroup(true);
  };

  const [mapUsertoFaclityGroupsArr, setMapUsertoFaclityroupsArr] = useState([
    {
      id: generateID(4),
      facilityList: facilityGroupUsersList,
      rolesList: userRolesList,
    },
  ]);

  const addFacilityGroupRole = () => {
    handleAddUser();
    // Update the copy array
    setMapUsertoFaclityroupsArr((prevState) => [
      ...prevState,
      {
        id: generateID(4),
        facilityList: facilityGroupUsersList,
        rolesList: userRolesList,
      },
    ]);
  };

  const deleteAddFaciityGroupRole = (id: any, index) => {
    handleDelete(index);
    setMapUsertoFaclityroupsArr((prevState) =>
      prevState.filter((el) => el.id !== id)
    );
  };

  // Delete Popup
  const [showDelete, setDeleteFacilityGroup] = useState(false);
  const [activeType, setActiveType] = useState("Company");
  const deleteFacilityGroup = (type: any) => {
    setDeleteFacilityGroup(true);
    setActiveType(type);
    // deleteGroupAPI()
  };

  // Delete API group Integrated...
  const deleteGroupAPI = async () => {
    let tokenID = localStorage.getItem("@tokenID");
    setDeleteFacilityGroup(false);
    try {
      const token = tokenID;
      const endpoint = `https://dev-tnt.app.aone.ai/api/1.0/facilityGroup/delete?facilityGroupId=${10023}`;
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      // const payload = {
      //   "facilityGroupName": groupName,
      //   "facilityGroupTypeId": "MGMT_STRUCTURE",
      //   "partyId": 'Company'
      // }
      const response = await fetch(endpoint, {
        method: "GET",
        headers: headers,
        // body: JSON.stringify(payload),
      });
      if (!response.ok) {
        toast("Problem");
        throw new Error("Network response was not ok");
      }
      const resp = await response.json();
      toast(`${resp.responseMessage}`);
      facilityGroupAPIsNeedTocall();
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  const callDeleteGroup = async () => {
    deleteGroupAPI();
  };

  //Disable Popup
  const [showDisable, setShowDisable] = useState(false);
  const setDisablePopup = () => {
    setShowDisable(true);
  };

  const [activeTab, onclickActivePopup] = useState("basicInfo");

  // FacilityGroup AddSubGroupAPI...

  const AddSubGroupAPI = async () => {
    setAddSubGroups1(false);
    let tokenID = localStorage.getItem("@tokenID");
    setDeleteFacilityGroup(false);
    try {
      const token = tokenID;
      const endpoint = `https://dev-tnt.app.aone.ai/api/1.0/facilityGroup/addSubGroup`;
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      const payload = {
        parent: activeGroupID,
        children: addGroupList.map((obj) => obj.id),
      };

      const response = await fetch(endpoint, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      if (response.status == 200) {
        const resp = await response.json();
        console.log(resp, "Add SubGroup");
        toast("SubGroup Added");
        facilityGroupAPIsNeedTocall();
      } else {
        toast("Sub Group API Error");
      }
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // Add user Functionality..................

  const [selectedUsers, setSelectedUsers] = useState([]);
  const handleUserSelection = (event, index) => {
    const { value } = event.target;
    const updatedUsers = [...selectedUsers];
    updatedUsers[index] = {
      ...updatedUsers[index],
      partyId: value,
    };
    setSelectedUsers(updatedUsers);
  };
  const handleRoleSelection = (event, index) => {
    const { value } = event.target;
    const updatedUsers = [...selectedUsers];
    updatedUsers[index] = {
      ...updatedUsers[index],
      roleTypeId: value,
    };
    setSelectedUsers(updatedUsers);
  };
  const handleDelete = (index) => {
    const updatedUsers = [...selectedUsers];
    updatedUsers.splice(index, 1);
    setSelectedUsers(updatedUsers);
  };
  const handleAddUser = () => {
    const newUser = {
      partyId: "",
      roleTypeId: "",
    };
    setSelectedUsers([...selectedUsers, newUser]);
    console.log(selectedUsers, "ADDUser7878");
  };

  // /facilityGroup/AssignUser.
  const assignUserAPI = async () => {
    try {
      // activeCardRef.current
      // alert(activeCardRef.current);
      let finalArr = [...selectedUsers];
      finalArr = finalArr
        .map((user) => {
          if (user.partyId !== "" && user.roleTypeId !== "") {
            return { ...user, facilityGroupId: activeCardRef.current };
          }
          return null;
        })
        .filter((user) => user !== null);

      // Calling API for Assignuser..
      let token = localStorage.getItem("@tokenID");
      const endpoint = `https://dev-tnt.app.aone.ai/api/1.0/assignUser/facilityGroup`;
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      const payload = {
        toAdd: finalArr,
        toRemove: [],
      };
      const response = await fetch(endpoint, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      const resp = await response.json();
      console.log(resp, "finalAddUserArr");
      console.log(payload, "finalAddUserArr");
      if (resp.statusCode == "OK") {
        let chips = findTypeAndNameById(
          facilityGroupsDummyjson,
          activeCardRef.current
        );
        toast(`Users Added for ${chips.type} ID: ${activeCardRef.current} `);
        facilityGroupAPIsNeedTocall();
      } else {
        toast("Assign User API Error");
      }
      console.log(finalArr, "finalAddUserArr");
    } catch (error) {
      toast("Assign User Popup Error");
    }
  };

  // fetch Existingusers for FacilityGroup...
  const existingUsersList = useRef([]);
  const existingRolesList = useRef([]);
  const fetchFacilityGroupExistingUsersList = async () => {
    let token = localStorage.getItem("@tokenID");
    try {
      const option = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      };
      const response = await fetch(
        `https://dev-tnt.app.aone.ai/api/1.0/assignUser/facilityGroup/list?facilityGroupId=${activeCardRef.current}`,
        option
      );
      let resp = { data: [] };
      if (response.status == 200) {
        resp = await response.json();
        console.log(resp, "group ExsitingUser");
        existingUsersList.current = resp.data.map((obj) => obj.partyId);
        existingRolesList.current = resp.data.map((obj) => obj.roleTypeId);
        existingUsersList.current.forEach((obj) => {
          addFacilityGroupRole();
        });
        toast("Fetched Existing Users List..");
      } else {
        toast("Problem In Fetching Existing Users List..");
      }
      if (resp.data.length == 0) {
        addFacilityGroupRole();
      }
      // console.log(resp, "Add SubGroup");
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  useEffect(() => {
    // alert("users tab")
    if (activeTab === "users") {
      // alert("users tab")
      setMapUsertoFaclityroupsArr([]);
      fetchFacilityGroupExistingUsersList();
    }
  }, [activeTab]);

  // Facility Group Groupname, SubGroups and users Update API's Functionality...
  const [updatedgroupName, setUpdateGroupName] = useState("");
  const callUpdateAPI = async (activeTab) => {
    if (activeTab === "basicInfo") {
      try {
        let token = localStorage.getItem("@tokenID");
        const updateName = `https://dev-tnt.app.aone.ai/api/1.0/facilityGroup/updateGroup`;
        const headers = {
          "Content-Type": "application/json",
          token: token,
        };
        const payload = {
          facilityGroupName: updatedgroupName,
          partyId: localStorage.getItem("@partyID"),
          facilityGroupId: activeCardRef.current,
        };
        console.log(payload, "@updateName");
        let resp = await fetch(updateName, {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(payload),
        });

        if (resp.status == 200) {
          // let finalResp = await resp.json();
          toast("Group Name Updated Successfully..");
          facilityGroupAPIsNeedTocall();
        } else {
          toast("Update Group Name API Error");
        }
      } catch (error) {
        toast("Update Group Name API Error");
      }
    } else if (activeTab === "subGroups") {
      let chips = findTypeAndNameById(
        facilityGroupsDummyjson,
        activeCardRef.current
      );
      console.log(chips, "updateSub");
      // let filterKey = chips.type == 'region' ? 'CITY' : chips?.type == 'city' ? 'CLUSTER' : 'facility'
      // setSubGroupChipData(chips.name)
      // chipData
      let needToRemoveArr = chips.name.filter((obj) => {
        if (chipData.find((ob) => ob.id == obj.id) == undefined) {
          return obj;
        }
      });
      let needToAddArr = chipData.filter((obj) => {
        if (chips?.name.find((ob) => ob.id == obj.id) == undefined) {
          return obj;
        }
      });
      // Calling Update Subgroup...
      try {
        let token = localStorage.getItem("@tokenID");
        const response = await fetch(
          `https://dev-tnt.app.aone.ai/api/1.0/facilityGroup/updateSubGroup`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              token: token,
            },
            body: JSON.stringify({
              parent: activeCardRef.current,
              removeChildren: needToRemoveArr.map((obj) => {
                return { childId: obj.id, fromDate: obj.fromDate };
              }),
              addChildren: needToAddArr.map((obj) => obj.id),
            }),
          }
        );
        console.log(response, "updateSubAPIResp");
        if (response.status == 200) {
          toast("Facility SubGroup Updated Successfully");
          facilityGroupAPIsNeedTocall();
        } else {
          toast("Facility SubGroup Updated API Error");
        }
      } catch (error) {
        toast("Facility SubGroup Updated API Error");
      }
      console.log(chipData, "updateSub");
      console.log(needToRemoveArr, "updateSubRemove");
      console.log(needToAddArr, "updateSubADD");
      // fromDate
    } else if (activeTab === "users") {
      // fetching Existing UsersList...
      // fetchFacilityGroupExistingUsersList();
    }
  };

  return (
    <>
      <div className="Group-regions-con">
        <FacilityDropCards
          setAddUserPopup={setAddUserPopup}
          setAddSubGroups={setAddSubGroups}
          setEditGroupPop={setEditGroupPop}
          setDisablePopup={setDisablePopup}
          deleteFacilityGroup={deleteFacilityGroup}
          showAndHideFunction={showAndHideFunction}
          activeList={activeList}
          // moveFacilityCard = {moveFacilityCard}
          type={"company"}
          data={{
            name: "Company",
            regions: facilityGroupsDummyjson?.length,
            facilities: facilityGroupsDummyjson?.reduce(
              (accumulator, currentValue) => {
                if (currentValue.hasOwnProperty("facilities")) {
                  return accumulator + currentValue.facilities;
                } else {
                  return accumulator;
                }
              },
              0
            ),
            id: "company",
          }}
          setIsDraggingEnabled={setIsDraggingEnabled}
        />
      </div>
      {activeList.includes("company") && (
        <div className="facilityGroupsCont">
          <ToastContainer position="bottom-left" />
          {facilityGroupsDummyjson.map((obj) => (
            <>
              <FacilityDropCards
                key={obj.id}
                activeList={activeList}
                setAddUserPopup={setAddUserPopup}
                setAddSubGroups={setAddSubGroups}
                setEditGroupPop={setEditGroupPop}
                setDisablePopup={setDisablePopup}
                deleteFacilityGroup={deleteFacilityGroup}
                showAndHideFunction={showAndHideFunction}
                // moveFacilityCard = {moveFacilityCard}
                type={obj.type}
                data={obj}
                setIsDraggingEnabled={setIsDraggingEnabled}
              />
              {activeList.includes(obj.id) && (
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId={obj.id}>
                    {(provided, snapshot) => (
                      <div
                        className="facility-groups-region-cont"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {obj.citiesList.map((reg, index) => (
                          <Draggable
                            key={reg.id}
                            draggableId={reg.id}
                            index={index}
                            isDragDisabled={!isDraggingEnabled}
                          >
                            {(dragProvided) => (
                              <div
                                {...dragProvided.draggableProps}
                                {...dragProvided.dragHandleProps}
                                ref={dragProvided.innerRef}
                              >
                                <FacilityDropCards
                                  setAddUserPopup={setAddUserPopup}
                                  setAddSubGroups={setAddSubGroups}
                                  setEditGroupPop={setEditGroupPop}
                                  setDisablePopup={setDisablePopup}
                                  activeList={activeList}
                                  deleteFacilityGroup={deleteFacilityGroup}
                                  key={reg.id}
                                  showAndHideFunction={showAndHideFunction}
                                  // moveFacilityCard={moveFacilityCard}
                                  type={reg.type}
                                  data={reg}
                                  setIsDraggingEnabled={setIsDraggingEnabled}
                                />
                                {activeList.includes(reg.id) && (
                                  <DragDropContext onDragEnd={handleDragEnd}>
                                    <Droppable droppableId={reg.id}>
                                      {(provided, snapshot) => (
                                        <div
                                          className="cities-list-cont"
                                          ref={provided.innerRef}
                                          {...provided.droppableProps}
                                        >
                                          {reg.clustersList.map(
                                            (city, index) => (
                                              <Draggable
                                                key={city.id}
                                                draggableId={city.id}
                                                index={index}
                                                isDragDisabled={
                                                  !isDraggingEnabledCity
                                                }
                                              >
                                                {(dragProvided) => (
                                                  <div
                                                    {...dragProvided.draggableProps}
                                                    {...dragProvided.dragHandleProps}
                                                    ref={dragProvided.innerRef}
                                                  >
                                                    <FacilityDropCards
                                                      key={city.id}
                                                      setAddUserPopup={
                                                        setAddUserPopup
                                                      }
                                                      activeList={activeList}
                                                      setAddSubGroups={
                                                        setAddSubGroups
                                                      }
                                                      setEditGroupPop={
                                                        setEditGroupPop
                                                      }
                                                      showAndHideFunction={
                                                        showAndHideFunction
                                                      }
                                                      deleteFacilityGroup={
                                                        deleteFacilityGroup
                                                      }
                                                      // moveFacilityCard={moveFacilityCard}
                                                      type={city.type}
                                                      data={city}
                                                      setIsDraggingEnabled={
                                                        setIsDraggingEnabled
                                                      }
                                                    />
                                                    {activeList.includes(
                                                      city.id
                                                    ) && (
                                                      <DragDropContext
                                                        onDragEnd={
                                                          handleDragEnd
                                                        }
                                                      >
                                                        <Droppable
                                                          droppableId={city.id}
                                                        >
                                                          {(
                                                            provided,
                                                            snapshot
                                                          ) => (
                                                            <div
                                                              className="facility-list-cont"
                                                              ref={
                                                                provided.innerRef
                                                              }
                                                              {...provided.droppableProps}
                                                            >
                                                              {city.facilityList.map(
                                                                (
                                                                  fac,
                                                                  index
                                                                ) => (
                                                                  <Draggable
                                                                    key={fac.id}
                                                                    draggableId={
                                                                      fac.id
                                                                    }
                                                                    index={
                                                                      index
                                                                    }
                                                                    isDragDisabled={
                                                                      !isDraggingEnabledFaci
                                                                    }
                                                                  >
                                                                    {(
                                                                      dragProvided
                                                                    ) => (
                                                                      <div
                                                                        {...dragProvided.draggableProps}
                                                                        {...dragProvided.dragHandleProps}
                                                                        ref={
                                                                          dragProvided.innerRef
                                                                        }
                                                                      >
                                                                        <FacilityDropCards
                                                                          key={
                                                                            fac.id
                                                                          }
                                                                          activeList={activeList}
                                                                          setAddUserPopup={
                                                                            setAddUserPopup
                                                                          }
                                                                          setAddSubGroups={
                                                                            setAddSubGroups
                                                                          }
                                                                          setEditGroupPop={
                                                                            setEditGroupPop
                                                                          }
                                                                          deleteFacilityGroup={
                                                                            deleteFacilityGroup
                                                                          }
                                                                          showAndHideFunction={
                                                                            showAndHideFunction
                                                                          }
                                                                          // moveFacilityCard={moveFacilityCard}
                                                                          type={
                                                                            fac.type
                                                                          }
                                                                          data={
                                                                            fac
                                                                          }
                                                                          setIsDraggingEnabled={
                                                                            setIsDraggingEnabled
                                                                          }
                                                                        />
                                                                      </div>
                                                                    )}
                                                                  </Draggable>
                                                                )
                                                              )}
                                                              {
                                                                provided.placeholder
                                                              }
                                                            </div>
                                                          )}
                                                        </Droppable>
                                                      </DragDropContext>
                                                    )}
                                                  </div>
                                                )}
                                              </Draggable>
                                            )
                                          )}
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                  </DragDropContext>
                                )}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}

              {/* groups chnage confirmation popup */}

              <Modal
                show={showConfirm}
                onHide={() => setConfirmationPopup(false)}
                centered
                backdrop="static"
                className="confirmationPopup"
              >
                <Modal.Header className="confirmFacilityHeaderCont">
                  <h4 className="confirmFacilityHeader">
                    Confirm facility grouping
                  </h4>
                  <div className="closeIconDiv">
                    <img
                      className="confirmFacilityCloseIcon"
                      src={IconClose}
                      alt="icon"
                      onClick={() => setConfirmationPopup(false)}
                    />
                  </div>
                </Modal.Header>
                <Modal.Body className="confirmFacilityBodyCont">
                  <div className="confirmFacilityBody">
                    <div className="confirmFacilityTxtCont">
                      <p>
                        This action will shift the group Bangalore and the
                        associated assets from <b>South Region</b> to the{" "}
                        <b>North Region</b>. Do you want to proceed?
                      </p>
                    </div>
                    <div className="confirmFacilityWarningCont">
                      <div className="confirnWarningImgTxtCont">
                        <img
                          src={IconWarning}
                          alt="icon"
                          className="warningIcon"
                        />
                        <p>Warning</p>
                      </div>
                      <p className="confirmWarningTxt">
                        This will also shift all the sub-groups and associated
                        assets under <b>Bangalore</b> to <b>North Region</b>.
                      </p>
                    </div>
                    <div className="confirnFacilityCheckBoxCont">
                      <input type="checkbox" />
                      <p>Don’t ask again</p>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="confirmationPopupFooter">
                  <button
                    className="confirnCancleBtn"
                    onClick={() => setConfirmationPopup(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="confirmBtn"
                    onClick={() => setConfirmationPopup(false)}
                  >
                    Confirm
                  </button>
                </Modal.Footer>
              </Modal>
            </>
          ))}

          {/*Add Subgroups*/}

          <Modal
            show={addSubGroups}
            onHide={() => setAddSubGroups1(false)}
            centered
            backdrop="static"
            className="facilityAddSubGroupsPopup"
          >
            <Modal.Header className="facilityAddSubGroupsHeaderCont">
              <h4 className="facilityAddSubGroupsHeader">Add subgroups</h4>
              <div className="closeIconDiv">
                <img
                  src={IconClose}
                  alt="icon"
                  onClick={() => {
                    setAddSubGroups1(false);
                    setShowSelectSubGroup(false);
                  }}
                />
              </div>
            </Modal.Header>
            <Modal.Body className="facilityAddSubGroupsBodyCont">
              <div className="facilityAddSubGroupsBody">
                <p className="facilityAddSubGroupsBodyHeader">
                  Facility Group/Facility associations of <b>North Region</b>
                </p>
                <div className="subGroupsContainer">
                  {chipData.map((el) => (
                    <p className="subGroupsChipsCont" key={el.id}>
                      {el.name}{" "}
                      {existedSubGroups.current.find(
                        (obj) => obj.id == el.id
                      ) == undefined && (
                        <>
                          {" "}
                          <img
                            src={ChipCloseIcon}
                            alt="icon"
                            className="chipCloseIcon"
                          />
                          <img
                            onClick={() => removeAndAddToChips("remove", el)}
                            src={ActiveChipCloseIcon}
                            alt="icon"
                            className="chipCloseIconHover"
                          />
                        </>
                      )}
                    </p>
                  ))}
                  {/* <input className="add-groups-input"  type="text"/> */}
                  <div className="subGroupsChipsInputCont">
                    <input
                      type="text"
                      value={inputSubGroup}
                      onClick={() => setShowSelectSubGroup(true)}
                      onChange={(el) => filterData(el)}
                    />
                    {showSelectSubGroup && (
                      <div
                        onClick={() => setShowSelectSubGroup(false)}
                        className="chipsFilterDiv"
                      >
                        {dataChips.map((el) => (
                          <div
                            onClick={() => removeAndAddToChips("Add", el)}
                            className="chipsListCont"
                          >
                            <p className="headerList">{el.name}</p>
                            <div className="alreadyused-cont">
                              <p>
                                {el.type}
                                {`(${el.id})`}
                              </p>
                              <p className="alreadyUsedTxt">
                                {chipData.find((obj) => obj.id == el.id) !==
                                undefined
                                  ? "already used"
                                  : ""}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="facilityAddSubGroupsFooter">
              <button
                onClick={() => setAddSubGroups1(false)}
                className="facilityAddSubGroupsCancelBtn"
              >
                Cancel
              </button>
              <button
                onClick={AddSubGroupAPI}
                className="facilityAddSubGroupsConfirmBtn"
              >
                {" "}
                Confirm
              </button>
            </Modal.Footer>
          </Modal>

          {/* Add Users Popup */}

          <Modal
            show={addUserPopup}
            onHide={() => setAddUserPopup1(false)}
            centered
            backdrop="static"
            className="facilityGroupsAddUsersPopup"
          >
            <Modal.Header className="facilityGroupsAddUsersHeaderCont">
              <h4 className="facilityGroupsAddUsersHeader">
                Map users to groups
              </h4>
              <div className="closeIconDiv">
                <img
                  src={IconClose}
                  alt="icon"
                  onClick={() => setAddUserPopup1(false)}
                />
              </div>
            </Modal.Header>
            <Modal.Body className="facilityAddUsersPopBody">
              <div className="facilityAddUsersPopBodyCont">
                <h4>
                  Mapping following users to <b>North Region.</b>
                </h4>
                <div className="facilityAddUsersDetailsCont">
                  {/* <div className="facilityAddUsersDetails">
              <p className="popFacilityUsersTxt">User</p>
              <p className="popUsersRoleTxt">Role</p>
            </div> */}
                  <div className="facilityAddUsersSelectorsHeightCont">
                    {mapUsertoFaclityGroupsArr.map((obj, index) => (
                      <div className="facilityAddUsersSelectorsCont">
                        <select
                          className="popUsersSelect"
                          onChange={(event) =>
                            handleUserSelection(event, index)
                          }
                        >
                          <option value="" disabled selected>
                            Select User..
                          </option>
                          {obj.facilityList.map((list) => (
                            <option key={list.partyId} value={list.partyId}>
                              {list.firstName}
                            </option>
                          ))}
                        </select>
                        <select
                          className="popUsersRoleSelect"
                          onChange={(event) =>
                            handleRoleSelection(event, index)
                          }
                        >
                          <option value="" disabled selected>
                            Select Role
                          </option>
                          {obj.rolesList.map((list) => (
                            <option
                              key={list.roleTypeId}
                              value={list.roleTypeId}
                            >
                              {list.description}
                            </option>
                          ))}
                        </select>
                        <img
                          src={deleteIcon}
                          alt="delete-icon"
                          onClick={() =>
                            deleteAddFaciityGroupRole(obj.id, index)
                          }
                        />
                      </div>
                    ))}
                  </div>

                  <div className="facilityAddUsersDetailsBtnCont">
                    <button
                      className="facilityAddUsersDetailsBtn"
                      onClick={() => addFacilityGroupRole()}
                    >
                      <img src={AddIcon} alt="icon" />
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="facilityAddUserFooterPop">
              <button
                onClick={() => setAddUserPopup1(false)}
                className="facilityAddUserCancelBtn"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setAddUserPopup1(false);
                  handleAddUser();
                  assignUserAPI();
                }}
                className="facilityAddUserConfirmBtn"
              >
                {" "}
                Confirm
              </button>
            </Modal.Footer>
          </Modal>

          {/* Delete Popup */}

          <Modal
            show={showDelete}
            onHide={() => setDeleteFacilityGroup(false)}
            centered
            backdrop="static"
            className="facilityGroupDeletePopup"
          >
            <Modal.Header className="deleteFacilityGroupHeaderCont">
              <h4 className="deleteFacilityGroupPopHeader">
                Delete North Region?
              </h4>
              <div
                className="closeIconDiv"
                onClick={() => setDeleteFacilityGroup(false)}
              >
                <img src={IconClose} alt="icon" />
              </div>
            </Modal.Header>
            <Modal.Body className="deleteFacilityGroupBody">
              <div className="deleteFacilityGroupBodyCont">
                <p className="deleteFacilityGroupContentTxt">
                  This action will permanently delete the facility group{" "}
                  <b>North Region</b> and its association with other facility
                  groups/facilities.
                </p>
                <p className="deleteFacilityGroupConfoTxt">
                  Do you wish to continue?
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer className="deleteFacilityGroupFooter">
              <button
                className="deleteFacilityGroupCancelBtn"
                onClick={() => setDeleteFacilityGroup(false)}
              >
                Cancel
              </button>
              <button
                className="deleteFacilityGroupDeleteBtn"
                onClick={callDeleteGroup}
              >
                Delete
              </button>
            </Modal.Footer>
          </Modal>

          {/* Disable Popup */}

          <Modal
            show={showDisable}
            onHide={() => setShowDisable(false)}
            centered
            backdrop="static"
            className="facilityGroupDisablePopup"
          >
            <Modal.Header className="disableFacilityGroupHeaderCont">
              <h4 className="disableFacilityGroupPopHeader">
                Disable North Region?
              </h4>
              <div
                className="closeIconDiv"
                onClick={() => setShowDisable(false)}
              >
                <img src={IconClose} alt="icon" />
              </div>
            </Modal.Header>
            <Modal.Body className="disableFacilityGroupBody">
              <div className="disableFacilityGroupBodyCont">
                <p className="disableFacilityGroupContentTxt">
                  This action will disable the facility group{" "}
                  <b>North Region</b> and its association with other facility
                  groups/facilities.
                </p>
                <p className="disableFacilityGroupConfoTxt">
                  Do you wish to continue?
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer className="disableFacilityGroupFooter">
              <button
                className="disableFacilityGroupCancelBtn"
                onClick={() => setShowDisable(false)}
              >
                Cancel
              </button>
              <button
                className="disableFacilityGroupDeleteBtn"
                onClick={() => setShowDisable(false)}
              >
                Disable
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
      {/* Edit Group Popup */}

      <Modal
        show={editGroup}
        onHide={() => setEditGroup(false)}
        centered
        backdrop="static"
        className="facilityEditGroupPopup"
      >
        <Modal.Header className="facilityEditGroupHeaderCont">
          <h4 className="facilityEditGroupHeader">Edit Group</h4>
          <div
            className="closeIconDiv"
            onClick={() => {
              setEditGroup(false);
              onclickActivePopup("basicInfo");
            }}
          >
            <img src={IconClose} alt="icon" />
          </div>
        </Modal.Header>
        <Modal.Body className="facilityEditGroupBody">
          <div className="facilityEditGroupBodyCont">
            <div className="facilityEditGroupBodyHeaderCont">
              {activeCardRef.current !== "10012" && (
                <p
                  className={
                    activeTab == "basicInfo"
                      ? "activeEditGroupHeaderTxt"
                      : "editGroupHeaderTxt"
                  }
                  onClick={() => onclickActivePopup("basicInfo")}
                >
                  Basic Information
                </p>
              )}
              <p
                className={
                  activeTab == "subGroups"
                    ? "activeEditGroupHeaderTxt"
                    : "editGroupHeaderTxt"
                }
                onClick={() => onclickActivePopup("subGroups")}
              >
                Sub Groups
              </p>
              {activeCardRef.current !== "10012" && (
                <p
                  className={
                    activeTab == "users"
                      ? "activeEditGroupHeaderTxt"
                      : "editGroupHeaderTxt"
                  }
                  onClick={() => onclickActivePopup("users")}
                >
                  Users
                </p>
              )}
            </div>
            {activeTab == "basicInfo" && activeCardRef.current !== "10012" ? (
              <div className="facilityEditGroupBasicInfoCont">
                <div className="facilityEditGroupBasicInfoHeader">
                  <p>Group Name</p>
                  {/* <p>Group Type *</p> */}
                </div>
                <div className="facilityEditGroupBasicInfoInputs">
                  <input
                    type="text"
                    className="basicInfoInput"
                    value={updatedgroupName}
                    onChange={(e) => setUpdateGroupName(e.target.value)}
                  />
                  {/* <select className="basicInfoSelector">
                  <option value="" disabled selected>
                    Group Type
                  </option>
                  <option>Facility</option>
                  <option>Cluster</option>
                  <option>Region</option>
                </select> */}
                </div>
              </div>
            ) : activeTab == "subGroups" ? (
              <div className="facilityEditSubGroupsContainer">
                {chipData.map((el) => (
                  <p className="subGroupsChipsCont">
                    {el.name}{" "}
                    <img
                      src={ChipCloseIcon}
                      alt="icon"
                      className="chipCloseIcon"
                    />
                    <img
                      onClick={() => removeAndAddToChips("remove", el)}
                      src={ActiveChipCloseIcon}
                      alt="icon"
                      className="chipCloseIconHover"
                    />
                  </p>
                ))}
                <div className="subGroupsChipsInputCont">
                  <input
                    type="text"
                    value={inputSubGroup}
                    onClick={() => setShowSelectSubGroup(true)}
                    onChange={(el) => filterData(el)}
                  />
                  {showSelectSubGroup && (
                    <div
                      onClick={() => setShowSelectSubGroup(false)}
                      className="chipsFilterDiv"
                    >
                      {dataChips.map((el) => (
                        <div
                          onClick={() => removeAndAddToChips("Add", el)}
                          className="chipsListCont"
                        >
                          <p className="headerList">{el.name}</p>
                          <div className="alreadyused-cont">
                            <p>
                              {el.type}
                              {`(${el.id})`}
                            </p>
                            <p className="alreadyUsedTxt">
                              {chipData.find((obj) => obj.id == el.id) !==
                              undefined
                                ? "already used"
                                : ""}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ) : activeTab == "users" && activeCardRef.current !== "10012" ? (
              <div className="facilityEditGroupUsersCont">
                <div className="facilityEditGroupUsersHeightCont">
                  {mapUsertoFaclityGroupsArr.map((obj, index) => (
                    <div className="facilityEditGroupUsersSelectorsCont">
                      <select
                        className="UsersSelectorsCont"
                        defaultValue={
                          existingUsersList.current.length > index
                            ? existingUsersList.current[index]
                            : ""
                        }
                      >
                        <option value="" disabled selected>
                          Select User
                        </option>
                        {obj.facilityList.map((list) => (
                          <option key={list.partyId} value={list.partyId}>
                            {list.firstName}
                          </option>
                        ))}
                      </select>
                      <select
                        className="UsersSelectorsCont"
                        defaultValue={
                          existingRolesList.current.length > index
                            ? existingRolesList.current[index]
                            : ""
                        }
                      >
                        <option value="" disabled selected>
                          Select Role
                        </option>
                        {obj.rolesList.map((list) => (
                          <option key={list.roleTypeId} value={list.roleTypeId}>
                            {list.description}
                          </option>
                        ))}
                      </select>
                      <img
                        src={deleteIcon}
                        alt="icon"
                        onClick={() => deleteAddFaciityGroupRole(obj.id)}
                      />
                    </div>
                  ))}
                </div>

                <div className="facilityEditGroupUsersBtnCont">
                  <button
                    onClick={() => addFacilityGroupRole()}
                    className="facilityEditGroupUsersBtn"
                  >
                    <img src={AddIcon} alt="icon" />
                    Add
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="facilityEditGroupFooter">
          <button
            onClick={() => {
              setEditGroup(false);
              onclickActivePopup("basicInfo");
            }}
            className="facilityEditGroupCancelBtn"
          >
            Cancel
          </button>
          <button
            // basicInfo
            onClick={() => {
              setEditGroup(false);
              callUpdateAPI(activeTab);
            }}
            className="facilityEditGroupUpdateBtn"
          >
            {" "}
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default FacilityGroupsContainer;

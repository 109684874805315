import React, { useEffect } from "react";
import "./EventDuration.scss";
import Heatmap from "./Heatmap";
import { useQuery, useQueryClient } from "react-query";
import FilterComponent from "../../eventSummary/components/FilterComponent";
import EventDurationState from "../state/EventDurationState";
import { cancelExistingQuery } from "../../../Utils/utils";
import { getEventDuration } from "../service/EventDurationService";
import { getMapList } from "../utils";
import LoaderStyle from "../../../a1Components/LoaderStyle/LoaderStyle";
import DisplayError from "../../../a1Components/DisplayError/DisplayError";
import iconChevronLeft from "../../../assets/icons/iconchevronleft.svg";
import iconChevronRight from "../../../assets/icons/iconchevronright.svg";
import { useStateValue } from "../../../redux/StateProvider";
import moment from "moment";

interface PropTypes {
  showFilter: boolean;
  hideFilter: () => void;
}

function EventDuration(props: PropTypes) {
  const queryClient = useQueryClient();
  const [{ dateRange, dateChip }, dispatch] = useStateValue();
  
  const { showFilter } = props;
  const pageSize = 5;

  const {
    searchingIndex,
    setSearchingIndex,
    filterDataSet,
    setFilterDataSet,
    selectedFilterKey,
    setSelectedFilterKey,
    queryParams,
    setQueryParams,
    responseData,
    setResponseData,
    selectedFilterValue,
    setSelectedFilterValue,
    pageIndex,
    setPageIndex,
    pageIndexRef,
    chartDataObj,
    setChartDataObj,
  } = EventDurationState();

  const { data, isLoading, isFetching, error, refetch, dataUpdatedAt } =
    useQuery(
      "getEventDurationApi",
      () =>
        getEventDuration(
          queryParams.filterByType,
          queryParams.filterById,
          queryParams.areaId,
          queryParams.startDate,
          queryParams.endDate,
          pageIndexRef.current,
          pageSize
        ),
      {
        enabled: false,
        keepPreviousData: false,
      }
    );

  const checkPrevButtonEnabled = () => {
    if (responseData.paginationMap.currentPage === 1) {
      return false;
    }
    return (
      responseData.paginationMap.prevPage !==
      responseData.paginationMap.currentPage
    );
  };

  const checkNextButtonEnabled = () => {
    return (
      responseData.paginationMap.nextPage !==
      responseData.paginationMap.currentPage
    );
  };

  useEffect(() => {
    cancelExistingQuery("getEventDurationApi", queryClient);
    return () => {
      cancelExistingQuery("getEventDurationApi", queryClient);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        cancelExistingQuery("getEventDurationApi", queryClient);
        await refetch();
      } catch (error) {
        console.error("Error in refetching api:", error);
      }
    };
    fetchData();
  }, [queryParams, pageIndexRef.current]);

  useEffect(() => {
    if (data !== undefined && data.hasOwnProperty("eventDuration")) {
      const eventDuration = data.eventDuration;
      const result = getMapList(eventDuration);
      setChartDataObj(result);
      setResponseData(data);
    }
  }, [data, dataUpdatedAt]);

  useEffect(() => {
    if (showFilter && selectedFilterKey.length !== 0) {
      let temp: any = {
        ...queryParams,
        extraDetails: true,
      };
      if (selectedFilterKey.includes("AREA")) {
        const facilityId = selectedFilterValue[selectedFilterValue.length - 2];
        const areaId = selectedFilterValue[selectedFilterValue.length - 1];
        temp = {
          ...temp,
          filterByType: "facility",
          filterById: facilityId.name,
          areaId: areaId.name,
        };
      } else {
        const type = selectedFilterKey[selectedFilterKey.length - 1];
        const value = selectedFilterValue[selectedFilterValue.length - 1];
        temp = {
          ...temp,
          filterByType: type.toLowerCase(),
          filterById: value.name,
          areaId: "_NA_",
        };
      }

      setQueryParams(temp);
    } else {
      const temp = {
        ...queryParams,
        filterByType: "_NA_",
        filterById: "_NA_",
        areaId: "_NA_",
        extraDetails: true,
      };
      setQueryParams(temp);
    }
  }, [selectedFilterKey, selectedFilterValue, filterDataSet, showFilter]);

  useEffect(() => {
    const startDate = moment(dateRange[0]).valueOf();
    const endDate = moment(dateRange[1]).valueOf();
    const temp = {
      ...queryParams,
      startDate: startDate,
      endDate: endDate,
    };
    setQueryParams(temp);
  },[dateRange])

  return (
    <>
      {showFilter && (
        <FilterComponent
          searchingIndex={searchingIndex}
          setSearchingIndex={setSearchingIndex}
          setFilterDataSet={setFilterDataSet}
          filterDataSet={filterDataSet}
          selectedFilterKey={selectedFilterKey}
          setSelectedFilterKey={setSelectedFilterKey}
          selectedFilterValue={selectedFilterValue}
          setSelectedFilterValue={setSelectedFilterValue}
          showFilter={showFilter}
        />
      )}

      {isLoading || isFetching ? (
        <div className="loadingBox1">
          <LoaderStyle />
        </div>
      ) : error ? (
        <div className="loadingBox1">
          <DisplayError type="err_1" />
        </div>
      ) : data !== undefined && typeof data === "number" ? (
        <div className="loadingBox1">
          <DisplayError type="err-500/404" />
        </div>
      ) : chartDataObj.description.length === 0 ? (
        <DisplayError type="err-empty" />
      ) : (
        <>
          <Heatmap
            description={chartDataObj.description}
            chartData={chartDataObj.data}
          />
          {responseData.eventDuration.length !== 0 && (
            <div className="paginationMainCont">
              <img
                src={iconChevronLeft}
                style={{
                  opacity: checkPrevButtonEnabled() ? 1 : 0.3,
                  cursor: checkPrevButtonEnabled() ? "pointer" : "default",
                }}
                onClick={() => {
                  if (checkPrevButtonEnabled()) {
                    setPageIndex((prev) => prev - 1);
                  }
                }}
              />
              <p className="paginationText">
                Showing {(pageIndex - 1) * pageSize + 1}-
                {(pageIndex - 1) * pageSize + responseData.eventDuration.length}{" "}
                of {responseData.paginationMap.totalCount} Event Types
              </p>
              <img
                src={iconChevronRight}
                style={{
                  opacity: checkNextButtonEnabled() ? 1 : 0.3,
                  cursor: checkNextButtonEnabled() ? "pointer" : "default",
                }}
                onClick={() => {
                  if (checkNextButtonEnabled()) {
                    setPageIndex((prev) => prev + 1);
                  }
                }}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default EventDuration;

import React from "react";
import "../../App_one.scss";

function A1Button({ children, onClick, active, anovi }: any) {
  return (
    <>
      {anovi ? (
        <button
          type="button"
          className={`${active ? "active btnAnovi" : "btnAnovi"}`}
          onClick={onClick}
        >
          {children}
        </button>
      ) : (
        <button
          type="button"
          className={`${active ? "active btn" : "btn"}`}
          onClick={onClick}
        >
          {children}
        </button>
      )}
    </>
  );
}

export default A1Button;

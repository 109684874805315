import React from "react";
import axios from "axios";
// import the core library.
import ReactEChartsCore from "echarts-for-react/lib/core";
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from "echarts/core";
import * as R from "ramda";
// import components, all suffixed with Component
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
} from "echarts/components";
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import { CanvasRenderer } from "echarts/renderers";

function StackedHorizontalBar({ percentage , name}: any) {
  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    CanvasRenderer,
  ]);
  const options = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        // Use axis to trigger tooltip
        type: "shadow", 
      },
      formatter: function (params) {
        var tooltipContent = "";
        tooltipContent += `<div style='background-color: white; padding: 10px; display: flex; flex-direction: column; border-radius: 8px;'>
        <p style='font-size: 14px; font-weight: 500; color: black;'>${name}</p>
        <div style='display: flex; flex-direction: row; gap: 8px; align-items: baseline; text-align: center;'>
        <span style='width:10px; height: 10px; background-color:#6461E3; border-radius: 50px;' ></span>
        <p style='font-size: 12px;  font-weight: 400; margin: 0; padding: 0;'>
        ${params[0].seriesName} <span style='font-weight: 600;'> ${params[0].value}%
        </span> 
        </p> 
         </div>
         </div>`;
        return tooltipContent;
    }
    },
    legend: {
      show: false,
    },
    grid: {
      height: 40,
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    xAxis: {
      type: "value",
      show: false,
    },
    yAxis: {
      type: "category",
      show: false,
      data: [name]
    },
    color: ["#6461E3", "#E1E3F0"],
    series: [
      {
        name: "Compliance",
        type: "bar",
        stack: "total",
        label: {
          show: parseFloat(percentage) >= 5,
          position: "inside",
          formatter: (params: any) => {
            return parseFloat(percentage) >= 5 ? `${parseFloat(percentage)}%` : '';
          }
        }
       ,
        emphasis: {
          // Disable hover effect
          focus: "none",
          opacity: 1, // Set opacity to 1 to disable hover effect
        },
        itemStyle: {
          barBorderRadius: [4, parseFloat(percentage)===100 ? 4: 0, parseFloat(percentage)===100 ? 4: 0, 4], // Apply border radius to the bars
          opacity: 1, // Set opacity to 1 to disable hover effect
        },
        data: [parseFloat(percentage).toFixed(2)],
      },
      {
        name: "",
        type: "bar",
        stack: "total",
        label: {
          show: parseFloat(percentage)  === 0 ? true : false,
          position: "inside",
          formatter: `${[100 - parseFloat(percentage) === 0 ? 100 : parseFloat(percentage)]}%`, // Display the value of each bar
        },
        itemStyle: {
          barBorderRadius: [parseFloat(percentage)===0 ? 4: 0,4,4, parseFloat(percentage)===0 ? 4: 0], // Apply border radius to the bars
          opacity: 1, // Set opacity to 1 to disable hover effect
        },
        emphasis: {
          // Disable hover effect
          focus: "none",
          opacity: 1, // Set opacity to 1 to disable hover effect
        },
        data: [100 - parseFloat(percentage)],
      },
    ],
  };

  return (
    <div style={{ height: 40,flexGrow: 1, margin: 0, padding: 0 }}>
      <ReactEChartsCore
        echarts={echarts}
        option={options}
        notMerge={true}
        lazyUpdate={true}
        theme={"theme_name"}
      />
    </div>
  );
}

export default StackedHorizontalBar;

import { useState, useLayoutEffect } from 'react';
import Select from 'react-select';
import './DropdownFilter.scss';
import { overflow } from 'html2canvas/dist/types/css/property-descriptors/overflow';

type PropTypes = {
  placeholder: string;
  value: String;
  onChange:(value: string, index: number) => void;
  options: String;
};

const DropdownSelect = (propTypes: PropTypes) => {
  const [isError, setIsError] = useState(false);
  

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderColor: isError ? 'red' : '#bdcbfb',
      minHeight: '30px',
      height: '32px',
      '&:hover': { borderColor: isError ? 'red' : '#bdcbfb' },
    }),


    indicatorsContainer: (provided: any) => ({
      ...provided,
      height: '32px',
    }),

    menuList: (provided: any) => ({
      ...provided,
      maxWidth: '150%',
      "::-webkit-scrollbar": {
        width: "8px",
      },
    }),

    menu: (provided: any) => ({
      ...provided,
      maxWidth: '150%',
      marginTop: '0px',
    }),

    placeholder: (provided: any) =>({
      ...provided,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'noWrap',
      maxWidth: '90%',
    })

  };

  const handleBlur = () => {
    if (!propTypes.value) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  };

  return (
    <div>
      <Select
        className="editDropdownField"
        styles={customStyles}
        maxMenuHeight={150}
        placeholder={propTypes.placeholder}
        value={propTypes.value}
        onChange={propTypes.onChange}
        onBlur={handleBlur}
        options={propTypes.options}
        isClearable
      />
      {isError && (
        <label className="labelStyle">This field is mandatory.</label>
      )}
    </div>
  );
};

export default DropdownSelect;

import React from "react";
import {AhuStatus} from "./AHUStatus";
import './AHUDetailsCard.scss'
import TemperatureIcon from "../assets/ic_ahu_temperature.svg"
import EnergyIcon from "../assets/ic_ahu_energy.svg"
import FloorIcon from "../assets/ic_ahu_floor.svg"
import {MeterMap, Summary} from "../types";
import {convertMetersToMap, lastUpdatedTimeToString, roundToTwoDecimalNumber} from "../../../Utils/utils";

type AHUDetailsCardProps = {
    ahuDetails: Summary;
}

export const AHUDetailsCard: React.FC<AHUDetailsCardProps> = ({ahuDetails}) => {

    const metersMap: MeterMap = convertMetersToMap(ahuDetails?.meters);

    return (
        <div className="ahuCardParent">
            <div className="ahuDetailsCard">

                <div className="ahuRowOne">
                    {metersMap.get("TRIP_STATUS")?.meterValue &&
                        <div className="ahuTripped">
                            TRIPPED
                        </div>
                    }
                </div>

                <div className="ahuRowTwo">
                    <div className="ahuId">{ahuDetails?.assetId}</div>
                    <AhuStatus statusType={metersMap.get("STATUS")?.meterValue}></AhuStatus>
                </div>

                <div className="ahuRowThree">
                    <div className="ahuName">{ahuDetails?.assetName}</div>
                    <div className="ahuType">{metersMap.get("MODE")?.meterValue}</div>
                </div>

                <div className="ahuRowFour">
                    <div>
                        <div className="temperatureContent">
                            <img
                                className="temperatureIcon"
                                src={TemperatureIcon}
                                alt="Icon"
                            />
                            <span className={`ahuTemperatureText ${ahuDetails?.avgTemp > ahuDetails?.maxTemp ? 'redText' : ''}`}>
                                {roundToTwoDecimalNumber(ahuDetails?.avgTemp)}</span>
                            <span className={`ahuTemperatureText ${ahuDetails?.avgTemp > ahuDetails?.maxTemp ? 'redText' : ''}`}>
                                {/*{ToDo}*/}
                                °C</span>
                        </div>
                        <div className="temperateMinMax">
                            <span className="ahuMinMaxTemperatureTitle">Min</span>
                            <span
                                className="ahuMinMaxTemperatureText">{roundToTwoDecimalNumber(ahuDetails?.minTemp)}</span>
                            <span className="ahuMinMaxTemperatureText">°C -</span>
                            <span className="ahuMinMaxTemperatureTitle">Max</span>
                            <span
                                className="ahuMinMaxTemperatureText">{roundToTwoDecimalNumber(ahuDetails?.maxTemp)}</span>
                            <span className="ahuMinMaxTemperatureText">°C</span>
                        </div>
                    </div>

                    <div>
                        <div className="temperatureContent">
                            <img
                                className="temperatureIcon"
                                src={EnergyIcon}
                                alt="Icon"
                            />
                            <span
                                className="ahuTemperatureText">{ahuDetails?.energyConsumption} {ahuDetails?.energyUnit}</span>
                        </div>
                        <div className="temperateMinMax">
                            <span className="ahuMinMaxTemperatureText">Energy Consumption</span>
                        </div>
                    </div>
                </div>

                <div className="ahuRowFive">
                    <div className="floorContent">
                        <img
                            className="floorIcon"
                            src={FloorIcon}
                            alt="Icon"
                        />
                        <span className="floorText">{ahuDetails?.facilityName}</span>
                    </div>
                    <div className="updatedTime">{lastUpdatedTimeToString(ahuDetails?.lastUpdatedTime)}</div>
                </div>
            </div>
        </div>
    );
};

/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment";
import { number } from "prop-types";
import * as R from "ramda";
import { useState, useEffect } from "react";
import "./style.scss";

function IAQ({ data, abbre, dailyMeters,monthlyMeters,lastCommTime }:any) {
  const [airData, setAirData] = useState();
  const [airMonthData, setAirMonthData] = useState();
  const [airMassData, setAirMassData] = useState();
  useEffect(() => {
    dailyMeters.map((ele) => {
      ele.meterTypeId.includes("AIR_FLOW_AGR") && setAirData(ele);
    });
    dailyMeters.map((ele) => {
      ele.meterTypeId.includes("F_MASS") && setAirMassData(ele);
    });
    console.log(airMassData,"airMassData")
    !R.isEmpty(monthlyMeters) && !R.isNil(monthlyMeters) &&
    monthlyMeters.map((ele) => {
      ele.meterTypeId.includes("AIR_FLOW_AGR") && setAirMonthData(ele);
    });
    
  }, [dailyMeters,monthlyMeters]);
  const [latestTime, setLatestTime] = useState();
  const [co2, setCo2] = useState()
  const [pm1_0, setPm1_0] = useState()
  const [pm2_5, setPm2_5] = useState()
  const [pm10, setPm10] = useState()

  
  useEffect(() => {
    !R.isEmpty(lastCommTime) && !R.isNil(lastCommTime) && lastCommTime !== null && setLatestTime(lastCommTime) ;
  }, [lastCommTime]);

  useEffect(() => {
  
   

    data.map((ele:any) => {
      ele.meterTypeId.includes("CO2") && setCo2(ele);
    });
    data.map((ele:any) => {
      ele.meterTypeId.includes("PM1_0") && setPm1_0(ele);
    });
    data.map((ele:any) => {
      ele.meterTypeId.includes("PM2_5") && setPm2_5(ele);
    });
    data.map((ele:any) => {
      ele.meterTypeId.includes("PM10") && setPm10(ele);
    });
  }, [data]);
  return (
    <>
      <div className="lastUpdated">
        {!R.isEmpty(latestTime) && !R.isNil(latestTime) ? (
          <h3>Updated {moment(latestTime).fromNow()}</h3>
        ) : (
          <h3>&nbsp;</h3>
        )}
      </div>
      <div className="assetCard-colum">
        <div className="assetCard-col">
        
          <p>CO <sub>2</sub></p>
          {!R.isEmpty(co2) && !R.isNil(co2) && co2  !== undefined ? (
            <h2>
              {co2?.meterValue.toFixed(2)} <span>{co2?.readingUomDesc}</span>
            </h2>
          ) : (
            <h2>-</h2>
          )}
        </div>
       
        <div className="assetCard-col">
          <p>PM <sub>1.0</sub></p>
          {!R.isEmpty(pm1_0) && !R.isNil(pm1_0) && pm1_0  !== undefined ? (
            <h2>
              {pm1_0?.meterValue.toFixed(2)} <span>{pm1_0?.readingUomDesc}</span>
            </h2>
          ) : (
            <h2>-</h2>
          )}
        </div>
        {console.log("pm2_5",pm10)}
        <div className="assetCard-col">
        <p>PM <sub>2.5</sub></p>
          {!R.isEmpty(pm2_5) &&
          !R.isNil(pm2_5) &&
          pm2_5  !== undefined ?(
            <h2>
              {Number(pm2_5?.meterValue).toFixed(2)}
              <span> {pm2_5?.readingUomDesc}</span>
            </h2>
          ) : (
            <h2>-</h2>
          )}
        </div>

        <div className="assetCard-col">
        <p>PM <sub>10</sub></p>
          {!R.isEmpty(pm10) &&
          !R.isNil(pm10) &&
          pm10  != undefined ?(
            <h2>
              {Number(pm10?.meterValue).toFixed(2)}
              <span> {pm10?.readingUomDesc}</span>
            </h2>
          ) : (
            <h2>-</h2>
          )}
        </div>
      </div>
    </>
  );
}

export default IAQ;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import react ,{ useEffect, useState } from "react";
import "./IncidentSummary.scss";
import ReactEcharts from "echarts-for-react";
import LinkWithIcon from "../../../a1Components/FormElements/linkWithIcon";
import ButtonView from "../Components/ViewButton/ButtonView";
// import IncidentSummarySubCard from "../incidentSummarySubCard/incidentSummarySubCard";
import IncidentSummarySubCardHead from "./IncidentSummarySubCardHead";
import DisplayNote from "../../../a1Components/DisplayNote/DisplayNote";
import moment from "moment";
import axios from "axios";
import * as R from "ramda";
import DisplayError from "../../../a1Components/DisplayError/DisplayError";
import IncidentSummaryBtn from "../incidentSummaryBtn/IncidentSummaryBtn";
import IncidentSummaryCard from "../incidentSummaryCard/IncidentSummaryCard";

function IncidentSummary(props: any) {
  const { displayInc, dateRange } = props;
  const [buttomTab, setButtomTab] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [responseDetails, setResponseDetails] = useState([]);
  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const [chartOne, setChartone] = useState([]);
  const [chartTwo, setCharttwo] = useState([]);
  const [dateArray, setDateArray] = useState([0, 0, 0, 0, 0, 0]);

  useEffect(() => {
   
    
    
   
  }, [responseData])
  
  const testFucntionForAPI = async () => {
    setLoading(true);
    try {
    const startDate = moment(dateRange[0]).utc().valueOf();
    const endDate = moment(dateRange[1]).utc().valueOf();

      // http://13.232.50.238:8080/videoAnalytics/incident/metrics/?start=1678818600000&end=1678858976000
      const url = `https://hawkeye.aone.ai/analytics/videoAnalytics/incident/summary/?start=${startDate}&end=${endDate}`;
      const response = await axios.get(url);
      console.log(response,"dataaa")
      if (response.status === 200) {
        setResponseData(response?.data?.data);
        let chartTimeRnage =
        response?.data?.data?.stack_chart?.timeframe_range.map(
          (obj: moment.MomentInput) => moment(obj).format("D MMM")
        );
      setDateArray(chartTimeRnage);
      setChartone(response.data?.data?.stack_chart?.data[0]);
      setCharttwo(response.data?.data?.stack_chart?.data[3]);
       
      } else {
        alert(response);
         setResponseData([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
       setResponseData([]);
    }
  };
       
  const getIncidentSummaryDetailsData = async () => {
    setLoading(true);
    
    try {
      const startDate = moment(dateRange[0]).utc().valueOf();
      const endDate = moment(dateRange[1]).utc().valueOf();
      const url = `https://hawkeye.aone.ai/analytics/videoAnalytics/incident/list/?start=${startDate}&end=${endDate}&page=0&size=5&direction=1`;
      const response = await axios.get(url);
      console.log("vedio list",response)
      if (response.status === 200) {
        setResponseDetails(response?.data?.data);
      } else {
        setResponseDetails([]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setResponseDetails([]);
    }
  };

  useEffect(() => {
    testFucntionForAPI();
    getIncidentSummaryDetailsData();
  }, [dateRange]);

  // console.log("the printed state",chartOne,"two,",chartTwo,"three",dateArray)
  const onClick = () => {
    displayInc(false);
  };

  // let chartTimeRnage = responseData.stack_chart.timeframe_range.map((obj) =>
  //   moment(obj).format("D MMM")
  // );

  // .map((obj) => moment(obj).format('Do MMM'));

  const option1 = {
    tooltip: {},
    grid: {
      height: "70%",
    },
    xAxis: {
      data: dateArray,
    },
    yAxis: {
      show: false,
    },

    series: [
      // {
      //   name: chartOne?.name,
      //   type: "bar",
      //   stack: "one",
      //   color: "#0165FF",
      //   label: "bar1",
      //   data: chartOne?.heights,
      // },
      {
        name: chartTwo?.name,
        type: "bar",
        stack: "one",
        color: "#80B2FF",
        label: "bar1",
        data: chartTwo?.heights,
      },
    ],
    label: {
      show: true,
      position: "top",
    },
    barWidth: "30%",
  };

  const option2 = {
    tooltip: {},
    grid: {
      height: "70%",
    },
    xAxis: {
      data: dateArray,
    },
    yAxis: {
      show: false,
    },

    series: [
      {
        name: chartTwo?.name,
        type: "bar",
        stack: "one",
        color: "#0165FF",
        label: "bar",
        data: chartTwo?.heights,
      },
    ],

    label: {
      show: true,
      position: "top",
    },
    barWidth: "30%",
  };

  const option3 = {
    tooltip: {},
    grid: {
      height: "70%",
    },
    xAxis: {
      data: dateArray,
    },
    yAxis: {
      show: false,
    },

    series: [
      {
        name: chartTwo?.name,
        type: "bar",
        stack: "one",
        color: "#80B2FF",
        label: chartTwo?.type,
        data: chartTwo?.heights,
      },
    ],

    label: {
      show: true,
      position: "top",
    },
    barWidth: "30%",
  };

  const changeState = () => {
    setButtomTab(!buttomTab);
  };

  const [activeCardN, setActiveCardName] = useState("All Detections");

  const OnclickChange = (e: string) => {
     setActiveCardName(e);
  };
console.log("response data",responseData)
  return ( 
    <>
      {loading ? (
        <DisplayNote />
      ) : (
        <>
          { (
            <>
              {
                <>
                  <div className="incidentSummaryContainer">
                    <div className="incidentSummaryBtnContainer">
                      <div className="incidentSummaryBtnCompCont">
                        {!R.isEmpty(responseData) && !R.isNil(responseData) && responseData?.groupdata.map((value, index) => {
                          return (
                            <IncidentSummaryBtn
                              callBack={OnclickChange}
                              data={value}
                              state={activeCardN}
                              key={index}
                            />
                          );
                        })}
                      </div>

                      <div className="incidentSummarySelector">
                        <select className="incidentSelector">
                        <option value="Malaysia">Malaysia</option>
                        </select>
                      </div>
                    </div>
                    
                    {loading ? (
                      <DisplayNote />
                    ) : 
                    (
                     
                      <div>
                         {!R.isEmpty(responseData) && !R.isNil(responseData)  && activeCardN ===  responseData?.groupdata[0].name && (
                          <ReactEcharts option={option1} />
                        )}
                         {!R.isEmpty(responseData) && !R.isNil(responseData)  && activeCardN === responseData?.groupdata[3].name && (
                          <ReactEcharts option={option2} />
                        )}
                         {/* { !R.isEmpty(responseData) && !R.isNil(responseData)  && activeCardN === responseData.groupdata[2].name && (
                          <ReactEcharts option={option3} />
                        )} */}
                         
                       
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <ButtonView
                        callBackFun={changeState}
                        status={buttomTab}
                        text={"Hide Incident Details"}
                      />
                      <LinkWithIcon
                        onClick={onClick}
                        cardname="Asset Summary"
                        label="View All"
                        link="/asset-list"
                      />
                    </div>

                    {buttomTab && (
                      <>
                        <IncidentSummarySubCardHead />
                        {!R.isEmpty(responseDetails) && !R.isNil(responseDetails) && 
                        responseDetails.slice(0, 5).map((item, index) => {
                          return <IncidentSummaryCard data={item} key={index} />
                        })}
                      </>
                    )}
                  </div>
                </>
               }
            </>
         
          )}
        </>
      )}
    </>
  );
}

export default IncidentSummary;

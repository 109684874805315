import React, { useEffect, useRef, useState } from "react";
import "./EventSummaryTrendChart.scss";
import { TrendChartDataType } from "../../../a1Pages/AssetDetailPage/types/TrendChartDataType";

import ListDownIcon from "../../../assets/icons/listDownIcon.svg";
import SelectedBarChartIcon from "../assets/selectedBarChartIcon.svg";
import UnSelectedBarChartIcon from "../assets/unselectedBarChartIcon.svg";
import SelectedLineChartIcon from "../assets/selectedLineChartIcon.svg";
import UnselectedLineChartIcon from "../assets/unselectedLineChartIcon.svg";
import TrendBarChart from "../../../a1Pages/AssetDetailPage/charts/TrendBarChart";
import TrendLineChart from "../../../a1Pages/AssetDetailPage/charts/TrendLineChart";
import Switch from "../../../a1Components/FormElements/switch";
import RefreshIcon from "../assets/refreshIcon.svg";

type PropTypes = {
  data: TrendChartDataType;
  trendRange: string[];
  selectedTrendRange: string;
  setSelectedTrendRange: (value: string) => void;
  trendChartHeading: string;
  showRefreshButton: boolean;
  handleRefreshButton: () => void;
  lineChart: boolean;
  setLineChart: (value: boolean) => void;
};

function EventSummaryTrendChart({
  data,
  trendRange,
  selectedTrendRange,
  setSelectedTrendRange,
  trendChartHeading,
  showRefreshButton,
  handleRefreshButton,
  lineChart,
  setLineChart,
}: PropTypes) {
  const [showDropDown, setShowDropDown] = useState(false);
  const [dropdownWidth, setDropdownWidth] = useState(0);
  const [btnState, setBtnState] = useState(true);
  const dropdownRef = useRef(null);

  const chartSwitch = () => {
    setLineChart(!lineChart);
  };

  function capitalizeFirstLetter(value: string) {
    if (!value || typeof value !== "string") {
      return "";
    }
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  useEffect(() => {
    if (dropdownRef.current) {
      const width = dropdownRef.current.offsetWidth;
      setDropdownWidth(width);
    }
  }, [dropdownRef.current]);

  return (
    <>
      <div className="totalEventsOverHeadingDiv">
        <p className="totalEventsOverHeadingText">
          {capitalizeFirstLetter(trendChartHeading)} Events Over Time
        </p>
        {showRefreshButton && (
          <div className="refreshDiv" onClick={handleRefreshButton}>
            <p className="refreshText">Reset</p>
            <img src={RefreshIcon} className="refreshIcon" />
          </div>
        )}
      </div>

      {/* For Date Ragne Selector and Trend Chart Settings */}
      <div
        className="trendCartSettingMainCont"
        style={{
          justifyContent: trendRange.length > 1 ? "space-between" : "flex-end",
        }}
      >
        {trendRange.length > 1 && (
          <div className="dateRagneFrequenciesDiv">
            <div
              className="dropDownView"
              onClick={() => setShowDropDown(!showDropDown)}
              ref={dropdownRef}
            >
              <p className="dropDownText">Viewing {selectedTrendRange}</p>
              <img
                src={ListDownIcon}
                alt="icon"
                className={`arrowIcon ${
                  showDropDown ? "rotate-180" : "rotate-0"
                }`}
              />
            </div>
            {showDropDown && (
              <div
                className="dateRagneFrequenciesMenu"
                style={{ width: `${dropdownWidth}px` }}
              >
                {trendRange.map((item, index) => (
                  <div
                    className="dateRagneFrequenciesButton"
                    onClick={() => {
                      setSelectedTrendRange(item);
                      setShowDropDown(!showDropDown);
                    }}
                    style={{
                      borderBottom:
                        trendRange.length - 1 !== index
                          ? "1px solid rgba(189, 203, 251, 1)"
                          : "",
                    }}
                  >
                    <p className="dateRagneFrequenciesText">{item}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <div className="trendChartSettingCont">
          {!lineChart ? (
            <img
              loading="lazy"
              src={SelectedBarChartIcon}
              onClick={chartSwitch}
            />
          ) : (
            <img
              loading="lazy"
              src={UnSelectedBarChartIcon}
              alt="bar chart"
              onClick={chartSwitch}
            />
          )}

          {btnState ? (
            <Switch
              label=""
              id="online"
              checked={lineChart}
              onChange={chartSwitch}
              isAlignCenter={true}
              onToggle={undefined}
            />
          ) : (
            <Switch
              label=""
              id="online"
              checked={lineChart}
              onChange={() => null}
              isAlignCenter={true}
              onToggle={undefined}
            />
          )}
          {lineChart ? (
            <img
              src={SelectedLineChartIcon}
              loading="lazy"
              alt="bar chart"
              onClick={chartSwitch}
            />
          ) : (
            <img
              src={UnselectedLineChartIcon}
              loading="lazy"
              alt="bar chart"
              onClick={chartSwitch}
            />
          )}
        </div>
      </div>

      {!lineChart ? (
        <TrendBarChart xAxisData={data.xAsix} yAxisData={data.yAsix} />
      ) : (
        <TrendLineChart xAxisData={data.xAsix} yAxisData={data.yAsix} />
      )}
    </>
  );
}

export default EventSummaryTrendChart;

import dropDownOpenIcon from '../assets/dropdownOpenIcon.svg';
import dropDownCloseIcon from '../assets/dropdownCloseIcon.svg';
import './searchableFilters.scss'
import filterTickIcon from '../assets/selectedTikicon.svg'
import { useEffect, useMemo } from 'react';
import { ASSET_CATEGORY_HIERARCHY, FACILITY_HIERARCHY } from '../../../Constants/constants';
import PageFiltersState from '../utils/pageFiltersState';
import PageFiltersUtils from '../utils/PageFiltersUtils';

function SearchableFilters({ hierarchyDisabled, filtersMappingArr,handleSelectedFilter, filterHierarchyData }) {
    const {filteredData, setFilteredData,searchVal, setSearchVal, showData, setShowData, showMore, setShowMore} = PageFiltersState();
    const INITIAL_DISPLAY_COUNT = 14;
    const utils = new PageFiltersUtils();
    useEffect(() => {
        setFilteredData([...filtersMappingArr.filter((obj) => obj.isVisible)]);
    }, [filtersMappingArr])

    useEffect(() => {
        setFilteredData([...filtersMappingArr].filter((obj) => obj.isVisible).filter((el) => el?.name?.toLowerCase().includes(searchVal?.toLowerCase())))
    }, [searchVal]);

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };
    const visibleData = useMemo(() => {
        return showMore ? filteredData : filteredData.slice(0, INITIAL_DISPLAY_COUNT);
    }, [filteredData, showMore, INITIAL_DISPLAY_COUNT]);

    console.log('SREEEEEE:::',filtersMappingArr);

    return (
        <div className='searchableFiltersMainContainer'>
            <div className='iconAndNameContainer' onClick={() => setShowData(!showData)}>
                <img src={showData ? dropDownOpenIcon : dropDownCloseIcon} alt="dropdown icon" />
                <p>{utils.changeLabelsAccordingToRequirement(filtersMappingArr[0]?.group)}</p>
            </div>
            <div className={`filterSearchInputContainer ${showData ? 'show' : ''}`}>
                <input
                    value={searchVal}
                    className='filterSearchInput'
                    type='search'
                    placeholder='Search...'
                    onChange={(e) => setSearchVal(e.target.value)}
                />
            </div>
            <div className={`filterChipsContainer ${showData ? 'show' : ''}`}>
                {filteredData.length === 0 ? (
                    <p>No Results..</p>
                ) : (
                    <>
                    
                        {visibleData.map((el) => (
                            <div
                                key={el.id}
                                className={`filterChipButtonContainer ${el.isSelected ? 'active' : ''}`}
                                onClick={() => {
                                    handleSelectedFilter(el, ASSET_CATEGORY_HIERARCHY.includes(el.group) ? 1 : FACILITY_HIERARCHY.includes(el.group) ? 2 : 0);
                                    ASSET_CATEGORY_HIERARCHY.includes(el.group) ? !['Status', 'Asset Type'].includes(el.group) && filterHierarchyData(1 , el.group, ['', ''])
                                : FACILITY_HIERARCHY.includes(el.group) && !['Sub Locations'].includes(el.group) && hierarchyDisabled &&
                                   filterHierarchyData(2, el.group, ['', '']);
                                }}
                            > {el.isSelected && <img src={filterTickIcon} alt="selected" />}
                                <p>{el.name}</p>
                            </div>
                        ))}
                        {filteredData.length > INITIAL_DISPLAY_COUNT && (
                            <p onClick={toggleShowMore} className='seeMoreText'>
                                {showMore ? 'See Less' : 'See More'}
                            </p>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default SearchableFilters;


/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-lone-blocks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
// import './Searchbar.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import assetIcon from "../../../../assets/image/asset-icon.svg";
import CloseButton from "../../../../assets/icons/CloseButton.svg";
import SearchIcon from "../../../../assets/icons/iconSearchWhite.svg";
import Iconfeathersearch from "../../../../assets/icons/Icon feather-search.svg";
import { height } from "@mui/system";
import { useStateValue } from "../../../../redux/StateProvider";
import "./Searchbar.scss";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

function Searchbar({
  searchPlaceholder,
  label,
  label1,
  label2,
  historyList,
  slot,
  slot2,
}) {
  const [search, setSearch] = useState("");
  const [hovercard, setHoverCord] = useState(false);
  const [searchBar, setSearchBar] = useState(false);
  const [{ searchText }, dispatch] = useStateValue();
  const [searchEnabled, setSearchEnabled] = useState(false);

  let headerList = ["Asset Name", "Asset Id"];

  const [selectTagStatus, setSelectTagstatus] = useState(false);
  let headers = [...headerList];
  const [selectTagText, setSelectTagText] = useState(headers[0]);

  function mouseLeave() {
    setSelectTagstatus(false);
  }

  const [searchkey, setSearchkey] = useState("");

  const handleSearchBar = (e) => {
    {
      searchBar ? setSearchBar(false) : setSearchBar(true);
    }
  };

  const myMouseEnter = () => {
    {
      hovercard ? setHoverCord(false) : setHoverCord(true);
    }
  };

  const captureText = () => {
    console.log("the selected value", selectTagText, searchkey);
    let value = [
      {
        targetVal: selectTagText,
        serchVal: searchkey,
      },
    ];
    dispatch({ type: "SET_SEARCH_TEXT", searchText: value });
    setSearchEnabled(true);
  };

  const updateKey = (value: string) => {
    setSearchkey(value);
    if (value === "" && searchEnabled) {
      closeSetup();
    }
    // let value =[{
    //   "targetVal": selectTagText,
    //   "serchVal" : searchkey

    // }]
    // dispatch({ type: "SET_SEARCH_TEXT", searchText:value});
  };

  const closeSetup = () => {
    setSearchkey("");
    let value = [
      {
        targetVal: selectTagText,
        serchVal: "",
      },
    ];
    dispatch({ type: "SET_SEARCH_TEXT", searchText: value });
    setSearchEnabled(false);
  };

  useEffect(() => {
    const fetchData = () => {
      if (searchText !== "") {
        setSelectTagText(searchText[0]?.targetVal);
        setSearchkey(searchText[0]?.serchVal);
        setSearchBar(true);
      }
    };
    if (slot2) {
      fetchData();
    }
  }, [searchText]);

  return (
    <>
      {slot && (
        <div className="searchbar">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder={searchPlaceholder}
              onChange={captureText}
              value={""}
              readOnly
            />
            <img
              src={Iconfeathersearch}
              className="faSearch"
              onClick={handleSearchBar}
              style={{
                position: "absolute",
                color: "blue",
                right: "11px",
                top: "7px",
                width: "18px",
                height: "22px",
              }}
            />
            {searchBar ? (
              <div className="text-field-position search-box">
                <input
                  id="try-different-email"
                  type="text"
                  name="name"
                  placeholder="name"
                  className="email-input"
                  value={""}
                  readOnly
                />
                <span className="removeClick" onClick={handleSearchBar}>
                  &times;
                </span>
              </div>
            ) : null}
            <div className="hover-according" onMouseEnter={myMouseEnter}>
              <button type="button" className="btn ">
                {label}
              </button>
              <button type="button" className="btn ">
                {label1}
              </button>
              <button type="button" className="btn ">
                {label2}
              </button>
              <p>Search created for you</p>
              <div className="history_List">
                <img src={assetIcon} />
                <p>{historyList}?</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {slot2 && (
        <div className="assetSearchCont">
          <div className="left-element" onMouseLeave={mouseLeave}>
            <DropdownButton
              id="dropdown-variants-secondary"
              className="width-100"
              variant="variant"
              title={selectTagText}
              // disabled={searchState}
              size="lg"
            >
              {headers.map((item) => (
                <Dropdown.Item
                  key={item}
                  onClick={() => {
                    setSelectTagText(item);
                  }}
                >
                  {selectTagText === item ? (
                    <span>&#10003; &nbsp;</span>
                  ) : (
                    <span>&nbsp; &nbsp; &nbsp;</span>
                  )}
                  {item}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          <input
            type="text"
            className="middle-element"
            placeholder={"Search"}
            value={searchkey}
            onChange={(e) => {
              updateKey(e.target.value);
              //setSearchkey(e.target.value);
              // inputData(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.keyCode === 13) {
                e.preventDefault(); // Prevent the default form submission behavior
                captureText();
              }
            }}
          />

          {searchkey === "" ? (
            <div className={"right-element-close"}>
              <span className={`icon-search `} onClick={captureText} />
            </div>
          ) : (
            <div className="searchIconDiv">
              <img
                className="closeIcon"
                src={CloseButton}
                onClick={closeSetup}
              />
              <div className="searchButtonDiv" onClick={captureText}>
                <img className="searchIcon" src={SearchIcon} />
                <p className="searchBtnText">Search</p>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
export default Searchbar;

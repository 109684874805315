/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment";
import { number } from "prop-types";
import * as R from "ramda";
import { useState, useEffect } from "react";
import "./style.scss";

function Fuel({ data, abbre, dailyMeters,lastCommTime }:any) {
  const [kwhData, setKwhData] = useState();
  useEffect(() => {
    dailyMeters.map((ele) => {
      ele.meterTypeId.includes("Wh_EB") && setKwhData(ele);
    });
  }, [dailyMeters]);
  const [latestTime, setLatestTime] = useState();
  const [powerFactor, setPowerFactor] = useState();
  useEffect(() => {
   
    data.map((ele) => {
      ele.meterTypeId.includes("POWER_FACTOR") &&
        setPowerFactor(ele.meterValue);
    });
  }, [data]);
  useEffect(() => {
    !R.isEmpty(lastCommTime) && !R.isNil(lastCommTime) && lastCommTime !== null && setLatestTime(lastCommTime) ;
  }, [lastCommTime]);
  return (
    <>
      <div className="lastUpdated">
        {!R.isEmpty(latestTime) && !R.isNil(latestTime) ? (
          <h3>Updated {moment(latestTime).fromNow()}</h3>
        ) : (
          <h3>&nbsp;</h3>
        )}
      </div>
      <div className="assetCard-colum">
        <div className="assetCard-col">
          {/* {!R.isEmpty(data) ? (
          data.map((el) => {
            el.meterTypeId.includes("Consumed") && (
              <h2>
                {el?.meterValue} - <span>{el?.readingUomDesc}</span>
              </h2>
            );
          })
        ) : (
          <h2></h2>
        )} */}
          <p>Today Fuel Consumed </p>
          {!R.isEmpty(kwhData) ? (
            <h2>
              {/* {kwhData?.sum.toFixed(2)} <span>{kwhData?.defaultUom}</span> */}
            </h2>
          ) : (
            <h2></h2>
          )}
        </div>

        <div className="assetCard-col">
          <p>MTD Fuel Consumed </p>
          {!R.isEmpty(data) ? (
            data.map((el) => {
              el.meterTypeId.includes("Score") && (
                <h2>
                  {/* {el.meterValue}%{" "}
                  <span className={`assetCard-col-badge ${el.grade}`}>
                    {el.grade}
                  </span> */}
                </h2>
              );
            })
          ) : (
            <h2></h2>
          )}
        </div>

        <div className="assetCard-col">
          <p>Average Flow Rate</p>
          {!R.isEmpty(powerFactor) &&
          !R.isNil(powerFactor) &&
          typeof powerFactor == "number" ? (
            <h2>
              {/* {Number(powerFactor).toFixed(2)}
              <span className={`assetCard-col-badge `}></span> */}
            </h2>
          ) : (
            <h2>-</h2>
          )}
        </div>
      </div>
    </>
  );
}

export default Fuel;

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-const-assign */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
//@ts-nocheck

import React from "react";
import StackedBarChart from "../E-Charts/StackedBarChart";
import useState from "react-usestateref";
import "./AnoviAlertComp.scss";
import StackedBarAnovi from "../E-Charts/stackedHorizontalBar/StackedBarAnovi";
import Group1326 from "../../assets/image/Group1326.svg";
import Group1327 from "../../assets/image/Group1327.svg";
import Group7946 from "../../assets/image/Group7946.png";
import upArreow from "../../assets/icons/SopCountImg.svg";

function AnoviAlertComp() {
  const [data, setData] = useState({
    totalAlerts: "140",
    open: "48",
    close: "60",
    resolution: "3.14",
  });

  var option = {
    color: ["#ff6b2d", "#4caf50"],
    legend: {
      icon: "rect",
      left: 20,
       bottom: "-5px",
    },
    tooltip: {},
    dataset: {
      dimensions: ["product", "open", "close"],
      source: [
        { product: "13 jan", open: 43.3, close: 85.8 },
        { product: "14 jan", open: 83.1, close: 73.4 },
        { product: "15 jan", open: 43.3, close: 85.8 },
        { product: "16 jan", open: 83.1, close: 73.4 },
        { product: "17 jan", open: 43.3, close: 85.8 },
        { product: "18 jan", open: 83.1, close: 73.4 },
      ],
    },
    xAxis: { type: "category" },
    yAxis: {},
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: [{ type: "bar" }, { type: "bar" }],
  };

  return (
    <div className="alertComp">
      <div className="alertComp_Header">
        <div className="dataDiv">
          <div className="title">
            <h3>Total Alerts Today</h3>
          </div>
          <div className="count">
            <h1>{data.totalAlerts}</h1>
            <h5>
              <img src={upArreow} className="percentageIcon" /> lesser than
              yesterday
            </h5>
          </div>
          <div className="status">
            <div className="circle">
              <div>
                <img src={Group1326} />
              </div>
              <h3>
                {data.open} <span>Open</span>
              </h3>
            </div>
            <div className="circle">
              <div>
                <img src={Group1327} />
              </div>
              <h3>
                {data.close} <span>Closed</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="alertComp_chart">
        {/* <StackedBarChart /> */}
        <StackedBarAnovi data={option} comp="alerts" />
      </div>
      <div className="time">
        <div>
          <img src={Group7946} />
        </div>
        <div>
          <div className="divStyle">
            <h3> Mean time of resolution</h3>
            <h1>
              {data.resolution} mins
              <span> <img src={upArreow} style={{width:10,height:10}} />more than yesterday</span>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnoviAlertComp;

/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment";
import * as R from "ramda";
import { useState, useEffect } from "react";
import "./style.scss";

function WaterPulse({
  data,
  abbre,
  dailyMeters,
  monthlyMeters,
  lastCommTime,
}: any) {
  const [waterData, setWaterData] = useState();
  const [waterMonthData, setWaterMonthData] = useState();
  useEffect(() => {
    dailyMeters.map((ele) => {
      ele.meterTypeId.includes("WATER_VOLUME") && setWaterData(ele);
    });
    monthlyMeters.map((ele) => {
      ele.meterTypeId.includes("WATER_VOLUME") && setWaterMonthData(ele);
    });
  }, [dailyMeters, monthlyMeters]);
  const [latestTime, setLatestTime] = useState();

  useEffect(() => {
    !R.isEmpty(lastCommTime) &&
      !R.isNil(lastCommTime) &&
      lastCommTime !== null &&
      setLatestTime(lastCommTime);
  }, [lastCommTime]);
  return (
    <>
      <div className="lastUpdated">
        {!R.isEmpty(latestTime) && !R.isNil(latestTime) ? (
          <h3>Updated {moment(latestTime).fromNow()}</h3>
        ) : (
          <h3>&nbsp;</h3>
        )}
      </div>
      <div className="assetCard-colum">
        <div className="assetCard-col">
          <p>Today Water Consumed </p>
          {!R.isEmpty(waterData) ? (
            <h2>
              {waterData?.sum.toFixed(2)} <span>{waterData?.defaultUom}</span>
            </h2>
          ) : (
            <h2></h2>
          )}
        </div>

        <div className="assetCard-col">
          <p>MTD Water Consumed </p>
          {!R.isEmpty(waterMonthData) ? (
            <h2>
              {waterMonthData?.sum.toFixed(2)}{" "}
              <span>{waterMonthData?.defaultUom}</span>
            </h2>
          ) : (
            <h2></h2>
          )}
        </div>
      </div>
    </>
  );
}

export default WaterPulse;

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateRangePicker } from "react-date-range";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import './DateRangeSelectorInput.scss';
import DateFormat from '../DateFormatters/DateFormat';
import {ReportDatePickerData} from '../DataTypes';


const DateRangeSelectorInput = ({ PlaceHolder,state,activeInput, field, title, value, updateDateRange }: ReportDatePickerData) => {
  const [calenderPopUpScreen, setCalenderPopupScreen] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: moment().startOf("day").valueOf(),
      endDate: moment().endOf("day").valueOf(),
      key: "selection",
    },
  ]);

  const [dateRangeValue, setDateRangeValue] = useState({});
  const [duration, setDuration] = useState(0);
  const dateFormatter = new DateFormat();


  const applyAction = () => {
    updateDateRange(dateRangeValue);
    setCalenderPopupScreen(false);
  };

  useEffect(() => updateDateRange(dateRange[0]), []);

  const handleDateChange = (ranges: any) => {
    setDateRangeValue(ranges.selection);
    setDateRange(() => [ranges.selection]);
    const startDate = moment(ranges.selection.startDate, "DD/MM/YYYY");
    const toDate = moment(ranges.selection.endDate, "DD/MM/YYYY");
    const durationInDays = toDate.diff(startDate, "days") + 1;
    setDuration(durationInDays);
  };

  const handleClick = () => {
    activeInput();
    setCalenderPopupScreen(true);
  }

  return (
    <div className="dateInputCardContainer">
      <h4 className="tempInputTitleText">{title}{field === "Y" ? " * " : ""}</h4>
      <div className="styleInp">
        <input type="text" onClick={handleClick} required={true}
          value={value.startDate && moment(value.startDate).format("DD/MM/YYYY") + "-" +  moment(value.endDate).format("DD/MM/YYYY")} className="inputTempTextValue" placeholder={PlaceHolder} />
        <div className='calenderIcon'>
          <FontAwesomeIcon onClick={handleClick} icon={faCalendar} />
        </div>

      </div>
      {calenderPopUpScreen && state ? (
        <div className="selectCustomDate">
          <DateRangePicker ranges={dateRange} onChange={handleDateChange} showDateDisplay={false} dateDisplayFormat="DD/MM/YYYY" maxDate={new Date()} minDate={dateFormatter.get365DaysAgo()} />
          {duration > 31 ?
            <div className='warningMsgClass'>
              <p className='warningMsg'>Cannot be greater than 31 days.</p>
            </div> : null
          }
          <div className="bottonButtonsDiv">
            <button onClick={() => setCalenderPopupScreen(false)} className="cancelButton"> Cancel </button>
            {
             duration <= 31 ?
                <button onClick={applyAction} className="applyButton"> Apply </button> :
                <button className="applyButtonOpacity"> Apply </button>
            }
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default DateRangeSelectorInput
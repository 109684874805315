import { getTrendChartKey } from "../../../Constants/constants";
import { roundToTwoDecimalNumber } from "../../../Utils/utils";
import {
  ResponseDataType,
  TrendChartData,
  TrendChartDataType,
} from "../types/TrendChartDataType";

export default class TrendChartUtils {
  getKeyByValue(
    object: Record<string, string>,
    value: string
  ): string | undefined {
    return Object.keys(object).find((key) => object[key] === value);
  }

  extractData(data: object, meterTypeIdsList: string[]) {
    const xAsixData: string[] = [];
    const yAxisData: TrendChartData[] = [];

    for (const date in data?.trendSummary) {
      const entries: ResponseDataType[] = data?.trendSummary[date];
      if (entries.length !== 0) {
        xAsixData.push(entries[0].dateValue);
        entries.forEach((entry: ResponseDataType) => {
          if (meterTypeIdsList.includes(entry.type)) {
            const value =
            entry.type === "KWH" || entry.type === "Wh_EB" || entry.type === "AIR_FLOW_AGR" || entry.type === "WTR_FLOW_AGR"
                ? entry.sum
                : entry.average;
            const temp = yAxisData.find(
              (item: TrendChartData) => item.key === entry.type
            );
            if (temp === undefined) {
              const keyName = this.getKeyByValue(getTrendChartKey, entry.type);
              const yAxisDataObj: TrendChartData = {
                name: keyName,
                key: entry.type,
                data: [roundToTwoDecimalNumber(value)],
              };
              yAxisData.push(yAxisDataObj);
            } else {
              const dataObj = [...temp.data, roundToTwoDecimalNumber(value)];
              temp.data = dataObj;
            }
          }
        });
      }
    }

    const result: TrendChartDataType = {
      xAsix: xAsixData,
      yAsix: yAxisData,
    };

    return result;
  }
}

import { useState } from 'react'
import InActiveMeterIcon from "../../../assets/icons/metersInActiveIcon.svg"
import EnergyVoltsIcon from "../../../assets/icons/energyVoltsIcon.svg"
import ActiveMeterIcon from "../../../assets/icons/activeEnergyIcon.svg"
import EnergyNavIcon from "../../../assets/icons/energyNavIcon.svg"
import mainMeterActiveIcon from '../Assets/MainMeterActiveIcon1.svg'
import mainMeterInactiveIcon from '../Assets/MainMeterInActiveIcon.svg'
import * as R from "ramda";
import "./MeterCard.scss"

function MeterCard(props: any) {
    const {mainMeter ,data, decimalVal, changeMeterId, active, tabsArr, activeTab, consumption , abbreviation } = props;
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: decimalVal,
    });
    const [showActiveMainMeter, setActiveMainMeter] = useState(false);
    return (
        <>
        {
            mainMeter ? 
                    <div onMouseLeave={()=> setActiveMainMeter(false)} onMouseEnter={()=> setActiveMainMeter(true)} className={active ? 'mainMeterCard activeMeter' : 'mainMeterCard'} onClick={() => changeMeterId(data.fixedAssetId)} >
                        <img src={(active || showActiveMainMeter) ? mainMeterActiveIcon : mainMeterInactiveIcon} alt='mainMeterIcon' />
                        <div>
                            <p className='mainMeterAssetName'>{data.fixedAssetName}</p>
                            <p className='mainMeterAssetValue'>{consumption == null ? '- ' : formatter.format(consumption)} {consumption !== null && abbreviation}</p>

                        </div>
                    </div> 
            :  
            <div className={active ? 'meterCard activeMeter' : 'meterCard'} onClick={() => changeMeterId(data.fixedAssetId)}>
            <div className='metricIconsContainer'>
                {
                    tabsArr[+activeTab].name === "Energy" ? <> {<img src={!active ? InActiveMeterIcon : ActiveMeterIcon} alt="icon" className="inactiveIcon" /> }
                    </> : <>{<img src={tabsArr[+activeTab].subMeterIcon} alt="icon" className="inactiveIcon" />}</>}
                {/* {active && <img src={EnergyNavIcon} alt="icon" />} */}
            </div>

            <p className='meterCardHeader'><span>{data.fixedAssetName}</span></p>
            <div className="meterValuesCont">
                <img src={tabsArr[+activeTab].consumedIcon} alt="icon" className="voltsIcon" />
                <p className="meterValues">
                {/* meterValues */}
                    {
                      consumption == null ? '- ' :  formatter.format(consumption)
                    } {consumption !== null && abbreviation}</p>
            </div>
            {/* {data.classEnum !== 'Sub meter' && data.classEnum !== null && <p className='mainMeterDesc'> {data.classEnum} </p>} */}
        </div>
        }
        </>
      
    )
}

export default MeterCard
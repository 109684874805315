import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { SidebarData } from "./sidenavBarData";
import "../../App_one.scss";
import * as R from "ramda";
import LogoutIcon from "../../assets/icons/icon-logout.svg";
import AssetDataUtil from "../../Utils/AssetDataUtil";
import { useStateValue } from "../../redux/StateProvider";
import AssetFilterUtil from "../../Utils/AssetFilterUtil";
import ReactTooltip from "react-tooltip";
import settingsicon from "./settings-icon.svg";

function SideNavBar({ pages,updatePathName  }:any) {
  console.log("pagesinside sidenav",pages)
  const [{ assetFilters }, dispatch] = useStateValue();

  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  const [Data, setData] = useState(SidebarData);
  const [isLogin, setIslogin] = useState(false);

  const logOut = () => {
    var logOutval = "false";
    localStorage.clear();
    localStorage.setItem("loginState", logOutval);
    window.location.href = "/";
    setIslogin(false);
  };

  useEffect(() => {
    const filterData:any = [];
    if (!R.isNil(pages) && !R.isEmpty(pages)) {
      SidebarData.map((ele, id) => {
        if (pages.includes(ele.id)) {
          filterData.push(ele);
        }
      });
    }
    console.log(filterData, "filterDataNav", pages);
    setData(filterData);
  }, [pages]);

  const sideBarBtnClick = async (path: any) => {
    // const assetFilterUtil = new AssetFilterUtil();
    // const emptyFilters = await assetFilterUtil.getClearAssetFilters(
    //   Object.assign({}, assetFilters)
    // );
    // dispatch({ type: "UPDATE_ASSET_FILTERS", assetFilters: emptyFilters });
    // console.log("emptying asset filters");
    if (path === "/asset-list") {
      const assetFilterUtil = new AssetFilterUtil();
      const emptyFilters = await assetFilterUtil.getClearAssetFilters(
        Object.assign({}, assetFilters)
      );
      dispatch({ type: "UPDATE_ASSET_FILTERS", assetFilters: emptyFilters });
      dispatch({
        type: "SET_DASHBOARD_FILTERS",
        dashboardFilters: [],
      });
    }

    updatePathName(path);
  };


  return (
    <div className="navBar-menu">
     
      <ul onClick={showSidebar}>
     
        {Data.map((item, index) => {
          return (
            <li key={index}
            
            >
              <NavLink
               data-tip={item.title}
                to={item.path}
                activeclassname="active"
                onClick={() => {
                  sideBarBtnClick(item.path)
                  dispatch({ type: "SET_SEARCH_TEXT", searchText:""});
                }
                }
              
              >
                {item.icon}
                
              </NavLink>
              <ReactTooltip className="reactToolTip" />
            </li>
          );
        })}
       
        <li className="logout_icon">
          <img src={LogoutIcon} onClick={logOut} />
        </li>
      </ul>
    </div>
  );
}

export default SideNavBar;

import { SettingsApiServices } from "../../../services/settingsPageServices/SettingsApiServices";
import LocalStorageService from "../../../Utils/LocalStorageService";
import AssetFilterData from "../../assetList/utils/AssetFilterData";
import * as R from "ramda";

export default class PageFiltersServices {
    getAssetFilterData = async (setFilterDataList) => {
        let tokenID = LocalStorageService.getTokenData();
        const services = new SettingsApiServices();
        const response = await services.FacilityStatistics(tokenID);
    
        if (
          !R.isEmpty(response) &&
          !(
            response.statusCode == "BAD_REQUEST" ||
            response.responseMessage == "error"
          )
        ) {
          const utils = new AssetFilterData();
          const newFilterList = utils.getAssetFilterData();
          setFilterDataList(newFilterList);
        }
      };
}
import { Offcanvas } from "react-bootstrap";
import { useEffect} from "react";
import "./pageFilterModal.scss";
import closeIcon from '../assets/icon-close.svg';
import SearchableFilters from "../components/searchableFilters";
import { FilterUtils } from "../../assetList/utils/FilterUtils";
import PageFiltersUtils from "../utils/PageFiltersUtils";
import PageFiltersState from "../utils/pageFiltersState";
import ScrollButton from "../components/ScrollButton";

const PageFilterModal = ({ controlPageFiltersGroups, filtersData, setFilterDataList, show, handleModal, updateSearchList }) => {
    const pageFiltersUtils = new PageFiltersUtils();
    const {hierarchyDisabled, setHierarchyDisabled, currentFiltersRef,setCurrentFilters, setIsApplyDisabled, isApplyDisabled, scrollableContainerRef, isAtBottom, setIsAtBottom, isAtTop, setIsAtTop, setFiltersMappingArr, filtersMappingArr } = PageFiltersState()
    useEffect(() => {
        setFiltersMappingArr([]);
        setCurrentFilters(JSON.parse(JSON.stringify(filtersData.current)));
        setHierarchyDisabled([...new Set(filtersData?.current?.filter((obj)=> obj?.hierarchyList?.length >= 6 )[0]?.data?.map((obj)=> obj?.group))].length ===1 ? false : true )
    }, [filtersData.current])

    useEffect(() => {
        pageFiltersUtils.formatData(setFiltersMappingArr, currentFiltersRef);
    }, [currentFiltersRef.current])

    const handleSelectedFilter = (obj, index) => {
        pageFiltersUtils.handleSelectedFilter(obj, index, setCurrentFilters);
    };

    const filterHierarchyData = (index, group, hierarchyList) => {
        const utils = new FilterUtils();
        const response = utils.filterHierarchyData(index, group, hierarchyList, currentFiltersRef.current);
        setCurrentFilters(response);
    };

    const handleCancel = () => {
        setCurrentFilters(JSON.parse(JSON.stringify(filtersData.current)));
        handleModal(false);
    };

    const handleApply = () => {
        setFilterDataList(currentFiltersRef.current);
        handleModal(false);
        updateSearchList(currentFiltersRef)
    };

    useEffect(() => {
        // Comparing currentFilters and filtersData.current to enable/disable Apply button
        const isSame = pageFiltersUtils.deepCompare(currentFiltersRef.current, filtersData.current);
        setIsApplyDisabled(isSame);
    }, [currentFiltersRef.current, filtersData.current]);

    return (
        <Offcanvas show={show} onHide={() => handleCancel()} placement="end" className="custom-offcanvas">
            <div className="pageFiltersModalContainer">
                <div className="modalHeaderContainer">
                    <p>Filter</p>
                    <div className="modalHeaderCloseIcon" onClick={() => handleCancel()}>
                        <img src={closeIcon} alt="Close" />
                    </div>
                </div>
                {/* Scroll up button (only showing if not at the top) */}
                {!isAtTop && (
                    <ScrollButton type={'up'} scrollableContainerRef={scrollableContainerRef} setIsAtBottom={setIsAtBottom} setIsAtTop={setIsAtTop} />
                )}
                {/* Scrollable container */}
                <div
                    className="modalBodyScrollableContainer"
                    ref={scrollableContainerRef}
                    onScroll={() => pageFiltersUtils.checkScrollPosition(scrollableContainerRef.current,  setIsAtBottom, setIsAtTop)}
                >
                    {filtersMappingArr.length !== 0 && filtersMappingArr.filter((el) => el.length !== 0 &&  controlPageFiltersGroups[el[0].group] ).map((el) =>{
                        console.log('groupType::',el)
                       return <SearchableFilters hierarchyDisabled={hierarchyDisabled} key={el[0]?.group} filtersMappingArr={el} handleSelectedFilter={handleSelectedFilter}
                            filterHierarchyData={filterHierarchyData} />
})
                            }
                </div>
                {/* Scroll down button (only showing if not at the bottom) */}
                {!isAtBottom && (
                    <ScrollButton type={'down'} scrollableContainerRef={scrollableContainerRef} setIsAtBottom={setIsAtBottom} setIsAtTop={setIsAtTop} />
                )}
                <div className="modalBottomButtonsContainer">
                    <div className="filterApplyButton" style={{ cursor: isApplyDisabled ? 'not-allowed' : 'pointer', opacity: isApplyDisabled ? 0.5 : 1 }} onClick={() => !isApplyDisabled && handleApply()} >
                        Apply
                    </div>
                    <div className="filterCancelButton" onClick={() => handleCancel()}> Cancel </div>
                </div>
            </div>
        </Offcanvas>
    );
};

export default PageFilterModal;

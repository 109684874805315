/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./style.scss";

function LinkWithIcon({ cardname, label, link, onClick }) {
  return (
    <div className="linkWithIcon">
      {/* <Link to={link} className="viewAll" onClick={() => onClick(cardname)}> */}
      <a className="viewAll" onClick={onClick}>
        {label}
        <FontAwesomeIcon icon={faAngleRight} className="viewall-icon normal" />
        <FontAwesomeIcon icon={faArrowRight} className="viewall-icon hover" />
      </a>
      {/* </Link> */}
    </div>
  );
}

export default LinkWithIcon;

/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState, useEffect } from "react";
import "./CustDoughnut.scss";
import * as R from "ramda";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";

function CustDoughnut({ right, data }: any) {
  console.log("the right", right);
  const [percentage, setPercentage] = useState(0);
  const [color, setColor] = useState("red");

  useEffect(() => {
    console.log("datadatadata", data.complianceSummary);
    if (
      !R.isEmpty(data?.complianceSummary) &&
      !R.isNil(data?.complianceSummary)
    ) {
      setPercentage(data?.complianceSummary[0]?.compliancePercent);
    } else {
      setPercentage(0);
    }
  }, [data]);

  useEffect(() => {
    if (!R.isEmpty(percentage) && !R.isNil(percentage) && percentage >= 50) {
      setColor("green");
    } else if (
      !R.isEmpty(percentage) &&
      !R.isNil(percentage) &&
      percentage >= 25
    ) {
      setColor("orange");
    } else {
      setColor("red");
    }
  }, [percentage]);

  const getDonughtData = async () => {
    // const url = `https://aone-qa.app.aone.ai/api/1.0/assets/complianceSummary?username=superadmin@si&password=si@superadmin&fromDate=1680373799000&thruDate=1680546599000`;
    // const resp = await axios.get(url, {
    //   method: "GET",
    //   headers: { "Content-Type": "application/json" },
    // })
    // console.log("the graph data",resp?.data?.complianceSummary[0]?.compliancePercent)
  };

  const strokeWidth = 10;
  const radius = 40;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (circumference * percentage) / 100;

  var option = {
    series: [
      {
        type: "pie",
        radius: ["65%", "80%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: false,
          },
        },
        data: [
          { value: parseFloat(percentage), name: "Label 1" },
          { value: 100-parseFloat(percentage), name: "Label 2" },
        ],
        tooltip: {
          show: false,
        },
        silent: true,
      },
    ],
    color: ['#4CAF50', '#E1E3F0'], // Custom colors for each data item
    graphic: [
      {
        type: "text",
        left: "center",
        top: "middle",
        style: {
          text: `${percentage}%`, // Text to be inserted inside the donut chart
          textAlign: "center",
          fill: "#000000", // Color of the text
          fontWeight: "bold",
          fontSize: 12,
        },
      },
    ],
  };
  return (
    <div
      style={{
        display: "flex",
        height: "25vh",
        flexDirection: "row",
        justifyContent: "flex-start",
        marginLeft: "3vw",
        padding: "2%",
        alignItems: "center",
      }}
    >
      <div style={{width:'25%'}}>
        <ReactEChartsCore
          echarts={echarts}
          option={option}
          notMerge={true}
          lazyUpdate={true}
          theme={"theme_name"}
        />
      </div>
      <h5 style={{ fontWeight: "bold" }}>Compliant</h5>
      {/* <svg className="chart" width="200" height="200"> */}
      {/* <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="green" />
            <stop offset="50%" stopColor="green" />
            <stop offset="100%" stopColor="green" />
          </linearGradient>
        </defs> */}
      {/* <circle
          className="background"
          cx="100"
          cy="100"
          r={radius}
          strokeWidth={strokeWidth}
        /> */}
      {/* <circle
          className="progress"
          cx="100"
          cy="100"
          r={radius}
          strokeWidth={strokeWidth}
          stroke="url(#gradient)"
          style={{
            strokeDasharray: `${circumference} ${circumference}`,
            strokeDashoffset: offset,
          }}
        /> */}
      {/* <text className="percentage" x={right ? "14%" : "19%"} y="53%">
          {percentage}%
        </text>
        <text className="percentageName" x={right ? "28%" : "38%"} y="53%">
          Compliant
        </text> */}
      {/* <text className="percentageName descriptionText" x="39%" y="61%">
          <span>4.7%</span>4.7
        </text>
        <text className="descriptionText" x="44%" y="61%">
          more than yesterday
        </text> */}
      {/* </svg> */}
    </div>
  );
}

export default CustDoughnut;

import moment from "moment";
import React, { useEffect, useState } from "react";
import * as R from "ramda";
import Modal from "react-bootstrap/Modal";
import { useStateValue } from "../../redux/StateProvider";
import "./AssetEventLogCard.scss";
import { DashBoardServices } from "../../services/DasboardServices/DashboardService";

function AssetEventLogCard({ data }: any) {
  const [modalShow, setModalShow] = useState(false);
  const [eventLogData, setEventLogData] = useState<any>({
    eventStatusId: "",
    eventCategory: "",
    data: [],
    eventStatus: "",
    count: 0,
    parentEventCategoryId: "",
    parentEventCategory: "",
    eventCategoryId: "",
  });

  const [tempDataList, setTempData] = useState<any>([]);

  const [landingData, setLandingData] = useState([]);

  useEffect(() => {
    let tempList: any = [...data.data];
    let list: any = tempList.sort((a: any, b: any) => a.startTime - b.startTime);
    setLandingData(list);
  }, [data])

  const initialSorting = (data: any) => {
    let tempList: any = [...data];
    let list: any = tempList.sort((a: any, b: any) => a.startTime - b.startTime);
    return list;
  }

  const [index, setIndex] = useState(1);
  const [{ dateRange }] = useStateValue();
  const [isLoading, setIsLoading] = useState(false);

  type responseType = {
    eventStatusId: string,
    eventCategory: string,
    data: object,
    eventStatus: string,
    count: number,
    parentEventCategoryId: string,
    parentEventCategory: string,
  };

  const eventLogFetch = async (val: any, id: any) => {
    setIsLoading(true);
    const fromDateStr = moment(dateRange[0]).startOf("day").toISOString();
    const endDateStr = moment(dateRange[1]).endOf("day").toISOString();
    const FetchDB = new DashBoardServices();
    let eventData = await FetchDB.eventLogFetch(moment(fromDateStr).valueOf(), moment(endDateStr).valueOf(), val, id);
    if (eventData === "error") {
    } else if (typeof eventData === "number") {
    } else {
      let list: any = await eventData?.eventLog ? eventData?.eventLog[0] : [];
      let tempData: any = list && list !== undefined ? list?.data : [];
      let sortingData = initialSorting([...eventLogData?.data, ...tempData]);
      setTempData(sortingData);
      let obj = {
        eventStatusId: list?.eventStatusId,
        eventCategory: list?.eventCategory,
        data: [...sortingData],
        eventStatus: list?.eventStatus,
        count: list?.count,
        parentEventCategoryId: list?.parentEventCategoryId,
        parentEventCategory: list?.parentEventCategory,
      };
      setEventLogData(obj);
    }
    setIsLoading(false);
  };

  const loadMore = () => {
    eventLogFetch(index + 1, data.eventStatusId);
    setIndex(index + 1);
  };

  const dataSorting = (key: any) => {
    let tempList: any = [...eventLogData.data];
    switch (key) {
      case "startTime":
        let list: any = tempList.sort(
          (a: any, b: any) => a.startTime - b.startTime
        );
        setTempData(list);
        break;
      case "endTime":
        let endTime: any = tempList.sort(
          (a: any, b: any) => a.endTime - b.endTime
        );
        setTempData(endTime);
        break;
      // Add more cases as needed
      case "duration":
        let duration: any = tempList.sort(
          (a: any, b: any) => a.duration - b.duration
        );
        setTempData(duration);
        break;
      case "id":
        let id: any = tempList.sort((a: any, b: any) => a.id - b.id);
        setTempData(id);
        break;
      default:
        // Code to execute when none of the cases match the expression
        break;
    }
  };

  const dataSortingOne = (key: any) => {
    let tempList: any = [...eventLogData.data];
    switch (key) {
      case "startTime":
        let list: any = tempList.sort(
          (a: any, b: any) => b.startTime - a.startTime
        );
        setTempData(list);
        break;
      case "endTime":
        let endTime: any = tempList.sort(
          (a: any, b: any) => b.endTime - a.endTime
        );
        setTempData(endTime);
        break;
      // Add more cases as needed
      case "duration":
        let duration: any = tempList.sort(
          (a: any, b: any) => b.duration - a.duration
        );
        setTempData(duration);
        break;
      case "id":
        let id: any = tempList.sort((a: any, b: any) => b.id - a.id);
        setTempData(id);
        break;
      default:
        // Code to execute when none of the cases match the expression
        break;
    }
  };

  const [facilityData, setFacilityData] = useState<any>({});

  useEffect(() => {
    eventLogFetch(index, data.eventStatusId);
    let myfacility: any = localStorage.getItem("singleAssetCardDetails");
    let detailsFac = JSON.parse(myfacility);
    setFacilityData(detailsFac);
  }, []);

  //convert milliseconds into duration in days:HH:mm:ss
  function millisecondsToTime(milliseconds: any) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    // Formatting the time in "n day(s) hh:mm:ss" format
    const formattedTime = `${days > 0 ? `${days} day${days > 1 ? "s " : " "}` : ""}${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    return formattedTime;
  }

  //convert milliseconds into Days
  function millisecondsToLocalDate(milliseconds: any) {
    const date = new Date(milliseconds);
    const localDateFormat = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}, ${date.toLocaleTimeString()}`;
    return localDateFormat;
  }

  return (
    <>
      <Modal
        style={{ height: "100vh" }}
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton style={{ backgroundColor: "#FFFFFF" }}>
          <div className="typeModalHeaderCont">
            <h1 className="typeModalHeader">
              Event Log : {eventLogData.eventStatus}
            </h1>
            <p className="typeModalPara">
              Showing {eventLogData.count}/{data.count} events of{" "}
              {facilityData && facilityData[0]?.facilityName}
            </p>
          </div>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#FFFFFF" }}>
          <div className="typeModalContainer">
            <div className="eventCardContPopup">
              <div className="eventHeaderContPoup">
                <div className="tableTabOne">
                  <p>S.No</p>
                  {/* <div>
                    <div
                      className="up-arrow"
                      onClick={() => dataSorting("id")}
                    ></div>
                    <div
                      className="down-arrow"
                      onClick={() => dataSortingOne("id")}
                    ></div>
                  </div> */}
                </div>

                <div className="tableTab">
                  <p>Start Time</p>
                  <div>
                    <div
                      className="up-arrow"
                      onClick={() => dataSorting("startTime")}
                    ></div>
                    <div
                      className="down-arrow"
                      onClick={() => dataSortingOne("startTime")}
                    ></div>
                  </div>
                </div>
                <div className="tableTab">
                  <p>End Time</p>
                  <div>
                    <div
                      className="up-arrow"
                      onClick={() => dataSorting("endTime")}
                    ></div>
                    <div
                      className="down-arrow"
                      onClick={() => dataSortingOne("endTime")}
                    ></div>
                  </div>
                </div>
                <div className="tableTab">
                  <p>Duration</p>
                  <div>
                    <div
                      className="up-arrow"
                      onClick={() => dataSorting("duration")}
                    ></div>
                    <div
                      className="down-arrow"
                      onClick={() => dataSortingOne("duration")}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="bodytag">
                {tempDataList.map((obj: any, index: any) => (
                  <div key={index} className="cardDetailsPopup">
                    <div className="tableTabOne">
                      <p>{index + 1}</p>
                    </div>
                    <div className="tableTab">
                      <p>{moment(obj.startTime).format('lll') === "Invalid date" ? "-" : millisecondsToLocalDate(obj.startTime)}</p>
                    </div>
                    <div className="tableTab">
                      <p>{moment(obj.endTime).format('lll') === "Invalid date" ? "-" : millisecondsToLocalDate(obj.endTime)}</p>
                    </div>
                    <div className="tableTab">
                      <p>{moment(obj.duration).format("hh:mm") === "Invalid date" ? "-" : millisecondsToTime(obj.duration)}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="modalBtnCont">
              {isLoading && (
                <div
                  style={{
                    backgroundColor: "#4287f5",
                    cursor: "wait",
                    padding: "3px",
                    paddingLeft: "13px",
                    outline: "none",
                    border: "none",
                    borderRadius: "4px",
                    color: "#FFFFFF",
                    fontWeight: "500",
                    fontSize: "12px",
                    paddingRight: "13px",
                  }}
                >
                  Loading...
                </div>
              )}
              {!isLoading && eventLogData.data.length < data.count ? (
                <button
                  style={{
                    backgroundColor: "#4287f5",
                    padding: "3px",
                    paddingLeft: "13px",
                    outline: "none",
                    border: "none",
                    borderRadius: "4px",
                    color: "#FFFFFF",
                    fontWeight: "500",
                    fontSize: "12px",
                    paddingRight: "13px",
                  }}
                  onClick={loadMore}
                >
                  Load more
                </button>
              ) : null}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="eventCardCont">
        <div className="eventHeaderCont">
          <p>S.No</p>
          <p>Start Time</p>
          <p>End Time</p>
          <p>Duration</p>
        </div>
        {landingData && landingData.map((obj: any, index: any) => (
          <div className="cardDetails">
            <p className="sNoTxt">{index + 1}</p>
            <p className="startTimeTxt">{moment(obj.startTime).format('lll') === "Invalid date" ? "-" : millisecondsToLocalDate(obj.startTime)}</p>
            <p className="endTimeTxt"> {moment(obj.endTime).format('lll') === "Invalid date" ? "-" : millisecondsToLocalDate(obj.endTime)}</p>
            <p className="durationTxt">{millisecondsToTime(obj.duration) === "Invalid date" ? "-" : millisecondsToTime(obj.duration)}</p>
            {/* <p className="durationTxt">{moment(obj.duration).format('HH:mm')  === "Invalid date" ? "-" :moment(obj.duration).format('HH:mm')}</p> */}
          </div>
        ))}
        {data.count > 5 ? (
          <div className="viewAllCont">
            <p className="viewAllTxt" onClick={() => setModalShow(true)}>
              {" "}
              View All <span>{">"}</span>{" "}
            </p>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default AssetEventLogCard;

/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import activityTicketsIcon from "../assets/images/activity-tickets-icon.svg";
import RingGaugeChart from "../OperationalDashboardComp/RingGauge/RingGaugeChart";

import "./CustomerSuccessTeam.scss";

function CustomerSuccessTeam({ data }) {
  return (
    <div className="customerSuccess">
      {data &&
        data.map((ele, id) => {
          return (
            <div className="customersuccess-container">
              <div className="customer-details">
                <div>
                  <img
                    className="customer-avatar"
                    src="https://cachedimages.podchaser.com/256x256/aHR0cHM6Ly9jcmVhdG9yLWltYWdlcy5wb2RjaGFzZXIuY29tL2RmODZjM2RjZTg5ZDI2ZGQzZWI5NWU4YzI1MDBhMTIyLmpwZWc%3D/aHR0cHM6Ly93d3cucG9kY2hhc2VyLmNvbS9pbWFnZXMvbWlzc2luZy1pbWFnZS5wbmc%3D"
                    alt="dp"
                  />
                </div>
                <div>
                  <h6>{ele.Name}</h6>
                  <p>
                    {ele.locatedAT}, {ele.Region}
                  </p>
                </div>
              </div>
              <div className="ticket-details">
                <RingGaugeChart key={id} />
                <div className="ringGaugeChart">
                  <RingGaugeChart key={id} />
                  <img src={activityTicketsIcon} />
                  <h6>{ele.ticket_Value}</h6>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default CustomerSuccessTeam;

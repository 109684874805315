export const trendChartLegendData = (assetType: string): string[] => {
  const chartLegend = {
    TEMP_ENERGY: ["Temperature compliance trend", "Energy Consumption Trend"],
    ENERGY: [
      "Consumption (kWh)",
      "Power Factor",
      "Apparent Power (kVA)",
      "Load (kW)",
      "Reactive power (kVAR)",
    ],
    AIR: ["Consumption (m3)"],
    WATER: ["Consumption (KL)"],
    DEFAULT: ["Temperature compliance trend"],
  };
  return chartLegend[assetType] || chartLegend.DEFAULT;
};

export const metricTrendRange = (type: string): string[] => {
  const data = {
    Today: [
      "Last 7 days",
      "Last 14 days",
      "Last 30 days",
      "Last 60 days",
      "Last 90 days",
    ],
    "This Week": ["Last 4 weeks", "Last 8 weeks", "Last 12 weeks"],
    "This Month": [
      "Last 3 months",
      "Last 6 months",
      "Last 9 months",
      "Last 12 months",
    ],
    Custom: [""],
    DEFAULT: [""],
  };
  return data[type] || data.DEFAULT;
};

export const meterTypeIdListData = (assetType: string): string[] => {
  const meterTypes = {
    TEMP_ENERGY: ["TEMP_COMP_PCT", "KWH"],
    ENERGY: ["Wh_EB", "POWER_FACTOR", "KVA", "KW", "KVAR"],
    AIR: ["AIR_FLOW_AGR"],
    WATER: ["WTR_FLOW_AGR"],
    DEFAULT: ["TEMP_COMP_PCT"],
  };

  return meterTypes[assetType] || meterTypes.DEFAULT;
};

/**
 * Check the Asset contains the Trend Chart or not.
 */
export const trendAsssetTypeCheck = (id: string): boolean => {
  const trendAsssetTypeList = ["TEMP_ENERGY", "TEMP", "ENERGY", "AIR", "WATER"];
  return trendAsssetTypeList.includes(id);
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { useStateValue } from "../../../redux/StateProvider";
import "./index.scss";

function FilterChip(props) {
  const [{ getFilterChip }, dispatch] = useStateValue();
  const onSelectedChips = (chip) => {
    props.selectedChips(chip);
  };
  return (
    <>
      <div
        className="filterChip"
        onClick={() => onSelectedChips(props.filterChipName)}
      >
        {props.filterChipName}
      </div>
    </>
  );
}

export default FilterChip;

import { useState } from "react";
import CountryIcon from "../../assets/icons/CountryIcon.svg";
import IconClose from "../../assets/icons/IconClose.svg";
import "./PhoneNumberComponent.scss";
import { HelperFunctions } from "../../Utils/HelperFunctions";
import { FieldValidatorUtil } from "../../Utils/fieldValidation/FieldValidatorUtil";
import FormValidator from "../../Utils/FormValidations/FormValidations";

const PhoneNumberComponent = ({
  updataDataListPurposes,
  onChangeAddress,
  popUpScreenData,
  phoneNumber,
  invalidArr,
  setInvalidArr,
}: any) => {
  const [showPhonePurpose, setShowPhonePurpose] = useState(false);
  const phoneNumberSetPurposeData = [...popUpScreenData];
  const [phonePurposeList, setPhonePurposeList] = useState([]);
  const [purposeText, setPurposeText] = useState("Set purpose");
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);

  const dataCheck = (e: any) => {
    let tempList = [...phonePurposeList];
    if (tempList.includes(e)) {
      let index = tempList.indexOf(e); // get the index of the element to remove
      tempList.splice(index, 1); // remove the element at the specified index
    } else {
      tempList.push(e);
    }
    setPhonePurposeList(tempList);
    updataDataListPurposes("phonePurpose", tempList);
    setPurposeText(HelperFunctions.getPurposeString(tempList));
  };

  const checkName = (name: string, val: string) => {
    const validate = new FieldValidatorUtil();
    const validArr = [
      "validateOrgName",
      "validateRelationShip",
      "validateEmail",
      "validateCity",
      "validatePhoneNumber",
    ];

    if (!validArr.includes(name)) {
      return;
    }

    let isValid = false;

    if (name === "validateOrgName") {
      isValid = validate.validateOrgDetail(val);
    } else if (name === "validatePhoneNumber") {
      isValid = validate.isValidPhoneNumber(phoneNumber);
    } else {
      isValid = validate.isValidAssetData(val);
    }

    setInvalidArr((prevInvalidArr) => {
      if (isValid) {
        return prevInvalidArr.filter((obj) => obj !== name);
      } else {
        if (!invalidArr.includes(name)) {
          return [...prevInvalidArr, name];
        } else {
          return [...prevInvalidArr];
        }
      }
    });
  };

  return (
    <>
      <div className="inputsCont">
        <h6 className="createOrgHeader">Phone Number *</h6>
        <div className="numberInputCont">
          <div className="numberInputImgCont">
            <img src={CountryIcon} alt="icon" />
            <select className="numberSelector">
              <option>+91</option>
            </select>
          </div>
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => {
              onChangeAddress(e, "phoneNumber");
            }}
            className="numberInput"
            style={{
              border: `${
                invalidArr.includes("validatePhoneNumber")
                  ? "1px solid red"
                  : ""
              }`,
            }}
            onBlur={() => checkName("validatePhoneNumber", phoneNumber)}
          />
        </div>
        {invalidArr.includes("validatePhoneNumber") && (
          <p className="invalid-feedback inputFieldError">
            {new FormValidator().validateMobileNumberValue(
              phoneNumber,
              /^[0-9]+$/,
              10
            )}
          </p>
        )}
        {/* <p className="setPurposeTxt" onClick={() => setShowPhonePurpose(true)}>
          {purposeText}
        </p> */}
      </div>

      {/* {showPhonePurpose && (
        <div className="phoneNumberSetPurposeCont">
          <div className="setPurposeCloseCont">
            <img
              src={IconClose}
              alt="icon"
              onClick={() => {
                setShowPhonePurpose(false);
              }}
            />
          </div>
          <div className="setPurposeDetailsCont">
            {phoneNumberSetPurposeData?.length !== 0
              ? phoneNumberSetPurposeData?.map((obj, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => dataCheck(obj?.contactMechPurposeTypeId)}
                      className="setPurposeDetails"
                    >
                      <input
                        checked={phonePurposeList.includes(
                          obj?.contactMechPurposeTypeId
                        )}
                        className="setPurposeCheckbox"
                        type="checkbox"
                      />
                      <p className="checkboxTxt">{obj?.description}</p>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      )} */}
    </>
  );
};

export default PhoneNumberComponent;

import React, { useRef, useState, useEffect } from "react";
import "./ScrollingText.scss";

type PropTypes = {
  classNames: string;
  data: string;
  spanClassNames: string;
  spanData: string;
  color: string;
};

function ScrollingText({
  classNames,
  data,
  spanClassNames,
  spanData,
  color
}: PropTypes) {
  const [isHovered, setIsHovered] = useState(false);
  const textRef = useRef<HTMLParagraphElement>(null);
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      setIsTextOverflowing(
        textRef.current.scrollWidth > textRef.current.clientWidth
      );
    }
  }, [data]);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <div className="scrollingMainDiv">
      <p
        className={`scrollingText ${classNames}`}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <p
          ref={textRef}
          style={{
            animation:
              isTextOverflowing && isHovered
                ? "scrollLeftRight 5s linear infinite"
                : "none",
            margin: 0,
            color:color
          }}
        >
          {data} <span className={spanClassNames}>{spanData}</span>
        </p>
      </p>
    </div>
  );
}

export default ScrollingText;

/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck

import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import AddIcon from "../../assets/icons/AddIcon.svg";
import ExpandAllIcon from "../../assets/icons/ExpandAllIcon.svg";
import CollapseAllIcon from "../../assets/icons/CollapseAllIcon.svg";
import IconClose from "../../assets/icons/IconClose.svg";
import RightArrowIcon from "../../assets/icons/RightArrowIcon.svg";
import GroupingCloseIcon from "../../assets/icons/GroupingCloseIcon.svg";
import "./FacilityGroups.scss";
import FacilityGroupsContainer from "./facilityGroupsContainer/FacilityGroupsContainer";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import LoaderEffect from "../../a1Components/LoaderEffects/LoaderEffect";
import LoaderStyle from "../../a1Components/LoaderStyle/LoaderStyle";
import FormValidator from "../../Utils/FormValidations/FormValidations";
import { SettingsApiServices } from "../../services/settingsPageServices/SettingsApiServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// const qrcodesArr = require("./facilityGroup.js");

const FacilityGroups: React.FC = () => {

  function generateID(length: any) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const [showAllGroups, showAllGroupsData] = useState({
    isShowAll: false,
    number: 123
  });
  const expandAll = () => {
    showAllGroupsData({
      isShowAll: true,
      number: generateID(7)
    });
  };
  const collapseAll = () => {
    showAllGroupsData({
      isShowAll: false,
      number: generateID(7)
    });
  };

  const [showAddNewGroup, setAddNewGroup] = useState(false);

  const [updatePopup, setUpdatePopup] = useState(false);

  const facilityGroupingData = [
    {
      city: "Banglore",
      region1: "South Region",
      region2: "North Region",
    },
    {
      city: "JP NAgar",
      region1: "Chennai",
      region2: "Banglore",
    },
    {
      city: "Chennai",
      region1: "South Region",
      region2: "North Region",
    },
  ];

  // Add group API integration..

  // group name and inputs state..
  const [groupName, setGroupName] = useState("");
  const [groupType, setGroupType] = useState("CLUSTER");

  const callAddSubGroupAPIForRegion = async (child) => {
    let tokenID = localStorage.getItem("@tokenID");
    try {
      const token = tokenID;
      const endpoint = `https://dev-tnt.app.aone.ai/api/1.0/facilityGroup/addSubGroup`;
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      const payload = {
        parent: '10012',
        children: [child],
      };
      const response = await fetch(endpoint, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      if (resp.status == 200) {
        const resp = await response.json();
        console.log(resp, "Add SubGroup");
        toast(`Subgroup ID: ${child} added to Company`);
      } else {
        toast('Problem In Adding Subgroup to Facility Group')
      }
      facilityGroupAPIsNeedTocall();
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }


  const addGroupAPI = async () => {
    let tokenID = localStorage.getItem("@tokenID");
    if ((groupName == '') || (groupName.length == 0)) {
      // alert("Please Enter Group name")
    } else {
      // console.log(tokenID,"dumbu")
      try {
        const token = tokenID;
        const endpoint =
          "https://dev-tnt.app.aone.ai/api/1.0/facilityGroup/addGroup";
        const headers = {
          "Content-Type": "application/json",
          token: token,
        };
        const payload = {
          "facilityGroupName": groupName,
          "facilityGroupTypeId": groupType,
          "partyId": "10012"
        }
        const response = await fetch(endpoint, {
          method: "POST",
          headers: headers,
          body: JSON.stringify(payload),
        });
        if (!response.status == 200) {
          setGroupName('')
          setGroupType('CLUSTER')
          toast("Add Facility Group API problem...");
          throw new Error("Network response was not ok");
        }
        const resp = await response.json();
        setGroupName('')
        setGroupType('CLUSTER')
        console.log(resp, "group777");
          facilityGroupAPIsNeedTocall();
          setAddNewGroup(false)
        toast(`FacilityGroup Created ${resp.facilityGroupId}`);

      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    }
  };
  const [isLoad, setLoader] = useState(true);
  const [faciltGroupsRawData, setFacilityGroupsRwaData] = useState([]);
  const [formattedStructureFgroupsList, setFormattedStructure] = useState([]);
  const [facilityListForSubGroup, setFacilityListForSubgroups] = useState([]);
  const [userRolesList, setUserRolesList] = useState([]);
  const [facilityGroupUsersList, setFacilityGroupUsersList] = useState([]);



  const facilityGroupAPIsNeedTocall = async () => {
    let token = localStorage.getItem("@tokenID");
    const getFacilityGriupsList = async () => {
      setLoader(true);
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      try {
        const response = await fetch(`https://dev-tnt.app.aone.ai/api/1.0/facilityGroup/list?partyId=10012`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        });

      // if (!response.ok) {
      //   // throw new Error("Network response was not ok");
      //   // let finalRespObj = await response.json();
      //   console.log(response, "groupList777")
      // }
      let finalRespObj = await response.json();
      console.log(finalRespObj, "groupList777")
      // setFacilityGroupsRwaData(finalRespObj);
      let faciGroupListArr = new FormValidator();

      setFormattedStructure(faciGroupListArr.convertFacilityGroupCustomStructure(finalRespObj));
      setLoader(false)
      } catch (error) {
        toast("getFacilityGroupsList API Failed");
        setLoader(false)

      }
    }
    const getFacilityAdduserListApi = async () => {
      try {

        const headers = {
          "Content-Type": "application/json",
          token: token,
        };
        const response = await fetch(`https://dev-tnt.app.aone.ai/api/1.0/facilityGroup/groupTypeList?partyId=10012`, {
          method: "GET",
          headers: headers
        });
        if (!response.ok) {
          console.log(response, "groupList777")
        }
        let finalRespObj = await response.json();
        console.log(finalRespObj.facilityGroupTypeIdList, "subGroupsList")
        setFacilityGroupsRwaData(finalRespObj.facilityGroupTypeIdList);
      } catch (error) {
        toast("getFacilityAdduserListApi ERROR")
      }
    }
    const getListForShiftAsset = async () => {
      try {
        let opt = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        };
        let respObj = await fetch(
            "https://dev-tnt.app.aone.ai/api/1.0/facility/list?userPartyId=Company&extraDetails=false",
            opt
        );
        let finalObj = await respObj.json();

        if (finalObj !== undefined) {
          setFacilityListForSubgroups(finalObj.facilities);
          // console.log(finalObj.facilities,"ShiftList7878787")
        }
      } catch (error){
        console.error("Error fetching data:", error);
      }
    }
    const getRolesList = async () => {
      try {
        const getRoles = await new SettingsApiServices().FacilityStatistics(token);
        console.log(getRoles.roleTypes, "rolesList7878")
        setUserRolesList(getRoles.roleTypes);
      } catch (error) {
        // alert('User Roles API Error...')
      }
    }
    // FACILITY GROUP USERS LIST API
    const getFacilityGroupUsersList = async () => {
      let opt = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      };
      let respObj = await fetch(
        "https://dev-tnt.app.aone.ai/api/1.0/user/list",
        opt
      );
      let finalObj = await respObj.json();
      if (finalObj !== undefined) {
        // setFacilityListForSubgroups(finalObj.facilities);
        console.log(finalObj, "usersList7878787")
        setFacilityGroupUsersList(finalObj.data);
      }
    };
    getFacilityGroupUsersList();
    getRolesList();
    getListForShiftAsset();
    getFacilityAdduserListApi();
    getFacilityGriupsList();
  }
  useEffect(() => {
    facilityGroupAPIsNeedTocall();
  }, [])

  return (
    <>
      <ToastContainer position="bottom-left" />
      <div className="facilityGroupsFilterBtnCont">

        <div className="facilityGroupsBtnsContainer">
          {isLoad ? <div style={{ marginTop: '45%', marginRight: '40%' }}> <LoaderStyle />  </div> :
            <div className="collapseAndExpandBtnsCont">
              <button className="collapseBtn" onClick={collapseAll}>
                <img src={CollapseAllIcon} alt="icon" />
                Collapse all
              </button>
              <button className="expandBtn" onClick={expandAll}>
                <img src={ExpandAllIcon} alt="icon" />
                Expand all
              </button>
            </div>}
          {!isLoad && <button
            className="addNewGroupBtn"
            onClick={() => setAddNewGroup(true)}
          >
            <img src={AddIcon} alt="icon" />
            Add Group
          </button>}
          {showAddNewGroup && (
            <div className="facilityAddNewGroupPopupCont">
              <div className="facilityAddGroupPopHeaderCont">
                <h4 className="facilityAddGroupPopHeader">
                  Add Facility Group
                </h4>
                <div className="closeIconDiv">
                  <img
                    src={IconClose}
                    alt="iocn"
                    onClick={() => setAddNewGroup(false)}
                  />
                </div>
              </div>
              <div className="facilityAddGroupInputCont">
                <p>Group *</p>
                <input
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                ></input>
              </div>
              <div className="facilityAddGroupSelectCont">
                <p>Group Type *</p>
                <select
                  value={groupType}
                  onChange={(event) => setGroupType(event.target.value)}
                >
                  <option value="CLUSTER">Cluster</option>
                  <option value="CITY">City</option>
                  <option value="REGION">Region</option>
                </select>
              </div>
              <div className="facilityAddGroupBtnCont">
                <button className="facilityAddGroupBtn" onClick={addGroupAPI}>
                  <img src={AddIcon} alt="icon" />
                  Add
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="facilityGroupsComponentCont">
        {!isLoad && <FacilityGroupsContainer facilityGroupAPIsNeedTocall={facilityGroupAPIsNeedTocall} facilityGroupUsersList={facilityGroupUsersList} userRolesList={userRolesList} facilityListForSubGroup={facilityListForSubGroup} faciltGroupsRawData={faciltGroupsRawData} formattedStructureFgroupsList={formattedStructureFgroupsList} showAllGroups={showAllGroups} />}
      </div>
      {!isLoad && <div className="facilityGroupsFooterCont">
        <button className="discardBtn">Discard</button>
        <button className="updateBtn" onClick={() => setUpdatePopup(true)}>
          Update
        </button>
      </div>}

      {/* Update Popup */}
      <Modal
        show={updatePopup}
        onHide={() => setUpdatePopup(false)}
        className="facilityGroupsUpdatePopup"
        centered
        backdrop="static"
      >
        <Modal.Header className="updatePopupHeaderCont">
          <h4 className="updatePopupHeader">Update facility grouping</h4>
          <div className="closeIconDiv">
            <img
              src={IconClose}
              alt="icon"
              onClick={() => setUpdatePopup(false)}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="updateFacilityGroupPopupBody">
          <div className="updatePopupBodyContainer">
            <p className="updatePopupBodyHeader">
              If you proceed, the following changes on grouping will get
              reflected. You can undo the changes below.
            </p>
            <div className="facilityGroupingContainer">
              {facilityGroupingData.map((obj) => (
                <div className="facilityGrouping">
                  <div className="facilityGroupCityCont">
                    <input type="checkbox" />
                    <p className="facilityGroupCity">{obj.city}</p>
                    <img src={RightArrowIcon} alt="icon" />
                  </div>
                  <div className="facilityGroupRegionsCont">
                    <p className="facilityGroupRegion1">{obj.region1}</p>
                    <p className="facilityGroupRegion2">{obj.region2}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="updateFacilityGroupPopupFooter">
          <button
            onClick={() => setUpdatePopup(false)}
            className="updatePopupCancelBtn"
          >
            Cancel
          </button>
          <button
            onClick={() => setUpdatePopup(false)}
            className="updatePopupConfirmBtn"
          >
            {" "}
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FacilityGroups;

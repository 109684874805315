export const theme = {
  light: {
    colors: {
      textCopy: "#182F43",
      headingColor: "#000000",
      bodyBg: "#EDEEF8",
      contentAreaBg: "#E1E3F0",
      cardBg: "#FFFFFF",
      cardBorder: "#DAE2FF",
      ctaIconBg: "#FFFFFF",
      ctaIconBorder: "#FFFFFF",
    },
  },
  dark: {
    colors: {
      textCopy: "#FFFFFF",
      headingColor: "#F19CFF",
      bodyBg: "#0C1228",
      contentAreaBg: "#0C1228",
      cardBg: "#14223A",
      cardBorder: "#DAE2FF",
      ctaIconBg: "transparent",
      ctaIconBorder: "#1E3359",
    },
  },
};

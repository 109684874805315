/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
// import the core library.
import ReactEChartsCore from "echarts-for-react/lib/core";
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from "echarts/core";
import { GaugeChart, PieChart, GaugeSeriesOption } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import "./RingGaugeChart.scss";
import { width } from "@amcharts/amcharts4/.internal/core/utils/Utils";
// Register the required components

function RingGaugeChart() {
  echarts.use([PieChart, GaugeChart, CanvasRenderer]);
  const gaugeData = [
    {
      value: 60,
      name: "Perfect",
      title: {
        offsetCenter: ["0%", "-100%"],
      },
      detail: {
        valueAnimation: true,
        offsetCenter: ["0%", "0%"],
      },
    },
  ];

  var option = {
    series: [
      {
        type: "gauge",
        startAngle: 270,
        endAngle: -450,
        pointer: {
          show: false,
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            borderWidth: 1,
            borderColor: "#4caf50",
          },
        },
        axisLine: {
          lineStyle: {
            width: 2,
          },
        },
        splitLine: {
          show: false,
          distance: 0,
          length: 10,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
          distance: 10,
        },
        data: gaugeData,
        title: {
          fontSize: 2,
        },
        detail: {
          width: 20,
          height: 20,
          fontSize: 12,
          color: "auto",
          borderColor: "auto",
          borderRadius: 0,
          borderWidth: 0,
        },
      },
    ],
  };

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={option}
      notMerge={true}
      lazyUpdate={true}
      theme={"theme_name"}
      className="ringGauge"
    />
  );
}

export default RingGaugeChart;

import { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./EventListDetail.scss";
import ClockIcon from "../assets/ic_clock.svg";
import MarkerIcon from "../assets/ic_marker.svg";
import DownloadIcon from "../assets/Icon feather-download.svg";
import DownArrow from "../assets/ic_down_arrow_blue.svg";
import { useQuery, useQueryClient } from "react-query";
import { getEventDetails } from "../service/EventListService";
import LoaderStyle from "../../../a1Components/LoaderStyle/LoaderStyle";
import DisplayError from "../../../a1Components/DisplayError/DisplayError";
import moment from "moment";
import EventListState from "../state/EventListState";
import { cancelExistingQuery } from "../../../Utils/utils";
import { formatDuration } from "../utils/Utils";
import { MediaListType } from "../types/EventListTypes";
import { API_BASE_URL_DOWNLOAD } from "../../../Constants/constants";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import PlayIcon from "../assets/videoPlayIcon.svg";
import DownloadMediaIcon from '../assets/download-icon-white.svg';
import OnGoingEvent from "../assets/on-going-icon.svg";
import { getAreaDetails, getFacilityDetails } from "../utils";

type PropsType = {
  show: boolean;
  onHide: () => void;
  eventId: string;
  startDateTime: number;
  assetId: string;
  objectCount: string;
  showMediaCarouselPopUp: (data: MediaListType[], index: number) => void;
};

function EventListDetail({
  show,
  onHide,
  eventId,
  startDateTime,
  assetId,
  objectCount,
  showMediaCarouselPopUp,
}: PropsType) {  
  const queryClient = useQueryClient();
  const [metaDataObj, setMetaDataObj] = useState<any>(null);
  const [showMetaDataObj, setShowMetaDataObj] = useState(false);
  const [facilityData, setFacilityData] = useState<any>({});
  const [areaData, setAreaData] = useState<any>({});

  const { hoveredCloseButton, setHoveredCloseButton } = EventListState();

  const { data, isLoading, isFetching, error, refetch, dataUpdatedAt } =
    useQuery(
      "getEventDetailsData",
      () => getEventDetails(eventId, startDateTime, assetId),
      {
        enabled: false,
        keepPreviousData: false,
      }
    );

  const refetchEventDetails = async () => {
    try {
      await refetch();
    } catch (error) {
      console.error("Error in refetching api:", error);
    }
  };

  const handleMouseEnter = () => {
    setHoveredCloseButton(true);
  };

  const handleMouseLeave = () => {
    setHoveredCloseButton(false);
  };

  const handleDownloadAll = () => {
    var zip = new JSZip();

    // Add an image file in a folder named 'images'
    var imgFolder = zip.folder("images");
    var videoFolder = zip.folder("videos");

    // Array to hold all fetch promises for media data
    const fetchPromises = data?.mediaList.map(
      (item: MediaListType, index: number) => {
        const mimeTypeList = item.mimeTypeId.split("/");
        const fileName = `${item.dataResourceId}.${mimeTypeList[1]}`;
        const url = `${API_BASE_URL_DOWNLOAD.slice(0,-1)}${item.url}`;

        if (mimeTypeList[0] === "image") {
          // Fetch image data as a Blob
          return fetch(url)
            .then((response) => {
              if (!response.ok) throw new Error("Network response was not ok");
              return response.blob();
            })
            .then((blob) => {
              imgFolder?.file(fileName, blob);
            })
            .catch((error) => {
              console.error(`Error fetching image: ${error}`);
            });
        } else if (mimeTypeList[0] === "video") {
          return fetch(url)
            .then((response) => {
              if (!response.ok) throw new Error("Network response was not ok");
              return response.blob();
            })
            .then((blob) => {
              videoFolder?.file(fileName, blob);
            })
            .catch((error) => {
              console.error(`Error fetching video: ${error}`);
            });
        }
      }
    );

    Promise.all(fetchPromises).then(() => {
      zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, "events.zip");
      });
    });
  };

  const downloadFile = (mediaObj: MediaListType) => {
    const mimeTypeList = mediaObj.mimeTypeId.split("/");
    const fileName = `${mediaObj.dataResourceId}.${mimeTypeList[1]}`;
    const url = `${API_BASE_URL_DOWNLOAD.slice(0,-1)}${mediaObj.url}`;
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      });
  };

  useEffect(() => {
    const extractData = async () => {
     try{
      const facility = await getFacilityDetails(data.locatedAtFacilityId);
      setFacilityData(facility);
       const area = await getAreaDetails(data.locatedAtLocationSeqId);
       setAreaData(area);
        }
     catch(error){
      console.error(error)
     }
    };
    extractData();
  }, [data]);

  useEffect(() => {
    cancelExistingQuery("getEventDetailsData", queryClient);
    return () => {
      cancelExistingQuery("getEventDetailsData", queryClient);
    };
  }, []);

  useEffect(() => {
    refetchEventDetails();
  }, [eventId, startDateTime, assetId]);

  useEffect(() => {
    if (data !== undefined) {
      if (data?.hasOwnProperty("metadata")) {
        setMetaDataObj(JSON.parse(data?.metadata));
      }
    }
  }, [data, dataUpdatedAt]);

  if (isLoading || isFetching) {
    return (
      <div className="loadingBox1">
        <LoaderStyle />
      </div>
    );
  }

  if (error) {
    return (
      <>
        <Modal.Header className="eventListDetailPopUpHeader"></Modal.Header>
        <Modal.Body>
          <div className="loadingBox1">
            <DisplayError type="err_1" />
          </div>
          <div className="eventDetailsMainDiv">
            <div className="modalFooterDiv">
              <div
                className="closeButtonDiv"
                style={{
                  backgroundColor: hoveredCloseButton
                    ? "rgba(95, 91, 91, 1)"
                    : "rgba(255, 255, 255, 1)",
                }}
                onMouseEnter={() => handleMouseEnter()}
                onMouseLeave={handleMouseLeave}
                onClick={onHide}
              >
                <p
                  className="closeButtonText"
                  style={{
                    color: hoveredCloseButton
                      ? "rgba(255, 255, 255, 1)"
                      : "rgba(95, 91, 91, 1)",
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </>
    );
  }

  if (data !== undefined && typeof data === "number") {
    return (
      <>
        <Modal.Header className="eventListDetailPopUpHeader"></Modal.Header>
        <Modal.Body>
          <div className="loadingBox1">
            <DisplayError type="err-500/404" />
          </div>
          <div className="eventDetailsMainDiv">
            <div className="modalFooterDiv">
              <div
                className="closeButtonDiv"
                style={{
                  backgroundColor: hoveredCloseButton
                    ? "rgba(95, 91, 91, 1)"
                    : "rgba(255, 255, 255, 1)",
                }}
                onMouseEnter={() => handleMouseEnter()}
                onMouseLeave={handleMouseLeave}
                onClick={onHide}
              >
                <p
                  className="closeButtonText"
                  style={{
                    color: hoveredCloseButton
                      ? "rgba(255, 255, 255, 1)"
                      : "rgba(95, 91, 91, 1)",
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </>
    );
  }

  if (data === undefined) {
    return (
      <>
        <Modal.Header className="eventListDetailPopUpHeader"></Modal.Header>
        <Modal.Body>
          <div className="loadingBox1">
            <DisplayError type="err-empty" />
          </div>
          <div className="eventDetailsMainDiv">
            <div className="modalFooterDiv">
              <div
                className="closeButtonDiv"
                style={{
                  backgroundColor: hoveredCloseButton
                    ? "rgba(95, 91, 91, 1)"
                    : "rgba(255, 255, 255, 1)",
                }}
                onMouseEnter={() => handleMouseEnter()}
                onMouseLeave={handleMouseLeave}
                onClick={onHide}
              >
                <p
                  className="closeButtonText"
                  style={{
                    color: hoveredCloseButton
                      ? "rgba(255, 255, 255, 1)"
                      : "rgba(95, 91, 91, 1)",
                  }}
                >
                  Close
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </>
    );
  }

  let formattedDuration = formatDuration(data.startDateTime, data.endDateTime);

  return (
    <>
      <Modal.Header className="eventListDetailPopUpHeader"></Modal.Header>
      <Modal.Body>
        <div className="eventDetailsMainDiv">
          <div className="eventDetailsHeader">
            <div className="titleAndTimeDiv">
              <p className="eventDetailsTitle">{data.eventDescription}</p>
              {data.endDateTime!==null?(
              <div className="timeDiv">
                <img src={ClockIcon} />
                <p className="eventTimeText">{formattedDuration}</p>
              </div>):(
              <div className="onGoingMainDiv">
                 <img src={OnGoingEvent} className="blinkingImage" />
                 <p className="onGoingText">Ongoing</p>
               </div>)}
            </div>
            <div className="eventDetailsDiv">
              <div className="timeStampAndEventCountDiv">
                <p className="eventTimeStampData">
                  {moment(data.startDateTime).format("MMMM Do YYYY, h:mm a")}
                  {data.endDateTime ? ` - ${moment(data.endDateTime).format("MMMM Do YYYY, h:mm a")}` : ""}
                </p>
                <div className="eventCountDiv">
                  <p className="eventOccuredCunt">#{objectCount}</p>
                  <p className="textCount">Count</p>
                </div>
              </div>
              <div className="eventCategoryDiv">
                <p className="eventCategoryData">{data.eventCategoryDesc}</p>
                <p className="eventCategoryDataName">{data.eventTypeDesc}</p>
              </div>
            </div>
            <div className="facilityDetailsAndDownloadDiv">
              <div className="facilityDetailsDiv">
                <img src={MarkerIcon} />
                <div className="facilityDataDiv">
                  <p className="eventFaciliyName">
                    {facilityData.facilityName}{areaData.locationName === "" ? "" : `, ${areaData.locationName}`}
                  </p>
                  <p className="eventFacilityAddress">{data.facilityAddress}</p>
                </div>
              </div>
              {data.mediaList.length !== 0 && (
                <div className="downloadButton" onClick={handleDownloadAll}>
                  <img src={DownloadIcon} className="downloadAll" />
                  <p className="downloadButtonText">Download All</p>
                </div>
              )}
            </div>
          </div>
          <div className="eventSnapshotParentDiv">
            <div className="eventSnapshotMainDiv">
              <div className="eventSnapShotDiv">
                {data.mediaList.length === 0 ? (
                  <div className="noMediaAvailableDiv">
                    <p className="noMediaAvailable">
                      No Media Available for this Event.
                    </p>
                  </div>
                ) : (
                  <>
                      { data.mediaList.map(
                          (item: MediaListType, index: number) => (
                            <>
                              {item.mimeTypeId.includes("image") ? (
                                <div className="icon-container" >
                                  <img
                                    src={`${API_BASE_URL_DOWNLOAD.slice(0,-1)}${item.url}`}
                                    className="mediaThumbnail"
                                    onClick={() =>
                                      showMediaCarouselPopUp(
                                        data.mediaList,
                                        index
                                      )
                                    }
                                    loading="lazy"
                                  />
                                  <img
                                    className="download-icon"
                                    src={DownloadMediaIcon}
                                    onClick={() => downloadFile(item)}
                                    alt="Download Icon"
                                  />
                                </div>
                              ) : (
                                <div className="icon-container">
                                  <video
                                    className="mediaThumbnail"
                                    onClick={() =>
                                      showMediaCarouselPopUp(
                                        data.mediaList,
                                        index
                                      )
                                    }
                                  >
                                    <source
                                      src={`${API_BASE_URL_DOWNLOAD.slice(0,-1)}${item.url}`}
                                    />
                                  </video>
                                  <img
                                    src={PlayIcon}
                                    className="videoPlayIcon"
                                  />
                                  <img
                                    className="download-icon"
                                    src={DownloadMediaIcon}
                                    onClick={() => downloadFile(item)}
                                    alt="Download Icon"
                                  />
                                </div>
                              )}
                            </>
                          )
                        )}
                  </>
                )}
              </div>
            </div>
          </div>
          {metaDataObj !== null && (
            <div className="metadataParentDiv">
              <div
                className="metadataDivHeading"
                onClick={() => setShowMetaDataObj((prev) => !prev)}
              >
                <p className="metaDataHeadingText">View Metadata</p>
                <img
                  src={DownArrow}
                  className={`${showMetaDataObj ? "rotate-0" : "rotate-90"}`}
                  style={{ width: "0.7rem", height: "auto" }}
                />
              </div>
              {showMetaDataObj && (
                <div className="metadataDiv">
                  <p className="metaDataText">
                    {metaDataObj?.eventId}: {metaDataObj?.objectCount}
                  </p>
                </div>
              )}
            </div>
          )}
          <div className="modalFooterDiv">
            <div
              className="closeButtonDiv"
              style={{
                backgroundColor: hoveredCloseButton
                  ? "rgba(95, 91, 91, 1)"
                  : "rgba(255, 255, 255, 1)",
              }}
              onMouseEnter={() => handleMouseEnter()}
              onMouseLeave={handleMouseLeave}
              onClick={onHide}
            >
              <p
                className="closeButtonText"
                style={{
                  color: hoveredCloseButton
                    ? "rgba(255, 255, 255, 1)"
                    : "rgba(95, 91, 91, 1)",
                }}
              >
                Close
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
}

export default EventListDetail;

import axios from "axios";
import { API_BASE_URL } from "../../Constants/constants";

const baseURL = "https://snakeoil-api.atsuyatech.com";

interface RawData {
  viewIndex: number;
  viewSize: number;
  extraDetails: boolean;
  searchBy: SearchItem[];
}

interface SearchItem {
  isSearch: boolean;
  searchKey: string;
  searchValue: string[];
}

export class AssetServices {
  async getFacilityAS() {
    const url = `${baseURL}/getCityList`;
    const resp = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    return resp.json();
  }

  async clusterAS() {
    const url = `${baseURL}/getClusterList`;
    const resp = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    return resp.json();
  }

  async regionAS() {
    const url = `${baseURL}/getRegionList`;
    const resp = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    return resp.json();
  }

  async assetsAS() {
    const url =
      "https://aone.atsuyatech.com//api/1.0/assets?username=rajesh.reddy@swiggy.in&password=changeme&pageSize=100000&pageIndex=1&facilityList=SI_IM45,SI_IM100";
    const req = await axios.get(url);
    // const resp = await fetch(url, {
    //     method: "GET",
    //     headers: { "Content-Type": "application/json" },
    // });
    return req;
  }

  async assetsList(tokenID: any, currentPage: number, viewSize: number, searchString: string, searchKey: string, settingsFilterPreferences: SearchItem[]) {
    try {
      const raw: RawData = {
        viewIndex: currentPage,
        viewSize: viewSize,
        extraDetails: true,
        searchBy: [],
      };

      if (searchString) {
        raw.searchBy.push({
          isSearch: true,
          searchKey: searchKey,
          searchValue: [searchString],
        });
      }

      if (settingsFilterPreferences.length > 0) {
        for (const preference of settingsFilterPreferences) {
          raw.searchBy.push({
            isSearch: true,
            searchKey: preference.searchKey,
            searchValue: preference.searchValue,
          });
        }
      }

      const response = await fetch(
          `${API_BASE_URL}assets/list`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${tokenID}`,
            },
            body: JSON.stringify(raw),
          }
      );

      if (response.status !== 200) {
        throw new Error(`HTTP Status: ${response.status}`);
      }

      return await response.json();

    } catch (error) {
      throw error;
    }
  }
}

import "./LoadMoreBtn.scss";

type loaderproptypes = {
  state: boolean;
  title: string;
  onClickFunction: () => void;
  loadingText: string;
};

const LoadMoreBtn = ({
  state,
  title,
  loadingText,
  onClickFunction,
}: loaderproptypes) => {
  return (
    <>
      {state ? (
        <div className="loaderClass">
          <p className="loasderText">{loadingText}</p>
          <div className="loader" />
        </div>
      ) : (
        <div onClick={onClickFunction} className="loaderText">
          <p className="loasderText">{title}</p>
        </div>
      )}
    </>
  );
};

export default LoadMoreBtn;

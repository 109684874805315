import React from "react";
import {
  EventListType,
  EventLog,
  FilterDataType,
  SearchListType,
} from "../types/EventListTypes";
import useState from "react-usestateref";
import { AssetDataStatus } from "../../assetList/types";

function EventListState() {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [showDownloadEventPopUp, setShowDownloadEventPopUp] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [responseData, setResponseData] = useState<EventListType>({
    paginationMap: {
      totalPage: 0,
      nextPage: 0,
      pageSize: 0,
      prevPage: 0,
      totalCount: 0,
      currentPage: 0,
    },
    eventLog: [],
  });
  const [showDetails, setShowDetails] = useState(false);
  const [hoveredCloseButton, setHoveredCloseButton] = useState(false);
  const [selectedFileFormat, setSelectedFileFormat] = useState("Excel");
  const [duration, setDuration] = useState(0);
  const [filterDataList, setFilterDataList, filterDataListRef] = useState<
    FilterDataType[]
  >([]);
  const [searchByList, setSearchByList, searchByListRef] = useState<
    SearchListType[]
  >([]);
  const [activeInput, setActiveInput] = React.useState<string>("");
  const [disableApplyFilter, setDisableApplyFilter] = useState(true);
  const [showClearAll, setShowClearAll] = useState(false);

  const [eventList, setEventList] = useState<EventLog[]>([]);
  const [showLoading, setShowLoading] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loadMoreStatus, setLoadMorestatus] = useState(false);
  const [eventDataStatus, setEventDataStatus] = useState<AssetDataStatus>({
    showLoading: false,
    showError: false,
    showEmptyData: false,
    showAsset: false,
    showLoadMoreButton: false,
  });
  const [eventCountStatus, setEventCountStatus] = useState({
    assetsLengthCount: 0,
    showAssetCount: false,
    totalAssetCount: 0,
  });

  return {
    hoveredIndex,
    setHoveredIndex,
    showDownloadEventPopUp,
    setShowDownloadEventPopUp,
    pageIndex,
    setPageIndex,
    responseData,
    setResponseData,
    showDetails,
    setShowDetails,
    hoveredCloseButton,
    setHoveredCloseButton,
    selectedFileFormat,
    setSelectedFileFormat,
    duration,
    setDuration,
    filterDataList,
    setFilterDataList,
    filterDataListRef,
    searchByList,
    setSearchByList,
    searchByListRef,
    activeInput,
    setActiveInput,
    disableApplyFilter,
    setDisableApplyFilter,
    showClearAll,
    setShowClearAll,
    eventList,
    setEventList,
    showLoading,
    setShowLoading,
    showLoadMore,
    setShowLoadMore,
    showError,
    setShowError,
    loadMoreStatus,
    setLoadMorestatus,
    eventDataStatus,
    setEventDataStatus,
    eventCountStatus,
    setEventCountStatus,
  };
}

export default EventListState;

import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import TimeInputField from './TimeInputField';
import '../DateTimeRangeSelectorInput/DateTimeRangeSelectorInput.scss';
import {ReportDatePickerData} from '../DataTypes';
import { convertedIntoTwoDecimalNumber } from '../../ModelPopupcomp/DateTimeRangeInputComponent/Utils';


const TimeRangePicker = ({PlaceHolder,activeInput,field,state, title, value, updateDateRange}:ReportDatePickerData) => {
  const [calenderPopUpScreen, setCalenderPopupScreen] = useState(false);
  const [dateRange, setDateRange] = useState({startDate: "00:00",  endDate: `${convertedIntoTwoDecimalNumber(new Date().getHours())}:${convertedIntoTwoDecimalNumber(new Date().getMinutes())}`});
  const [checkDateTimeDuration, setCheckDateTimeDuration] = useState(false);

  const applyAction = () => {
    updateDateRange(dateRange);
    setCalenderPopupScreen(false);
  };

  useEffect(() => updateDateRange(dateRange),[])

  useEffect(() => {
    if(dateRange.startDate !== "" && dateRange.endDate !== "") {
      const startTimeArray = dateRange.startDate.split(":");
      const endTimeArray = dateRange.endDate.split(":");
      
      const startSeconds = parseInt(startTimeArray[0]) * 3600 + parseInt(startTimeArray[1]) * 60;
      const endSeconds = parseInt(endTimeArray[0]) * 3600 + parseInt(endTimeArray[1]) * 60;

      // Calculate duration in seconds
      const durationInSeconds = endSeconds - startSeconds;
      setCheckDateTimeDuration(durationInSeconds < 0)
    }else {
      setCheckDateTimeDuration(false)
    }
  }, [dateRange])

  const handleClick = () => {
    activeInput();
    setCalenderPopupScreen(true);
  }

  return (
    <div className="dateTimeInputCardContainer">
      <h4 className="tempInputTitleText">{title}{field === "Y" ? " *" : ""}</h4>
      <div className="styleInp">
        <input type="text" onClick={handleClick} required={true} 
         value={(dateRange.startDate + " - " + dateRange.endDate ) }  className="inputTempTextValue" placeholder={PlaceHolder} />
        <div className='calenderIcon'>
          <FontAwesomeIcon onClick={handleClick} icon={faCalendar} />
        </div>    
      </div>
      {calenderPopUpScreen && state ? (
        <div className="slectDateTimeCard">
          <TimeInputField  title='Start Time' value={dateRange.startDate} handleDateChange={e => setDateRange({ ...dateRange, startDate: e })} max={''} min={''}/>
          <TimeInputField title='End Time' value={dateRange.endDate} handleDateChange={e => setDateRange({ ...dateRange, endDate: e })} max={''} min={''}/>
          { checkDateTimeDuration ? 
            <div className='warningMsgClass'>
              <p className='warningMsg'>Please check your time inputs</p> 
            </div> : null
          }
          <div className="bottonButtonsDiv">
            <button onClick={() => setCalenderPopupScreen(false)} className="cancelButton">Cancel</button>
            {
             !checkDateTimeDuration && dateRange.startDate !== "" && dateRange.endDate !== ""  ?  <button onClick={applyAction} className="applyButton">Apply</button> :  <button className="applyButtonOpacity">Apply</button>
            }   
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default TimeRangePicker
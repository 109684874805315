/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-nocheck
import moment from "moment";
import * as R from "ramda";
import { useState, useEffect } from "react";
import "./style.scss";

function DG({ data, abbre, dailyMeters, load,lastCommTime }:any) {
  // console.log("dataset",data)
  const [kwhData, setKwhData] = useState();
  useEffect(() => {
    dailyMeters.map((ele) => {
      ele.meterTypeId.includes("Wh_DG") && setKwhData(ele);
    });
    dailyMeters.map((ele, id) => {
      ele.meterTypeId.includes("FUEL_LEVEL_PCT") && setFuelLeftData(ele.sum);
    });
    let runHoursAvg = dailyMeters.find(
      (obj) => obj.meterTypeId === "RUNHR_ACTUAL"
    );
    let valueSet = millisecondsToMinutesSeconds(runHoursAvg?.sum);
    setRunHoursAverage(valueSet);

    let loadAverageVal = dailyMeters.find(
      (obj) => obj.meterTypeId === "DG_AVG_LOAD"
    );
    setLoadAverageValue(loadAverageVal);

    let fuelConsumedValue = dailyMeters.find(
      (obj) => obj.meterTypeId === "FUEL_CONSUMED"
    );

    setFuelConsumed(fuelConsumedValue);
  }, [dailyMeters]);
  


  const millisecondsToMinutesSeconds = (ms) => {
    //   let duration = moment.duration(ms, 'milliseconds');
    //   let fromMinutes = Math.floor(duration.asMinutes());
    //   let fromSeconds = Math.floor(duration.asSeconds() - fromMinutes * 60);

    //   return Math.floor(duration.asSeconds()) >= 60 ? (fromMinutes<= 9 ? '0'+fromMinutes : fromMinutes) +':'+ (fromSeconds<= 9 ? '0'+fromSeconds : fromSeconds)
    // : '00:'+(fromSeconds<= 9 ? '0'+fromSeconds : fromSeconds);
    let milliseconds = ms;
    let duration = moment.duration(milliseconds);
    let hours = duration.hours();
    let minutes = duration.minutes();
    let seconds = duration.seconds();
    let formateval;
    if (hours > 0) {
      formateval = hours + "h" + ":" + minutes + "m";
    } else {
      formateval = minutes + "m" + ":" + seconds + "s";
    }
    let formatted = moment.utc(milliseconds).format("h:mm");
    return formateval;
  };
  const [latestTime, setLatestTime] = useState();
  const [fuelData, setFuelData] = useState();
  const [fuelConsumed, setFuelConsumed] = useState();
  const [fuelLeftData, setFuelLeftData] = useState();
  const [kvaData, setKvaData] = useState();
  const [runHoursAverage, setRunHoursAverage] = useState();
  const [loadAverageValue, setLoadAverageValue] = useState();
  const [kvaPercentageData, setKvaPercentageData] = useState();

  useEffect(() => {
    //  console.log("DGdata!!!",data,"daily meterss",dailyMeters)
   
    data.map((ele, id) => {
      ele.meterTypeId.includes("FUEL_LEVEL_PCT") && setFuelData(ele.meterValue);
    });

    let filterDataKva = data.find((obj) => obj.meterTypeId === "KVA");
    setKvaData(filterDataKva?.meterValue);
  }, [data]);
  useEffect(() => {
    setKvaPercentageData(load);
  }, [load]);
  useEffect(() => {
    !R.isEmpty(lastCommTime) && !R.isNil(lastCommTime) && lastCommTime !== null && setLatestTime(lastCommTime) ;
  }, [lastCommTime]);

  // console.log("DGdata fuelData!!!", load);
  return (
    <>
      <div className="lastUpdated">
        {!R.isEmpty(latestTime) && !R.isNil(latestTime) ? (
          <h3>Updated {moment(latestTime).fromNow()}</h3>
        ) : (
          <h3>&nbsp;</h3>
        )}
      </div>
      <div className="assetCard-colum">
        <div className="assetCard-col">
          <p>Energy Generated</p>
          {!R.isEmpty(kwhData) && !R.isNil(kwhData) ? (
            <h2>
              {kwhData?.sum.toFixed(2)} <span>{kwhData?.defaultUom}</span>
            </h2>
          ) : (
            <h2>-</h2>
          )}
          <p>Run Hours(avg)</p>
          {!R.isEmpty(runHoursAverage) && !R.isNil(runHoursAverage) ? (
            <>
              <h2>
                {runHoursAverage} <span></span>
              </h2>
            </>
          ) : (
            <h2>-</h2>
          )}
        </div>

        <div className="assetCard-col">
          <p>Asset Score</p>
          {!R.isEmpty(data) && !R.isNil(data) ? (
            data.map((el) => {
              el.meterTypeId.includes("Score") && (
                <h2>
                  {el.meterValue}%{" "}
                  <span className={`assetCard-col-badge ${el.grade}`}>
                    {el.grade}
                  </span>
                </h2>
              );
            })
          ) : (
            <h2>-</h2>
          )}
        </div>

        <div className="assetCard-col">
          <p>Fuel Remaining</p>
          {!R.isEmpty(fuelLeftData) && !R.isNil(fuelLeftData) ? (
            <h2
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {fuelLeftData
                ? fuelLeftData && Number(fuelLeftData).toFixed(2)
                : "0"}{" "}
              L
              {!R.isEmpty(fuelData) && !R.isNil(fuelData) ? (
                <span> {fuelData && fuelData}%</span>
              ) : (
                ""
              )}
            </h2>
          ) : (
            <h2></h2>
          )}
          <h2 style={{ display: "flex", flexDirection: "row" }}>
            {!R.isEmpty(fuelConsumed) && !R.isNil(fuelConsumed) ? (
              <>
                {Number(fuelConsumed?.sum).toFixed(2)}{" "}
                <span className="fontSpan">(consumed)</span>
              </>
            ) : (
              "-"
            )}{" "}
          </h2>
          {/* <h2>
               
                {/* <span className={`assetCard-col-badge ${ele.grade}`}>
                  {ele.grade}
                </span> 
              </h2> */}
        </div>
        {/* data.map((ele, id) => {
      ele.meterTypeId.includes("KVA") && setKvaData(ele.meterValue);
    });

    data.map((ele, id) => {
      ele.meterTypeId.includes("KVA") &&
        setKvaPercentageData(ele.currentLoadInPct);
    }); */}
        <div className="assetCard-col">
          <p>Load</p>

          <h2
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {!R.isEmpty(kvaData) && !R.isNil(kvaData)
              ? Number(kvaData).toFixed(2) + "kVA"
              : "-"}
            {!R.isEmpty(kvaPercentageData) && !R.isNil(kvaPercentageData) ? (
              <span>{Number(kvaPercentageData).toFixed(2)} %</span>
            ) : (
              <span>0 %</span>
            )}
          </h2>
          <h2 style={{ display: "flex", flexDirection: "row" }}>
            {!R.isEmpty(loadAverageValue) && !R.isNil(loadAverageValue) ? (
              <>
                {Number(loadAverageValue?.average).toFixed(2)}{" "}
                <span className="fontSpan">(average)</span>
              </>
            ) : (
              "-"
            )}
          </h2>

          {/* {!R.isEmpty(data) ? (
          data.map((el) => {
            el.meterTypeId.includes("KVA") && (
              <h2>
                {el.meterValue}
                {el.readingUomDesc}{" "}
                {/* <span className={`assetCard-col-badge ${el.grade}`}>
                  {el.grade}
                </span> 
              </h2>
            );
          })
        ) : (
          <h2></h2>
        )} */}
        </div>
      </div>
    </>
  );
}

export default DG;

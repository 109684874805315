import React, { useState, useEffect } from "react";
import "./AssetEventLog.scss";
import AssetEventLogCard from "./AssetEventLogCard";
import ArrowIcon from "../../assets/icons/ArrowIcon.svg";
import ArrowDown from "../../assets/icons/ArrowDown.svg";
import ArrowRight from "../../assets/icons/ArrowRight.svg";
import * as R from "ramda";
function AssetEventLog({ data }: any) {
  //const [data, setEventData] = useState<any>([...data]);
  const [showChild, setShowChild] = useState("");

  console.log("AssetEventLog", data);

  const [showArrow, setShowArrow] = useState(false);
  const setClickStatus = (obj: any) => {
    setShowChild(showChild === obj?.eventStatus ? "" : obj?.eventStatus);
    setShowArrow(!showArrow);
  };

  const [activeEventLog, setActiveEventLog] = useState([]);

  const handleChangeEvent = (key: any) => {
    let list = data.filter((ele: any) => {
      if (ele.parentEventCategory === key) return ele;
    });
    setActiveEventLog(list);
  };
  const [dropDownList, setDropDownList] = useState([]);

  useEffect(() => {
    let list: any = [];
    for (let obj of data) {
      if (!list.includes(obj.parentEventCategory)) {
        list.push(obj.parentEventCategory);
      }
    }
    setDropDownList(list);
    handleChangeEvent(list[0]);
  }, [data])


  return (
    <div className="eventContainer">
      <div className="dropdownCont">
        <select id="selectId" onChange={(e) => handleChangeEvent(e.target.value)} className="selector" >
          {dropDownList?.map((ele: any, index: any) => {
            return (
              <option key={index} value={ele}> {ele} </option>
            )
          })}
        </select>
      </div>
      <div className="headerCont">
        <p>Event Type</p>
        <p>Count</p>
      </div>

      {/* //Map List */}
      {activeEventLog.map((obj: any, index: any) => (
        <div key={index} className="detailsCont">
          <div className="detailsHeaderCont" onClick={() => setClickStatus(obj)} >
            <div className="detailsHeader">
              <img src={ArrowRight} alt="icon" className={`headerIcon ${showChild === obj?.eventStatus ? 'headerIconActive' : ''}`} loading="lazy" />
              <p>{obj?.eventStatus}</p>
            </div>
            <p className="count" style={{ cursor: "pointer" }}>{obj?.count}</p>
          </div>
          {showChild === obj?.eventStatus && <AssetEventLogCard data={obj} />}
        </div>
      ))}
    </div>
  );
}

export default AssetEventLog;

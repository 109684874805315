/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import moment from "moment";
import "./PeakTimeAndFootfall.scss";
import * as R from "ramda";
// import Group7946 from "../../../assets/icons/Group7946.svg";
import FootfallImg from "../../../assets/icons/FootfallImg.svg";
import { VideoAnalyticsDBServices } from "../videoAnalyticsDbService/videoAnalyticsDbService";
import axios from "axios";

const dummyPeakTimeJSON = {
  peak_timeframe: {
    duration: 7,
    name: "today",
    description: "day",
    start: 36172863123,
    end: 36172863789,
  },
  stats: [
    {
      name: "Average",
      value: "687",
    },
    {
      name: "Median",
      value: "720",
    },
    {
      name: "Mode",
      value: "625",
    },
  ],
};


function PeakTimeAndFootfall({dateRange}) {
  const [loading, setLoading] = useState(false);
  const [apiData, setapiData] = useState({})


  const getapiData = async () => {
    setLoading(true);
    try {
      const startDate = moment(dateRange[0]).utc().valueOf();
      const endDate = moment(dateRange[1]).utc().valueOf();
      const url = `https://hawkeye.aone.ai/analytics/videoAnalytics/incident/metrics/?start=${startDate}&end=${endDate}`;
      const response = await axios.get(url);
      console.log({response})
      if (response.status === 200) {
        console.log({response})
        setapiData(response?.data?.data);
      } else {
        setapiData({});
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setapiData({});
    }
  };
const [averageDuration, setaverageDuration] = useState()
const [uniqueCount, setUniqueCount] = useState()
  useEffect(() => {
   let average :any;
   let unique:any;
   
 if(!R.isEmpty(apiData) && !R.isNil(apiData)){
  average = apiData?.average.find((obj:any) => obj.type === "CUSTOMER_DETECTION")
  let miliSec = average.duration ;
  const seconds = Math.floor((miliSec / 1000) % 60);
console.log("secondsseconds",seconds)
if(seconds > 60 ){
  const minutes = Math.floor((miliSec / 1000 / 60) % 60);
  let addStr = minutes + " min"
  setaverageDuration(addStr)
}
else {
  let addStr2 = seconds + " sec"
  setaverageDuration(addStr2)
}


  unique = apiData?.unique.find((obj:any) => obj.name === "Customer Detected")
  setUniqueCount(unique.count)
  // console.log(average,unique,"average,unique")
 }
 else{
  setaverageDuration({})
 }
   
  }, [apiData])
  

  useEffect(() => {
    getapiData();
    // let TempObj = data.find(
    //   (obj: any) => obj.type == element.type
    // );
   
  }, [dateRange]);

  return (
    <div className="peakTimeAndFootfallContainer">
      {/* <div className="peakTimeAndFootfallTxtCont">
        <li className="peakTimeAndFootfallTxtHead">
          {dummyPeakTimeJSON.peak_timeframe.name}
        </li>
        <li className="peakTimeAndFootfallTxt">
          {dummyPeakTimeJSON.peak_timeframe.description}
        </li>
        <li className="peakTimeAndFootfallTime">
          {moment(dummyPeakTimeJSON.peak_timeframe.start).format("LT")} -{" "}
          {moment(dummyPeakTimeJSON.peak_timeframe.end).format("LT")}
        </li>
      </div> */}
      <div className="footfallImageCountContainer">
        <img className="footfallImage" src={FootfallImg} alt="FootfallImg" />
        <ul className="footfallCountTextCont">
          <li className="footfallCount">{!R.isEmpty(uniqueCount) && !R.isNil(uniqueCount) && Number(uniqueCount)? Number(uniqueCount) : "-"}</li>
          <li className="footfallCountText">
            Count
          </li>
          {/* unique{
                "type": 6,
                "name": "Customer Detected",
                "count": 0,
                "delta": 0.0
            }, */}
        </ul>
       
      </div>


      <div className="footfallImageCountContainer">
        {/* <img className="footfallImage" src={Group7946} alt="Footfa" /> */}
        <ul className="footfallCountTextCont">
          <li className="footfallCount">{!R.isEmpty(averageDuration) && !R.isNil(averageDuration)  ? averageDuration : "-"}</li>
          <li className="footfallCountText">
            Average
          </li>
        </ul>
       
      </div>




      {/* <div className="footfallImageCountContainer">
        <img className="footfallImage" src={FootfallImg} alt="FootfallImg" />
        <ul className="footfallCountTextCont">
          <li className="footfallCount">{dummyPeakTimeJSON.stats[0].value}</li>
          <li className="footfallCountText">
            {dummyPeakTimeJSON.stats[0].name}
          </li>
        </ul>
      </div> */}
    </div>
  );
}

export default PeakTimeAndFootfall;

import React from "react";
import "../../App_one.scss";

function PortalHeader({ children, isDark, open }: any) {
  return (
    <div
      className={open ? "portalHeader-rightOpen" : "portalHeader"}
      style={{ backgroundColor: isDark ? "#0C1228" : "#EDEEF8" }}
    >
      {children}
    </div>
  );
}

export default PortalHeader;

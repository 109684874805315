import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import "../../App_one.scss";
import "./ActivitySubAlerts.scss";
import moment from "moment";
import styled from "styled-components";
import * as R from "ramda";
import watchList from "../../assets/icons/activitytab-watchlist.svg";
import alerts from "../../assets/icons/activitytab-alerts.svg";
import tickets from "../../assets/icons/activitytab-tickets.svg";

function ActivitySubAlert({
  hideComponent,
  name,
  type,
  createdTime,
  lastUpdated,
  id,
  value,
  facilityid,
  alertDes,
  assetName,
  alertTypeDesc,
  escalationLevel,
  level,
  callBackalertPopUp,
  acticityImg,
}: any) {
  let varSec = true;
  const divStyle = {
    borderLeft: varSec ? "5px solid #fa0808" : "0px solid #fa0808",
    // Add other inline styles as needed
  };

  return (
    <>
      <div
        key={id}
        style={{
          width: level ? "95%" : "auto",
          cursor: type === "ALERT" ? "pointer" : "default",
          borderLeft: !acticityImg ? "5px solid #fa0808" : "1px solid #ccc",
        }}
        onClick={() => {
          if (type === "ALERT") {
            callBackalertPopUp(value);
          } else {
            //callBackalertPopUp(false);
          }
        }}
        className="assetCart__scss_H"
      >
        {acticityImg && (
          <div className="icon">
            <img
              src={
                type === "WATCH"
                  ? watchList
                  : type === "ALERT"
                  ? alerts
                  : type === "TICKET"
                  ? tickets
                  : ""
              }
            />
          </div>
        )}
        <div style={{"width":"100%"}}>
        {!acticityImg ? <div className="flex_row_H1">
          <div className="row_1">
           <> <h5 key={id}>{assetName}</h5>
            <h6>
              {name}{" "}
              {!R.isEmpty(escalationLevel) && !R.isNil(escalationLevel) ? (
                <span> Level {escalationLevel}</span>
              ) : (
                ""
              )}
            </h6> </> 
          </div>
          <div className="row_1">
            <h4>
              {createdTime ? moment(createdTime).startOf("hour").fromNow() : ""}
            </h4>
          </div>
        </div>:""}
        <div className="flex_row_H1">
          <div className="row_1">
            <h5 style={{color: "#374dc6"}}>{alertTypeDesc}</h5>
          </div>
        </div>
        <div className="flex_row_H1">
          <div className="row_1">
            <h2>{alertDes}</h2>
          </div>
        </div>
      {acticityImg ?  <div className="flex_row_H1">
          <div className="row_1">
          <h6>
             
              {!R.isEmpty(escalationLevel) && !R.isNil(escalationLevel) ? (
                <span> Level {escalationLevel}</span>
              ) : (
                ""
              )}
            </h6>
            </div>
            <div className="row_1">
          <h4>
              {createdTime ? moment(createdTime).startOf("hour").fromNow() : ""}
            </h4>
          </div>
        </div> : ""}
        </div>
       
      </div>
    </>
  );
}

export default ActivitySubAlert;

import React from "react";
import axios from "axios";
import { json } from "stream/consumers";
import moment from "moment";
import { API_BASE_URL } from "../../Constants/constants";
import { ConsumptionFlowData } from "../../a1Components/consumptionFlow/type/ConsumptionFlowData";
import * as R from "ramda";
import { AssetListType } from "./type";
import LocalStorageService from "../../Utils/LocalStorageService";

let controller = new AbortController();
let signal = controller.signal;

export class DashBoardServices {
  async searchAssets(value: any) {
    try {
      const url = `https://us-central1-cylinderdetection.cloudfunctions.net/cylinderHistoryAPI?cylinderId=${value}`;
      const resp = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      return await resp.json();
    } catch (error) {
      return "error";
    }
  }

  async fetchFacilityHealthPI() {
    const url = `${API_BASE_URL}/PanIndiaView`;
    const resp = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    return await resp.json();
  }

  async fetchUtilizationPI() {
    const url = `${API_BASE_URL}/utilisation`;
    const resp = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    return await resp.json();
  }

  async distributionTabFunPI() {
    const url = `${API_BASE_URL}/distribution`;
    const resp = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    return await resp.json();
  }

  async shankeyLivePI() {
    const url = `${API_BASE_URL}/data`;
    const resp = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    return await resp.json();
  }

  //anovi compliance api
  async anoviComplianceSummary(fromDateStr: string, toDateStr: string) {
    const token_id: any = localStorage.getItem("@tokenID");
    console.log("@tokenID", token_id);
    const url = `${API_BASE_URL}assets/complianceSummary?fromDate=${fromDateStr}&thruDate=${toDateStr}`;
    //var errText = "err-500/404";
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token_id}`
        },
      });

      if (response.status === 200) {
        // console.log("respdata",response,response.json())
        let data = await response.json();
        return data;
      } else {
        return response.status;
      }
    } catch {
      return "error";
    }
  }

  async anoviAssetSumm(fromDateStr: string, toDateStr: string) {
    const userName = localStorage.getItem("userName");
    const password = localStorage.getItem("password");
    const token_id: any = localStorage.getItem("@tokenID");
    const url = `${API_BASE_URL}/fixedAsset/assetStatus`;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token_id}`
        },
      });

      if (response.status === 200) {
        return await response.json();
      } else {
        return response.status;
      }
    } catch {
      return "error";
    }
  }

  async anoviComplianceSummaryTwo(fromDateStr: string, toDateStr: string) {
    const token_id: any = localStorage.getItem("@tokenID");
    //console.log("the compliance data two", token_id);
    // const url = `${API_BASE_URL}assets/assetsComplianceSummary?username=superadmin@si&password=si@superadmin&fromDate=1680373799000&thruDate=1680546599000`;
    const url = `${API_BASE_URL}assets/assetsComplianceSummary?fromDate=${fromDateStr}&thruDate=${toDateStr}`;
    //var errText = "err-500/404";
    //console.log("the compliance data two", url);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token_id}`
        },
      });

      if (response.status === 200) {
        // console.log("respdata",response,response.json())
        let data = await response.json();
        return await data;
      } else {
        return response.status;
      }
    } catch {
      return "error";
    }
  }

  async anoviTicketsDulTwo(fromDateStr: string, toDateStr: string) {
    const userName = localStorage.getItem("userName");
    const password = localStorage.getItem("password");
    const token_id: any = localStorage.getItem("@tokenID");
    const url = `${API_BASE_URL}assets/userTickets?token=${token_id}&startDate=${fromDateStr}&endDate=${toDateStr}`;
    try {
      const resp = await axios.get(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      console.log("datadatadata", resp);
      if (resp.status === 200) {
        return await resp?.data;
      } else {
        return "err";
      }
    } catch {
      return "err";
    }
  }

  async anoviTicketsDul(fromDateStr: string, toDateStr: string) {
    const url = `${API_BASE_URL}assets/summary`;

    const token_id: any = localStorage.getItem("@tokenID");
    var errText = "err-500/404";
    try {
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          token: token_id,
          type: "SERVICE_REQUESTS",
          fromDate: fromDateStr,
          thruDate: toDateStr,
        }),
        headers: { "Content-Type": "application/json" },
      });

      if (resp.status === 200) {
        return await resp.json();
      } else if (resp.status === 500 || resp.status === 504) {
        return errText;
      }
    } catch {
      return errText;
    }
  }

  async dieselGeneratorFunPI(fromDateStr: string, toDateStr: string) {
    const url = `${API_BASE_URL}assets/summary`;
    const token_id: any = localStorage.getItem("@tokenID");

    try {
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          token: token_id,
          type: "DG",
          fromDate: fromDateStr,
          thruDate: toDateStr,
        }),
        headers: { "Content-Type": "application/json" },
      });

      if (resp.status === 200) {
        return await resp.json();
      } else {
        return "err";
      }
    } catch {
      return "err";
    }
  }

  async complianceAssetSummary(fromDateStr: string, toDateStr: string) {
    const url = `${API_BASE_URL}assets/summary`;
    const token_id: any = localStorage.getItem("@tokenID");
    try {
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          token: token_id,
          type: "COMPLIANCE",
          fromDate: fromDateStr,
          thruDate: toDateStr,
        }),
        headers: { "Content-Type": "application/json" },
      });
      // console.log("resp.status for compliance::", resp.status);
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return "err";
      }
    } catch {
      return "err";
    }
  }

  async energyCardFunPI(fromDateStr: string, toDateStr: string) {
    const url = `${API_BASE_URL}assets/summary`;
    const token_id: any = localStorage.getItem("@tokenID");
    try {
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          token: token_id,
          type: "ENERGY",
          fromDate: fromDateStr,
          thruDate: toDateStr,
        }),
        headers: { "Content-Type": "application/json" },
      });
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return "err";
      }
    } catch {
      return "err";
    }
  }

  async ticketComponentFunPI(fromDateStr: string, toDateStr: string) {
    const url = `${API_BASE_URL}assets/summary`;
    const token_id: any = localStorage.getItem("@tokenID");
    try {
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          token: token_id,
          type: "SERVICE_REQUESTS",
          fromDate: fromDateStr,
          thruDate: toDateStr,
        }),
        headers: { "Content-Type": "application/json" },
      });
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return "err";
      }
    } catch {
      return "err";
    }
  }

  async clusterViewCL() {
    const url = `${API_BASE_URL}/Cluster`;
    const resp = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    return await resp.json();
  }

  async regionViewRN() {
    const url = `${API_BASE_URL}/Region`;
    const resp = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    return await resp.json();
  }

  async cityViewCT() {
    const url = `${API_BASE_URL}/City`;
    const resp = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    return await resp.json();
  }

  // Top 5 cities
  // async fetchTopCities() {
  //   const url = "https://snakeoil-api.atsuyatech.com/Top5citiesDetails";
  //   const resp = await fetch(url, {
  //     method: "GET",
  //     headers: { "Content-Type": "application/json" },
  //   });
  //   return await resp.json();
  // }

  // async fetchConsumption() {
  //   const url = "https://snakeoil-api.atsuyatech.com/consumptionDetails";
  //   const resp = await fetch(url, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   });
  //   return await resp.json();
  // }

  // async fetchEquipmentUtilisation() {
  //   const url = "https://snakeoil-api.atsuyatech.com/EquipmentUtilisation";
  //   const resp = await fetch(url, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   });
  //   return await resp.json();
  // }

  // async fetchDefectiveCylinders() {
  //   const url = "https://snakeoil-api.atsuyatech.com/DefectiveCylinders";
  //   const resp = await fetch(url, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   });
  //   return await resp.json();
  // }

  // async fetchOverall() {
  //   const url = "https://snakeoil-api.atsuyatech.com/OverallHealth";
  //   const resp = await fetch(url, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   });
  //   return await resp.json();
  // }
  // IOCL dashboard
  async loadSummary(startDate: string, endDate: string) {
    try {
      const url = `https://us-central1-cylinderdetection.cloudfunctions.net/loadDetailsAPI?startDate=${startDate}&endDate=${endDate}`;
      // const url = `http://${domain}/loadDetailsAPI/${fromDateStr}/${toDateStr}`
      const resp = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (resp.status === 200) {
        return await resp.json();
      }
    } catch (error) {
      // console.log("thre loader message", error);
      return "err";
    }
  }
  // Api for DG Energy Generated Vs Run Hr
  async dGEnergyGeneratedRunHr(fromDateStr: String, toDateStr: String) {
    const token_id: any = localStorage.getItem("@tokenID");
    console.log(fromDateStr, toDateStr, "@Date");
    try {
      const url = `${API_BASE_URL}assets/engeryGeneratedVsRunHr?token=${token_id}&fromDate=${fromDateStr}&thruDate=${toDateStr}`;
      const resp = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return "err";
      }
    } catch (error) {
      return "err";
    }
  }

  async consumptionTrendList(fromDateStr: any, toDateStr: any) {
    const userName = localStorage.getItem("userName");
    const password = localStorage.getItem("password");
    const token_id: any = localStorage.getItem("@tokenID");
    const url = `${API_BASE_URL}assets/consumptionCard?token=${token_id}&startDate=${fromDateStr}&endDate=${toDateStr}`;
    console.log("@url", url);
    try {
      const resp = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return "err";
      }
    } catch (error) {
      return "err";
    }
  }

  async comparisonDataWithInputs(
    fromDateStr: any,
    toDateStr: any,
    id1: any,
    id2: any
  ) {
    const token_id: any = localStorage.getItem("@tokenID");
    const url = `${API_BASE_URL}assets/comparisonData?token=${token_id}&startDate=${fromDateStr}&endDate=${toDateStr}&Option=WATER-LITRE&filterBy=${id1},${id2}`;
    console.log("@url", url);
    try {
      const resp = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return "err";
      }
    } catch (error) {
      return "err";
    }
  }

  async getListofConsumtions() {
    const token_id: any = localStorage.getItem("@tokenID");

    const url = `${API_BASE_URL}assets/enumData?token=${token_id}&enumTypeId=WATER&enumCode=COMPARE`;
    try {
      const resp = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return "err";
      }
    } catch (error) {
      return "err";
    }
  }

  async getTrendChartData(
    fromDateStr: any,
    toDateStr: any,
    activeId: any,
    facilityId: any,
    filterByType: any
  ) {
    const userName = localStorage.getItem("userName");
    const password = localStorage.getItem("password");
    const url = `${API_BASE_URL}assets/waterGasConsumption?username=${userName}&password=${password}&fromDate=${fromDateStr}&thruDate=${toDateStr}&filterBy=${facilityId}&type=${activeId}/O&filterByType=${filterByType}`;
    console.log("getTrendChartDataUrl", url);
    try {
      const resp = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return "err";
      }
    } catch (error) {
      return "err";
    }
  }

  async getAssetDetailLiveData(fromDateStr: any, toDateStr: any) {
    const deviceId = localStorage.getItem("assetId");
    const token_id: any = localStorage.getItem("@tokenID");
    const url = `${API_BASE_URL}fixedAsset/getTsRawAssetData?token=${token_id}&fixedAssetId=${deviceId}&startTime=${fromDateStr}&endTime=${toDateStr}`;
    try {
      const resp = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return resp.status;
      }
    } catch {
      return "error";
    }
  }

  //anoviTempCompliance
  async anoviTempCompliance(fromDateStr: any, toDateStr: any) {
    const token_id: any = localStorage.getItem("@tokenID");
    console.log("the date", fromDateStr, toDateStr);
    let url = `${API_BASE_URL}assets/complianceAndNcData?filterByType=_NA_&filterById=_NA_&startDate=${fromDateStr}&endDate=${toDateStr}&pageSize=5&pageIndex=1`;
    try {
      const resp = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token_id}`
        },
      });
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return resp.status;
      }
    } catch {
      return "error";
    }
  }

  //anovi event summary
  async anoviEventSummary(fromDateStr: Number, toDateStr: Number, key: String) {
    const token_id: any = localStorage.getItem("@tokenID");
    let url = `${API_BASE_URL}assets/eventSummary?token=${token_id}&eventId=${key}&startDate=${fromDateStr}&endDate=${toDateStr}`;
    try {
      const resp = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return resp.status;
      }
    } catch {
      return "error";
    }
  }

  async getActivityCenter(
    fromDateStr: any,
    stateVal: any,
    endDate: any,
    deviceId: any
  ) {
    controller.abort(); // Cancel the previous request
    controller = new AbortController();
    signal = controller.signal;

    const token_id = localStorage.getItem("@tokenID");
    const url = `${API_BASE_URL}fixedAsset/activity?token=${token_id}&fromDate=${fromDateStr}&thruDate=${endDate}&fixedAssetId=${deviceId}&pageSize=20&pageIndex=${stateVal}`;
    try {
      const resp = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        signal, 
      });
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return resp.status;
      }
    } catch (error) {
    if (error.name === 'AbortError') {
      return "cancelled";
    }
    return "error";
  }
}

  async getFilterActivityCenter(
    fromDateStr: any,
    stateVal: any,
    endDate: any,
    deviceId: any,
    filterByValue: string,
  ) {
    controller.abort(); // Cancel the previous request
    controller = new AbortController();
    signal = controller.signal;

    const tokenId = LocalStorageService.getToken();
    const url = `${API_BASE_URL}fixedAsset/activity?token=${tokenId}&fromDate=${fromDateStr}&thruDate=${endDate}&fixedAssetId=${deviceId}&pageSize=20&pageIndex=${stateVal}&filterByKey=metadata&filterByValue=${filterByValue}`;
    try {
      const resp = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return resp.status;
      }
    } catch {
      return "error";
    }
  }

  async getAlertReasons() {
    const tokenId: any = LocalStorageService.getToken();
    const url = `${API_BASE_URL}assets/enumData?token=${tokenId}&enumTypeId=ALERT_REASONS&enumCode=OPTION`;
    try {
      const resp = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return resp.status;
      }
    } catch {
      return "error";
    }
  }

  async getCloseAlert(alertId: any, reason: any) {
    const token_id: any = localStorage.getItem("@tokenID");
    const url = `${API_BASE_URL}fixedAsset/closeAlert`;
    try {
      const resp = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: token_id,
          alertId: alertId,
          reason: reason,
        }),
      });
      return resp;
    } catch {
      return "error";
    }
  }

  //Event Log Api in Asset Detail Page
  async eventLogFetch(
    startTime: any,
    endTime: any,
    index: any,
    eventStatus: any
  ) {
    let token_id: any = localStorage.getItem("@tokenID");
    const deviceId = localStorage.getItem("assetId");
    const url = `${API_BASE_URL}fixedAsset/getEventStatusLog?token=${token_id}&fixedAssetId=${deviceId}&startDate=${startTime}&endDate=${endTime}&eventStatusType=${eventStatus}&pageIndex=${index}`;
    try {
      const resp = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return resp.status;
      }
    } catch {
      return "error";
    }
  }

  async getAssetApiFetch() {
    const token_id: any = localStorage.getItem("@tokenID");
    const deviceId = localStorage.getItem("assetId");
    const assetTypesLocal: any = localStorage.getItem("assetTypes");
    const url = `${API_BASE_URL}assets?token=${token_id}&assetId=${deviceId}&assetTypeList=${assetTypesLocal}&mode=search`;
    try {
      const resp = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return resp.status;
      }
    } catch {
      return "error";
    }
  }

  async getAssetList(
    assetFilters: any,
    pageSize: number,
    pageIndex: number,
    searchText: any
  ): Promise<AssetListType | number | string> {
    const token_id: string | null = localStorage.getItem("@tokenID");
    const assetTypesLocal: any = localStorage.getItem("assetTypes");

    let url = "";

    let pageSizeVal = pageSize.toString();
    let pageIndexStr = pageIndex.toString();

    if (searchText === "" || searchText[0]?.serchVal === "") {
      url = `${API_BASE_URL}assets?token=${token_id}&pageSize=${pageSizeVal}&pageIndex=${pageIndexStr}&assetTypeList=${assetTypesLocal}&mode=view`;
    } else {
      let assetName = "";
      let assetID = "";
      if (searchText[0]?.targetVal === "Asset Id") {
        assetID = searchText[0]?.serchVal;
        assetName = "";
      } else if (searchText[0]?.targetVal === "AssetName") {
        assetID = "";
        assetName = searchText[0]?.serchVal;
      }
      url = `${API_BASE_URL}assets?token=${token_id}&pageSize=${pageSizeVal}&pageIndex=${pageIndexStr}&assetTypeList=${assetTypesLocal}&assetId=${assetID}&assetName=${assetName}&mode=search`;
    }

    // Checking for Facility Ids
    if (
      !R.isNil(assetFilters) &&
      !R.isEmpty(assetFilters.get("Facility")) &&
      !R.isNil(assetFilters.get("Facility"))
    ) {
      const facilityList = assetFilters
        .get("Facility")
        .filter((item) => item.isSelected === true && item.id !== "All");
      const facilityIdList = facilityList.map((item) => item.id);
      const facIdStr = facilityIdList.join(",").toString();
      if (facIdStr !== "") url += `&facilityList=${facIdStr}`;
    }

    // Checking for Health Status Ids
    if (
      !R.isNil(assetFilters) &&
      !R.isEmpty(assetFilters.get("Status")) &&
      !R.isNil(assetFilters.get("Status"))
    ) {
      const healthStatusList = assetFilters
        .get("Status")
        .filter((item) => item.isSelected === true && item.id !== "All");
      const healthStatusIdList = healthStatusList.map((item) => item.id);
      const healthStatusStr = healthStatusIdList.join(",").toString();
      if (healthStatusStr !== "") url += `&healthStatus=${healthStatusStr}`;
    }

    // Checking for Health Status Ids
    if (
      !R.isNil(assetFilters) &&
      !R.isEmpty(assetFilters.get("Status")) &&
      !R.isNil(assetFilters.get("Status"))
    ) {
      const healthStatusList = assetFilters
        .get("Status")
        .filter((item) => item.isSelected === true && item.id !== "All");
      const healthStatusIdList = healthStatusList.map((item) => item.id);
      const healthStatusStr = healthStatusIdList.join(",").toString();
      if (healthStatusStr !== "") url += `&healthStatus=${healthStatusStr}`;
    }

    //  // Checking for compliance
    // if (
    //   !R.isEmpty(assetFilters.complianceIds) &&
    //   !R.isNil(assetFilters.complianceIds)
    // ) {
    //   const complianceStr = assetFilters.complianceIds.join(",").toString();
    //   url += `&complianceTypes=${complianceStr}`;
    // }

    // Checking for Monitored Asset Type List
    if (
      !R.isNil(assetFilters) &&
      !R.isEmpty(assetFilters.get("Asset Type")) &&
      !R.isNil(assetFilters.get("Asset Type"))
    ) {
      const assetTypeList = assetFilters
        .get("Asset Type")
        .filter((item) => item.isSelected === true && item.id !== "All");
      const assetTypeIdList = assetTypeList.map((item) => item.id);
      const monAssetTypeIdStr = assetTypeIdList.join(",").toString();
      if (monAssetTypeIdStr !== "")
        url += `&monitoredAssetTypeList=${monAssetTypeIdStr}`;
    }

    try {
      const resp = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return resp.status;
      }
    } catch {
      return "error";
    }
  }

  async eventLogOnLandingFetch(fromDate: any, endDate: any) {
    const token_id: any = localStorage.getItem("@tokenID");
    const deviceId = localStorage.getItem("assetId");
    const startTime = moment(fromDate).valueOf();
    const endTime = moment(endDate).valueOf();
    const url = `${API_BASE_URL}fixedAsset/getEventLog?token=${token_id}&fixedAssetId=${deviceId}&startDate=${startTime}&endDate=${endTime}`;
    try {
      const resp = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return resp.status;
      }
    } catch {
      return "error";
    }
  }

  async asset_Summary_new(fromDateStr: string, toDateStr: string) {
    const url = `${API_BASE_URL}assets/summary`;
    const token_id: any = localStorage.getItem("@tokenID");
    const deviceId = localStorage.getItem("assetId");
    try {
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          token: token_id,
          fixedAssetId: deviceId,
          fromDate: fromDateStr,
          thruDate: toDateStr,
        }),
        headers: { "Content-Type": "application/json" },
      });
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return "err";
      }
    } catch {
      return "err";
    }
  }

  async user_Downloads_API(fromDate: any, endDate: any, pageIndex: any) {
    const token_id: any = localStorage.getItem("@tokenID");
    const url = `${API_BASE_URL}reports/userDownloads/?token=${token_id}&pageSize=${20}&pageIndex=${pageIndex}&endDate=${endDate}&startDate=${fromDate}`;
    try {
      const resp = await fetch(url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return resp.status;
      }
    } catch {
      return "error";
    }
  }

  async Schedule_Report_API(StartDate: any, EndDate: any) {
    const token_id: any = localStorage.getItem("@tokenID");
    const url = `${API_BASE_URL}reports/scheduleReportGeneration?token=${token_id}&fromDate=${StartDate}&thruDate=${EndDate}`;
    try {
      const resp = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token_id}`,
        },
        body: JSON.stringify({
          token: token_id,
          fromDate: StartDate,
          thruDate: EndDate,
          contentId: "PDTC_RPT",
          reportFormat: "xlsx",
        }),
      });
      return resp;
    } catch {
      return "error";
    }
  }

  async User_Schedule_Report_API(
    StartDate: any,
    EndDate: any,
    min: any,
    max: any
  ) {
    console.log("min and max", min, max);
    let minVal = parseFloat(min);
    let maxVal = parseFloat(max);
    const token_id: any = localStorage.getItem("@tokenID");
    const url = `${API_BASE_URL}reports/scheduleReportGeneration?token=${token_id}&fromDate=${StartDate}&thruDate=${EndDate}`;
    try {
      const resp = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token_id}`,
        },
        body: JSON.stringify({
          token: token_id,
          fromDate: StartDate,
          minVal: minVal,
          maxVal: maxVal,
          thruDate: EndDate,
          contentId: "UDTC_RPT",
          reportFormat: "xlsx",
        }),
      });
      return resp;
    } catch {
      return "error";
    }
  }

  async oldEnergyFacilityManagerData(params: any) {
    try {
      const data = await fetch(
        `${API_BASE_URL}assets/consumptionTrend?filterByType=facility&endDate=${params.endDate}&startDate=${params.startDate}&productMeterTypeId=Wh_EB&classEnumId=FAC_EB_MAIN&filterByDate=${params.dateType}&filterById=${params.filterById}`,
        params.options
      );
      if (data.status === 200) {
        const resp = await data.json();
        return resp;
      }
      return "Error";
    } catch (error) {
      // 
      return "Error";
    }
  }

  async getSingleFacilityManagerData(params: any) {
    try {
      // ${API_BASE_URL}
      let url = `${API_BASE_URL}assets/consumptionTrend?fixedAssetTypeId=${params.fixedAssetTypeId}&fixedAssetId=${params.fixedAssetId}&filterByType=${params.facilityType}&filterById=${params.filterById}&endDate=${params.endDate}&startDate=${params.startDate}&pageIndex=1&pageSize=${params.pageSize}&consumptionType=${params.consumptionType}&frequency=${params.dateType}&fetchShiftData=${params.fetchShiftData}`;
if (params.range !== undefined) {
    url += `&range=${params.range}`;
}
if(params.isSubMeterConsp !== undefined){
  url += `&isSubMeterConsp=${params.isSubMeterConsp}`;
}
if(params.facilityIdForConsp !== undefined){
  url += `&facilityIdForConsp=${params.facilityIdForConsp}`;
}
      const data = await fetch( url,
        // fixedAssetTypeId=&fixedAssetId=&
        // `${API_BASE_URL}assets/consumptionTrend?filterByType=facility&endDate=${params.endDate}&startDate=${params.startDate}&fixedAssetId=${params.fixedAssetId}&fixedAssetTypeId=${params.fixedAssetTypeId}&pageIndex=${params.pageIndex}&pageSize=${params.pageSize}&trendFlag=${params.trendFlag}&consumptionType=${params.consumptionType}&shiftFlag=${params.shiftFlag}&productMeterTypeId=${params.productMeterTypeId}&classEnumId=FAC_EB_MAIN&filterByDate=${params.dateType}&filterById=${params.filterById}`,
        params.options
      );
      if (data.status === 200) {
        const resp = await data.json();
        return resp;
      }
      return "Error";
    } catch (error) {
      if(error?.code == 20){
        return "cancelled";
      }
      return "Error";
    }
  }

  async getReportDownload(data: object) {
    const token_id: any = localStorage.getItem("@tokenID");
    const url = `${API_BASE_URL}reports/scheduleReportGeneration`;
    try {
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${token_id}`,
          "Content-Type": "application/json",
        },
      });
      return resp;
    } catch {
      return "error";
    }
  }


  //list of reports
  async getListofReports() {
    const url = `${API_BASE_URL}reports/allReports`;
    try {
      const resp = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (resp.status === 200) {
        let data = await resp.json();
        return data;
      }
      else return resp.status;
    } catch (error) {
      return "error";
    }
  }

  //forgot password api's
  async getUserInfo(userName: string) {
    const url = `${API_BASE_URL}login/userContactDetails?userLoginId=${userName}`;
    try {
      const resp = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      return resp;
    } catch (error) {
      return "error";
    }
  }


  async generateOTP(data: {
    userLoginId: string,
    contactString: string,
    contactType: string
  }) {
    const url = `${API_BASE_URL}login/generateOtp?userLoginId=${data.userLoginId}&contactString=${data.contactString}&contactType=${data.contactType}`;
    try {
      const resp = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      return resp;
    } catch {
      return "error";
    }
  }

  //change password Api
  async getChangePassword(data: object) {
    const token_id: any = localStorage.getItem("@tokenID");
    const url = `${API_BASE_URL}passwordchange`;
    try {
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${token_id}`,
          "Content-Type": "application/json",
        },
      });
      return resp;
    } catch {
      return "error";
    }
  }

  //validateOtp
  async validateOTP(user: string, input: string) {
    const url = `${API_BASE_URL}login/validateOtp?userLoginId=${user}&passcode=${input}`
    try {
      const resp = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      return resp;
    } catch {
      return "error";
    }
  }

  async getConsumptionFlowData(
    startDate: any,
    endDate: any,
    filterId: string
  ): Promise<ConsumptionFlowData | number | string> {
    const tokenId: any = localStorage.getItem("@tokenID");
    try {
      const option = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenId}`,
        },
      };
      const response = await fetch(
        `${API_BASE_URL}energy/consumptionFlow?startDate=${startDate}&endDate=${endDate}&filterById=${filterId}`,
        option
      );
      if (response.status === 200) {
        return await response.json();
      } else {
        return response.status;
      }
    } catch (error) {
      return "error";
    }
  }

  async getUpdateNewPassword(data: object) {
    const url = `${API_BASE_URL}login/forgotPassword`;
    try {
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      return resp;
    } catch {
      return "error";
    }
  }

  async getTrendData(body: object): Promise<object | number | string> {
    controller.abort(); // Cancel the previous request
    controller = new AbortController();
    signal = controller.signal;

    const tokenId: any = localStorage.getItem("@tokenID");
    const url = `${API_BASE_URL}assets/metricTrendSummary`;
    const option =  {
      "method": "POST",
      "body": JSON.stringify(body),
      "headers": {
        "Authorization": `Bearer ${tokenId}`,
        "Content-Type": "application/json",
      },
      signal: signal,
    }
    try {
      const resp = await fetch(url, option);
      const response = await resp.json();
      if (resp.status === 200) {
        return response;
      } else {
        return resp.status;
      }
    }
    catch (error) {
      return "error";
    }
  }

}

import { useState } from "react";
import "./FilterLabel.scss";
import UncheckedBox from "../assets/uncheckedBox.svg";
import HoveredBox from "../assets/hoveredCheckbox.svg";
import CheckedBox from "../assets/checkedBox.svg";

type PropTypes = {
  isSelected: boolean;
  title: string;
  onClick: () => void;
};

function FilterLabel({ isSelected, title, onClick }: PropTypes) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="filterLabelDiv"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      <img
        className="checkboxImage"
        src={
          isHovered && !isSelected
            ? HoveredBox
            : isSelected
            ? CheckedBox
            : UncheckedBox
        }
      />
      <p className="filterLabelText">{title}</p>
    </div>
  );
}

export default FilterLabel;

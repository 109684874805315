import React, { useState } from "react";
import SelectedEventDetails from "./SelectedEventDetails";
import BackIcon from "../assets/back button animated.svg";
import CrossIcon from "../assets/closeButtonIcon.svg";
import "./SelectedEventCategory.scss";
import { SelectedEventData } from "../types";

type PropTypes = {
  data: SelectedEventData[];
  clearSelectedCategory: () => void;
  handleSelectedSubEvent: (id: string, name: string) => void;
};

function SelectedEventCategory({
  data,
  clearSelectedCategory,
  handleSelectedSubEvent,
}: PropTypes) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="selectedEventCategory">
      <div className="selectedEventHeader">
        <img
          className="eventCloseIcon"
          src={isHovered ? CrossIcon : BackIcon}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={clearSelectedCategory}
        />
        <p className="goBackText">Go Back</p>
      </div>
      <div className="eventDetailsChart">
        {data.map((item: SelectedEventData) => (
          <SelectedEventDetails
            name={item.name}
            percent={item.percent}
            value={item.value}
            id={item.parentNoteTypeId}
            handleSelectedSubEvent={handleSelectedSubEvent}
          />
        ))}
      </div>
    </div>
  );
}

export default SelectedEventCategory;

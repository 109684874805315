import React, { useState } from "react";
import {
  EventCategoryListType,
  EventSummaryDataType,
  EventSummaryParams,
  FilterDataObj,
  SelectedEventData,
  SelectedValueType,
} from "../types";
import { TrendChartDataType } from "../../../a1Pages/AssetDetailPage/types/TrendChartDataType";

function EventSummaryState() {
  const [searchingIndex, setSearchingIndex] = useState<number | null>(null);
  const [filterDataSet, setFilterDataSet] = useState<FilterDataObj[]>([]);
  const [selectedFilterKey, setSelectedFilterKey] = useState<string[]>([]);
  const [selectedFilterValue, setSelectedFilterValue] = useState<
    SelectedValueType[]
  >([]);
  const [showEventCountFreq, setShowEventCountFreq] = useState(false);

  const [trendRange, setTrendRange] = useState<string[]>([]);
  const [selectedTrendRange, setSelectedTrendRange] = useState<string>("");

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [queryParams, setQueryParams] = useState<EventSummaryParams>({
    filterByType: "_NA_",
    filterById: "_NA_",
    filterByDate: "",
    areaId: "_NA_",
    startDate: 0,
    endDate: 0,
    extraDetails: true,
    trendKey: "_NA_",
    previousEventRange: "_NA_",
  });
  const [trendChartData, setTrendChartData] = useState<TrendChartDataType>({
    xAsix: [],
    yAsix: [],
  });
  const [trendChartHeading, setTrendChartHeading] = useState("Total");
  const [showRefreshButton, setShowRefreshButton] = useState(false);

  const [selectedEventData, setSelectedEventData] =
    useState<EventCategoryListType | null>(null);
  const [selectedEventDetailsData, setSelectedEventDetailsData] = useState<
    SelectedEventData[]
  >([]);
  const [selectedSubEventId, setSelectedSubEventId] = useState<null | string>(
    null
  );
  const [apiLoadingState, setApiLoadingState] = useState({
    isSummaryLoading: false,
    isTrendLoading: false,
  });

  const [responseData, setResponseData] = useState<EventSummaryDataType>({
    all: {
      totalEvent: 0,
      eventTypeList: [],
      eventCategoryList: [],
      previousTotalEvent: "_NA_",
    },
    data: [],
  });
  const [eventFreqPerText, setEventFreqPerText] = useState("");
  const [lineChart, setLineChart] = useState(false);
  const [imagePosition, setImagePosition] = useState("");

  return {
    searchingIndex,
    setSearchingIndex,
    filterDataSet,
    setFilterDataSet,
    selectedFilterKey,
    setSelectedFilterKey,
    showEventCountFreq,
    setShowEventCountFreq,
    trendRange,
    setTrendRange,
    selectedTrendRange,
    setSelectedTrendRange,
    hoveredIndex,
    setHoveredIndex,
    selectedIndex,
    setSelectedIndex,
    queryParams,
    setQueryParams,
    trendChartData,
    setTrendChartData,
    trendChartHeading,
    setTrendChartHeading,
    showRefreshButton,
    setShowRefreshButton,
    selectedEventData,
    setSelectedEventData,
    selectedEventDetailsData,
    setSelectedEventDetailsData,
    selectedSubEventId,
    setSelectedSubEventId,
    apiLoadingState,
    setApiLoadingState,
    responseData,
    setResponseData,
    selectedFilterValue,
    setSelectedFilterValue,
    eventFreqPerText,
    setEventFreqPerText,
    lineChart,
    setLineChart,
    imagePosition, setImagePosition
  };
}

export default EventSummaryState;

import { useEffect, useState } from "react";
import "./CompanyInformation.scss";

import Slider from "./slider/Slider";

import CompanyInformationData from "./CompanyInformation.json";
import ImageComponent from "../../Utils/imageSelector/ImageSelectorParentComponent";

const CompanyInformation = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [emissionFactor, setEmissionFactor] = useState<null | number>(null);
  const [image, setImage] = useState<string | null>(null);

  const [utilization, setUtilization] = useState<number>(0);
  const [savings, setSavings] = useState<number>(0);
  const [compliance, setCompliance] = useState<number>(0);
  const [emissions, setEmissions] = useState<number>(0);

  const onUtilizationChanged = (data: number) => {
    setUtilization(data);
  };

  const onSavingsChanged = (data: number) => {
    setSavings(data);
  };

  const onComplianceChanged = (data: number) => {
    setCompliance(data);
  };

  const onEmissionsChanged = (data: number) => {
    setEmissions(data);
  };

  const fetchData = async () => {
    const data = CompanyInformationData.users;
    setName(data.name);
    setEmail(data.email);
    setPhoneNumber(data.phone);
    setAddress(data.address);
    setImage(data.image);
    setEmissionFactor(data.emissionFactor);
    setUtilization(data.utilization);
    setSavings(data.savings);
    setCompliance(data.compliance);
    setEmissions(data.emissions);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="companyInformationContainer">
      <p className="title">Company Information</p>
      <div className="companyInformationDetails">
        <div className="companyDetails-left">
          <div className="companyDetails-input">
            <p>Account Name</p>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="companyDetails-input">
            <p>Email</p>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="companyDetails-input">
            <p>Phone</p>
            <input
              type="number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>

          <div className="companyDetails-input">
            <p>Address</p>
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>

          <div className="companyDetails-input">
            <p>Emission Factor</p>
            <input
              type="number"
              value={emissionFactor?.toString()}
              onChange={(e) => setEmissionFactor(parseInt(e.target.value))}
            />
          </div>
        </div>
        <div className="companyDetails-right">
          <ImageComponent
            image={image}
            setImage={setImage}
            heading="Account Logo"
          />

          <p className="weightage weightage-div">Weightage</p>

          <div className="slider-container">
            <p className="category">Utilization</p>

            <div className="slider">
              <Slider value={utilization} onChange={onUtilizationChanged} />

              <p className="category-result">{utilization}%</p>
            </div>
          </div>

          <div className="slider-container margin">
            <p className="category">Savings</p>

            <div className="slider">
              <Slider value={savings} onChange={onSavingsChanged} />

              <p className="category-result">{savings}%</p>
            </div>
          </div>

          <div className="slider-container margin">
            <p className="category">Compliance</p>

            <div className="slider">
              <Slider value={compliance} onChange={onComplianceChanged} />

              <p className="category-result">{compliance}%</p>
            </div>
          </div>

          <div className="slider-container margin">
            <p className="category">Emissions</p>
            <div className="slider">
              <Slider value={emissions} onChange={onEmissionsChanged} />

              <p className="category-result">{emissions}%</p>
            </div>
          </div>
        </div>
      </div>

      <div className="horizontal-line" />

      <div className="action-area">
        <button className="cancel-button" onClick={() => {}}>
          Cancel
        </button>
        <button className="update-button" onClick={() => {}}>
          Update
        </button>
      </div>
    </div>
  );
};

export default CompanyInformation;

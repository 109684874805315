import {Data, Summary} from "../types";

// export const fetchData = (facilityId : string, startDate: number, endDate : number): Promise<Summary[]> => {
//     console.log("API CALLED")
//     const mockDataObject = JSON.parse(mockJSON);
//     const data: Data = mockDataObject.data;
//     return Promise.resolve(data.summary)
// }

export const fetchData = async (facilityId : string, startDate: number, endDate : number) => {
    const userName = await localStorage.getItem("userName");
    const password = await localStorage.getItem("password");
    const apiUrl = `https://aone.atsuyatech.com/api/1.0/assets/airHandlingUnitData?facilityId=${facilityId}&username=${userName}&password=${password}&startDate=${startDate}&endDate=${endDate}`;

    try {
        const response = await fetch(apiUrl);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const apiResponse = await response.json();
        return apiResponse.data.summary;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};


const mockJSON = `{
    "data": {
        "summary": [
            {
                "energyConsumption": "122.00",
                "serialNumber": null,
                "facilityId": "ATS_CHN_ADY_001",
                "assetTypeId": "ATS_EB",
                "maxTemp": 23.0,
                "avgTemp": 27.4625,
                "minTemp": 20.0,
                "meters": [
                    {
                        "readingUomId": " ",
                        "meterTypeId": "STATUS",
                        "compliance": false,
                        "meterValue": true,
                        "readingDate": "1696935721156",
                        "readingUomDesc": " "
                    },
                    {
                        "readingUomId": " ",
                        "meterTypeId": "TRIP_STATUS",
                        "compliance": false,
                        "meterValue": true,
                        "readingDate": "1696935721156",
                        "readingUomDesc": " "
                    },
                    {
                        "readingUomId": " ",
                        "meterTypeId": "MODE",
                        "compliance": false,
                        "meterValue": "M",
                        "readingDate": "1696935721156",
                        "readingUomDesc": " "
                    },
                    {
                        "readingUomId": "TEMP_C",
                        "meterTypeId": "RET_TEMP",
                        "compliance": false,
                        "meterValue": 20.5,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "C"
                    },
                    {
                        "readingUomId": "TEMP_C",
                        "meterTypeId": "CW_SUP_TEMP",
                        "compliance": false,
                        "meterValue": 7.0,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "C"
                    },
                    {
                        "readingUomId": "TEMP_C",
                        "meterTypeId": "CW_RET_TEMP",
                        "compliance": false,
                        "meterValue": 12.0,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "C"
                    },
                    {
                        "readingUomId": "FREQ",
                        "meterTypeId": "VFD",
                        "compliance": false,
                        "meterValue": 60.0,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "Hz"
                    },
                    {
                        "readingUomId": "OTH_PCT",
                        "meterTypeId": "CW_VALVE",
                        "compliance": false,
                        "meterValue": 70,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "%"
                    },
                    {
                        "readingUomId": "OTH_PCT",
                        "meterTypeId": "CW_VALVE_OVR",
                        "compliance": false,
                        "meterValue": 74,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "%"
                    },
                    {
                        "readingUomId": "ENERGY_KVAH",
                        "meterTypeId": "KVAH",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kVAh"
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "BA",
                        "meterValue": 0.400000006,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "BV",
                        "meterValue": 228.4000092,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "WATT",
                        "meterTypeId": "BW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "W"
                    },
                    {
                        "readingUomId": "PF",
                        "meterTypeId": "PFB",
                        "meterValue": 0,
                        "readingDate": 1699399738000,
                        "readingUomDesc": null
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "RA",
                        "meterValue": 0.5,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "RV",
                        "meterValue": 230.1999969,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "WATT",
                        "meterTypeId": "RW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "W"
                    },
                    {
                        "readingUomId": "PF",
                        "meterTypeId": "PFR",
                        "meterValue": 0,
                        "readingDate": 1699399738000,
                        "readingUomDesc": null
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "YA",
                        "meterValue": 1.700000048,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "YV",
                        "meterValue": 233.6000061,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "WATT",
                        "meterTypeId": "YW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "W"
                    },
                    {
                        "readingUomId": "PF",
                        "meterTypeId": "PFY",
                        "meterValue": 0,
                        "readingDate": 1699399738000,
                        "readingUomDesc": null
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "RY",
                        "meterValue": 401.1000061,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "YB",
                        "meterValue": 401.7000122,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "BR",
                        "meterValue": 395.5,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "WATT",
                        "meterTypeId": "TW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "W"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "VLN_AV",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "TA",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": null,
                        "meterTypeId": "MOD_STATE",
                        "meterValue": 0,
                        "readingDate": 1699399738000,
                        "readingUomDesc": ""
                    },
                    {
                        "readingUomId": "FREQUENCY",
                        "meterTypeId": "FREQUENCY",
                        "meterValue": 50.04999924,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "Hz"
                    },
                    {
                        "readingUomId": "ENERGY_KVA",
                        "meterTypeId": "KVA",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kVA"
                    },
                    {
                        "readingUomId": "ENERGY_KVAR",
                        "meterTypeId": "KVAR",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kVAR"
                    },
                    {
                        "readingUomId": "ACTIVE_POWER_LOAD",
                        "meterTypeId": "KW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kW"
                    },
                    {
                        "readingUomId": null,
                        "meterTypeId": "POWER_FACTOR",
                        "meterValue": 1.486000061,
                        "readingDate": 1699399738000,
                        "readingUomDesc": ""
                    },
                    {
                        "readingUomId": "ACTIVE_POWER",
                        "meterTypeId": "Wh_EB",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kWh"
                    },
                    {
                        "readingUomId": "TF_hr",
                        "meterTypeId": "RHR_EB",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "hr"
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "DEVIATION_AMP",
                        "meterValue": 96.153848,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "NEUTRAL_AMP",
                        "meterValue": 1.367479,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "ENERGY_VA",
                        "meterTypeId": "RVA",
                        "meterValue": 115.09999845,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "VA"
                    },
                    {
                        "readingUomId": "ENERGY_VA",
                        "meterTypeId": "YVA",
                        "meterValue": 397.120021583,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "VA"
                    },
                    {
                        "readingUomId": "ENERGY_VA",
                        "meterTypeId": "BVA",
                        "meterValue": 91.36000505,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "VA"
                    },
                    {
                        "readingUomId": "ENERGY_VA",
                        "meterTypeId": "TVA",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "VA"
                    },
                    {
                        "readingUomId": "ENERGY_KWH",
                        "meterTypeId": "KWH",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kWh"
                    }
                ],
                "assetTypeDesc": "EB",
                "assetId": "ATS_ASSET_1",
                "assetName": "Atsuya Office - 1",
                "lastUpdatedTime": 1699349872131,
                "facilityName": "Adyar Office",
                "condSpaceLayout": [
                    {
                        "area": "1500.0",
                        "elevation": "5.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 3",
                        "assetTypeId": "ATS_AC",
                        "latitude": "3.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699377422940,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 27.875000,
                                "readingDate": 1699377422940,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699377422940,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699377422940,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699377422940,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699377422940,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12627",
                        "assetName": "ANOVI 8",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12627",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone3",
                        "longitude": "4.0"
                    },
                    {
                        "area": "1300.0",
                        "elevation": "0.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 4",
                        "assetTypeId": "ACTUATOR",
                        "latitude": "9.0",
                        "meters": [],
                        "assetTypeDesc": "Actuator",
                        "assetId": "11800",
                        "assetName": "AC_2_Red-3_Table",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_11800",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone4",
                        "longitude": "2.0"
                    },
                    {
                        "area": "1150.0",
                        "elevation": "1.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 5",
                        "assetTypeId": "ACTUATOR",
                        "latitude": "1.0",
                        "meters": [],
                        "assetTypeDesc": "Actuator",
                        "assetId": "12626",
                        "assetName": "ANOVI 7",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12626",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone5",
                        "longitude": "0.0"
                    },
                    {
                        "area": "1300.0",
                        "elevation": "7.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 4",
                        "assetTypeId": "ATS_AC",
                        "latitude": "5.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699377412102,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 27.687500,
                                "readingDate": 1699377412102,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699377412102,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699377412102,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699377412102,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699377412102,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12620",
                        "assetName": "ANOVI 1",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12620",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone4",
                        "longitude": "6.0"
                    },
                    {
                        "area": "650.0",
                        "elevation": "4.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 2",
                        "assetTypeId": "ATS_AC",
                        "latitude": "2.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699377382406,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 27.937500,
                                "readingDate": 1699377382406,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699377382406,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699377382406,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699377382406,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699377382406,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12623",
                        "assetName": "ANOVI 4",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12623",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone2",
                        "longitude": "3.0"
                    },
                    {
                        "area": "1500.0",
                        "elevation": "9.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 3",
                        "assetTypeId": "ACTUATOR",
                        "latitude": "8.0",
                        "meters": [],
                        "assetTypeDesc": "Actuator",
                        "assetId": "11753",
                        "assetName": "AC_1_Blue-2",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_11753",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone3",
                        "longitude": "9.0"
                    },
                    {
                        "area": "1500.0",
                        "elevation": "6.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 3",
                        "assetTypeId": "ATS_AC",
                        "latitude": "4.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699377397490,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 27.687500,
                                "readingDate": 1699377397490,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699377397490,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699377397490,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699377397490,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699377397490,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12624",
                        "assetName": "ANOVI 5",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12624",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone3",
                        "longitude": "5.0"
                    },
                    {
                        "area": "1000.0",
                        "elevation": "3.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 1",
                        "assetTypeId": "ACTUATOR",
                        "latitude": "7.0",
                        "meters": [],
                        "assetTypeDesc": "Actuator",
                        "assetId": "11752",
                        "assetName": "AC_1_Blue-1",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_11752",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone1",
                        "longitude": "8.0"
                    },
                    {
                        "area": "1000.0",
                        "elevation": "2.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 1",
                        "assetTypeId": "ATS_AC",
                        "latitude": "0.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699349871776,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 26.125000,
                                "readingDate": 1699349871776,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699349871776,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699349871776,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699349871776,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699349871776,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12621",
                        "assetName": "ANOVI 2",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12621",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone1",
                        "longitude": "1.0"
                    },
                    {
                        "area": "1150.0",
                        "elevation": "8.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 5",
                        "assetTypeId": "ATS_AC",
                        "latitude": "6.0",
                        "meters": [],
                        "assetTypeDesc": "AC",
                        "assetId": "12622",
                        "assetName": "ANOVI 3",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12622",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone5",
                        "longitude": "7.0"
                    }
                ],
                "energyUnit": "kWh"
            },
             {
                "energyConsumption": "122.00",
                "serialNumber": null,
                "facilityId": "ATS_CHN_ADY_001",
                "assetTypeId": "ATS_EB",
                "maxTemp": 23.0,
                "avgTemp": 27.4625,
                "minTemp": 20.0,
                "meters": [
                    {
                        "readingUomId": " ",
                        "meterTypeId": "STATUS",
                        "compliance": false,
                        "meterValue": true,
                        "readingDate": "1696935721156",
                        "readingUomDesc": " "
                    },
                    {
                        "readingUomId": " ",
                        "meterTypeId": "TRIP_STATUS",
                        "compliance": false,
                        "meterValue": true,
                        "readingDate": "1696935721156",
                        "readingUomDesc": " "
                    },
                    {
                        "readingUomId": " ",
                        "meterTypeId": "MODE",
                        "compliance": false,
                        "meterValue": "M",
                        "readingDate": "1696935721156",
                        "readingUomDesc": " "
                    },
                    {
                        "readingUomId": "TEMP_C",
                        "meterTypeId": "RET_TEMP",
                        "compliance": false,
                        "meterValue": 20.5,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "C"
                    },
                    {
                        "readingUomId": "TEMP_C",
                        "meterTypeId": "CW_SUP_TEMP",
                        "compliance": false,
                        "meterValue": 7.0,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "C"
                    },
                    {
                        "readingUomId": "TEMP_C",
                        "meterTypeId": "CW_RET_TEMP",
                        "compliance": false,
                        "meterValue": 12.0,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "C"
                    },
                    {
                        "readingUomId": "FREQ",
                        "meterTypeId": "VFD",
                        "compliance": false,
                        "meterValue": 60.0,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "Hz"
                    },
                    {
                        "readingUomId": "OTH_PCT",
                        "meterTypeId": "CW_VALVE",
                        "compliance": false,
                        "meterValue": 70,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "%"
                    },
                    {
                        "readingUomId": "OTH_PCT",
                        "meterTypeId": "CW_VALVE_OVR",
                        "compliance": false,
                        "meterValue": 74,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "%"
                    },
                    {
                        "readingUomId": "ENERGY_KVAH",
                        "meterTypeId": "KVAH",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kVAh"
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "BA",
                        "meterValue": 0.400000006,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "BV",
                        "meterValue": 228.4000092,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "WATT",
                        "meterTypeId": "BW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "W"
                    },
                    {
                        "readingUomId": "PF",
                        "meterTypeId": "PFB",
                        "meterValue": 0,
                        "readingDate": 1699399738000,
                        "readingUomDesc": null
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "RA",
                        "meterValue": 0.5,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "RV",
                        "meterValue": 230.1999969,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "WATT",
                        "meterTypeId": "RW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "W"
                    },
                    {
                        "readingUomId": "PF",
                        "meterTypeId": "PFR",
                        "meterValue": 0,
                        "readingDate": 1699399738000,
                        "readingUomDesc": null
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "YA",
                        "meterValue": 1.700000048,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "YV",
                        "meterValue": 233.6000061,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "WATT",
                        "meterTypeId": "YW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "W"
                    },
                    {
                        "readingUomId": "PF",
                        "meterTypeId": "PFY",
                        "meterValue": 0,
                        "readingDate": 1699399738000,
                        "readingUomDesc": null
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "RY",
                        "meterValue": 401.1000061,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "YB",
                        "meterValue": 401.7000122,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "BR",
                        "meterValue": 395.5,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "WATT",
                        "meterTypeId": "TW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "W"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "VLN_AV",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "TA",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": null,
                        "meterTypeId": "MOD_STATE",
                        "meterValue": 0,
                        "readingDate": 1699399738000,
                        "readingUomDesc": ""
                    },
                    {
                        "readingUomId": "FREQUENCY",
                        "meterTypeId": "FREQUENCY",
                        "meterValue": 50.04999924,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "Hz"
                    },
                    {
                        "readingUomId": "ENERGY_KVA",
                        "meterTypeId": "KVA",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kVA"
                    },
                    {
                        "readingUomId": "ENERGY_KVAR",
                        "meterTypeId": "KVAR",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kVAR"
                    },
                    {
                        "readingUomId": "ACTIVE_POWER_LOAD",
                        "meterTypeId": "KW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kW"
                    },
                    {
                        "readingUomId": null,
                        "meterTypeId": "POWER_FACTOR",
                        "meterValue": 1.486000061,
                        "readingDate": 1699399738000,
                        "readingUomDesc": ""
                    },
                    {
                        "readingUomId": "ACTIVE_POWER",
                        "meterTypeId": "Wh_EB",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kWh"
                    },
                    {
                        "readingUomId": "TF_hr",
                        "meterTypeId": "RHR_EB",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "hr"
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "DEVIATION_AMP",
                        "meterValue": 96.153848,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "NEUTRAL_AMP",
                        "meterValue": 1.367479,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "ENERGY_VA",
                        "meterTypeId": "RVA",
                        "meterValue": 115.09999845,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "VA"
                    },
                    {
                        "readingUomId": "ENERGY_VA",
                        "meterTypeId": "YVA",
                        "meterValue": 397.120021583,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "VA"
                    },
                    {
                        "readingUomId": "ENERGY_VA",
                        "meterTypeId": "BVA",
                        "meterValue": 91.36000505,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "VA"
                    },
                    {
                        "readingUomId": "ENERGY_VA",
                        "meterTypeId": "TVA",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "VA"
                    },
                    {
                        "readingUomId": "ENERGY_KWH",
                        "meterTypeId": "KWH",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kWh"
                    }
                ],
                "assetTypeDesc": "EB",
                "assetId": "ATS_ASSET_1",
                "assetName": "Atsuya Office - 1",
                "lastUpdatedTime": 1699349872131,
                "facilityName": "Adyar Office",
                "condSpaceLayout": [
                    {
                        "area": "1500.0",
                        "elevation": "5.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 3",
                        "assetTypeId": "ATS_AC",
                        "latitude": "3.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699377422940,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 27.875000,
                                "readingDate": 1699377422940,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699377422940,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699377422940,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699377422940,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699377422940,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12627",
                        "assetName": "ANOVI 8",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12627",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone3",
                        "longitude": "4.0"
                    },
                    {
                        "area": "1300.0",
                        "elevation": "0.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 4",
                        "assetTypeId": "ACTUATOR",
                        "latitude": "9.0",
                        "meters": [],
                        "assetTypeDesc": "Actuator",
                        "assetId": "11800",
                        "assetName": "AC_2_Red-3_Table",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_11800",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone4",
                        "longitude": "2.0"
                    },
                    {
                        "area": "1150.0",
                        "elevation": "1.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 5",
                        "assetTypeId": "ACTUATOR",
                        "latitude": "1.0",
                        "meters": [],
                        "assetTypeDesc": "Actuator",
                        "assetId": "12626",
                        "assetName": "ANOVI 7",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12626",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone5",
                        "longitude": "0.0"
                    },
                    {
                        "area": "1300.0",
                        "elevation": "7.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 4",
                        "assetTypeId": "ATS_AC",
                        "latitude": "5.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699377412102,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 27.687500,
                                "readingDate": 1699377412102,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699377412102,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699377412102,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699377412102,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699377412102,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12620",
                        "assetName": "ANOVI 1",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12620",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone4",
                        "longitude": "6.0"
                    },
                    {
                        "area": "650.0",
                        "elevation": "4.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 2",
                        "assetTypeId": "ATS_AC",
                        "latitude": "2.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699377382406,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 27.937500,
                                "readingDate": 1699377382406,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699377382406,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699377382406,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699377382406,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699377382406,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12623",
                        "assetName": "ANOVI 4",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12623",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone2",
                        "longitude": "3.0"
                    },
                    {
                        "area": "1500.0",
                        "elevation": "9.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 3",
                        "assetTypeId": "ACTUATOR",
                        "latitude": "8.0",
                        "meters": [],
                        "assetTypeDesc": "Actuator",
                        "assetId": "11753",
                        "assetName": "AC_1_Blue-2",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_11753",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone3",
                        "longitude": "9.0"
                    },
                    {
                        "area": "1500.0",
                        "elevation": "6.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 3",
                        "assetTypeId": "ATS_AC",
                        "latitude": "4.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699377397490,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 27.687500,
                                "readingDate": 1699377397490,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699377397490,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699377397490,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699377397490,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699377397490,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12624",
                        "assetName": "ANOVI 5",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12624",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone3",
                        "longitude": "5.0"
                    },
                    {
                        "area": "1000.0",
                        "elevation": "3.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 1",
                        "assetTypeId": "ACTUATOR",
                        "latitude": "7.0",
                        "meters": [],
                        "assetTypeDesc": "Actuator",
                        "assetId": "11752",
                        "assetName": "AC_1_Blue-1",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_11752",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone1",
                        "longitude": "8.0"
                    },
                    {
                        "area": "1000.0",
                        "elevation": "2.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 1",
                        "assetTypeId": "ATS_AC",
                        "latitude": "0.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699349871776,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 26.125000,
                                "readingDate": 1699349871776,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699349871776,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699349871776,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699349871776,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699349871776,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12621",
                        "assetName": "ANOVI 2",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12621",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone1",
                        "longitude": "1.0"
                    },
                    {
                        "area": "1150.0",
                        "elevation": "8.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 5",
                        "assetTypeId": "ATS_AC",
                        "latitude": "6.0",
                        "meters": [],
                        "assetTypeDesc": "AC",
                        "assetId": "12622",
                        "assetName": "ANOVI 3",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12622",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone5",
                        "longitude": "7.0"
                    }
                ],
                "energyUnit": "kWh"
            },
             {
                "energyConsumption": "122.00",
                "serialNumber": null,
                "facilityId": "ATS_CHN_ADY_001",
                "assetTypeId": "ATS_EB",
                "maxTemp": 23.0,
                "avgTemp": 27.4625,
                "minTemp": 20.0,
                "meters": [
                    {
                        "readingUomId": " ",
                        "meterTypeId": "STATUS",
                        "compliance": false,
                        "meterValue": true,
                        "readingDate": "1696935721156",
                        "readingUomDesc": " "
                    },
                    {
                        "readingUomId": " ",
                        "meterTypeId": "TRIP_STATUS",
                        "compliance": false,
                        "meterValue": true,
                        "readingDate": "1696935721156",
                        "readingUomDesc": " "
                    },
                    {
                        "readingUomId": " ",
                        "meterTypeId": "MODE",
                        "compliance": false,
                        "meterValue": "M",
                        "readingDate": "1696935721156",
                        "readingUomDesc": " "
                    },
                    {
                        "readingUomId": "TEMP_C",
                        "meterTypeId": "RET_TEMP",
                        "compliance": false,
                        "meterValue": 20.5,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "C"
                    },
                    {
                        "readingUomId": "TEMP_C",
                        "meterTypeId": "CW_SUP_TEMP",
                        "compliance": false,
                        "meterValue": 7.0,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "C"
                    },
                    {
                        "readingUomId": "TEMP_C",
                        "meterTypeId": "CW_RET_TEMP",
                        "compliance": false,
                        "meterValue": 12.0,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "C"
                    },
                    {
                        "readingUomId": "FREQ",
                        "meterTypeId": "VFD",
                        "compliance": false,
                        "meterValue": 60.0,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "Hz"
                    },
                    {
                        "readingUomId": "OTH_PCT",
                        "meterTypeId": "CW_VALVE",
                        "compliance": false,
                        "meterValue": 70,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "%"
                    },
                    {
                        "readingUomId": "OTH_PCT",
                        "meterTypeId": "CW_VALVE_OVR",
                        "compliance": false,
                        "meterValue": 74,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "%"
                    },
                    {
                        "readingUomId": "ENERGY_KVAH",
                        "meterTypeId": "KVAH",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kVAh"
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "BA",
                        "meterValue": 0.400000006,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "BV",
                        "meterValue": 228.4000092,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "WATT",
                        "meterTypeId": "BW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "W"
                    },
                    {
                        "readingUomId": "PF",
                        "meterTypeId": "PFB",
                        "meterValue": 0,
                        "readingDate": 1699399738000,
                        "readingUomDesc": null
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "RA",
                        "meterValue": 0.5,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "RV",
                        "meterValue": 230.1999969,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "WATT",
                        "meterTypeId": "RW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "W"
                    },
                    {
                        "readingUomId": "PF",
                        "meterTypeId": "PFR",
                        "meterValue": 0,
                        "readingDate": 1699399738000,
                        "readingUomDesc": null
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "YA",
                        "meterValue": 1.700000048,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "YV",
                        "meterValue": 233.6000061,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "WATT",
                        "meterTypeId": "YW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "W"
                    },
                    {
                        "readingUomId": "PF",
                        "meterTypeId": "PFY",
                        "meterValue": 0,
                        "readingDate": 1699399738000,
                        "readingUomDesc": null
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "RY",
                        "meterValue": 401.1000061,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "YB",
                        "meterValue": 401.7000122,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "BR",
                        "meterValue": 395.5,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "WATT",
                        "meterTypeId": "TW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "W"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "VLN_AV",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "TA",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": null,
                        "meterTypeId": "MOD_STATE",
                        "meterValue": 0,
                        "readingDate": 1699399738000,
                        "readingUomDesc": ""
                    },
                    {
                        "readingUomId": "FREQUENCY",
                        "meterTypeId": "FREQUENCY",
                        "meterValue": 50.04999924,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "Hz"
                    },
                    {
                        "readingUomId": "ENERGY_KVA",
                        "meterTypeId": "KVA",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kVA"
                    },
                    {
                        "readingUomId": "ENERGY_KVAR",
                        "meterTypeId": "KVAR",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kVAR"
                    },
                    {
                        "readingUomId": "ACTIVE_POWER_LOAD",
                        "meterTypeId": "KW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kW"
                    },
                    {
                        "readingUomId": null,
                        "meterTypeId": "POWER_FACTOR",
                        "meterValue": 1.486000061,
                        "readingDate": 1699399738000,
                        "readingUomDesc": ""
                    },
                    {
                        "readingUomId": "ACTIVE_POWER",
                        "meterTypeId": "Wh_EB",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kWh"
                    },
                    {
                        "readingUomId": "TF_hr",
                        "meterTypeId": "RHR_EB",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "hr"
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "DEVIATION_AMP",
                        "meterValue": 96.153848,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "NEUTRAL_AMP",
                        "meterValue": 1.367479,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "ENERGY_VA",
                        "meterTypeId": "RVA",
                        "meterValue": 115.09999845,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "VA"
                    },
                    {
                        "readingUomId": "ENERGY_VA",
                        "meterTypeId": "YVA",
                        "meterValue": 397.120021583,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "VA"
                    },
                    {
                        "readingUomId": "ENERGY_VA",
                        "meterTypeId": "BVA",
                        "meterValue": 91.36000505,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "VA"
                    },
                    {
                        "readingUomId": "ENERGY_VA",
                        "meterTypeId": "TVA",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "VA"
                    },
                    {
                        "readingUomId": "ENERGY_KWH",
                        "meterTypeId": "KWH",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kWh"
                    }
                ],
                "assetTypeDesc": "EB",
                "assetId": "ATS_ASSET_1",
                "assetName": "Atsuya Office - 1",
                "lastUpdatedTime": 1699349872131,
                "facilityName": "Adyar Office",
                "condSpaceLayout": [
                    {
                        "area": "1500.0",
                        "elevation": "5.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 3",
                        "assetTypeId": "ATS_AC",
                        "latitude": "3.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699377422940,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 27.875000,
                                "readingDate": 1699377422940,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699377422940,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699377422940,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699377422940,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699377422940,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12627",
                        "assetName": "ANOVI 8",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12627",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone3",
                        "longitude": "4.0"
                    },
                    {
                        "area": "1300.0",
                        "elevation": "0.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 4",
                        "assetTypeId": "ACTUATOR",
                        "latitude": "9.0",
                        "meters": [],
                        "assetTypeDesc": "Actuator",
                        "assetId": "11800",
                        "assetName": "AC_2_Red-3_Table",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_11800",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone4",
                        "longitude": "2.0"
                    },
                    {
                        "area": "1150.0",
                        "elevation": "1.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 5",
                        "assetTypeId": "ACTUATOR",
                        "latitude": "1.0",
                        "meters": [],
                        "assetTypeDesc": "Actuator",
                        "assetId": "12626",
                        "assetName": "ANOVI 7",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12626",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone5",
                        "longitude": "0.0"
                    },
                    {
                        "area": "1300.0",
                        "elevation": "7.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 4",
                        "assetTypeId": "ATS_AC",
                        "latitude": "5.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699377412102,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 27.687500,
                                "readingDate": 1699377412102,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699377412102,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699377412102,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699377412102,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699377412102,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12620",
                        "assetName": "ANOVI 1",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12620",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone4",
                        "longitude": "6.0"
                    },
                    {
                        "area": "650.0",
                        "elevation": "4.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 2",
                        "assetTypeId": "ATS_AC",
                        "latitude": "2.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699377382406,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 27.937500,
                                "readingDate": 1699377382406,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699377382406,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699377382406,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699377382406,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699377382406,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12623",
                        "assetName": "ANOVI 4",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12623",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone2",
                        "longitude": "3.0"
                    },
                    {
                        "area": "1500.0",
                        "elevation": "9.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 3",
                        "assetTypeId": "ACTUATOR",
                        "latitude": "8.0",
                        "meters": [],
                        "assetTypeDesc": "Actuator",
                        "assetId": "11753",
                        "assetName": "AC_1_Blue-2",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_11753",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone3",
                        "longitude": "9.0"
                    },
                    {
                        "area": "1500.0",
                        "elevation": "6.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 3",
                        "assetTypeId": "ATS_AC",
                        "latitude": "4.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699377397490,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 27.687500,
                                "readingDate": 1699377397490,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699377397490,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699377397490,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699377397490,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699377397490,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12624",
                        "assetName": "ANOVI 5",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12624",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone3",
                        "longitude": "5.0"
                    },
                    {
                        "area": "1000.0",
                        "elevation": "3.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 1",
                        "assetTypeId": "ACTUATOR",
                        "latitude": "7.0",
                        "meters": [],
                        "assetTypeDesc": "Actuator",
                        "assetId": "11752",
                        "assetName": "AC_1_Blue-1",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_11752",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone1",
                        "longitude": "8.0"
                    },
                    {
                        "area": "1000.0",
                        "elevation": "2.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 1",
                        "assetTypeId": "ATS_AC",
                        "latitude": "0.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699349871776,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 26.125000,
                                "readingDate": 1699349871776,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699349871776,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699349871776,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699349871776,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699349871776,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12621",
                        "assetName": "ANOVI 2",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12621",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone1",
                        "longitude": "1.0"
                    },
                    {
                        "area": "1150.0",
                        "elevation": "8.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 5",
                        "assetTypeId": "ATS_AC",
                        "latitude": "6.0",
                        "meters": [],
                        "assetTypeDesc": "AC",
                        "assetId": "12622",
                        "assetName": "ANOVI 3",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12622",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone5",
                        "longitude": "7.0"
                    }
                ],
                "energyUnit": "kWh"
            },
             {
                "energyConsumption": "122.00",
                "serialNumber": null,
                "facilityId": "ATS_CHN_ADY_001",
                "assetTypeId": "ATS_EB",
                "maxTemp": 23.0,
                "avgTemp": 27.4625,
                "minTemp": 20.0,
                "meters": [
                    {
                        "readingUomId": " ",
                        "meterTypeId": "STATUS",
                        "compliance": false,
                        "meterValue": true,
                        "readingDate": "1696935721156",
                        "readingUomDesc": " "
                    },
                    {
                        "readingUomId": " ",
                        "meterTypeId": "TRIP_STATUS",
                        "compliance": false,
                        "meterValue": true,
                        "readingDate": "1696935721156",
                        "readingUomDesc": " "
                    },
                    {
                        "readingUomId": " ",
                        "meterTypeId": "MODE",
                        "compliance": false,
                        "meterValue": "M",
                        "readingDate": "1696935721156",
                        "readingUomDesc": " "
                    },
                    {
                        "readingUomId": "TEMP_C",
                        "meterTypeId": "RET_TEMP",
                        "compliance": false,
                        "meterValue": 20.5,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "C"
                    },
                    {
                        "readingUomId": "TEMP_C",
                        "meterTypeId": "CW_SUP_TEMP",
                        "compliance": false,
                        "meterValue": 7.0,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "C"
                    },
                    {
                        "readingUomId": "TEMP_C",
                        "meterTypeId": "CW_RET_TEMP",
                        "compliance": false,
                        "meterValue": 12.0,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "C"
                    },
                    {
                        "readingUomId": "FREQ",
                        "meterTypeId": "VFD",
                        "compliance": false,
                        "meterValue": 60.0,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "Hz"
                    },
                    {
                        "readingUomId": "OTH_PCT",
                        "meterTypeId": "CW_VALVE",
                        "compliance": false,
                        "meterValue": 70,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "%"
                    },
                    {
                        "readingUomId": "OTH_PCT",
                        "meterTypeId": "CW_VALVE_OVR",
                        "compliance": false,
                        "meterValue": 74,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "%"
                    },
                    {
                        "readingUomId": "ENERGY_KVAH",
                        "meterTypeId": "KVAH",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kVAh"
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "BA",
                        "meterValue": 0.400000006,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "BV",
                        "meterValue": 228.4000092,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "WATT",
                        "meterTypeId": "BW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "W"
                    },
                    {
                        "readingUomId": "PF",
                        "meterTypeId": "PFB",
                        "meterValue": 0,
                        "readingDate": 1699399738000,
                        "readingUomDesc": null
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "RA",
                        "meterValue": 0.5,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "RV",
                        "meterValue": 230.1999969,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "WATT",
                        "meterTypeId": "RW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "W"
                    },
                    {
                        "readingUomId": "PF",
                        "meterTypeId": "PFR",
                        "meterValue": 0,
                        "readingDate": 1699399738000,
                        "readingUomDesc": null
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "YA",
                        "meterValue": 1.700000048,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "YV",
                        "meterValue": 233.6000061,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "WATT",
                        "meterTypeId": "YW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "W"
                    },
                    {
                        "readingUomId": "PF",
                        "meterTypeId": "PFY",
                        "meterValue": 0,
                        "readingDate": 1699399738000,
                        "readingUomDesc": null
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "RY",
                        "meterValue": 401.1000061,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "YB",
                        "meterValue": 401.7000122,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "BR",
                        "meterValue": 395.5,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "WATT",
                        "meterTypeId": "TW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "W"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "VLN_AV",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "TA",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": null,
                        "meterTypeId": "MOD_STATE",
                        "meterValue": 0,
                        "readingDate": 1699399738000,
                        "readingUomDesc": ""
                    },
                    {
                        "readingUomId": "FREQUENCY",
                        "meterTypeId": "FREQUENCY",
                        "meterValue": 50.04999924,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "Hz"
                    },
                    {
                        "readingUomId": "ENERGY_KVA",
                        "meterTypeId": "KVA",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kVA"
                    },
                    {
                        "readingUomId": "ENERGY_KVAR",
                        "meterTypeId": "KVAR",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kVAR"
                    },
                    {
                        "readingUomId": "ACTIVE_POWER_LOAD",
                        "meterTypeId": "KW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kW"
                    },
                    {
                        "readingUomId": null,
                        "meterTypeId": "POWER_FACTOR",
                        "meterValue": 1.486000061,
                        "readingDate": 1699399738000,
                        "readingUomDesc": ""
                    },
                    {
                        "readingUomId": "ACTIVE_POWER",
                        "meterTypeId": "Wh_EB",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kWh"
                    },
                    {
                        "readingUomId": "TF_hr",
                        "meterTypeId": "RHR_EB",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "hr"
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "DEVIATION_AMP",
                        "meterValue": 96.153848,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "NEUTRAL_AMP",
                        "meterValue": 1.367479,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "ENERGY_VA",
                        "meterTypeId": "RVA",
                        "meterValue": 115.09999845,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "VA"
                    },
                    {
                        "readingUomId": "ENERGY_VA",
                        "meterTypeId": "YVA",
                        "meterValue": 397.120021583,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "VA"
                    },
                    {
                        "readingUomId": "ENERGY_VA",
                        "meterTypeId": "BVA",
                        "meterValue": 91.36000505,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "VA"
                    },
                    {
                        "readingUomId": "ENERGY_VA",
                        "meterTypeId": "TVA",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "VA"
                    },
                    {
                        "readingUomId": "ENERGY_KWH",
                        "meterTypeId": "KWH",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kWh"
                    }
                ],
                "assetTypeDesc": "EB",
                "assetId": "ATS_ASSET_1",
                "assetName": "Atsuya Office - 1",
                "lastUpdatedTime": 1699349872131,
                "facilityName": "Adyar Office",
                "condSpaceLayout": [
                    {
                        "area": "1500.0",
                        "elevation": "5.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 3",
                        "assetTypeId": "ATS_AC",
                        "latitude": "3.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699377422940,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 27.875000,
                                "readingDate": 1699377422940,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699377422940,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699377422940,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699377422940,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699377422940,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12627",
                        "assetName": "ANOVI 8",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12627",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone3",
                        "longitude": "4.0"
                    },
                    {
                        "area": "1300.0",
                        "elevation": "0.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 4",
                        "assetTypeId": "ACTUATOR",
                        "latitude": "9.0",
                        "meters": [],
                        "assetTypeDesc": "Actuator",
                        "assetId": "11800",
                        "assetName": "AC_2_Red-3_Table",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_11800",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone4",
                        "longitude": "2.0"
                    },
                    {
                        "area": "1150.0",
                        "elevation": "1.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 5",
                        "assetTypeId": "ACTUATOR",
                        "latitude": "1.0",
                        "meters": [],
                        "assetTypeDesc": "Actuator",
                        "assetId": "12626",
                        "assetName": "ANOVI 7",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12626",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone5",
                        "longitude": "0.0"
                    },
                    {
                        "area": "1300.0",
                        "elevation": "7.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 4",
                        "assetTypeId": "ATS_AC",
                        "latitude": "5.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699377412102,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 27.687500,
                                "readingDate": 1699377412102,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699377412102,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699377412102,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699377412102,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699377412102,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12620",
                        "assetName": "ANOVI 1",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12620",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone4",
                        "longitude": "6.0"
                    },
                    {
                        "area": "650.0",
                        "elevation": "4.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 2",
                        "assetTypeId": "ATS_AC",
                        "latitude": "2.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699377382406,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 27.937500,
                                "readingDate": 1699377382406,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699377382406,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699377382406,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699377382406,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699377382406,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12623",
                        "assetName": "ANOVI 4",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12623",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone2",
                        "longitude": "3.0"
                    },
                    {
                        "area": "1500.0",
                        "elevation": "9.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 3",
                        "assetTypeId": "ACTUATOR",
                        "latitude": "8.0",
                        "meters": [],
                        "assetTypeDesc": "Actuator",
                        "assetId": "11753",
                        "assetName": "AC_1_Blue-2",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_11753",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone3",
                        "longitude": "9.0"
                    },
                    {
                        "area": "1500.0",
                        "elevation": "6.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 3",
                        "assetTypeId": "ATS_AC",
                        "latitude": "4.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699377397490,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 27.687500,
                                "readingDate": 1699377397490,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699377397490,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699377397490,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699377397490,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699377397490,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12624",
                        "assetName": "ANOVI 5",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12624",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone3",
                        "longitude": "5.0"
                    },
                    {
                        "area": "1000.0",
                        "elevation": "3.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 1",
                        "assetTypeId": "ACTUATOR",
                        "latitude": "7.0",
                        "meters": [],
                        "assetTypeDesc": "Actuator",
                        "assetId": "11752",
                        "assetName": "AC_1_Blue-1",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_11752",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone1",
                        "longitude": "8.0"
                    },
                    {
                        "area": "1000.0",
                        "elevation": "2.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 1",
                        "assetTypeId": "ATS_AC",
                        "latitude": "0.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699349871776,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 26.125000,
                                "readingDate": 1699349871776,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699349871776,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699349871776,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699349871776,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699349871776,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12621",
                        "assetName": "ANOVI 2",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12621",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone1",
                        "longitude": "1.0"
                    },
                    {
                        "area": "1150.0",
                        "elevation": "8.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 5",
                        "assetTypeId": "ATS_AC",
                        "latitude": "6.0",
                        "meters": [],
                        "assetTypeDesc": "AC",
                        "assetId": "12622",
                        "assetName": "ANOVI 3",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12622",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone5",
                        "longitude": "7.0"
                    }
                ],
                "energyUnit": "kWh"
            },
             {
                "energyConsumption": "122.00",
                "serialNumber": null,
                "facilityId": "ATS_CHN_ADY_001",
                "assetTypeId": "ATS_EB",
                "maxTemp": 23.0,
                "avgTemp": 27.4625,
                "minTemp": 20.0,
                "meters": [
                    {
                        "readingUomId": " ",
                        "meterTypeId": "STATUS",
                        "compliance": false,
                        "meterValue": true,
                        "readingDate": "1696935721156",
                        "readingUomDesc": " "
                    },
                    {
                        "readingUomId": " ",
                        "meterTypeId": "TRIP_STATUS",
                        "compliance": false,
                        "meterValue": true,
                        "readingDate": "1696935721156",
                        "readingUomDesc": " "
                    },
                    {
                        "readingUomId": " ",
                        "meterTypeId": "MODE",
                        "compliance": false,
                        "meterValue": "M",
                        "readingDate": "1696935721156",
                        "readingUomDesc": " "
                    },
                    {
                        "readingUomId": "TEMP_C",
                        "meterTypeId": "RET_TEMP",
                        "compliance": false,
                        "meterValue": 20.5,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "C"
                    },
                    {
                        "readingUomId": "TEMP_C",
                        "meterTypeId": "CW_SUP_TEMP",
                        "compliance": false,
                        "meterValue": 7.0,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "C"
                    },
                    {
                        "readingUomId": "TEMP_C",
                        "meterTypeId": "CW_RET_TEMP",
                        "compliance": false,
                        "meterValue": 12.0,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "C"
                    },
                    {
                        "readingUomId": "FREQ",
                        "meterTypeId": "VFD",
                        "compliance": false,
                        "meterValue": 60.0,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "Hz"
                    },
                    {
                        "readingUomId": "OTH_PCT",
                        "meterTypeId": "CW_VALVE",
                        "compliance": false,
                        "meterValue": 70,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "%"
                    },
                    {
                        "readingUomId": "OTH_PCT",
                        "meterTypeId": "CW_VALVE_OVR",
                        "compliance": false,
                        "meterValue": 74,
                        "readingDate": "1696935721156",
                        "readingUomDesc": "%"
                    },
                    {
                        "readingUomId": "ENERGY_KVAH",
                        "meterTypeId": "KVAH",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kVAh"
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "BA",
                        "meterValue": 0.400000006,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "BV",
                        "meterValue": 228.4000092,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "WATT",
                        "meterTypeId": "BW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "W"
                    },
                    {
                        "readingUomId": "PF",
                        "meterTypeId": "PFB",
                        "meterValue": 0,
                        "readingDate": 1699399738000,
                        "readingUomDesc": null
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "RA",
                        "meterValue": 0.5,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "RV",
                        "meterValue": 230.1999969,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "WATT",
                        "meterTypeId": "RW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "W"
                    },
                    {
                        "readingUomId": "PF",
                        "meterTypeId": "PFR",
                        "meterValue": 0,
                        "readingDate": 1699399738000,
                        "readingUomDesc": null
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "YA",
                        "meterValue": 1.700000048,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "YV",
                        "meterValue": 233.6000061,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "WATT",
                        "meterTypeId": "YW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "W"
                    },
                    {
                        "readingUomId": "PF",
                        "meterTypeId": "PFY",
                        "meterValue": 0,
                        "readingDate": 1699399738000,
                        "readingUomDesc": null
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "RY",
                        "meterValue": 401.1000061,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "YB",
                        "meterValue": 401.7000122,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "BR",
                        "meterValue": 395.5,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "WATT",
                        "meterTypeId": "TW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "W"
                    },
                    {
                        "readingUomId": "VOLTAGE",
                        "meterTypeId": "VLN_AV",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "V"
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "TA",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": null,
                        "meterTypeId": "MOD_STATE",
                        "meterValue": 0,
                        "readingDate": 1699399738000,
                        "readingUomDesc": ""
                    },
                    {
                        "readingUomId": "FREQUENCY",
                        "meterTypeId": "FREQUENCY",
                        "meterValue": 50.04999924,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "Hz"
                    },
                    {
                        "readingUomId": "ENERGY_KVA",
                        "meterTypeId": "KVA",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kVA"
                    },
                    {
                        "readingUomId": "ENERGY_KVAR",
                        "meterTypeId": "KVAR",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kVAR"
                    },
                    {
                        "readingUomId": "ACTIVE_POWER_LOAD",
                        "meterTypeId": "KW",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kW"
                    },
                    {
                        "readingUomId": null,
                        "meterTypeId": "POWER_FACTOR",
                        "meterValue": 1.486000061,
                        "readingDate": 1699399738000,
                        "readingUomDesc": ""
                    },
                    {
                        "readingUomId": "ACTIVE_POWER",
                        "meterTypeId": "Wh_EB",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kWh"
                    },
                    {
                        "readingUomId": "TF_hr",
                        "meterTypeId": "RHR_EB",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "hr"
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "DEVIATION_AMP",
                        "meterValue": 96.153848,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "CURRENT",
                        "meterTypeId": "NEUTRAL_AMP",
                        "meterValue": 1.367479,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "A"
                    },
                    {
                        "readingUomId": "ENERGY_VA",
                        "meterTypeId": "RVA",
                        "meterValue": 115.09999845,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "VA"
                    },
                    {
                        "readingUomId": "ENERGY_VA",
                        "meterTypeId": "YVA",
                        "meterValue": 397.120021583,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "VA"
                    },
                    {
                        "readingUomId": "ENERGY_VA",
                        "meterTypeId": "BVA",
                        "meterValue": 91.36000505,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "VA"
                    },
                    {
                        "readingUomId": "ENERGY_VA",
                        "meterTypeId": "TVA",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "VA"
                    },
                    {
                        "readingUomId": "ENERGY_KWH",
                        "meterTypeId": "KWH",
                        "meterValue": null,
                        "readingDate": 1699399738000,
                        "readingUomDesc": "kWh"
                    }
                ],
                "assetTypeDesc": "EB",
                "assetId": "ATS_ASSET_1",
                "assetName": "Atsuya Office - 1",
                "lastUpdatedTime": 1699349872131,
                "facilityName": "Adyar Office",
                "condSpaceLayout": [
                    {
                        "area": "1500.0",
                        "elevation": "5.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 3",
                        "assetTypeId": "ATS_AC",
                        "latitude": "3.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699377422940,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 27.875000,
                                "readingDate": 1699377422940,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699377422940,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699377422940,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699377422940,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699377422940,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12627",
                        "assetName": "ANOVI 8",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12627",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone3",
                        "longitude": "4.0"
                    },
                    {
                        "area": "1300.0",
                        "elevation": "0.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 4",
                        "assetTypeId": "ACTUATOR",
                        "latitude": "9.0",
                        "meters": [],
                        "assetTypeDesc": "Actuator",
                        "assetId": "11800",
                        "assetName": "AC_2_Red-3_Table",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_11800",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone4",
                        "longitude": "2.0"
                    },
                    {
                        "area": "1150.0",
                        "elevation": "1.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 5",
                        "assetTypeId": "ACTUATOR",
                        "latitude": "1.0",
                        "meters": [],
                        "assetTypeDesc": "Actuator",
                        "assetId": "12626",
                        "assetName": "ANOVI 7",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12626",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone5",
                        "longitude": "0.0"
                    },
                    {
                        "area": "1300.0",
                        "elevation": "7.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 4",
                        "assetTypeId": "ATS_AC",
                        "latitude": "5.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699377412102,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 27.687500,
                                "readingDate": 1699377412102,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699377412102,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699377412102,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699377412102,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699377412102,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12620",
                        "assetName": "ANOVI 1",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12620",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone4",
                        "longitude": "6.0"
                    },
                    {
                        "area": "650.0",
                        "elevation": "4.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 2",
                        "assetTypeId": "ATS_AC",
                        "latitude": "2.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699377382406,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 27.937500,
                                "readingDate": 1699377382406,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699377382406,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699377382406,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699377382406,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699377382406,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12623",
                        "assetName": "ANOVI 4",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12623",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone2",
                        "longitude": "3.0"
                    },
                    {
                        "area": "1500.0",
                        "elevation": "9.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 3",
                        "assetTypeId": "ACTUATOR",
                        "latitude": "8.0",
                        "meters": [],
                        "assetTypeDesc": "Actuator",
                        "assetId": "11753",
                        "assetName": "AC_1_Blue-2",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_11753",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone3",
                        "longitude": "9.0"
                    },
                    {
                        "area": "1500.0",
                        "elevation": "6.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 3",
                        "assetTypeId": "ATS_AC",
                        "latitude": "4.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699377397490,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 27.687500,
                                "readingDate": 1699377397490,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699377397490,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699377397490,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699377397490,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699377397490,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12624",
                        "assetName": "ANOVI 5",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12624",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone3",
                        "longitude": "5.0"
                    },
                    {
                        "area": "1000.0",
                        "elevation": "3.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 1",
                        "assetTypeId": "ACTUATOR",
                        "latitude": "7.0",
                        "meters": [],
                        "assetTypeDesc": "Actuator",
                        "assetId": "11752",
                        "assetName": "AC_1_Blue-1",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_11752",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone1",
                        "longitude": "8.0"
                    },
                    {
                        "area": "1000.0",
                        "elevation": "2.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 1",
                        "assetTypeId": "ATS_AC",
                        "latitude": "0.0",
                        "meters": [
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": null,
                                "readingDate": 1699349871776,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "TEMP_C",
                                "meterTypeId": "TEMP",
                                "compliance": false,
                                "meterValue": 26.125000,
                                "readingDate": 1699349871776,
                                "readingUomDesc": "C"
                            },
                            {
                                "readingUomId": "CURRENT",
                                "meterTypeId": "CURRENT",
                                "meterValue": 0.000000,
                                "readingDate": 1699349871776,
                                "readingUomDesc": "A"
                            },
                            {
                                "readingUomId": "OTH_PCT",
                                "meterTypeId": "TEMP_COMP_PCT",
                                "meterValue": null,
                                "readingDate": 1699349871776,
                                "readingUomDesc": "%"
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_ABS",
                                "meterValue": null,
                                "readingDate": 1699349871776,
                                "readingUomDesc": ""
                            },
                            {
                                "readingUomId": null,
                                "meterTypeId": "TEMP_COMP_RNG",
                                "meterValue": null,
                                "readingDate": 1699349871776,
                                "readingUomDesc": ""
                            }
                        ],
                        "assetTypeDesc": "AC",
                        "assetId": "12621",
                        "assetName": "ANOVI 2",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12621",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone1",
                        "longitude": "1.0"
                    },
                    {
                        "area": "1150.0",
                        "elevation": "8.000000",
                        "facilityId": "ATS_CHN_ADY_001",
                        "serialNumber": null,
                        "locationName": "Zone 5",
                        "assetTypeId": "ATS_AC",
                        "latitude": "6.0",
                        "meters": [],
                        "assetTypeDesc": "AC",
                        "assetId": "12622",
                        "assetName": "ANOVI 3",
                        "facilityName": "Adyar Office",
                        "geoPointId": "AHU_12622",
                        "locationTypeEnumId": "FLT_LOCATION",
                        "locationSeqId": "zone5",
                        "longitude": "7.0"
                    }
                ],
                "energyUnit": "kWh"
            }
        ]
    }
}`
import React, { useState } from "react";
import "./MachineDetailsChildComponent.scss";

import ChevronIcon from "../../assets/icons/BlackRightArrow.svg";

function MachineDetailsChildComponent() {
  const qcRejectedCategory = [
    {
      id: 1,
      name: "Error bit in QR",
      value: 30,
    },
    {
      id: 2,
      name: "Tag number mismatch with QR",
      value: 30,
    },
    {
      id: 3,
      name: "QR code position / QR ID Font",
      value: 30,
    },
    {
      id: 4,
      name: "Physical Damage",
      value: 30,
    },
    {
      id: 5,
      name: "QR code titled on the QR tag",
      value: 30,
    },
    {
      id: 6,
      name: "Improper print / Over print",
      value: 30,
    },
    {
      id: 7,
      name: "Readibility of QR",
      value: 30,
    },
    {
      id: 8,
      name: "Margin of QR code",
      value: 30,
    },
    {
      id: 9,
      name: "Diffrence in contrast of QR code",
      value: 30,
    },
    {
      id: 10,
      name: "Presence of external Artifacts",
      value: 30,
    },
    {
      id: 11,
      name: "Match QR data with OCR",
      value: 30,
    },
    {
      id: 12,
      name: "Skewness",
      value: 30,
    },
    {
      id: 13,
      name: "Orientation of the QR with respect to the tag",
      value: 30,
    },
  ];

  const [showFailedQcData, setShowFailedQcData] = useState(false);
  const [showRejectedQcData, setShowRejectedQcData] = useState(false);

  return (
    <div className="mainContainerDiv">
      <div className="notYetQcRow dataInRow">
        <div className="qrChevron"></div>
        <p className="qrCategoryText lightTextData">QR NOT YET QC#</p>
        <p className="blackTextData">12</p>
      </div>
      <div className="qcOkRow dataInRow">
        <div className="qrChevron"></div>
        <p className="qrCategoryText lightTextData">QC OK#</p>
        <p className="blackTextData">641</p>
      </div>
      <div
        className="qcFailedRow dataInRow changePointer"
        onClick={() => {
          setShowFailedQcData(!showFailedQcData);
          setShowRejectedQcData(!showRejectedQcData);
        }}
      >
        <div className="qrChevrons marginTopMinus2px">
          <img
            src={ChevronIcon}
            className={`chevronImgIcon ${
              showFailedQcData ? "rotate-90-deg" : "rotate-0-deg"
            }`}
            alt="open"
          />
        </div>
        <p className="qrCategoryText lightTextData">QC FAILED#</p>
        <p className="blackTextData">347</p>
      </div>

      {showFailedQcData && (
        <>
          <div className="qrDuplicateRow dataInRow">
            <div className="qrChevron marginTopMinus2px"></div>
            <p className="qrCategoryText lightTextData">QR DUPLICATE#</p>
            <p className="blackTextData">12</p>
          </div>
          <div
            className="qcRejectedRow dataInRow changePointer"
            onClick={() => setShowRejectedQcData(!showRejectedQcData)}
          >
            <div className="qrChevrons marginTopMinus2px">
              <img
                src={ChevronIcon}
                className={`chevronImgIcon ${
                  showRejectedQcData ? "rotate-90-deg" : "rotate-0-deg"
                }`}
                alt="open"
              />
            </div>
            <p className="qrCategoryText lightTextData">QC REJECTED</p>
            <p className="blackTextData">269</p>
          </div>
          {showRejectedQcData &&
            qcRejectedCategory.map((item) => (
              <div
                className="qcRejectedCategoryListRow dataInRow"
                key={item.id}
              >
                <p className="blackTextData qcRejectedCategoryText">
                  {item.name}
                </p>
                <p className="blackTextData">{item.value}</p>
              </div>
            ))}
        </>
      )}
    </div>
  );
}

export default MachineDetailsChildComponent;

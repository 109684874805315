/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
// import the core library.
import ReactEChartsCore from "echarts-for-react/lib/core";
// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from "echarts/core";
// Import charts, all with Chart suffix
import {
  // LineChart,
  BarChart,
  // PieChart,
  // ScatterChart,
  // RadarChart,
  // MapChart,
  // TreeChart,
  // TreemapChart,
  // GraphChart,
  GaugeChart,
  // FunnelChart,
  // ParallelChart,
  // SankeyChart,
  // BoxplotChart,
  // CandlestickChart,
  // EffectScatterChart,
  // LinesChart,
  // HeatmapChart,
  // PictorialBarChart,
  // ThemeRiverChart,
  // SunburstChart,
  // CustomChart,
} from "echarts/charts";
// import components, all suffixed with Component
import {
  // GridSimpleComponent,
  GridComponent,
  // PolarComponent,
  // RadarComponent,
  // GeoComponent,
  // SingleAxisComponent,
  // ParallelComponent,
  // CalendarComponent,
  // GraphicComponent,
  // ToolboxComponent,
  TooltipComponent,
  // AxisPointerComponent,
  // BrushComponent,
  TitleComponent,
  // TimelineComponent,
  // MarkPointComponent,
  // MarkLineComponent,
  // MarkAreaComponent,
  // LegendComponent,
  // LegendScrollComponent,
  // LegendPlainComponent,
  // DataZoomComponent,
  // DataZoomInsideComponent,
  // DataZoomSliderComponent,
  // VisualMapComponent,
  // VisualMapContinuousComponent,
  // VisualMapPiecewiseComponent,
  // AriaComponent,
  // TransformComponent,
  DatasetComponent,
} from "echarts/components";
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
  CanvasRenderer,
  // SVGRenderer,
} from "echarts/renderers";

// Register the required components

function OptExpandBarChart() {
  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    GaugeChart,
    CanvasRenderer,
  ]);
  interface DataItem {
    value: number;
    groupId: string;
  }

  var option = {
    xAxis: {
      data: ["North", "East", "West", "South"],
    },
    yAxis: {},
    dataGroupId: "",
    animationDurationUpdate: 500,
    series: {
      type: "bar",
      id: "sales",
      data: [
        {
          value: 5,
          groupId: "North",
        },
        {
          value: 2,
          groupId: "East",
        },
        {
          value: 3,
          groupId: "West",
        },
        {
          value: 3,
          groupId: "South",
        },
      ] as DataItem[],
      universalTransition: {
        enabled: true,
        divideShape: "clone",
      },
    },
  };

  const drilldownData = [
    {
      dataGroupId: "North",
      data: [
        ["Cats", 4],
        ["Dogs", 2],
        ["Cows", 1],
        ["Sheep", 2],
        ["Pigs", 1],
      ],
    },
    {
      dataGroupId: "East",
      data: [
        ["Apples", 4],
        ["Oranges", 2],
      ],
    },
    {
      dataGroupId: "West",
      data: [
        ["Toyota", 4],
        ["Opel", 2],
        ["Volkswagen", 2],
      ],
    },
    {
      dataGroupId: "South",
      data: [
        ["Toyota", 4],
        ["Opel", 2],
        ["Volkswagen", 2],
      ],
    },
  ];

  //   myChart.on('click', function (event) {
  //     if (event.data) {
  //       var subData = drilldownData.find(function (data) {
  //         return data.dataGroupId === (event.data as DataItem).groupId;
  //       });
  //       if (!subData) {
  //         return;
  //       }
  //       myChart.setOption<echarts.EChartsOption>({
  //         xAxis: {
  //           data: subData.data.map(function (item) {
  //             return item[0];
  //           })
  //         },
  //         series: {
  //           type: 'bar',
  //           id: 'sales',
  //           dataGroupId: subData.dataGroupId,
  //           data: subData.data.map(function (item) {
  //             return item[1];
  //           }),
  //           universalTransition: {
  //             enabled: true,
  //             divideShape: 'clone'
  //           }
  //         },
  //         graphic: [
  //           {
  //             type: 'text',
  //             left: 50,
  //             top: 20,
  //             style: {
  //               text: 'Back',
  //               fontSize: 18
  //             },
  //             onclick: function () {
  //               myChart.setOption<echarts.EChartsOption>(option);
  //             }
  //           }
  //         ]
  //       });
  //     }
  //   });
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={option}
      notMerge={true}
      lazyUpdate={true}
      theme={"theme_name"}
    />
  );
}

export default OptExpandBarChart;

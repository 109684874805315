import React from "react";
import axios from "axios";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import * as R from "ramda";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
} from "echarts/components";
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import { CanvasRenderer } from "echarts/renderers";

function TemperatureComplianceHeader({ color, percentage }: any) {
  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    CanvasRenderer,
  ]);
  const options = {
    tooltip: {
      //trigger: "axis",
      show: false,
    },
    legend: {
      show: false,
    },
    grid: {
      height: 40,
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
    xAxis: {
      type: "value",
      show: false,
    },
    yAxis: {
      type: "category",
      show: false,
    },
    color: [color],
    series: [
      {
        name: "Direct",
        type: "bar",
        stack: "total",
        label: {
          show: true,
          color:'#FFFFFF',
          position: "inside",
          formatter: `${parseFloat(percentage)}%`, // Display the value of each bar
        },
        emphasis: {
          // Disable hover effect
          focus: "none",
          opacity: 1, // Set opacity to 1 to disable hover effect
        },
        itemStyle: {
          barBorderRadius: [50, 50, 50, 50], // Apply border radius to the bars
          opacity: 1, // Set opacity to 1 to disable hover effect
        },
        data: [96],
      },
    ],
  };

  return (
    <div
      style={{
        height: 40,
        flexGrow: 1,
        margin: 0,
        pointerEvents: "none",
        padding: 0,
        backgroundColor:"#FFFFFF",
        width:'100%'
      }}
    >
      <ReactEChartsCore
        echarts={echarts}
        option={options}
        notMerge={true}
        lazyUpdate={true}
        theme={"theme_name"}
      />
    </div>
  );
}

export default TemperatureComplianceHeader;

import { useState, useEffect } from "react";
import LocalStorageService from "../../Utils/LocalStorageService";

const DashboardFilterData = () => {
  const [data, setData] = useState({
    region: [],
    city: [],
    cluster: [],
    facility: [],
    area: [],
    location: [],
    subLocation: [],
  });

  useEffect(() => {
    const regionData = LocalStorageService.getRegionData();
    const cityData = LocalStorageService.getCityData();
    const clusterData = LocalStorageService.getClusterData();
    const facilityData = LocalStorageService.getFacilityData();
    const areaData = LocalStorageService.getAreaData();
    const locationData = LocalStorageService.getLocationData();
    const sublocationData = LocalStorageService.getSubLocationData();

    const regionList = regionData.map((el: { facilityGroupId: string, facilityGroupName: string ,facilityGroupTypeId:string}) => ({
      id: el.facilityGroupId,
      name: el.facilityGroupName,
      typeId: el.facilityGroupTypeId,
    }));

    const cityList = cityData.map((el: { facilityGroupId: string, facilityGroupName: string, parentFacilityGroupId: string,facilityGroupTypeId:string }) => ({
      id: el.facilityGroupId,
      name: el.facilityGroupName,
      parentId: el.parentFacilityGroupId,
      typeId: el.facilityGroupTypeId,
    }));

    const clusterList = clusterData.map((el: { facilityGroupId: string, facilityGroupName: string, parentFacilityGroupId: string,facilityGroupTypeId:string }) => ({
      id: el.facilityGroupId,
      name: el.facilityGroupName,
      parentId: el.parentFacilityGroupId,
      typeId: el.facilityGroupTypeId,
    }));

    const facilityList = facilityData.map((el: { facilityId: string, facilityName: string, parentFacilityGroupId: string }) => ({
      id: el.facilityId,
      name: el.facilityName,
      parentId: el.parentFacilityGroupId,
      
    }));

    const areaList = areaData.map((el: { facilityId: any, locationName: any, locationSeqId: string }) => ({
      id: el.locationSeqId,
      name: el.locationName,
      parentId: el.facilityId,
    }));

    const locationList = locationData.map((el: { facilityId: string, name: string, locationId: string,locationSeqId: string }) => ({
      id: el.locationId,
      name: el.name,
      parentId: el.locationSeqId,
    }));

    const sublocationList = sublocationData.map((el: { facilityId: string, name: string, subLocationId: string, locationId: string}) => ({
      id: el.subLocationId,
      name: el.name,
      parentId: el.locationId,
    }));

    setData({
      region: regionList,
      city: cityList,
      cluster: clusterList,
      facility: facilityList,
      area: areaList,
      location: locationList,
      subLocation: sublocationList,
    });
  }, []);

  return data;
};

export default DashboardFilterData;

import './pageFilters.scss'
import FilterButtonICon from './assets/icon-button-filter.svg'
import { useEffect } from 'react'
import PageFilterModal from './pageFileterPoup/pageFilterModal';
import PageFiltersUtils from './utils/PageFiltersUtils';
import { FilterUtils } from '../assetList/utils/FilterUtils';
import PageFiltersState from './utils/pageFiltersState';
import FilterChipsButton from './components/FilterChipsButton';
import addAssetPlusIcon from './assets/AddAssetPlusIcon.svg'
import PageFiltersServices from './services/PageFiltersServices';

function PageFilters({controlPageFiltersGroups, isLoading, showLeft, showAddAsset, previousStateFilterData, updateSearchList, handleClearFilter, assetDisplayObj}) {
  const {showPageFilterModal, setPageFilterModal,setFilterDataList, filterDataListRef,setStructuredArr, structuredArrRef} = PageFiltersState();
  const pageFiltersUtils = new PageFiltersUtils();
  const pageFiltersServices = new PageFiltersServices();

  useEffect(()=>{
    previousStateFilterData.current.length == 0 ? pageFiltersServices.getAssetFilterData(setFilterDataList) : setFilterDataList(previousStateFilterData.current)
  },[previousStateFilterData])

  useEffect(() => {
    pageFiltersUtils.formatData(setStructuredArr, filterDataListRef)
  }, [filterDataListRef.current])

  const handleSelectedFilter = (obj, index) => {
    pageFiltersUtils.handleSelectedFilter(obj, index, setFilterDataList);
  };

  const filterHierarchyData = (index, group, hierarchyList) => {
    const utils = new FilterUtils();
    const response = utils.filterHierarchyData(index, group, hierarchyList, filterDataListRef.current);
    setFilterDataList(response);
  };

  const clearFilters = () =>{
    const utils = new FilterUtils();
    previousStateFilterData.current = [];
    const unselectedData = utils.clearSelectedFilter(filterDataListRef.current);
    setFilterDataList([...unselectedData]);
    handleClearFilter(filterDataListRef)
  }

  return (
    <div className='pageFIltersMainContainer'>
      {/* Page filters Action container */}
      <div className='pageFiltersActionContainer' style={{ justifyContent : !showLeft ? 'flex-end' : ''}}>
        {showLeft && <button onClick={()=> showAddAsset(true)} className='addAssetButtonPageFilters'>
          <img src={addAssetPlusIcon} />
          Add Asset
          </button>}
        <div className='rightFiltersContainer'>
          { isLoading ? '' : <p>{assetDisplayObj}</p> }
          <div className='filterButtonContainer' onClick={() => setPageFilterModal(true)}>
            <img src={FilterButtonICon} />
            <p> Filter</p>
          </div>
        </div>
      </div>
      {/* Selected filetr chips container */}
      {structuredArrRef.current?.flat()?.filter((obj) => obj.isSelected).length !== 0 && <div className='slectedFilterChipsDisplayContainer'>
        <div className='chipsContainer'>
          {
            structuredArrRef.current?.flat()?.filter((obj) => obj.isSelected).map((el) =>
             <FilterChipsButton element={el} updateSearchList={updateSearchList} handleSelectedFilter={handleSelectedFilter} filterHierarchyData={filterHierarchyData} filterDataListRef={filterDataListRef} />)
          }
        </div>
        <p className='clearAllButton' onClick={()=> clearFilters() }>CLEAR ALL</p>
      </div>}

      <PageFilterModal
        controlPageFiltersGroups={controlPageFiltersGroups}
        updateSearchList ={updateSearchList}
        setFilterDataList={setFilterDataList}
        filtersData={filterDataListRef}
        show={showPageFilterModal}
        handleModal={setPageFilterModal} />

    </div>
  )
}

export default PageFilters

/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment";
import { number } from "prop-types";
import * as R from "ramda";
import { useState, useEffect } from "react";
import "./style.scss";

function AIR({ data, abbre, dailyMeters,monthlyMeters,lastCommTime }:any) {
  const [airData, setAirData] = useState();
  const [airMonthData, setAirMonthData] = useState();
  const [airMassData, setAirMassData] = useState();
  useEffect(() => {
    dailyMeters.map((ele) => {
      ele.meterTypeId.includes("AIR_FLOW_AGR") && setAirData(ele);
    });
    dailyMeters.map((ele) => {
      ele.meterTypeId.includes("F_MASS") && setAirMassData(ele);
    });
    console.log(airMassData,"airMassData")
    !R.isEmpty(monthlyMeters) && !R.isNil(monthlyMeters) &&
    monthlyMeters.map((ele) => {
      ele.meterTypeId.includes("AIR_FLOW_AGR") && setAirMonthData(ele);
    });
    
  }, [dailyMeters,monthlyMeters]);
  const [latestTime, setLatestTime] = useState();

  useEffect(() => {
    !R.isEmpty(lastCommTime) && !R.isNil(lastCommTime) && lastCommTime !== null && setLatestTime(lastCommTime) ;
  }, [lastCommTime]);
  return (
    <>
      <div className="lastUpdated">
        {!R.isEmpty(latestTime) && !R.isNil(latestTime) ? (
          <h3>Updated {moment(latestTime).fromNow()}</h3>
        ) : (
          <h3>&nbsp;</h3>
        )}
      </div>
      <div className="assetCard-colum">
        <div className="assetCard-col">
        
          <p>Today Air Consumed </p>
          {!R.isEmpty(airData) ? (
            <h2>
              {airData?.sum.toFixed(2)} <span>{airData?.defaultUom}</span>
            </h2>
          ) : (
            <h2></h2>
          )}
        </div>

        <div className="assetCard-col">
          <p>MTD Air Consumed </p>
          {!R.isEmpty(airMonthData) ? (
            <h2>
              {airMonthData?.sum.toFixed(2)} <span>{airMonthData?.defaultUom}</span>
            </h2>
          ) : (
            <h2></h2>
          )}
        </div>

        <div className="assetCard-col">
          <p>Average Flow Rate</p>
          {!R.isEmpty(airMassData) &&
          !R.isNil(airMassData) &&
          airMassData  !== undefined ?(
            <h2>
              {Number(airMassData?.average).toFixed(2)}
              <span> {airMassData?.defaultUom}</span>
            </h2>
          ) : (
            <h2>-</h2>
          )}
        </div>
      </div>
    </>
  );
}

export default AIR;

/* eslint-disable @typescript-eslint/no-unused-vars */
import * as R from "ramda";
import AssetDataUtil from "./AssetDataUtil";

export default class AssetFilterUtil {
  HIER_FILTERS = ["region", "city", "cluster", "facility"];
  NON_HIER_FILTERS = ["status", "assetType", "compliance"];

  // type can be single or multiple
  applyFilter(assetFilters: any, title: any, id: any, isSingleFilter: any) {
    // console.log("assetFilters gotten is::", assetFilters);
    if (!isSingleFilter) {
      if (this.HIER_FILTERS.includes(title)) {
        assetFilters = this.adjustFilterOptionVisibility(
          assetFilters,
          title,
          true
        );
      }
      this.checkEmptyDropdowns(assetFilters);
      assetFilters = this.checkIsHierFilterApp(assetFilters);
    }

    const filterDesc: any = this.getFilterDescs(assetFilters);

    const respObj: any = {
      assetFilters,
      filterDesc,
    };

    return respObj;
  }

  updateMonitoredAssetTypes(assetFilters: any, assetCountByType: any) {
    let transformedArr: {
      name: any;
      id: any;
      groupId: any;
      isSelected: boolean;
      isVisible: boolean;
    }[] = [];

    // eslint-disable-next-line array-callback-return
    assetCountByType.map((assetTypeObj: any) => {
      transformedArr.push({
        name: assetTypeObj.assetType,
        id: assetTypeObj.assetTypeId,
        groupId: "assetType",
        isSelected: false,
        isVisible: true,
      });
    });

    transformedArr.unshift({
      name: "All",
      id: "All",
      groupId: "assetType",
      isSelected: true,
      isVisible: true,
    });

    assetFilters.assetType = transformedArr;
    return assetFilters;
  }

  getFilterDescs(assetFilters: any) {
    const filterDescResp: {
      titleInit: boolean;
      filterDescs: {
        status: string;
        assetType: string;
        compliance: string;
        region: string;
        city: string;
        cluster: string;
        facility: string;
      };
    } = {
      titleInit: true,
      filterDescs: {
        status: "",
        assetType: "",
        compliance: "",
        region: "",
        city: "",
        cluster: "",
        facility: "",
      },
    };

    // console.log("assetFilters are::", assetFilters);

    let descStr: any = "";
    this.HIER_FILTERS.forEach((filterStr: string) => {
      // console.log("HIER FILTER IS::", filterStr);

      if (assetFilters[filterStr].length > 1) {
        const selectFiltArr = assetFilters[filterStr]
          .filter((obj: any) => obj.isSelected)
          .map((obj: any) => obj.name);

        // console.log("selectFiltArr::", selectFiltArr);

        if (selectFiltArr.includes("All")) {
          const visibleFiltStr = assetFilters[filterStr]
            .filter((obj: any) => obj.isVisible && obj.id !== "All")
            .map((obj: any) => obj.name);

          let baseStr: string;

          baseStr = this.addEllipsis(visibleFiltStr[0], 15);

          descStr =
            visibleFiltStr.length === 1
              ? baseStr
              : baseStr + " + " + (visibleFiltStr.length - 1).toString();
        } else {
          const baseStr = this.addEllipsis(selectFiltArr[0], 15);

          descStr =
            selectFiltArr.length === 1
              ? baseStr
              : baseStr + " + " + (selectFiltArr.length - 1).toString();
        }

        filterDescResp.filterDescs[filterStr] = descStr;
      } else {
        filterDescResp.filterDescs[filterStr] = filterStr;
      }
    });

    this.NON_HIER_FILTERS.forEach((filterStr: string) => {
      if (assetFilters[filterStr].length > 1) {
        const selectFiltArr = assetFilters[filterStr]
          .filter((obj: any) => obj.isSelected)
          .map((obj: any) => obj.name);

        // console.log("selectFiltArr::", selectFiltArr);

        if (selectFiltArr.includes("All")) {
          const visibleFiltStr = assetFilters[filterStr]
            .filter((obj: any) => obj.isVisible && obj.id !== "All")
            .map((obj: any) => obj.name);

          let baseStr: string;

          if (visibleFiltStr.length === 0) {
            baseStr = filterStr;
            descStr = filterStr;
          } else {
            baseStr = this.addEllipsis(visibleFiltStr[0], 15);

            descStr =
              visibleFiltStr.length === 1
                ? baseStr
                : baseStr + " + " + (visibleFiltStr.length - 1).toString();
          }
        } else {
          const baseStr = this.addEllipsis(selectFiltArr[0], 15);

          descStr =
            selectFiltArr.length === 1
              ? baseStr
              : baseStr + " + " + (selectFiltArr.length - 1).toString();
        }
        filterDescResp.filterDescs[filterStr] = descStr;
      } else {
        filterDescResp.filterDescs[filterStr] = filterStr;
      }
    });
    return filterDescResp;
  }

  addEllipsis(text: string, length: number) {
    console.log("the text",length,text)
    if (!R.isEmpty(text) && !R.isNil(text) && text?.length > length) {
      text = text.substring(0, length) + "...";
    }
  
    return text;
  }

  updateAssetTypes(uniqAssetTypes: any) {
    const currAssetTypes = localStorage.getItem("@assetTypes")!;
    // console.log("currAssetTypes::", currAssetTypes);
  }

  applyNonHierFilterApp(assetFilters: any, assets: any) {
    this.NON_HIER_FILTERS.forEach((title: any) => {
      let selectedArr: any = [];

      if (assetFilters[title][0].isSelected) {
        selectedArr = assetFilters[title]
          .filter((obj: any) => !obj.isSelected)
          .map((obj: any) => obj.id);
      } else {
        selectedArr = assetFilters[title]
          .filter((obj: any) => obj.isSelected)
          .map((obj: any) => obj.id);
      }

      assets.forEach((assetObj: any) => {
        if (
          title === "status" &&
          !selectedArr.includes(assetObj.fixedAssetAssocStatusId)
        ) {
          assetObj.isVisible = false;
        }

        if (
          title === "assetType" &&
          !selectedArr.includes(assetObj.assetTypeDesc)
        ) {
          assetObj.isVisible = false;
        }
      });
    });

    const visibleAssets = assets.filter((obj: any) => obj.isVisible);
    const invisibleAssets = assets.filter((obj: any) => !obj.isVisible);
    // console.log("invisibleAsset::", invisibleAssets);

    return visibleAssets;
  }

  checkIsHierFilterApp(assetFilters: any) {
    const hiddenFacilities = assetFilters.facility.filter(
      (obj: any) => !obj.isVisible
    );

    // Filtering through Facilities
    // console.log("hiddenFac::", hiddenFacilities);
    if (hiddenFacilities.length === 0) {
      // There are no hidden facilities

      const selectedFacilities = assetFilters.facility
        .filter((obj: any) => obj.isSelected && obj.id !== "All")
        .map((obj: any) => obj.id);
      if (!R.isEmpty(selectedFacilities)) {
        assetFilters.facIds = selectedFacilities;
      } else {
        assetFilters.facIds = [];
      }
    } else {
      if (assetFilters.facility[0].isSelected) {
        // "All" is selected
        assetFilters.facIds = assetFilters.facility
          .filter((obj: any) => !obj.isSelected && obj.isVisible)
          .map((obj: any) => obj.id);
      } else {
        // "Facility aprat from 'All' is selected"
        assetFilters.facIds = assetFilters.facility
          .filter((obj: any) => obj.isSelected && obj.isVisible)
          .map((obj: any) => obj.id);
      }
    }

    // Filtering through Health Status
    const selectedHealthStatusIds = assetFilters.status
      .filter((obj: any) => obj.isSelected && obj.id !== "All")
      .map((obj: any) => obj.id);
    if (!R.isEmpty(selectedHealthStatusIds)) {
      assetFilters.healthStatusIds = selectedHealthStatusIds;
    } else {
      assetFilters.healthStatusIds = [];
    }

    // Filtering through Compliance
    const complianceIds = assetFilters.compliance
      .filter((obj: any) => obj.isSelected && obj.id !== "All")
      .map((obj: any) => obj.id);
    if (!R.isEmpty(complianceIds)) {
      assetFilters.complianceIds = complianceIds;
    } else {
      assetFilters.complianceIds = [];
    }

    // Filtering through Monitored Asset Types
    const monAssetTypeIds = assetFilters.assetType
      .filter((obj: any) => obj.isSelected && obj.id !== "All")
      .map((obj: any) => obj.id);
    if (!R.isEmpty(monAssetTypeIds)) {
      assetFilters.monitoredAssetTypeIds = monAssetTypeIds;
    } else {
      assetFilters.monitoredAssetTypeIds = [];
    }

    // console.log("assetFilters::", assetFilters);
    return assetFilters;
  }

  checkEmptyDropdowns(assetFilters: any) {
    this.HIER_FILTERS.forEach((filterStr: string) => {
      const isEmptyArr = assetFilters[filterStr].filter(
        (obj: any) => obj.isSelected
      );
      if (isEmptyArr.length === 0) {
        assetFilters[filterStr][0].isSelected = true;
      }
    });

    this.NON_HIER_FILTERS.forEach((filterStr: string) => {
      const isEmptyArr = assetFilters[filterStr].filter(
        (obj: any) => obj.isSelected
      );
      if (isEmptyArr.length === 0) {
        assetFilters[filterStr][0].isSelected = true;
      }
    });
    return assetFilters;
  }

  adjustFilterOptionVisibility(
    assetFilters: any,
    title: string,
    isMultiple: boolean
  ) {
    /* Region -> City -> Cluster -> Facility */
    /* The group ID of the the value at the start of the array is the same as the id of the subsequent value in the array */
    /* There are two parts to this. One is toggling visibility based on the parentCateg. And the second is toggling isSelected */

    let index = this.HIER_FILTERS.indexOf(title);

    if (isMultiple) {
      while (
        index <= this.HIER_FILTERS.length - 2 &&
        index !== this.HIER_FILTERS.length - 1
      ) {
        const parentCateg = this.HIER_FILTERS[index];
        const childCateg = this.HIER_FILTERS[index + 1];

        // Get array of all the parentIds
        const parentIdArr: any = [];

        // console.log("assetfilters in adjustoptionvisible::", assetFilters);

        if (assetFilters[parentCateg][0].isSelected) {
          // All is selected

          assetFilters[parentCateg].forEach(
            (eleParent: any, indexParent: number) => {
              if (
                indexParent !== 0 &&
                eleParent.isVisible &&
                !parentIdArr.includes(eleParent.id)
              ) {
                parentIdArr.push(eleParent.id);
              }
            }
          );
        } else {
          assetFilters[parentCateg].forEach(
            (eleParent: any, indexParent: number) => {
              if (
                eleParent.isSelected &&
                eleParent.isVisible &&
                !parentIdArr.includes(eleParent.id)
              ) {
                parentIdArr.push(eleParent.id);
              }
            }
          );
        }

        // Iterate through child and see if the group Id of child exists in the parentArr
        assetFilters[childCateg].forEach((eleChild: any) => {
          if (parentIdArr.includes(eleChild.groupId) || eleChild.id === "All") {
            eleChild.isVisible = true;
          } else {
            eleChild.isVisible = false;
          }
        });

        index += 1;
      }
    } else {
      while (
        index <= this.HIER_FILTERS.length - 2 &&
        index !== this.HIER_FILTERS.length - 1
      ) {
        const parentCateg = this.HIER_FILTERS[index];
        const childCateg = this.HIER_FILTERS[index + 1];

        // Get array of all the parentIds
        const parentIdArr: any = [];

        // console.log("assetfilters in adjustoptionvisible::", assetFilters);
        assetFilters[parentCateg].forEach(
          (eleParent: any, indexParent: number) => {
            if (
              eleParent.isSelected &&
              eleParent.isVisible &&
              !parentIdArr.includes(eleParent.id)
            ) {
              parentIdArr.push(eleParent.id);
            }
          }
        );

        // Iterate through child and see if the group Id of child exists in the parentArr
        assetFilters[childCateg].forEach((eleChild: any) => {
          if (parentIdArr.includes(eleChild.groupId) || eleChild.id === "All") {
            eleChild.isVisible = true;
          } else {
            eleChild.isVisible = false;
          }
        });

        index += 1;
      }
    }

    return assetFilters;
  }

  async getCategoryList(categName: any) {
    const categDataStr = await localStorage.getItem(categName);

    const categData = JSON.parse(categDataStr);

    const dataTransform = new AssetDataUtil();
    const transOpts = dataTransform.transformCategData(categData, categName);
    return transOpts;
  }

  async getClearAssetFilters(assetFilters: any) {
    // console.log("assetFilters::", assetFilters);
    const cities = await this.getCategoryList("cityIds");
    const regions = await this.getCategoryList("regionIds");
    const clusters = await this.getCategoryList("clusterIds");
    const facilities = await this.getCategoryList("facilityIds");
    const assetTypes = await this.getCategoryList("@assetTypes");

    const status = assetFilters.status.map((obj: any) => {
      if (obj.id === "All") {
        return {
          ...obj,
          isSelected: true,
          isVisible: true,
        };
      } else {
        return {
          ...obj,
          isSelected: false,
          isVisible: true,
        };
      }
    });

    const state = assetFilters.state.map((obj: any) => {
      if (obj.id === "All") {
        return {
          ...obj,
          isSelected: true,
          isVisible: true,
        };
      } else {
        return {
          ...obj,
          isSelected: false,
          isVisible: true,
        };
      }
    });

    const compliance = assetFilters.compliance.map((obj: any) => {
      if (obj.id === "All") {
        return {
          ...obj,
          isSelected: true,
          isVisible: true,
        };
      } else {
        return {
          ...obj,
          isSelected: false,
          isVisible: true,
        };
      }
    });

    // console.log("Status is::", status);

    const assetFilterObj = {
      dataLoaded: true,
      facIds: [],
      healthStatusIds: [],
      monitoredAssetTypeIds: [],
      status: status,
      type: assetFilters.type,
      compliance: compliance,
      facility: facilities,
      region: regions,
      city: cities,
      cluster: clusters,
      assetType: assetTypes,
      state: state,
    };

    return assetFilterObj;
  }
}

import React from "react";

export class DateFormat {
  localDateFormat(date: string) {
    if(date === ""){
        return date
    }else {
        const inputDate = new Date(date);
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, "0");
        const day = String(inputDate.getDate()).padStart(2, "0");
        const hours = String(inputDate.getHours()).padStart(2, "0");
        const minutes = String(inputDate.getMinutes()).padStart(2, "0");
        const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
        return formattedDate;
    }
  }

  get365DaysAgo = () => {
    const today = new Date();
    const pastDate = new Date(today.setDate(today.getDate() - 365));
    return pastDate;
  };

  getMaxDate = (date:any) => {
    let inputDate = date === "" ? new Date() : new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
    return formattedDate;
  };

  getMinDate = (minDate: number) => {
    const today = new Date();
    const pastDate = new Date(today.setDate(today.getDate() - minDate));
    const inputDate = new Date(pastDate);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const day = String(inputDate.getDate()).padStart(2, "0");
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
    return formattedDate;
  };

  padNumber = (number:number) => {
    return number.toString().padStart(2, '0');
  };

   // Set the formatted date to state
  formatDateTime = (value:string | number) => {
    if(value === "") return ""
    else {
      const date = new Date(value);
      const formattedDate = `${this.padNumber(date.getDate())}/${this.padNumber(date.getMonth() + 1)}/${date.getFullYear()} ${this.padNumber(date.getHours())}:${this.padNumber(date.getMinutes())}`;
      return formattedDate;
    }
  };

  //get time into milliseconds
  timeConvertedintomilliSeconds = (time:string) => {
    const currentDateUTC = new Date();
    const currentDate = new Date(currentDateUTC.toLocaleString('en-US', { timeZone: 'UTC' }));
    const [hours, minutes] = time.split(':').map(Number);
    currentDate.setUTCHours(hours);
    currentDate.setUTCMinutes(minutes);
    currentDate.setUTCSeconds(0);
    currentDate.setUTCMilliseconds(0);
    // Convert the date to epoch time
    let epochTime = currentDate.getTime();
    // Subtract 5 hours and 30 minutes
    epochTime -= (5 * 60 + 30) * 60 * 1000;
    return epochTime
  };
}

export default DateFormat;

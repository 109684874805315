import * as R from "ramda";
import { useMemo } from "react";
import useState from "react-usestateref";

const useGetPortletByPageId = (key: any) => {
  const [data, setData,dataRef] = useState<any>(null);
  const getData = async () => {
    const localStorageLoginData = localStorage.getItem("aoneLoginData");
    if (!R.isEmpty(localStorageLoginData) &&!R.isNil(localStorageLoginData)) {
          const parsedData = JSON.parse(localStorageLoginData);
          console.log(parsedData, 'portal')
          const portalPageIds = await parsedData?.loginDetails[0]?.portalDetails.flatMap((page: { portlets: any[]; }) => page.portlets.map((portlet: { portalPortletId: any; }) => portlet.portalPortletId));
        
          setData(portalPageIds);
    }
  };

  useMemo(() => {
    getData();
  }, [key]);

 
  return [dataRef.current];
};

export default useGetPortletByPageId;

import { color_d } from "echarts/types/dist/shared";
import React from "react";
import "./LoaderStyle.scss";

function LoaderStyle() {
  return (
    <div className="loaderSnippet" >
      <div className="snippet" data-title=".dot-typing">
        <div className="stage">
          <div className="dot-typing"></div>
        </div>
      </div>
      <div className="scrolling-words-container">
        <div className="scrolling-words-box">
          <ul>
            <li>Processing your data one bit at a time</li>
            <li>Hold on as we speak to your assets</li>
            <li>Analysing your data</li>
            <li>We’re almost there!</li>
            <li>Processing your data one bit at a time</li>
          </ul>
        </div>
        <span></span>
      </div>
    </div>
  );
}

export default LoaderStyle;

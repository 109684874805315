import aoneIcon from "../../assets/icons/aone-logo.svg";
import atsuyaLogoName from "../../assets/icons/atsuya-logo-name.svg";
import sosImage from "../../assets/icons/sos-logo.svg";
import "../LoginPage/LoginPageStyles.scss";

const LoginSubLayout = () => {
  return (
    <div className="subclassTwo  col-lg-8">
        <div className="headContainer">
            <img src={aoneIcon} alt="icon" loading="lazy" />
            </div>
              <div className="middleContainer">
                <div>
                  <h2>Adding minutes to the planet</h2>
                  <p> Integrated AI platform for operational excellence with an eye on sustainability. </p>
                </div>
              </div>
              <div className="buttomContainer">
                <div className="sosimgClss">
                  <img src={sosImage} alt="icon" loading="lazy" />
                </div>
                <div className="atsuyaClass">
                  <img src={atsuyaLogoName} alt="icon" loading="lazy" />
                </div>
              </div>
            </div>
  )
}

export default LoginSubLayout